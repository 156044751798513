import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
    ".btn .loader-wrap": {
      marginRight: 5,
      width: "auto",
      ".loader": {
        position: "relative",
        transform: "initial",
        ".lds-ring .ring-item": {
          borderColor: "#fff transparent transparent transparent"
        },
        "&.u-loader-xs": {
          top: 3,
        }
      }
    }
  }
}))
