import { useTheme } from '@mui/material'
import { Stack, SliderComponent } from '../../../components'
import { Box, Grid, Typography, Input, CircleLoader, Skeleton } from '@ntpkunity/controls'
import { FC, useContext, useEffect, useState } from 'react'
import { FinanceTypes, PaymentTabs, QueryKeys } from '../../../constants'
import { Term } from "../terms/term"
import { Mileage } from "../mileages/mileage"
import { CreditScore } from "../credit-scores/credit-score"
import { IVehicle } from 'interfaces'
import { Controller, useForm } from 'react-hook-form'
import { Apr } from '../apr/apr'
import { ActionType, OrderActionType, useOrderContext, usePaymentContext } from 'pages'
import { useQueryClient } from 'react-query'
import { AppContext } from '@app/context-provider'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { programData, quotationData, updateDownPayment, updateDueAtSigning } from 'pages/(order-management)/order/order-context-provider'
import {useParams} from "react-router-dom";

interface IFormInputs {
  down_payment: number
  monthly_payment: number
}

export const TabContent: FC<{
  program: any
  isLoading: boolean
  setupDataLoading: boolean
}> = ({
  program,
  isLoading,
  setupDataLoading
}) => {
    const theme = useTheme()
    const queryClient = useQueryClient();
    const { state, dispatch } = usePaymentContext()
    const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
    const { dispatch: pageDispatch } = useJourneyLayoutContext()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders
    const [downPaymentRange, setDownPaymentRange] = useState<any>()
    const { vin } = useParams()
    const setupData: any = queryClient.getQueryData(
      QueryKeys.GET_SETUP_DATA
    );
    const vehicle: IVehicle = queryClient.getQueryData(
      [QueryKeys.VEHICLE, vin]
    );

    const { control, setValue, setError, clearErrors, formState: { errors } } = useForm<IFormInputs>({
      defaultValues: {
        down_payment: orderState.down_payment,
        monthly_payment: orderState.estimated_monthly_payment
      }
    })

    useEffect(() => {
      clearErrors()
      setValue("down_payment", orderState.down_payment)
      setDownPaymentRange({
        minDownPayment: (program?.downpayment_chart?.[0]?.minimum_down_payment * vehicle?.internet_price) / 100,
        maxDownPayment: (program?.downpayment_chart?.[0]?.maximum_down_payment * vehicle?.internet_price) / 100,
        downPayment: orderState.down_payment
      })
    }, [program, orderState.down_payment])

    useEffect(() => {
      setValue("monthly_payment", orderState.estimated_monthly_payment)

    }, [orderState.estimated_monthly_payment])

    const calculateQuotation = (value) => {
      const downPayment = typeof value == 'string' ? parseFloat(value?.replace(/,/g, '')) : value;
      if (downPayment < downPaymentRange.minDownPayment) {
        setError("down_payment", {
          message: `${PLACEHOLDERS.PAYMENTS_VALUE_CANNOT_BE_LESS_THAN} ${downPaymentRange?.minDownPayment?.toLocaleString("en-US", {
            style: "currency",
            currency: appState.state.default_currency_code || "USD",
          })}`
        })
      } else if (downPayment > downPaymentRange.maxDownPayment) {
        setError("down_payment", {
          message: `${PLACEHOLDERS.PAYMENTS_VALUE_CANNOT_BE_GREATER_THAN} ${downPaymentRange?.maxDownPayment?.toLocaleString("en-US", {
            style: "currency",
            currency: appState.state.default_currency_code || "USD",
          })}`
        })
      } else {
        orderDispatch(updateDownPayment(downPayment))
      }
    }

    useEffect(() => {
      if (Object.keys(errors)?.length) {
        pageDispatch({
          type: pagesActionType.UPDATE_PAYMENTS,
          payload: {
            success: false,
            error: true
          }
        })
      }
    }, [errors?.down_payment])

    useEffect(() => {
      if (orderState?.estimated_monthly_payment) {
        pageDispatch({
          type: pagesActionType.UPDATE_PAYMENTS,
          payload: {
            success: true,
            error: false
          }
        })
      }
    }, [orderState.estimated_monthly_payment])

    useEffect(() => {
    }, [program])

    return (
      <>
        <Stack>
          <Box theme={theme} className='slider-ph' textAlign={'center'} mb={8}>
            {(isLoading || setupDataLoading) ? <Skeleton theme={theme} width={145} height={32} sx={{ margin: 'auto' }} /> :
              <Typography theme={theme} variant='h3' component={'h3'}>
                {(orderState?.due_at_signing || 0)?.toLocaleString("en-US", {
                  style: "currency",
                  currency: appState?.state?.default_currency_code || 'USD',
                })}
              </Typography>
            }
            <Typography theme={theme} variant='body2' component={'span'} className='text-muted'>
              {PLACEHOLDERS.PAYMENT_DUE_AT_SIGNING_AMOUNT}
            </Typography>
          </Box>
          <Grid theme={theme} container item spacing={2} justifyContent={'center'} mt={3}>
            <Grid theme={theme} item xs={6} md={4}>
              <Box theme={theme} mb={2} className='slider-monthly' ml={2} mr={2}>
                {(isLoading || setupDataLoading) ? <Skeleton theme={theme} width={'100%'} height={34} /> :
                  <SliderComponent
                    value={orderState?.estimated_monthly_payment}
                    min={0}
                    max={orderState?.estimated_monthly_payment}
                    disabled={true}
                  />
                }
              </Box>
              {(isLoading || setupDataLoading) ? <Skeleton theme={theme} width={'100%'} height={48} /> :
                <Controller
                  name="monthly_payment"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      placeholder={'0.00'}
                      type="decimal"
                      label={PLACEHOLDERS.PAYMENT_MONTHLY_PAYMENT_FIELD_LABEL}
                      startAdornment={<span className="symbol">{appState.state.default_currency_symbol}</span>}
                      {...field}
                      masking
                      maskDecimalScale={2}
                      maskNumeric
                      disabled
                    />
                  )}
                />
              }
            </Grid>
            <Grid theme={theme} item xs={6} md={4}>
              <Box theme={theme} mb={2} className='slider-downpayment' ml={2} mr={2}>
                {(isLoading || setupDataLoading) ? <Skeleton theme={theme} width={'100%'} height={34} /> :
                  <SliderComponent
                    value={orderState.down_payment}
                    min={downPaymentRange?.minDownPayment}
                    max={downPaymentRange?.maxDownPayment}
                    onChangeCommitted={(value) => {
                      setValue("down_payment", value)
                      calculateQuotation(value)
                    }}
                    disabled={false}
                  />
                }
              </Box>
              {(isLoading || setupDataLoading) ? <Skeleton theme={theme} width={'100%'} height={48} /> :
                <Controller
                  name="down_payment"
                  control={control}
                  render={({ field }) => (
                    <Input
                      fullWidth
                      theme={theme}
                      placeholder={'0.00'}
                      type="decimal"
                      startAdornment={<span className="symbol">{appState.state.default_currency_symbol}</span>}
                      label={PLACEHOLDERS.PAYMENT_DOWN_PAYMENT_FIELD_LABEL}
                      {...field}
                      masking
                      maskDecimalScale={2}
                      maskNumeric
                      error={errors?.down_payment?.message}
                      onChange={(e) => {
                        field.onChange(e)
                        clearErrors()
                      }}
                      onBlur={(e) => calculateQuotation(e?.target?.value)}
                    />
                  )}
                />
              }
            </Grid>
          </Grid>
        </Stack>

        {/******************* Term *****************/}
        <Term terms={setupData?.['contract-terms']} setupDataLoading={setupDataLoading} />
        {/******************* Term *****************/}

        {/******************* Mileage OR APR *****************/}
        {orderState.finance_type == FinanceTypes.LEASE ?
          <Mileage mileages={setupData?.['contract-allowed-annum-mileage']} setupDataLoading={setupDataLoading} /> :
          <Apr apr={program?.final_customer_rate} setupDataLoading={setupDataLoading} />
        }
        {/******************* Term *****************/}

        {/******************* CreditScore *****************/}
        <CreditScore creditScores={setupData?.['credit-ratings']} setupDataLoading={setupDataLoading} />
        {/******************* Term *****************/}
      </>
    )
  }
