import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const VinPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.vin-popup-wrap': {
    '.product-img': {
        textAlign: 'center',
        marginTop: '75px',
        img: {
            width: '360px',
            height: '115px',
            objectFit: 'cover',
        },
    }
  }
}))
