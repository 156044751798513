import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PaymentStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.payment-card-wrap': {
        '.payment': {
            display: 'flex',
            alignItems: 'center',
            gap: 24,
            cursor: 'pointer',
            'svg': {
                minWidth: '24px'
            },
            '.MuiTypography-root': {
                [theme.breakpoints.down('md')]: {
                    width: '85%'
                }
            },
            '&:first-child': {
                'svg': {
                    path: {
                        stroke: theme.palette.primary.main
                    }
                }
            },
            '&.selected': {
                backgroundColor: '#333',
                '.MuiTypography-root': {
                    color: theme.palette.common.white
                },
                'svg': {
                    path: {
                        stroke: theme.palette.common.white
                    }
                }
            }
        }
    }
}))
