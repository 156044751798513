import { useEffect, useState } from 'react';
import { useTheme } from '@mui/material';
import { Box, Button, Grid, Input, Typography } from '@ntpkunity/controls';
import { Stack, Textbox, CjButton } from '@components';
import { MilesPopupWrap } from './miles-popup.style';
import { PaymentType } from 'constants/enums';
import { Controller } from 'react-hook-form';
import { useAppContext } from "@app/context-provider";

export const MilesPopup = (props) => {
    const theme = useTheme()
    const { control, messages, errors, setValue, getValues, unregister } = props;
    const values = getValues()
    const { payment_type, year, make, model, trim, loan_lease_balance, is_customer_titled } = values
    const [loanPaymentStatus, setLoanPaymentStatus] = useState(loan_lease_balance || false)
    const [loanType, setLoanType] = useState(payment_type || PaymentType.Finance);
    const [customerType, setCustomerType] = useState(is_customer_titled || false);
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders

    const handleLoanPaymentStatus = (value) => {

        if (!value) {
            setValue(messages.name.PaymentType, null)
            setValue(messages.name.CustomerTitles, null)
            unregister(messages.name.LenderName)
            setValue(messages.name.LenderName, null)
            unregister(messages.name.ContractNumber)
            setValue(messages.name.ContractNumber, null)
            unregister(messages.name.PayOffAmount)
            setValue(messages.name.PayOffAmount, null)
        }
        setValue(messages.name.LoanLeaseBalance, value)
        setLoanPaymentStatus(value)
    }

    useEffect(() => {
        setValue(messages.name.CustomerTitles, is_customer_titled)
        setValue(messages.name.PaymentType, payment_type || PaymentType.Finance)

    }, [])

    const handleLoanType = (value) => {
        setValue(messages.name.PaymentType, value);
        setLoanType(value);
    };

    const handleCustomerType = (value) => {
        setValue(messages.name.CustomerTitles, value);
        setCustomerType(value);
    };

    return (
        <MilesPopupWrap theme={theme} className='miles-popup-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3}>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>
                    {PLACEHOLDERS.TRADE_IN_MILES_POPUP_MILES_PROMPT_LABEL} {year} {make} {model} {trim ? trim : ''}{PLACEHOLDERS.QUESTION_MARK_TEXT}
                </Typography>
                <Textbox>
                    <Controller
                        name={messages.name.Miles}
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                            <Input
                                type={'text'}
                                theme={theme}
                                maskNumeric
                                masking
                                maskDecimalScale={0}
                                placeholder='0'
                                endAdornment={
                                    <>
                                        <Typography theme={theme} component={'p'} className='text-muted' variant='body1'>{appState?.mileage_unit}</Typography>
                                    </>
                                }
                                {...field}
                                fullWidth
                                error={errors?.[messages.name.Miles] ? messages.validation['Miles'][errors?.[messages.name.Miles].type] : false}

                            />
                        )}

                    />
                </Textbox>
            </Stack>
            <Stack bgLight paddingXs={3} paddingMd={3} marginTopXs={2} marginTopMd={2}>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={1}>
                    {PLACEHOLDERS.TRADE_IN_MILES_POPUP_TITLE_QUERY}
                </Typography>
                <Box theme={theme} className='action-btn'>
                    <CjButton>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={3}
                            columnSpacing={1}
                        >
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    primary={!customerType}
                                    secondary={customerType}
                                    text={PLACEHOLDERS.TRADE_IN_MILES_POPUP_TITLE_YES}
                                    fullWidth
                                    onClick={() => handleCustomerType(true)}
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    primary={customerType}
                                    secondary={!customerType}
                                    text={PLACEHOLDERS.TRADE_IN_MILES_POPUP_TITLE_NO}
                                    fullWidth onClick={() => { handleCustomerType(false) }}
                                />
                            </Grid>
                        </Grid>
                    </CjButton>
                </Box>
            </Stack>
            <Stack bgLight paddingXs={3} paddingMd={3} marginTopXs={2} marginTopMd={2}>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={1}>
                    {PLACEHOLDERS.TRADE_IN_MILES_POPUP_PAYMENTS_PROMPT_LABEL}
                </Typography>
                <Box theme={theme} className='action-btn'>
                    <CjButton>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={3}
                            columnSpacing={1}
                        >
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    primary={!loanPaymentStatus}
                                    secondary={loanPaymentStatus}
                                    text={PLACEHOLDERS.TRADE_IN_MILES_POPUP_PAYMENTS_YES_OPTION}
                                    fullWidth

                                    onClick={() => { handleLoanPaymentStatus(true) }}
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    primary={loanPaymentStatus}
                                    secondary={!loanPaymentStatus}
                                    text={PLACEHOLDERS.TRADE_IN_MILES_POPUP_PAYMENTS_NO_OPTION}
                                    fullWidth onClick={() => { handleLoanPaymentStatus(false) }}
                                />
                            </Grid>
                        </Grid>
                    </CjButton>
                </Box>

                {loanPaymentStatus ? <>

                    <Typography theme={theme} component={'p'} variant='subtitle1' sx={{ mt: 3, mb: 1 }}>{PLACEHOLDERS.TRADE_IN_MILES_POPUP_LOAN_TYPE_PROMPT_LABEL}</Typography>
                    <CjButton>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={2}
                            columnSpacing={1}
                            sx={{ mb: 3 }}
                        >
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    primary={loanType !== PaymentType.Lease}
                                    secondary={loanType === PaymentType.Lease}
                                    text={PLACEHOLDERS.TRADE_IN_MILES_POPUP_LOAN_TYPE_LEASE_OPTION}
                                    fullWidth
                                    onClick={() => handleLoanType(PaymentType.Lease)}
                                />
                            </Grid>
                            <Grid item xs={6} theme={theme}>
                                <Button
                                    theme={theme}
                                    secondary={loanType === PaymentType.Finance}
                                    primary={loanType !== PaymentType.Finance}
                                    text={PLACEHOLDERS.TRADE_IN_MILES_POPUP_LOAN_TYPE_FINANCE_OPTION}
                                    fullWidth
                                    onClick={() => handleLoanType(PaymentType.Finance)}
                                />
                            </Grid>
                        </Grid>
                    </CjButton>
                    <Grid
                        theme={theme}
                        container
                        rowSpacing={3}
                        columnSpacing={3}
                    >
                        <Grid theme={theme} item xs={12}>
                            <Controller
                                control={control}
                                name={messages.name.LenderName}
                                rules={{ required: true }}
                                render={
                                    ({ field }) => (
                                        <Textbox>
                                            <Input
                                                type={'text'}
                                                fullWidth
                                                theme={theme}
                                                label={messages.label.LenderName}
                                                placeholder={PLACEHOLDERS.TRADE_IN_MILES_POPUP_TYPE_HERE_PLACEHOLDER}
                                                error={errors?.[messages.name.LenderName] ? messages.validation['LenderName'][errors?.[messages.name.LenderName].type] : false}
                                                {...field}
                                            />
                                        </Textbox>
                                    )}
                            />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                            <Controller
                                control={control}
                                name={messages.name.ContractNumber}
                                rules={{ required: true }}
                                render={
                                    ({ field }) => (
                                        <Textbox>
                                            <Input
                                                type={'text'}
                                                fullWidth
                                                theme={theme}
                                                label={messages.label.ContractNumber}
                                                placeholder={PLACEHOLDERS.TRADE_IN_MILES_POPUP_TYPE_HERE_PLACEHOLDER}
                                                error={errors?.[messages.name.ContractNumber] ? messages.validation['ContractNumber'][errors?.[messages.name.ContractNumber].type] : false}
                                                {...field}
                                            />
                                        </Textbox>
                                    )}
                            />
                        </Grid>
                        <Grid theme={theme} item xs={12}>
                            <Controller
                                control={control}
                                name={messages.name.PayOffAmount}
                                rules={{ required: true }}
                                render={
                                    ({ field }) => (
                                        <Textbox>
                                            <Input
                                                type='text'
                                                fullWidth
                                                theme={theme}
                                                label={messages.label.PayOffAmount}
                                                maskNumeric
                                                masking
                                                maskDecimalScale={0}
                                                startAdornment={<span className="symbol">{appState.default_currency_symbol}</span>}
                                                placeholder='0'
                                                {...field}
                                                error={errors?.[messages.name.PayOffAmount] ? messages.validation['PayOffAmount'][errors?.[messages.name.PayOffAmount].type] : false}
                                            />
                                        </Textbox>
                                    )}
                            />
                        </Grid>

                    </Grid>
                </> :
                    false
                }
            </Stack>
        </MilesPopupWrap>
    );
};
