import { useTheme } from '@mui/material'
import { Typography, Box, Icon } from '@ntpkunity/controls'
import { Stack } from '@components'
import { DealershipCardDetailWrap } from './dealership-card-detail.style'
import { DealershipDetail } from './dealership-card-detail.props'
import clsx from 'clsx'
import React, { useContext } from 'react'
import { AppContext } from '@app/context-provider'

export const DealershipCardDetail = ({ items, noDealershipTitle, noDealershipDetail }: DealershipDetail) => {
    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders;

    return (
        <>
            <DealershipCardDetailWrap className='dealership-card-detail-wrap' theme={theme}>
                {items?.map((item, index) => (
                    <Stack bgLight key={index} className={clsx({ 'dealer': true, 'selected': item.selected })} paddingXs={3} paddingMd={3} marginTopXs={2}>
                        <Box data-testId={index} theme={theme} onClick={item.onClick} >
                            {item.title &&
                                <Typography theme={theme} variant='subtitle1' component={'p'}>{item.title}</Typography>
                            }
                            {item.subtitle &&
                                <Typography theme={theme} variant='caption' component={'small'} className='text-muted'>
                                    {item.subtitle}
                                </Typography>
                            }
                            {item.distance &&
                                <Box theme={theme} className='location'>
                                    <Icon name='LocationIcon' />
                                    <Typography theme={theme} variant='body2' component={'p'} className='text-muted'>
                                        {item.distance} {PLACEHOLDERS.ZIP_CODE_MILES_AWAY}
                                    </Typography>
                                </Box>
                            }
                        </Box>
                    </Stack>
                ))}
                {noDealershipTitle &&
                    <Stack bgLight className='no-dealership' paddingXs={3} paddingMd={3} marginTopXs={2}>

                        <Typography theme={theme} variant='subtitle1' component={'p'} mb={1}>{noDealershipTitle}</Typography>

                        {noDealershipDetail &&
                            <Typography theme={theme} variant='caption' component={'small'} className='text-muted'>
                                {noDealershipDetail}
                            </Typography>
                        }
                    </Stack>
                }

            </DealershipCardDetailWrap>

        </>
    )
}


