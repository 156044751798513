export const ConsentStatus = {
    PUBLISHED: "Published"
}

export const DisclaimerNames = {
    CCPA: 'CCPA',
    TCPA: 'TCPA',
    TERMS_AND_CONDITIONS: 'TERMS_AND_CONDITIONS',
    PAYMENT_CONSENT: 'PAYMENT_CONSENT'
}
export const accessToken = 'settings'