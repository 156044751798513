import { FileUploader, Stack } from '@components';
import { useTheme } from '@mui/material';
import { Typography, Box, FileDragDrop, FilePreview, FileUpload, FileUploadPreview } from '@ntpkunity/controls';
import React, { useEffect, useState } from 'react';
import { Controller } from "react-hook-form";
import { getFileExtension } from "../../../helpers/methods";
import { useAppContext } from '@app/context-provider';
import { compressImage, readFileAsDataURL, base64ToFile, openInNextWindowTradeIn  } from '../../../helpers/methods';

export const UploadImagePopup = (props) => {
    const theme = useTheme();
    const { state: appState } = useAppContext();
    const PLACEHOLDERS = appState.language.placeholders;
    const { control, messages, errors, setValue, getValues, clearErrors, trigger, watch } = props;
    const values = getValues();
    const { asset_image } = values;
    const images = watch(messages.name.AssetImage);
    const MAX_IMAGES = 10;

    const [imageFiles, setImageFiles] = useState(asset_image || []);

    useEffect(() => {
        if (asset_image?.length > 0) {
            const files = base64ToFile(asset_image);
            loadFilesForPreview(asset_image, files);
        }
    }, [asset_image]);

    const onRemoveFile = (idx) => {
        const _index = Number(idx);
        const newImagesArray = imageFiles.filter((_obj, index) => index !== _index);
        setImageFiles(newImagesArray);
        const formImages = getValues(messages.name.AssetImage).filter((_obj, index) => index !== _index);
        setValue(messages.name.AssetImage, formImages);
        clearErrors(messages.name.AssetImage);
        trigger(messages.name.AssetImage); 
    };

    const onDropImage = async (e) => {
        e.preventDefault();
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            const files = Array.from(e.dataTransfer.files);
            try {
                const compressedFiles = await Promise.all(files.map(file => compressImage(file)));
                addFilesForPreview(compressedFiles);
                clearErrors();
                trigger();
            } catch (error) {
                console.error('Error compressing files on drop:', error);
            }
        }
    };

    const onImageUpload = async (e) => {
        if (e.target.files && e.target.files.length > 0) {
            const files = Array.from(e.target?.files);
            try {
                const compressedFiles = await Promise.all(files.map(file => compressImage(file)));
                addFilesForPreview(compressedFiles);
                clearErrors();
                trigger();
            } catch (error) {
                console.error('Error compressing files on upload:', error);
            }
        }
    };

    const loadFilesForPreview = async (asset_image, files) => {
        const newImagesArray = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            try {
                newImagesArray.push({
                    document: asset_image[i],
                    content_type: file.type,
                    name: `${file.name}${getFileExtension(file.type)}`,
                    size: (file.size).toFixed(2),
                    created_by: 'null'
                });
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }
        setImageFiles(newImagesArray);
    };

    const addFilesForPreview = async (files) => {
        const newImagesArray = [];
        const newImages64Array = [];

        for (let i = 0; i < files.length; i++) {
            const file = files[i];

            try {
                const result = await readFileAsDataURL(file);
                newImages64Array.push(result);
                newImagesArray.push({
                    document: result,
                    content_type: file.type,
                    name: file.name,
                    size: (file.size).toFixed(2),
                    created_by: 'null'
                });
            } catch (error) {
                console.error('Error reading file:', error);
            }
        }

        setImageFiles([...imageFiles, ...newImagesArray]);
        setValue(messages.name.AssetImage, [...(images || []), ...newImages64Array]);
        trigger(messages.name.AssetImage);
    };

    return (
        <Stack bgLight paddingMd={4} paddingXs={3}>
            <Typography theme={theme} variant='subtitle1' component={'p'} mb={1}>{PLACEHOLDERS.TRADE_IN_IMAGE_UPLOAD_PROMPT}</Typography>
            <Typography theme={theme} variant='caption' component={'small'} className='text-muted'>
                {PLACEHOLDERS.TRADE_IN_IMAGE_UPLOAD_INSTRUCTIONS}
            </Typography>
            <Box theme={theme} mt={3}>
                <FileUploader>
                    <Controller
                        name={messages.name.AssetImage}
                        control={control}
                        defaultValue={undefined}
                        rules={{
                            required: true,
                            validate: {
                                maxFiles: (value) =>
                                    (value?.length || 0) <= MAX_IMAGES || `${PLACEHOLDERS.TRADE_IN_IMAGE_UPLOAD_MAX_LIMIT} ${MAX_IMAGES} ${PLACEHOLDERS.IMAGES_LOWERCASE_TEXT}${PLACEHOLDERS.DOT_TEXT}`,
                            },
                        }}
                        render={({ field }) => (
                            <FileUpload
                                {...field}
                                theme={theme}
                                width="300"
                                accept='.jpg, .jpeg, .png'
                                hoverLabel={
                                    <>{`${PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_1} `}<span className='text-primary'> {PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_2}</span></>
                                }
                                height="243px"
                                backgroundColor="white"
                                onDrop={onDropImage}
                                onChange={onImageUpload}
                                allowMultiple={true}
                                inlinePreview={false}
                                error={errors?.[messages.name.AssetImage] ? messages.validation['AssetImage'][errors?.[messages.name.AssetImage].type] : false}
                            />
                        )}
                    />
                    {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
                        <FileUploadPreview
                            files={imageFiles.map((image, index) => ({
                                key: `${index}`,
                                file: image,
                                onRemoveFile: () => onRemoveFile(index.toString()),
                                viewIcon: true,
                                onViewFile: () => openInNextWindowTradeIn(image?.document ? image?.document : false),
                                preview: 'fileIcon'
                            }))}
                            theme={theme}
                        />
                    )}
                    {errors[messages.name.AssetImage] && (
                        <Typography component={'p'} theme={theme} color="error" variant="caption">
                            {messages.validation['AssetImage'][errors?.[messages.name.AssetImage].type]}
                        </Typography>
                    )}
                </FileUploader>
            </Box>
        </Stack>
    );
};
