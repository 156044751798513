import { useTheme } from '@mui/material'
import { Button, Grid, Input, Select, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox } from '@components'
import React from 'react'
import { LicensePlatePopupWrap } from './license-plate-poup.style'
import { useAppContext } from '@app/context-provider'

export const LicensePlatePopup = () => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    
    return(
        <LicensePlatePopupWrap theme={theme} className='license-plate-popup-wrap has-header-footer'>
            <Stack bgLight className='license-plate-popup'>
                <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>{PLACEHOLDERS.TRADE_IN_LICENSE_PLATE_POPUP_PROMPT_LABEL}</Typography>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2 }}
                    sx={{ mb: 2 }}
                >
                    <Grid item xs={12} theme={theme}>
                    <Textbox>
                        <Input type={'text'} fullWidth theme={theme} placeholder={PLACEHOLDERS.TRADE_IN_LICENSE_PLATE_POPUP_INPUT_PLACEHOLDER} />
                    </Textbox>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <Textbox>
                        <Select
                            theme={theme}
                            label={PLACEHOLDERS.TRADE_IN_LICENSE_PLATE_POPUP_STATE_LABEL}
                            items={[
                                {text: "California", value: '1'},
                                {text: "US", value: '2'},
                                {text: "UK", value: '3'},
                            ]}
                        />
                        </Textbox>
                    </Grid>
                </Grid>
            </Stack>
        </LicensePlatePopupWrap>
    )
}


