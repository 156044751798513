import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AddDeliveryDetailPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.delivery-detail-wrap': {
    width: '480px',
    [theme.breakpoints.down('md')]:{
        width: 'auto',
    },
    '&.has-header-footer': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 'calc(100vh - 240px)',
        maxHeight: 512,
        '.stack-wrap': {
            '&:last-child': {
                flexGrow: 1
            }
        }
    },
    '.question-stack-wrap': {
        padding: '12px',
        borderRadius: '8px',
        '.stack-row': {
            '.action-area': {
                width: '100%',
                '.MuiFormGroup-root': {
                    '.u-switch-label': {
                        justifyContent: 'space-between',
                        '.MuiFormControlLabel-label': {
                            color: theme.palette.grey[900]
                        }
                    }
                }
            }
        }
    },
    '.custom-calendar-wrap': {
        marginTop: '24px'
    },
    '&.choose-time':{
        '.selection-button-wrap': {
            flexWrap: 'wrap',
            '.cj-btn': {
                width: '102px',
                [theme.breakpoints.down('md')]: {
                    width: '31%'
                }
            }
        },
    },
    '&.choose-map': {
        '.google-map': {
            iframe: {
                border: '1px solid',
                borderColor: theme.palette.grey[100],
                borderRadius: '8px'
            }
        },
        '.stack-wrap': {
            form: {
                '.MuiGrid-root': {
                    '.MuiGrid-grid-md-3': {
                        maxWidth: '50%',
                        flexBasis: '50%'
                    }
                }
            }
        }
    },
    '.delivery-preference': {
        '.selection-button-wrap': {
            flexDirection: 'column',
            gap: '16px',
            '.cj-btn': {
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '18px 24px',
                '.MuiTypography-caption': {
                    fontSize: '14px',
                    color: theme.palette.primary.main
                },
                '&.selected': {
                    '.MuiTypography-caption': {
                        color: theme.palette.common.white,
                    }
                }
            }
        },
    },
    '.pay': {
        '.selection-button-wrap': {
            gap: '8px',
            '.cj-btn': {
                width: '100%',
                flexDirection: 'row',
                justifyContent: 'space-between',
                padding: '10px 16px',
                '.MuiTypography-root': {
                    fontSize: '14px',
                    width: '100%',
                    textAlign: 'center'
                }
            }
        },
    }
  }
}))
