import {
    MASTER_CARD_REGEX_2_SERIES_2,
    MASTER_CARD_REGEX_2_SERIES_5, MASTER_CARD_REGEX_SERIES_2,
    MASTER_CARD_REGEX_SERIES_5, VISA_CARD_REGEX,
    VISA_CARD_REGEX_2
} from "./regex";
import {CardType, TradeInJourneySteps} from "../constants/enums";
import dayjs from "dayjs";

export const determineCardType = (event, setCardType): string | undefined => {
    switch (event.length) {
        case 0:
            return setCardType('')
        case 2:
            if (
                (MASTER_CARD_REGEX_2_SERIES_5.exec(event) != null) ||
                (MASTER_CARD_REGEX_2_SERIES_2.exec(event) != null)
            ) {
                return setCardType(CardType.MASTERCARD)
            } else if (VISA_CARD_REGEX_2.exec(event) != null) {
                return setCardType(CardType.VISA)
            }
            break
        case 16:
            if (
                (MASTER_CARD_REGEX_SERIES_5.exec(event) != null) ||
                (MASTER_CARD_REGEX_SERIES_2.exec(event) != null)
            ) {
                return setCardType(CardType.MASTERCARD)
            } else if (VISA_CARD_REGEX.exec(event) != null) {
                return setCardType(CardType.VISA)
            }
    }
}

export const scrollOnTop = (): void => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const convertToAmountWithDecimals = (
    value: number,
    currency = 'USD',
    minimumFractionDigits = 2,
    maximumFractionDigits = 2
): string => {
    const isNegative = value < 0;
    const absoluteValue = Math.abs(value);

    const formattedValue = absoluteValue.toLocaleString('en-US', {
        style: 'currency',
        currency,
        minimumFractionDigits,
        maximumFractionDigits
    });

    return isNegative ? `-${formattedValue}` : formattedValue;
}

export const getSubstring = (value: string, start: number, end: number): string =>
    value.substring(start, end);


export const mapTradeInType = (type) => {
    switch (type) {
        case "Manual Estimate":
            return TradeInJourneySteps.ProvideManualEstimatePopup;
        case "Make Model":
            return TradeInJourneySteps.MakeModelPopupOne;
        case "Vin":
            return TradeInJourneySteps.VinOnePopup;
        case "License Plate":
            return TradeInJourneySteps.LicensePlatePopup;
        default:
            return undefined;
    }
}

export const reverseMapTradeInType = (step) => {
    switch (step) {
        case TradeInJourneySteps.ProvideManualEstimatePopup:
            return "Manual Estimate";
        case TradeInJourneySteps.MakeModelPopupOne:
            return "Make Model";
        case TradeInJourneySteps.VinOnePopup:
            return "Vin";
        case TradeInJourneySteps.LicensePlatePopup:
            return "License Plate";
        default:
            return undefined;
    }
}

export const getBase64 = (file: any) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = function () {
        const result: string = reader.result as string
        const base64Data = result.split(',')[1] // Remove the data URL scheme
        resolve(base64Data)
      }
      reader.onerror = (error) => reject(error)
    })
  }


  export const calculateAdjustedTradeInAmount = (order, currencySymbol) => {
    const trade_in_amount = order?.order_tradein?.trade_in_amount ? order?.order_tradein?.trade_in_amount : 0;
    const payoff_amount = order?.order_tradein?.payoff_amount ? order?.order_tradein?.payoff_amount : 0;

    let trade_in_number = typeof trade_in_amount === 'number' ? trade_in_amount : parseInt(trade_in_amount);
    let payoff_number = typeof payoff_amount === 'number' ? payoff_amount : parseInt(payoff_amount);

    const adjustedTradeInAmount = payoff_number > 0 ? trade_in_number - payoff_number : trade_in_number;

    const isNegative = adjustedTradeInAmount < 0;
    const absoluteValue = Math.abs(adjustedTradeInAmount);

    const formattedValue = absoluteValue.toLocaleString(undefined, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
    });

    return isNegative ? `-${currencySymbol}${formattedValue}` : `${currencySymbol}${formattedValue}`;
}


export const convertToAmount = (value: number): string => value?.toLocaleString()
export const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0;
        if (c === 'x') {
            return r.toString(16);
        } else { // c === 'y'
            return (r & 0x3 | 0x8).toString(16);
        }
    });
};

export const openInNextWindow = (url: string): Window | null => window.open(url)


export const getFileExtension = (mimeType) => {
    const mimeTypes = {
        "image/jpeg": ".jpg",
        "image/png": ".png",
        "image/gif": ".gif",
    };

    return mimeTypes[mimeType] || "";
}

export const compressImage = (file, quality = 0.7) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);

        reader.onload = (event) => {
            const img = new Image();

            img.onload = () => {
                const canvas = document.createElement('canvas');
                const ctx = canvas.getContext('2d');
                const maxWidth = 800;
                const maxHeight = 800;
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > maxWidth) {
                        height = Math.round((height *= maxWidth / width));
                        width = maxWidth;
                    }
                } else {
                    if (height > maxHeight) {
                        width = Math.round((width *= maxHeight / height));
                        height = maxHeight;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        if (blob) {
                            const compressedFile = new File([blob], file.name, {
                                type: file.type,
                                lastModified: Date.now(),
                            });
                            resolve(compressedFile);
                        } else {
                            reject('Blob creation failed');
                        }
                    },
                    file.type,
                    quality
                );
            };

            img.onerror = (error) => reject(`Image loading error: ${error}`);

            if (event.target && event.target.result) {
                img.src = event.target.result as string;
            } else {
                reject('Failed to convert file to data URL.');
            }
        };

        reader.onerror = (error) => reject(`File reading error: ${error}`);
    });
};

export const readFileAsDataURL = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader();

        fileReader.onload = (event) => {
            if (event.target && event.target.result) {
                resolve(event.target.result.toString());
            } else {
                reject('Failed to read file as data URL.');
            }
        };

        fileReader.onerror = () => {
            reject('Error reading file as data URL.');
        };

        fileReader.readAsDataURL(file);
    });
};

export const base64ToFile = (base64Strings) => {
    return base64Strings
        .map((base64String, index) => {
            const matches = base64String.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/);

            if (!matches || matches.length !== 3) {
                console.error(`Invalid Base64 string format for element at index ${index}.`);
                return null;
            }
            const mime = matches[1];
            const bstr = atob(matches[2]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            return new File([u8arr], `trade-in-vehicle-${index + 1}`, { type: mime });
        })
        .filter((file) => file !== null);
};

export const openInNextWindowTradeIn = (base64Data) => {
    if (!base64Data) return;

    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: base64Data.split(',')[0].split(':')[1].split(';')[0] });
    const blobUrl = URL.createObjectURL(blob);

    window.open(blobUrl, '_blank');
};

export const formatUrl = (url) => {

    if (!url.startsWith('http://') && !url.startsWith('https://')) {
      return `https://${url}`;
    }
    return url;

  }

export const loadUpscopeScripts =  (key:string) => {

    // Remove any existing Upscope scripts
    const existingScripts = document.querySelectorAll('script[data-upscope="true"]');
    existingScripts.forEach(script => script.remove());

    // First script
    const upscopeInitScript = document.createElement('script');
    upscopeInitScript.type = 'text/javascript';
    upscopeInitScript.async = true;
    upscopeInitScript.setAttribute('data-upscope', 'true');
    upscopeInitScript.innerHTML = `
      (function(w, u, d) {
        var i = function() { i.c(arguments) };
        i.q = [];
        i.c = function(args) { i.q.push(args) };
        var l = function() {
          var s = d.createElement('script');
          s.type = 'text/javascript';
          s.async = true;
          s.src = 'https://code.upscope.io/${key}.js';
          var x = d.getElementsByTagName('script')[0];
          x.parentNode.insertBefore(s, x);
        };
        if (typeof u !== "function") {
          w.Upscope = i;
          l();
        }
      })(window, window.Upscope, document);
      Upscope('init');
    `;
    document.head.appendChild(upscopeInitScript);

    // Second script
    const upscopeUpdateScript = document.createElement('script');
    upscopeUpdateScript.type = 'text/javascript';
    upscopeUpdateScript.async = true;
    upscopeUpdateScript.setAttribute('data-upscope', 'true');
    upscopeUpdateScript.innerHTML = `
      Upscope('updateConnection', {
        uniqueId: "USER UNIQUE ID",
        identities: ["list", "of", "identities", "here"]
      });
    `;
    document.head.appendChild(upscopeUpdateScript);
  };

export const areArraysEqual = (arr1: string | any[], arr2: string | any[]) => {

   if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;

    for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) return false;
    }

    return true;
};

export const setMoveInDate = (moveInDate: string) => {
        let monthYear = ''
        if (moveInDate?.length > 0) {
            if (parseInt(moveInDate?.toString()?.slice(0, 4)) > new Date().getFullYear()) {
            }
            monthYear = moveInDate?.toString()?.slice(5, 7) + moveInDate?.toString()?.slice(0, 4)
        }
        return monthYear
    }

export const getMoveInDate = (moveInDuration: string) => {
        let date = null
        if (moveInDuration && moveInDuration?.toString()?.length > 3) {
            const month = parseInt(moveInDuration?.toString().slice(0, 2))
            let year = parseInt(moveInDuration?.toString().slice(2, 6))
            date = `${year}-${month}-${1}`
            return date
        }
        return date
    }

    const formatCurrency = (value) => {
        return value?.toLocaleString(undefined, {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2
        })
      }
    export const getPaymentBreakdownText = (orderInfo, type, tenant) => {
        const years = orderInfo?.contract_term / 12;

        if (type === 'Finance') {
          return `The figures shown are estimates based on the consumer's selection. Various rates and terms are available to well-qualified customers who meet ${tenant} credit requirements. All offers are subject to credit approval and availability from existing retailer inventory in select states. A ${orderInfo?.contract_term}-month finance contract for the vehicle selected, with an APR of ${formatCurrency(orderInfo?.apr)}%, a down payment of $${formatCurrency(orderInfo?.down_payment)}, and an amount financed of $${formatCurrency(orderInfo?.net_finance_amount)}, requires ${orderInfo?.contract_term} monthly instalments of $${formatCurrency(orderInfo?.estimated_monthly_payment)}. Amount financed includes estimates for tax, title, and registration fees.`;
        } else if (type === 'Lease') {
          return `The figures shown are estimates based on the consumer's selection. Various lease terms are available to well-qualified customers who meet ${tenant} credit requirements. All offers are subject to credit approval and availability from existing retailer inventory in select states. Monthly lease payment of $${formatCurrency(orderInfo?.estimated_monthly_payment)} per month for ${orderInfo?.contract_term} months is based on an adjusted capitalized cost of $${formatCurrency(orderInfo?.adjusted_capitalized_cost || 0)}, which includes estimates for tax, title, and registration fees. $${formatCurrency(orderInfo?.due_at_signing)} cash due at signing includes $${formatCurrency(orderInfo?.capitalized_cost_reduction || 0)} capitalized cost reduction, $${formatCurrency(orderInfo?.estimated_monthly_payment)} first month payment, and $0 security deposit. Not all customers will qualify for security deposit waiver. Lessee responsible for insurance during the lease term and any excess wear and tear as defined in the lease contract, $0.25/mile over ${formatCurrency(orderInfo?.annual_usage*years)} miles (${formatCurrency(orderInfo?.annual_usage)}/year) and a disposition fee of $000 at lease end. Purchase option at lease end, excluding tax, title, and government fees, is $${formatCurrency(orderInfo?.rv_amount)}.`;
        } else {
          return 'Unknown order type';
        }
      }
    export  const getCustomerAddress = (customerData: any, order: any, dealerProfile: any) => {
        const dealerAddress = {
            street_address: dealerProfile?.dealer_address?.address_line_1,
            city: dealerProfile?.dealer_address?.city,
            state: dealerProfile?.dealer_address?.state_name,
            zip_code:dealerProfile?.dealer_address?.zip_code,
            address_type: 'Dealer'
          } as any;

        const addresses = customerData?.customer_addresses || order?.customer_info?.customer_addresses;

        const garagingAddressFallback = addresses?.find?.(address => address?.is_garaging === true);
        const garagingAddress = addresses?.find?.(address => address?.address_type === 'Garaging');

        if (garagingAddressFallback) {
          return {
            ...garagingAddressFallback,
            street_address: garagingAddressFallback?.address_line_1,
            state: garagingAddressFallback?.state_name
          };
        }
        else if (garagingAddress) {
            return {
              ...garagingAddress,
              street_address: garagingAddress?.address_line_1,
              state: garagingAddress?.state_name
            };
          }

        else {
          return dealerAddress;
        }
      };


export const formatPayloadDate = (date: any) => dayjs(date).format("YYYY-MM-DD");

export const getEmployedSinceDate = (employed_since: { toString: () => any; }) => {
    let date = null
    const employedSince = employed_since?.toString()
    if (employedSince && employedSince?.toString()?.length > 5) {
      const month = employedSince?.toString().slice(0, 2)
      const year = employedSince?.toString().slice(2, 6)
      date = `${year}-${month}-${'01'}`
      return date
    }
    return date
  }