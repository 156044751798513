import { Endpoint } from '@constants';
import { http } from '@utilities'
import axios from "axios";

export const DMS_BASE_URL = process.env.DMS_BASE_URL;
export const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;
const STRIPE_PUBLISHER_KEY = process.env.STRIPE_PUBLISHER_KEY;

export namespace StripePayment {
    export const getOrderDetails = async (ref: { ref: string }) => {
        return http(DMS_BASE_URL).get(`${Endpoint.EP_GET_ORDER_INFORMATION}/${ref}`).then((res) => {
            return res.data
        })
    }
    export const makePayment = async (data) => {
        return http(DMS_BASE_URL).post(Endpoint.MAKE_STRIPE_PAYMENT, data).then((res) => {
            return res.data
        })
    }
    export const getPayment = async (ref: { ref: string }) => {
        return http(DMS_BASE_URL).get(`${Endpoint.GET_STRIPE_PAYMENT}/${ref}`).then((res) => {
            return res.data
        })
    }
    export const getPaymentAllowedLimit = async (lender_id: { lender_id: any }) => {
        return http(CONFIG_BASE_URL).get(`${Endpoint.GET_PAYMENT_ALLOWED_LIMIT}/${lender_id}`).then((res) => {
            return res.data
        })
    }

    export const createStripeToken = async (cardData) => {


        const url = 'https://api.stripe.com/v1/tokens';

        const cardDataParams = {
            'card[number]': cardData.card.number,
            'card[exp_month]': String(cardData.card.exp_month),
            'card[exp_year]': String(cardData.card.exp_year),
            'card[cvc]': cardData.card.cvc
        };

        const params = new URLSearchParams(cardDataParams);


        try {
            const response = await axios.post(url, params, {
                headers: {
                    'Authorization': `Bearer ${STRIPE_PUBLISHER_KEY}`,
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
            });

            return response.data;
        } catch (error) {
            throw error.response.data.error
        }
    };

    export const getPlaidToken = async (data) => {
        return http(DMS_BASE_URL).post(Endpoint.EP_GET_PLAID_TOKEN, data).then((res) => {
            return res.data
        })
    }


}