import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const StackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.stack-wrap': {
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    borderRadius: 16,
    '&.bg-light': {
      backgroundColor: '#F5F5F7'
    },
    '&.bg-dark': {
      backgroundColor: theme.palette.grey[900]
    },
    '&.has-shadow': {
      boxShadow: '0px 16px 16px -8px rgba(0, 0, 0, 0.24)'
    },
    '&.d-flex': {
      display: 'flex',
    },
    '&.d-none': {
      display: 'none',
    },
    '&.justify-content-between': {
      justifyContent: 'space-between',
    },
    '&.flex-direction-column': {
      flexDirection: 'column'
    },
    '&.align-items-center': {
      alignItems: 'center'
    },
    '.loader-wrap': {
      minHeight: 150
    }
  }
}))
