import { Fragment, ReactNode } from "react";
import { useTheme } from '@mui/material'
import { SummaryListWrap } from "./summary-list.style";
import { SummaryListProps } from './summary-list.props'
import { Box, Typography } from "@ntpkunity/controls";

export const SummaryList = ({ options }: SummaryListProps) => {
    const theme = useTheme()
    return (
        <SummaryListWrap className='cj-summary-wrap' theme={theme}>
            {options?.map((item, index)=>{
                return  <Box theme={theme} key={index}>
                            {item.title &&
                                <Box theme={theme} className="summary-list">
                                    <Typography className='sub-title text-muted title' theme={theme} variant={'body2'} component={'span'} display={'block'} mt={1}>{item.title}</Typography>
                                    <Typography className='sub-title text-muted sub-title' theme={theme} variant={'body2'} component={'span'} display={'block'} mt={1}>{item.subtitle}</Typography>
                                </Box>
                            }
                            {item.action}
                        </Box>
            })}
        </SummaryListWrap>
    )
}
