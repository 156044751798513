import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DialogWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dialog-wrap': {
    '.line': {
        border: '1px solid ' + theme.palette.grey[100],
        margin: '0 24px'
    },
    '.MuiDialog-root': {
        '&.u-dialog': {
            '.MuiDialog-paper': {
                borderRadius: 24
            },
            '&:not(.confirmation-dialog)':{
                '.MuiDialog-paper': {
                    '.u-dialog-title': {
                        padding: '24px 60px',
                        '.title-text': {
                            width: 'calc(100% - 85px)'
                        }
                    },
                    '.u-dialog-content': {
                        padding: '0px 60px 0 60px',
                        borderTop: 0,
                        '.aspect-ratio-image-wrap': {
                            img: {
                                borderRadius: 16
                            }
                        }
                    },
                    '.MuiDialogActions-root': {
                        '&.u-dialog-action': {
                            padding: '24px 60px',
                            '.MuiButtonBase-root': {
                                whiteSpace: 'nowrap',
                                [theme.breakpoints.down('sm')]: {
                                    width: '100%'
                                }
                            }
                        }
                    }
                }
            },
           '&.confirmation-dialog':{
            '.u-dialog-content': {
                '.dialog-icon': {
                    '&.icon-alert': {
                        'svg': {
                            'path': {
                                stroke: theme.palette.error.main
                            }
                        }
                    }
                }
            }
           },
           '.footer-btns': {
                display: 'flex',
                flexDiction: 'row',
                gap: 16,
                [theme.breakpoints.down('md')]: {
                    width: '100%',
                    '.MuiButtonBase-root': {
                        '&:first-child': {
                            width: '25% !important'
                        }
                    }
                }
            },
            '.footer-full-width-btns': {
                display: 'flex',
                gap: 16,
                paddingTop: 8,
                paddingBottom: 48,
                [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                    width: '100%',
                    '.MuiButtonBase-root': {
                        width: '100%'
                    }
                }
            },
           [theme.breakpoints.down('sm')]: {
            '.MuiDialog-paper': {
                '.MuiDialogActions-root': {
                    '.MuiGrid-root': {
                        flexDirection: 'column-reverse',
                        maxWidth: '100%'
                    }
                }
            }
            
           }
        },
        '&.u-dialog-sm': {
            '.MuiDialog-paper': {
                maxWidth: '600px'
            },
        },
        '&.u-dialog-md': {
            '.MuiDialog-paper': {
                maxWidth: '800px'
            }
        },
        [theme.breakpoints.down('sm')]: {
            '&.u-dialog': {
                '.MuiDialog-paper': {
                    borderBottomRightRadius: 0,
                    borderBottomLeftRadius: 0,
                },
                '&:not(.confirmation-dialog)':{
                    '.MuiDialog-paper': {
                        '.u-dialog-title': {
                            padding: '24px 24px',
                            fontSize: '16px'
                        },
                        '.u-dialog-content': {
                            padding: '0px 24px 0px 24px'
                        },
                        '.MuiDialogActions-root': {
                            '&.u-dialog-action': {
                                padding: '24px 24px',
                            }
                        }
                    }
                },
                
            },
            '&.u-dialog-sm': {
                '.MuiDialog-paper': {
                    maxWidth: '100%'
                },
            },
            '&.u-dialog-md': {
                '.MuiDialog-paper': {
                    maxWidth: '100%'
                }
            },
        },
    }
  }
}))
