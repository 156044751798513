import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
    backgroundColor: '#F5F5F7',
    minHeight: 'calc(100vh - 80px)',
    marginLeft: '-8px',
    marginRight: '-8px',
    marginBottom: '-8px',
    padding: '0 8px 8px 8px',
    [theme.breakpoints.down('md')]:{
      padding: '0px 24px 24px 24px'
    },
    '.brand-search-tabs-filter': {
      '.scroll': {
        height: 'calc(100vh - 393px)'
      },
      '.filters-cta': {
        marginTop: 16,
        [theme.breakpoints.down('md')]: {
          marginTop: 8
        }
      }
    }
  }
}))
