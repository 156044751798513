import { useTheme } from "@mui/material";
import { Grid, Icon, Skeleton } from "@ntpkunity/controls";
import { ProductOrderCard } from "@components";
import React, { useContext, useEffect, useState } from "react";
import { Status } from "constants/enums";
import { useGetCurrencies, useGetCustomerByEmail, useGetCustomerOrders } from "@hooks";
import { QueryKeys } from "@constants";

import { AppContext } from "@app/context-provider";
import { useQueryClient } from "react-query";
import { useWorkflowContext } from "@app/router-workflow";

export const Orders = () => {
  const theme = useTheme();
  const { getOrderWorkflow } = useWorkflowContext();
  const queryClient = useQueryClient();

  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  const [currency, setCurrency] = useState<string>("");

  const { data: currencyData } = useGetCurrencies();

  const { data, isFetched, isLoading } = useGetCustomerByEmail(
    JSON.parse(localStorage.getItem("settings"))?.email
  );
  const { mutate: getAllCustomerOrders, data: customerOrders } =
    useGetCustomerOrders();

  useEffect(() => {
    if (isFetched) {
      const queryString = Object.values(Status)
        .map((value) => `&multiple_order_status=${value}`)
        .join("");
      getAllCustomerOrders(
        queryString.concat(`&customer_reference_id=${data?.reference_id}&latest_order=${true}`)
      );
    }
  }, [isFetched]);
  useEffect(() => {
    const tenant_configuration: any = queryClient.getQueryData(
      QueryKeys.TENANT_CONFIGURATION
    );
    const defaultCurrency = currencyData?.find(
      (currency: any) =>
        currency.id === tenant_configuration?.data?.default_currency
    )?.symbol;
    setCurrency(defaultCurrency);
  }, [currencyData]);
  useEffect(() => {
    const tenant_configuration: any = queryClient.getQueryData(
      QueryKeys.TENANT_CONFIGURATION
    );
    const defaultCurrency = currencyData?.find(
      (currency: any) =>
        currency.id === tenant_configuration?.data?.default_currency
    )?.symbol;
    setCurrency(defaultCurrency);
  }, [currencyData]);
  return (
    <>
      <Grid theme={theme} container item spacing={{ xs: 2 }}>
        {isLoading ? (
          <>
            {[1, 2, 3].map((x, index) => (
              <Grid theme={theme} item xs={12} md={4} lg={4} key={index}>
                <Skeleton
                  key={index}
                  theme={theme}
                  width={"300px"}
                  height={"450px"}
                />
              </Grid>
            ))}
          </>
        ) : (
          customerOrders?.result?.map((order, index) => (
            <Grid theme={theme} item xs={12} md={6} lg={4} key={index}>
              <ProductOrderCard
                status={order.status}
                productImg={order?.photo_urls?.[0]?.location || order?.photo_urls?.[0]?.location.length <= 0 && <Icon name="NoPreviewAvailable" />}
                secondaryTextOne={`${order?.year} ${order?.make}`}
                secondaryTextTwo={`${order?.finance_type} ${PLACEHOLDERS?.FOR_LOWERCASE_TEXT}`}
                primaryTextOne={`${order?.model} ${order?.trim_description}`}
                primaryTextTwo={`${currency}${Math.round(
                  order?.selling_price / order?.contract_term
                )}/${PLACEHOLDERS?.PER_MONTH}.`}
                orderId={order?.reference_number}
                vin={order?.vin}
                btnText={PLACEHOLDERS?.CONTINUE_ORDER_TEXT}
                onClick={() => {
                  getOrderWorkflow(order?.vin, order?.reference_number, order?.running_id)
                }}
              />
            </Grid>
          ))
        )}
      </Grid>
    </>
  );
};
