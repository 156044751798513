import { Link, useTheme } from "@mui/material";
import { Box, Button, Skeleton, Typography, Image } from "@ntpkunity/controls";
import { ProductShoppingCardProps } from "./product-shopping-card.props";
import { ProductShoppingCardWrap } from "./product-shopping-card.style";
import { Stack, CoverageItem } from "@components";
import { useContext, useEffect, useState } from "react";
import { fniContext } from "@utilities";
import { AddOnsType } from "constants/enums";
import clsx from 'clsx'
export const ProductShoppingCard = ({
  productImg,
  productTag,
  title,
  productPrice,
  desc,
  linkText,
  buttonText,
  onBtnClick,
  onLinkClick,
  buttonStatePrimary = true,
  buttonStateSecondary = false,
  coverageItem,
  coverageTitle,
  coveragePrice,
  changeTextonHover,
  onHoverText,
}: ProductShoppingCardProps) => {
  const theme = useTheme();
  const [buttonLabel, setButtonLabel] = useState<any>();
  const { isLoading } = useContext(fniContext);

  useEffect(() => {
    if (changeTextonHover) {
      setButtonLabel(buttonText);
    }
  }, [buttonText]);
  return (
    <ProductShoppingCardWrap theme={theme} className="product-shopping-card">
      <Stack className="cj-card">
        {productTag && (
          <Typography
            theme={theme}
            className={clsx({ 'prd-tag fw-sbold': true, 'oem': (productTag == AddOnsType.OEM), 'dealer': (productTag == AddOnsType.Dealer)})} 
            component="small"
            variant="caption"
          >
            {productTag}
          </Typography>
        )}
        <Box theme={theme} className="product-img">
          <Box theme={theme} className="cp" onClick={onLinkClick}>
            <Image
              theme={theme}
              src={
                productImg ?? require("../../public/assets/icons/no-img.svg")
              }
              aspectRatio="16:9"
            />
          </Box>
          <Box theme={theme} className="cj-card-content">
            <Box theme={theme}>
              <Box theme={theme} className="cp" onClick={onLinkClick}>
                {title && (
                  <Typography
                    theme={theme}
                    className="title"
                    variant="h4"
                    component={"h4"}
                    display={"block"}
                  >
                    {title}
                  </Typography>
                )}
                {productPrice && (
                  <Typography
                    theme={theme}
                    className="text-primary"
                    variant="subtitle1"
                    component={"p"}
                    display={"block"}
                  >
                    {productPrice}
                  </Typography>
                )}
                {desc && (
                  <Typography
                    theme={theme}
                    className="text-muted desc"
                    variant="caption"
                    component={"small"}
                    display={"block"}
                    mt={1}
                    mb={1}
                  >
                    {desc}
                  </Typography>
                )}
              </Box>
              {onLinkClick && (
                <Link
                  className="link decoration-none fw-medium"
                  variant="caption"
                  component={"a"}
                  display={"block"}
                  onClick={onLinkClick}
                >
                  {linkText}
                </Link>
              )}
            </Box>
            {coverageItem &&
              (isLoading ? (
                <Skeleton
                  theme={theme}
                  width={"100%"}
                  height={32}
                  sx={{ margin: "auto" }}
                />
              ) : (
                <CoverageItem
                  title={coverageTitle}
                  coveragePrice={coveragePrice}
                />
              ))}

            {buttonText && (
              <Button
                theme={theme}
                primary={buttonStatePrimary}
                secondary={buttonStateSecondary}
                className="btn-desktop"
                text={changeTextonHover ? buttonLabel : buttonText}
                onClick={onBtnClick}
                onMouseEnter={() => {
                  changeTextonHover && buttonStateSecondary &&
                  setButtonLabel(onHoverText);
                }}
                onMouseLeave={() => {
                  changeTextonHover &&
                    buttonStateSecondary &&
                    setButtonLabel(buttonText);
                }}
                fullWidth
              />
            )}
          </Box>
        </Box>
      </Stack>
    </ProductShoppingCardWrap>
  );
};
