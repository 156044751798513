import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'
import docIcon from 'public/assets/icons/documentIcon.svg'

export const ContractingStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.contracting-stack-wrap': {
    '.u-accordian-wrap': {
        borderRadius: 16,
        // padding: 24,
        marginBottom: 16,
        backgroundColor: '#F5F5F7',
        '.MuiAccordionSummary-root': {
            padding: 24,
            minHeight: 'auto',
            '.MuiAccordionSummary-content': {
                margin: 0,
                [theme.breakpoints.down('md')]: {
                    width: 'calc(100% - 60px)'
                },
                '.action-btn': {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 16,
                    '.items': {
                        display: 'flex',
                        [theme.breakpoints.down('md')]: {
                            marginLeft: 16
                        },
                        '&.error': {
                            svg: {
                                path: {
                                    stroke: theme.palette.error.main
                                }
                            }
                        }
                    },
                    '.MuiButtonBase-root': {
                        padding: 0
                    }
                }
            },
            '.MuiAccordionSummary-expandIconWrapper': {
                marginLeft: 16
            },
        },
        '&.Mui-expanded': {
            '.u-accordian-body': {
                padding: '0 24px',
                border: 0,
                [theme.breakpoints.down('sm')]:{
                    padding: '0 8px',
                },
                '.stack-wrap': {
                    padding: '16px 24px',
                    marginTop: 16,
                    gap: 16,
                    '&:first-child': {
                        marginTop: 0
                    },
                    '&:last-child': {
                        marginBottom: 24
                    },
                    '.title': {
                        display: 'inline-block',
                        alignItems: 'center',
                        gap: 4,
                        width: '100%',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    '.sub-title': {
                        marginLeft: 4
                    },
                    '.item-status-text': {
                        display: 'flex',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        gap: 8,
                        
                        '.text-msg': {
                            display: 'block',
                            [theme.breakpoints.down('md')]: {
                                display: 'none'
                            }
                        },
                        '&.error': {
                            svg: {
                                path: {
                                    stroke: theme.palette.error.main
                                }
                            }
                        }
                    }
                },
            }
        },
        '&:last-child': {
            marginBottom: 0
        },
    },
    '.main-title': {
        fontWeight: theme.customVariables.fontWeightSemiBold,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        [theme.breakpoints.down('md')]: {
            width: 'calc(100% - 80px)'
        },
        '&:before': {
            content: '" "',
            backgroundImage: `url(${docIcon})`,
            display: 'inline-block',
            width: 24,
            height: 24
        },
        '.title-with-icon': {
            whiteSpace: 'nowrap',
            width: '75%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },
    
    '.menu': {
        display: 'flex',
        alignItems: 'center',
        gap: 16,
        '.MuiButtonBase-root': {
            '&.icon-only': {
                padding: 0
            }
        }
    }
  }
}))
