import { useTheme } from '@mui/material'
import { Typography, Box, Input, Grid, Button, Snackbar, CircleLoader } from '@ntpkunity/controls'
import { Stack, Textbox } from '@components'
import { FC, useEffect, useReducer } from 'react'
import { useAppContext } from '@app/context-provider'
import { Controller, useForm } from 'react-hook-form'
import { useUpdateCustomerContact } from '@hooks'
import { SnackbarUtility } from '@utilities'
import DisableLoader from '../../../src/shared/assets/loader-disabled.gif'

interface ICustomerContractData {
    first_name: string
    last_name: string
    mobile_number: string
    email: string
}

export const AboutCard: FC<{
    customerReferenceID?: string
    contactData?: any
}> = ({ customerReferenceID, contactData }) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const { mutate: updateCustomerByReferenceId, isLoading: updateCustomerDataLoading } = useUpdateCustomerContact()
    const customer_data = JSON.parse(localStorage.getItem('settings'))

    const form = useForm<ICustomerContractData>({
        mode: 'onTouched'
    })

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors, isDirty },
    } = form

    useEffect(() => {
        if (contactData) {
            reset({
                first_name: contactData?.first_name,
                last_name: contactData?.last_name,
                mobile_number: contactData?.mobile_number,
                email: contactData?.email
            })
        }
    }, [contactData])

    const onSubmit = (data: ICustomerContractData) => {
        const payload = {
            customer_profile: {
                ...data,
                updated_by: customer_data?.email
            },
            reference_id: customerReferenceID
        };

        updateCustomerByReferenceId(payload, {
            onSuccess() {
                snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.PROFILE_DATA_SAVE))
                setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
            },
            onError(error) {
                snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.PROFILE_DATA_NOT_SAVE))
                setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
            }
        }
        )
    }

    return (
        <>
            <Stack paddingXs={3} paddingMd={4} className='delivery-preference'>
                <Typography theme={theme} variant='h4' component={'h4'} mb={3} display={'block'}>
                    {PLACEHOLDERS.ABOUT_CARD_ABOUT_YOU}
                </Typography>
                <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
                    <Grid theme={theme} item xs={6}>
                        <Textbox>
                            <Controller
                                name={"first_name"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: `${PLACEHOLDERS.LICENSE_DETAILS_FIRST_NAME_REQUIRED}`,
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_FIRST_NAME_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.first_name?.message}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={6}>
                        <Textbox>
                            <Controller
                                name={"last_name"}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: `${PLACEHOLDERS.LICENSE_DETAILS_LAST_NAME_REQUIRED}`,
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_LAST_NAME_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.last_name?.message}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={12} md={6} lg={6}>
                        <Textbox>
                            <Controller
                                name={"email"}
                                defaultValue={''}
                                control={control}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        placeholder={PLACEHOLDERS.FINANCING_INPUT_PLACEHOLDER}
                                        label={PLACEHOLDERS.FINANCING_EMAIL_TEXT}
                                        type="text"
                                        {...field}
                                        disabled={true}
                                        error={errors?.email?.message}

                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    <Grid theme={theme} item xs={12} md={6} lg={6}>
                        <Textbox>
                            <Controller
                                name={"mobile_number"}
                                defaultValue={''}
                                control={control}
                                rules={{
                                    required: {
                                        value: true,
                                        message: `${PLACEHOLDERS.CUSTOMER_PHONE_NUMBER_REQUIRED}`,
                                    }
                                }}
                                render={({ field }) => (
                                    <Input
                                        theme={theme}
                                        fullWidth
                                        maskNumeric
                                        masking
                                        format="### - ### - ####"
                                        placeholder={'000 - 000 - 0000'}
                                        label={PLACEHOLDERS.FINANCING_MOBILE_PHONE_NUMBER_FIELD_LABEL}
                                        type="text"
                                        {...field}
                                        error={errors?.mobile_number?.message}
                                    />
                                )}
                            />
                        </Textbox>
                    </Grid>
                    
                    <Grid theme={theme} item xs={12} md={6} lg={6}>
                        {isDirty ? (
                            <Box theme={theme} className='res-btn'>
                                <Button primary theme={theme}
                                    disabled={updateCustomerDataLoading}
                                    text={PLACEHOLDERS.ABOUT_CARD_SAVE_CHANGES}
                                    onClick={handleSubmit(onSubmit)}
                                    startIcon={updateCustomerDataLoading && <img src={DisableLoader} alt="Loader" />}
                                />
                            </Box>
                        ) : null}
                    </Grid>
                </Grid>
            </Stack>
            <Snackbar
                theme={theme}
                message={snackbarState.message}
                open={snackbarState.open}
                onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            />
        </>
    )
}


