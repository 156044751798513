import { useTheme } from '@mui/material/styles';
import { Radio } from '@ntpkunity/controls';
import { RatingWrap } from './rating.style';
import {Controller} from "react-hook-form";
import { useAppContext } from '@app/context-provider';

export default function RadioGroupRating(props) {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const {control, messages, errors} = props;
  return (
    <RatingWrap theme={theme} className='rating-wrap'>
        <Controller
            control={control}
            name={messages.name.Condition}
            rules={{ required: true}}
            render={({ field }) => (
        <Radio theme={theme}
               {...field}
               radioError={errors?.[messages.name.Condition]?.message?.toString()}
               options={[
            {
                icon: <>
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                        <path d="M30 20L33.09 26.26L40 27.27L35 32.14L36.18 39.02L30 35.77L23.82 39.02L25 32.14L20 27.27L26.91 26.26L30 20Z" stroke="#9295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>,
                checkedIcon: <>
                    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="30" cy="30" r="29" stroke="#0033FD" stroke-width="2"/>
                        <path d="M30 20L33.09 26.26L40 27.27L35 32.14L36.18 39.02L30 35.77L23.82 39.02L25 32.14L20 27.27L26.91 26.26L30 20Z" stroke="#0033FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </>,
                label: PLACEHOLDERS.RATING_EXCELLENT,
                value: 'Excellent'
            },
            {
              icon: <>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                  <path d="M30 33C33.866 33 37 29.866 37 26C37 22.134 33.866 19 30 19C26.134 19 23 22.134 23 26C23 29.866 26.134 33 30 33Z" stroke="#9295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M26.21 31.8899L25 40.9999L30 37.9999L35 40.9999L33.79 31.8799" stroke="#9295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </>,
              checkedIcon: <>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                  <path d="M30 33C33.866 33 37 29.866 37 26C37 22.134 33.866 19 30 19C26.134 19 23 22.134 23 26C23 29.866 26.134 33 30 33Z" stroke="#0033FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M26.21 31.8899L25 40.9999L30 37.9999L35 40.9999L33.79 31.8799" stroke="#0033FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </>,
              label: PLACEHOLDERS.RATING_VERY_GOOD,
              value: 'Very Good'
            },
            {
              icon: <>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                  <path d="M25 40H22C21.4696 40 20.9609 39.7893 20.5858 39.4142C20.2107 39.0391 20 38.5304 20 38V31C20 30.4696 20.2107 29.9609 20.5858 29.5858C20.9609 29.2107 21.4696 29 22 29H25M32 27V23C32 22.2044 31.6839 21.4413 31.1213 20.8787C30.5587 20.3161 29.7956 20 29 20L25 29V40H36.28C36.7623 40.0055 37.2304 39.8364 37.5979 39.524C37.9654 39.2116 38.2077 38.7769 38.28 38.3L39.66 29.3C39.7035 29.0134 39.6842 28.7207 39.6033 28.4423C39.5225 28.1638 39.3821 27.9063 39.1919 27.6875C39.0016 27.4687 38.7661 27.2939 38.5016 27.1752C38.2371 27.0565 37.9499 26.9967 37.66 27H32Z" stroke="#9295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </>,
              checkedIcon: <>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                  <path d="M25 40H22C21.4696 40 20.9609 39.7893 20.5858 39.4142C20.2107 39.0391 20 38.5304 20 38V31C20 30.4696 20.2107 29.9609 20.5858 29.5858C20.9609 29.2107 21.4696 29 22 29H25M32 27V23C32 22.2044 31.6839 21.4413 31.1213 20.8787C30.5587 20.3161 29.7956 20 29 20L25 29V40H36.28C36.7623 40.0055 37.2304 39.8364 37.5979 39.524C37.9654 39.2116 38.2077 38.7769 38.28 38.3L39.66 29.3C39.7035 29.0134 39.6842 28.7207 39.6033 28.4423C39.5225 28.1638 39.3821 27.9063 39.1919 27.6875C39.0016 27.4687 38.7661 27.2939 38.5016 27.1752C38.2371 27.0565 37.9499 26.9967 37.66 27H32Z" stroke="#0033FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </>,
                label: PLACEHOLDERS.RATING_GOOD,
                value: 'Good'
            },
            {
              icon: <>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                  <path d="M38 24L27 35L22 30" stroke="#9295A6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </>,
              checkedIcon: <>
                <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="30" cy="30" r="29" stroke="#CCCED9" stroke-width="2"/>
                  <path d="M38 24L27 35L22 30" stroke="#0033FD" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </>,
                label: PLACEHOLDERS.RATING_FAIR,
                value: 'Fair'
            },
          ]}
               />
               )}
        />
    </RatingWrap>
  );
}