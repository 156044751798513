import { useTheme } from '@mui/material'
import {Dialog, Button,} from '@ntpkunity/controls'
import { Popup } from '@components'
import { TradeInPopupWrap } from './tradeIn-journey-popup.style'
import {Suspense} from "react";
import {LoaderPopup} from "../trade-in-journey-components/loader-with-title/loader-component";
import { useAppContext } from '@app/context-provider';

export const TradeInJourneyPopup = ({
    isPopupOpen,
    closePopup,
    title,
    setNextPopup,
    isButtonDisabled,
    hasBackArrow,
    Component,
    goToBackPopup,
    previousPopupType,
    initialPopupType,
    control,
    handleSubmit,
    errors,
    messages,
    onSubmitTradeIn,
    setValue,
    getValues,
    clearErrors,
    reset,
    setError,
    isValid,
    trigger,
    makeModelYearTrim,
    setMakeModelYearTrim,
    unregister,
    handleRemove,
    onProceedWithTradeIn, watch, tradeInType
    }) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders


    const commonProps = {
        control,
        messages,
        setNextPopup,
        errors,
        setValue,
        closePopup,
        getValues,
        clearErrors,
        reset,
        previousPopupType, setError, trigger,
        makeModelYearTrim,
        setMakeModelYearTrim,
        unregister,
        initialPopupType,
        handleSubmit,
        onSubmitTradeIn,
        handleRemove,
        goToBackPopup,
        onProceedWithTradeIn, watch, tradeInType
    }
    const handleContinue = () => {
        handleSubmit(onSubmitTradeIn)()
    }
    return(
        <Popup>
            <Dialog
                theme={theme}
                size="sm"
                open={isPopupOpen}
                title={title}
                onCloseDialog={closePopup}
                onBackBtn={hasBackArrow && previousPopupType.previousState.currentState !== null ? () => goToBackPopup(): null}
                disablePortal
                noFooter
                customFooter={
                    isButtonDisabled ?
                    <Button theme={theme} primary text={PLACEHOLDERS.BUTTON_TEXT_CONTINUE} onClick={() => handleContinue()} disabled={!isValid}></Button>:
                        false
                }
                >
                    <TradeInPopupWrap theme={theme} className='trade-in-popup-wrap'>
                        <form onSubmit={handleSubmit(onSubmitTradeIn)}>
                        <Suspense fallback={<LoaderPopup />}>
                            {Component ? <Component {...commonProps} /> : null}
                        </Suspense>
                        </form>
                    </TradeInPopupWrap>
            </Dialog>
        </Popup>
    )
}


