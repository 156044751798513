import { useMutation, useQuery, useQueryClient } from "react-query";
import { TenantManagement } from '../apis'
import { useAuthentication } from "./useAuthenticationHook";

// TenantManagement.getTenant
export const useTenantConfiguration = (slug: string) => {
  return useQuery('tenant-configurations', () => {
    return TenantManagement.getTenantConfigurations({ slug })
  })
}

export const useDefaultLenderConfiguration = (company_id: number) => {
  const { authenticationStatus } = useAuthentication()

  return useQuery(
    'default-lender-configurations',
    () => {
      return TenantManagement.getDefaultLenderConfigurations({ company_id })
    },{
      enabled: authenticationStatus && !!company_id
    }
  )
}
