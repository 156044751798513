import { ReactNode } from "react";
import clsx from 'clsx'
import { useTheme } from '@mui/material'
import { CjAccordianWrap } from "./accordian.style";

export const AccordianBox = ({ className, children, dNone }: { className?: string, children: ReactNode, dNone?: boolean }) => {
    const theme = useTheme()

    return (
        <CjAccordianWrap className={clsx(className, { 'cj-accordian-wrap': true, 'd-none': dNone })} theme={theme}>
            {children}
        </CjAccordianWrap>
    )
}