import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const WayInsuranceWrap = styled(
    Box,
    {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.way-insurance-wrap': {
        height: 400
    }
}))
