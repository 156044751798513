import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const TrimLevelPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.trim-level-popup-wrap': {
    height: 'calc(100vh - 220px) !important',
    marginBottom: 60,
    marginTop: 24
  }
}))
