import { styled } from '@mui/material/styles'
import zIndex from '@mui/material/styles/zIndex'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.signature-dialog': {
    '.sigCanvas': {
      width: '100%',
      height: 320,
      padding: '40px 24px',
      borderRadius: 16,
      backgroundColor: '#F5F5F7'
    }
  },
  
}))



export const SignaturePopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
'&.signature-popup-wrap': {
  '.top-bar': {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    textAlign: 'right',
    display: 'block',
    height: 40,
    width: '100%',
    position: 'fixed',
    left: 0,
    top: 0,
    zIndex: 1400,
    a: {
      color: theme.palette.common.white,
      padding: 7,
      display: 'flex',
      width: 40,
      justifyContent: 'center',
      float: 'right',
      height: 40,
      textAlign: 'center',
      textDecoration: 'none',
      fontSize: 20,
      cursor: 'pointer',
      marginRight: 20,
      position: 'relative',
      zIndex: 1400,
      svg: {
        path: {
          stroke: theme.palette.common.white
        }
      }
    }
  },
  '.u-dialog': {
    '&.u-dialog-xl': {
      '.MuiDialog-container': {
        '.MuiDialog-paper': {
          maxWidth: '100%',
          margin: '10px 0 0 0',
          position: 'absolute',
          bottom: 0,
          borderRadius: 24,
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          maxHeight: 'calc(100% - 40px)',
          '.u-dialog-title': {
            display: 'none',
            [theme.breakpoints.down('md')]: {
              display: 'block',
            }
          },
          '.u-dialog-content': {
            backgroundColor: theme.palette.grey[50],
            overflow: 'hidden',
            padding: 8,
            border: 0,
            [theme.breakpoints.down('md')]: {
              overflow: 'auto',
              padding: 0,
              backgroundColor: theme.palette.common.white
            }
          }
        }
      }
    },
   
    '.wrap': {
      height: 'calc(100vh - 40px)',
      [theme.breakpoints.down('md')]: {
        height: 'auto'
      },
      '.MuiGrid-container': {
        [theme.breakpoints.down('md')]: {
          flexDirection: 'column-reverse'
        },
        '.iframe': {
          backgroundColor: theme.palette.common.white,
          borderRadius: 16,
          height: 'calc(100vh - 58px)',
          width: '100%',
          border: '1px solid',
          borderColor: '#E5E5E5',
          [theme.breakpoints.down('md')]: {
            height: 'auto',
            padding: '0 24px',
            border: 0
          },
          '.viewport': {
            transform: 'translateZ(0)',
            height: 'calc(100vh - 62px)',
            borderRadius: 16,
            width: '100%',
            overflow: 'auto',
            scrollbarWidth: 'auto',
            
            '&::-webkit-scrollbar': {
              width: 0,
              height: 7
            },
            '&::-webkit-scrollbar-track': {
              padding: '0 1px',
              borderRadius: 20
            },
            '&::-webkit-scrollbar-thumb': {
              transition: 'all 0.3s',
              backgroundColor: '#cdcdcd',
              borderRadius: 20
            },
            '&::-webkit-scrollbar-thumb:hover': {
              backgroundColor: '#a6a6a6'
            },
            
            iframe: {
              borderRadius: 16,
              height: 'calc(100vh - 160px)',
              
              [theme.breakpoints.down('md')]: {
                marginBottom: 0,
                border: '1px solid',
                borderColor: theme.palette.grey[100],
              }
            },
            '.sign-btn': {
              display: 'flex',
              justifyContent: 'center',
              padding: '24px 0px',
              margin: '0 60px',
              [theme.breakpoints.down('md')]:{
                margin: 0,
                borderTop: 0,
                '.MuiButtonBase-root': {
                  width: '100%'
                }
              }
            }
          }
        },
      },
      
      
      '.drawer-wrap': {
        // width: '480px',
        backgroundColor: theme.palette.common.white,
        borderRadius: 16,
        border: '1px solid',
        borderColor: '#E5E5E5',
        overflow: 'hidden',
        [theme.breakpoints.down('md')]: {
          width: '100%',
          padding: '24px',
          paddingTop: 0,
          border: 0,
        },
        
        '.signature-canvas': {
          backgroundColor: '#F5F5F7',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'end',
          alignSelf: 'stretch',
          borderRadius: 16,
          padding: 24,
          height: 225,
          position: 'relative',
          '.reset-btn': {
            position: 'absolute',
            top: 16,
            right: 16
          },
          '.sigCanvas': {
            height: 150,
            width: '100%'
          },
          '.title': {
            width: '100%',
            hr: {
              opacity: '0.4'
            },
            '.MuiTypography-root': {
              color: '#7F7F7F'
            }
          }
        },
        '.signed-doc': {
          width: '100%',
          '.title': {
            display: 'flex',
            gap: 4
          },
          '.item-status-text': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            '&.error': {
              svg: {
                path: {
                  stroke: theme.palette.error.main
                }
              }
            }
          }
        },
        '.scroll-container': {
          width: '100%',
          height: 'calc(100vh - 58px)',
          overflow: 'auto',
          padding: 40,
          transition: 'padding-right 0.3s ease',
          scrollbarWidth: 'auto',
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          gap: 24,
          flexShrink: 0,
          alignSelf: 'stretch',
          [theme.breakpoints.down('md')]: {
            height: 'auto',
            padding: 0
          },  
          '&::-webkit-scrollbar': {
            width: 6,
            height: 6,
          },
          '&::-webkit-scrollbar-track': {
            padding: '0 1px',
            borderRadius: 4
          },
          '&::-webkit-scrollbar-thumb': {
            transition: 'all 0.3s',
            backgroundColor: 'rgb(13 13 13 / 16%)',
            borderRadius: 4,
          },
          '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#a6a6a6'
          }
        },
      }
    },
  } 
}

}))

export const ScrollIconWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.scroll-icon': {
    width: 64,
    margin: 'auto',
    backgroundColor: theme.palette.common.white,
    borderRadius: 40,
    overflow: 'hidden',
    padding: '16px 12px',
    boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.16)',
    border: '1px solid' + theme.palette.grey[100],
    zIndex: 10,
    textAlign: 'center',
    position: 'fixed',
    left: '50%',
    bottom: 120,
    transform: 'translateX(-50%)',
    display: 'block',
    [theme.breakpoints.down('md')]: {
      display: 'none'
    }
  }
}))