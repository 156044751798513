export const defaultLanguages = {
 placeholders: {
  CONTRACTING_PAPERWORK_DONE: "Let’s get done with your paperwork",
  RESET_PASSWORD_HELP_TEXT: "The password should be 8 to 15 characters long, which must include uppercase, lowercase, digit and special character.",
  SIGN_IN_SUB_TITLE: "Now!",
  SIGN_IN_HELP_TEXT: "We will send a verification link to this email address to continue with your registration process.",
  SIGN_IN_PASSWORD_FIELD_LABEL: "Enter Your Password",
  TERM_AND: "and",
  SIGN_IN_EMAIL_NOT_FOUND: "Email not found. Please try again.",
  FORGOT_PASSWORD_INPUT_REGISTERED_EMAIL_ID: "Please input your registered email Id",
  RESEND_PASSWORD_MISSING_TENANT: "Missing tenant",
  RESEND_PASSWORD_RESET_LINK_NOT_SENT: "Reset link not sent",
  SIGN_IN_BUTTON_TEXT: "Continue",
  EMAIL_VERIFICATION_SECONDARY_DESCRIPTION: "Please click the link in the email to continue your sign up process. The verification link is valid for",
  BUTTON_TEXT_CONTINUE: "Continue",
  EMAIL_VERIFICATION_DURATION_TEXT: "30 minutes.",
  RESEND_PASSWORD_RESET_LINK_SENT: "Password reset link sent successfully",
  INVENTORY_ASSET_DETAILS_ENGINE_FIELD_LABEL: "Engine:",
  EMAIL_VERIFICATION_HELP_TEXT: "Didn’t receive the verification email?",
  RESEND_PASSWORD_RESEND_TEXT: "Resend",
  EMAIL_VERIFICATION_LINK_TEST: "Resend",
  EMAIL_VERIFICATION_LINK_NOT_SENT: "Email not sent. Please try again.",
  SIGN_IN_EMAIL_FIELD_LABEL: "Enter Your Email Address",
  RESET_PASSWORD_BUTTON_TEXT: "Save Password",
  USER_PROFILE_SUB_TITLE: "You...",
  USER_PROFILE_FIRST_NAME_FIELD_LABEL: "First Name",
  USER_PROFILE_LAST_NAME_FIELD_LABEL: "Last Name (optional)",
  USER_PROFILE_PASSWORD_FIELD_LABEL: "Create Password",
  USER_PROFILE_CONFIRM_PASSWORD_FIELD_LABEL: "Confirm Password",
  RESET_PASSWORD_EXPIRED_LINK_TYPES_VERFICATION: "verfication",
  RESET_PASSWORD_EXPIRED_LINK_TYPES_PASSWORD: "password",
  RESET_PASSWORD_ERROR_TITLE: "Link Expired!",
  RESET_PASSWORD_EXPIRED_RESET_LINK_SUB_TITLE: "This reset password link has been expired. Please click the button below to regenerate the link.",
  RESET_PASSWORD_EXPIRED_RESET_LINK_BUTTON_TEXT: "Send Reset Link",
  USER_PROFILE_BUTTON_TEXT: "Continue",
  SIGN_UP_CONSENT_STATUS_PUBLISHED: "Published",
  SIGN_UP_CONSENT_READ_AGREE: "Carefully Read And Agree",
  SIGN_UP_CONSENT_AGREE_CONTINUE: "I Agree & Continue",
  INVENTORY_FUNCTION_NOT_IMPLEMENTED: "Function not implemented.",
  INVENTORY_ASSET_DETAILS: "Asset Details",
  BUTTON_TEXT_SELECT_CONTINUE: "Select & Continue",
  INVENTORY_ASSET_DETAILS_SELLING_PRICE_FIELD_LABEL: "Selling Price",
  INVENTORY_ASSET_DETAILS_VEHICLE_FEATURES_SPECIFICATIONS_FIELD_LABEL: "Vehicle Features and Specs",
  INVENTORY_ASSET_DETAILS_DRIVE_TRAIN_FIELD_LABEL: "Drivetrain:",
  ADD_ONS_PART_NUMBER: "Part Number",
  ADD_ONS_PRODUCT: "Product",
  ADDON_SUB_TITLE: "Recommended coverage options based on owning your vehicle for 0.00 years while driving more than 00k miles per year. If you want to personalize the your coverage, please fill out the questionnaire",
  NEXT_F_I_PRODUCTS: "Next: F&I Products",
  INSURANCE_PROVIDER_NAME: "Insurance Provider Name",
  INSURANCE_ADD_HERE: "Add here . .",
  INSURANCE_AGENT_NAME: "Insurance Agent Name",
  ADDRESS_LAYOUT_DURATION_MONTHS: "month(s)",
  INVENTORY_ASSET_DETAILS_EXTERIOR_COLOR_FIELD_LABEL: "Exterior Color:",
  INVENTORY_ASSET_DETAILS_INTERIOR_COLOR_FIELD_LABEL: "Interior Color:",
  INVENTORY_ASSET_DETAILS_TRANSMISSION_TYPE_FIELD_LABEL: "Transmission Type:",
  INVENTORY_ASSET_DETAILS_STOCK_NUMBER_FIELD_LABEL: "Stock Number:",
  INVENTORY_ASSET_FILTER_ASSET_CONDITION: "Asset Condition",
  INVENTORY_ASSET_FILTER_MIN_FIELD_LABEL: "Min",
  INVENTORY_ASSET_FILTER_MAX_FIELD_LABEL: "Max",
  INVENTORY_ASSET_FILTER_FROM_FIELD_LABEL: "From",
  INVENTORY_ASSET_FILTER_TO_FIELD_LABEL: "To",
  INVENTORY_ASSET_FILTER_YEAR_FIELD_LABEL: "Year",
  INVENTORY_ASSET_FILTER_MAKE_FIELD_LABEL: "Make",
  INVENTORY_ASSET_FILTER_MODEL_FIELD_LABEL: "Model",
  INVENTORY_ASSET_FILTER_TRIM_FIELD_LABEL: "Trim",
  INVENTORY_ASSET_FILTER_MAKE_MODEL_TRIM_FIELD_LABEL: "Make / Model / Trim",
  INVENTORY_ASSET_FILTER_VIN_FIELD_LABEL: "VIN",
  INVENTORY_ASSET_FILTER_STOCK_FIELD_LABEL: "Stock",
  INVENTORY_ASSET_FILTER_STOCK_NUMBER_FIELD_LABEL: "Stock Number",
  INVENTORY_ASSET_FILTER_RESET_FILTERS: "Reset Filters",
  INVENTORY_ASSET_FILTER_APPLY_FILTERS: "Apply Filters",
  JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE: "Insurance",
  INVENTORY_ASSET_LIST_VIEW_HIDE_FILTERS: "Hide Filters",
  INVENTORY_ASSET_LIST_VIEW_SHOW_FILTERS: "Show Filters",
  INVENTORY_ASSET_LIST_SELLING_PRICE_HIGH_TO_LOW: "Selling Price (High to Low)",
  INVENTORY_ASSET_LIST_SELLING_PRICE_LOW_TO_HIGH: "Selling Price (Low to High)",
  INVENTORY_ASSET_LIST_SORT_BY: "Sort By",
  INVENTORY_ASSET_LIST_BUTTON: "Button",
  JOURNEY_LAYOUT_STEPS_COUNT_TITLE: "Payments",
  JOURNEY_LAYOUT_STEPS_COUNT_SUB_TITLE: "Next: F&I Products",
  JOURNEY_LAYOUT_PRODUCT_2024_BMW_4_SERIES_TITLE: "2024 BMW 4 Series",
  JOURNEY_LAYOUT_PRODUCT_2024_BMW_4_SERIES_SUB_TITLE: "i4 eDrive 35",
  JOURNEY_LAYOUT_PRODUCT_2024_BMW_4_SERIES_CATAGORY: "South Bay BMW | Torrance, CA",
  JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE: "2 minutes",
  JOURNEY_LAYOUT_STEP_ITEMS_F_I_PRODUCTS_TITLE: "F&I Products",
  JOURNEY_LAYOUT_STEP_ITEMS_ADD_ONS_TITLE: "Add-Ons",
  JOURNEY_LAYOUT_STEP_ITEMS_SELECTED_SUB_TITLE: "Selected",
  JOURNEY_LAYOUT_STEP_ITEMS_TRADE_IN_TITLE: "Trade-In",
  JOURNEY_LAYOUT_STEP_ITEMS_NONE_SELECTED_SUB_TITLE: "None Selected",
  JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE: "Schedule",
  JOURNEY_LAYOUT_STEP_ITEMS_FINANCING_TITLE: "Financing",
  FINANCING_TITLE: "Let’s get started with your credit application",
  FINANCING_SUB_TITLE: "You can either fill out and submit your credit application now or you can do it at the dealership store. Doing it now will save your time at the dealership. Remove the guesswork and confirm the loans you qualify for in advance.",
  FINANCING_PERSONAL_CREDIT_APPLICATION: "I would like to submit a personal credit application",
  FINANCING_BUSINESS_CREDIT_APPLICATION: "I would like to submit a business credit application",
  FINANCING_DEALERSHIP_STORE_CREDIT_APPLICATION: "I will fill out my credit application at the dealership store",
  FINANCING_TITLE_TELL_US_ABOUT_YOURSELF: "Tell us about yourself",
  FINANCING_PREFIX_OPTIONAL: "Prefix (Optional)",
  FINANCING_INPUT_PLACEHOLDER: "Type here...",
  FINANCING_FIRST_NAME_FIELD_LABEL: "First Name",
  FINANCING_MIDDLE_NAME_FIELD_LABEL: "Middle Name",
  FINANCING_LAST_NAME_FIELD_LABEL: "Last Name",
  FINANCING_SUFFIX_OPTIONAL_FIELD_LABEL: "Suffix (Optional)",
  FINANCING_SOCIAL_SECURITY_NUMBER_FIELD_LABEL: "Social Security Number",
  PAYMENT_BREAKDOWN_POPUP_TERMS_TEXT: "Terms",
  FINANCING_PRIVACY_POLICY_SSN_ENCRYPTION: "We take your privacy seriously. Your SSN will be fully encrypted.",
  FINANCING_HOME_PHONE_NUMBER_OPTIONAL_FIELD_LABEL: "Home Phone Number (Optional)",
  FINANCING_MOBILE_PHONE_NUMBER_FIELD_LABEL: "Mobile Phone Number",
  FINANCING_EMAIL_ADDRESS_FIELD_LABEL: "Email Address",
  FINANCING_DATE_OF_BIRTH_FIELD_LABEL: "Date of Birth",
  FINANCING_MINIMUM_AGE_REQUIREMENT: "You must be at least 18 years old to apply.",
  FINANCING_CO_APPLICANT_QUESTION: "Do you have a co-applicant?",
  FINANCING_CO_APPLICANT_INFORMATION: "If you want someone to be your co-applicant, their income will be added to yours, which increases your eligibility. Your co-applicant will share the responsibility with you for the loan.",
  FINANCING_SAVE_AND_CONTINUE: "Save & Continue",
  CONTRACTING_READ_CONTRACT: "Take your time and make sure you carefully read all the contract documents below before signing.",
  FINANCING_PRICES_AND_RATES_DISCLAIMER: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  FINANCING_PERSONAL_DETAILS: "Personal Details",
  FINANCING_CURRENT_RESIDENCE_QUESTION: "Where do you currently live?",
  FINANCING_ADDRESS_VERIFIED: "Address Verified",
  FINANCING_MOVE_IN_DATE_FIELD_LABEL: "Move-In Date",
  FINANCING_DURATION: "Duration",
  FINANCING_DURATION_YEARS: "Year(s)",
  FINANCING_DURATION_MONTHS: "Month(s)",
  FINANCING_VERIFY_ADDRESS: "Verify Address",
  FINANCING_BILLING_ADDRESS_QUESTION: "What will be your billing address?",
  FINANCING_CAR_PARKING_LOCATION_QUESTION: "Where will you park your car?",
  FINANCING_SAME_AS_CURRENT_ADDRESS: "Same as my current address",
  FINANCING_PRICE_AND_PRODUCT_DISCLAIMER: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  FINANCING_DRIVER_LICENSE_UPLOAD: "Upload your driver’s license",
  FINANCING_DRIVER_LICENSE_FRONT_UPLOAD: "Upload the image of the front side of your driver's license",
  FINANCING_DRIVER_LICENSE_BACK_UPLOAD: "Upload the image of the back side of your driver's license",
  FINANCING_CONFIRM_DRIVER_LICENSE_DETAILS: "Confirm your driver’s license details",
  FINANCING_LICENSE_NUMBER_FIELD_LABEL: "License Number",
  FINANCING_LICENSE_ISSUING_STATE_FIELD_LABEL: "License Issuing State",
  FINANCING_EXPIRATION_FIELD_LABEL: "Expiration",
  FINANCING_EMPLOYMENT_TYPE_FIELD_LABEL: "Employment Type",
  FINANCING_EMPLOYER_NAME_FIELD_LABEL: "Employer Name",
  FINANCING_EMPLOYER_PHONE_NUMBER_FIELD_LABEL: "Employer Phone Number",
  FINANCING_OCCUPATION_FIELD_LABEL: "Occupation",
  FINANCING_EMPLOYED_SINCE_FIELD_LABEL: "Employed Since",
  FINANCING_GROSS_ANNUAL_INCOME_FIELD_LABEL: "Gross Annual Income",
  FINANCING_OTHER_ANNUAL_INCOME_OPTIONAL_FIELD_LABEL: "Other Annual Income (Optional)",
  FINANCING_SOURCE_OF_OTHER_INCOME_FIELD_LABEL: "Source Of Other Income",
  FINANCING_WORKPLACE_QUESTION: "Where do you work?",
  FINANCING_WORK_HISTORY_REQUEST: "Please share your work history",
  FINANCING_MONTHLY_PAYMENT_FIELD_LABEL: "Monthly Payment",
  FINANCING_BANKRUPTCY_HISTORY_QUESTION: "Have you ever filed for bankruptcy?",
  FINANCING_BANKRUPTCY_HISTORY_YES: "Yes, I have",
  FINANCING_BANKRUPTCY_HISTORY_NO: "No, I have never",
  FINANCING_TITLE_PERSONAL_FINANCE_DETAILS: "Personal Finance Details",
  FINANCING_TITLE_EMPLOYMENT_DETAILS: "Employment Details",
  FINANCING_TITLE_LICENSE_DETAILS: "License Details",
  FINANCING_TITLE_RESIDENCE_DETAILS: "Residence Details",
  FINANCING_TITLE_PERSONAL_DETAILS: "Personal Details",
  FNI_PRODUCTS_TITLE: "Now, let’s help protect your new investment",
  INSURANCE_TITLE: "Do you have an insurance?",
  INSURANCE_SUB_TITLE: "If you already have an insurance, we’ll need some information about your insurance provider and coverage. You can also buy the insurance if you don’t have it already.",
  INSURANCE_ALREADY_HAVE: "Yes, I already have insurance",
  INSURANCE_WANT_TO_BUY: "No, I would like to buy insurance",
  INSURANCE_PROCEED_WITHOUT_INSURANCE: "Don’t want to add right now? Proceed without insurance",
  PAYMENT_TITLE: "Choose your desired payment",
  PAYMENT_SUB_TITLE: "Turn the dial to select the plan that best fits your budget",
  PAYMENT_TERMS_TEXT: "What will be the term for your vehicle?",
  PAYMENT_MILES_TEXT: "How many miles per year you will be driving?",
  PAYMENT_CREDIT_SCORES_TEXT: "What is your credit score?",
  CONTRACTING_CONTACT_US: "Contact us",
  PAYMENT_DISCLAIMER_TEXT: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  PAYMENT_DOWN_PAYMENT_FIELD_LABEL: "Down Payment",
  PAYMENT_12_MONTHS_TERM: "12 Months",
  PAYMENT_24_MONTHS_TERM: "24 Months",
  PAYMENT_36_MONTHS_TERM: "36 Months",
  PAYMENT_48_MONTHS_TERM: "48 Months",
  PAYMENT_CREDIT_SCORE_FAIR: "Fair",
  PAYMENT_CREDIT_SCORE_GOOD: "Good",
  PAYMENT_CREDIT_SCORE_EXCELLENT: "Excellent",
  PAYMENT_CREDIT_SCORE_EXCEPTIONAL: "Exceptional",
  PAYMENT_TITLE_LEASE: "Lease",
  PAYMENT_TITLE_FINANCE: "Finance",
  PAYMENT_FINANCE_TAB_CONTENT: "Finance Tab content",
  REBATES_TITLE: "Do you qualify for any incentive?",
  REBATES_SUB_TITLE_PART_1: "Based on your zip code",
  REBATES_SUB_TITLE_PART_2: ", your purchase is eligible of the following incentives. Please add incentives for which you believe you qualify. We will run the eligibility checks when you submit your order.",
  ADD_DELIVERY_STEP_ONE_CURRENT_TIME_ZONE: "Your current time zone is",
  SCHEDULE_TITLE: "How would you like to get your vehicle?",
  SCHEDULE_SUB_TITLE: "You can choose how would you like to get your vehicle and make changes at any stage according to your preference. Once you submit your order, we will follow up to confirm your schedule.",
  SCHEDULE_DEALERSHIP_STORE_PICKUP: "I will pickup from the dealership store",
  SCHEDULE_DELIVERY_REQUESTED: "I would like to get it delivered",
  SCHEDULE_APPOINTMENT_SCHEDULE_LATER: "I will schedule the appointment later",
  TRADE_IN_TITLE: "Do you have a vehicle to trade-in? Get estimate",
  TRADE_IN_SUB_TITLE: "Get credit toward a new vehicle when you trade-in your eligible vehicle to save time and money. See your trade-in value in seconds.",
  TRADE_IN_YES: "Yes, I have a trade-in",
  TRADE_IN_NO: "No, I don’t have a trade-in",
  ADD_DELIVERY_DETAILS_TITLE: "Add Delivery Details",
  ADD_DELIVERY_STEP_ONE_CHOOSE_DATE_FIELD_LABEL: "Choose Date",
  ADD_DELIVERY_STEP_ONE_FLEXIBLE_WITH_DATE: "I’m flexible with date",
  ADD_DELIVERY_STEP_ONE_CHOOSE_TIME_FIELD_LABEL: "Choose Time",
  ADD_DELIVERY_STEP_ONE_PACIFIC_TIME: "Pacific Time",
  ADD_DELIVERY_STEP_ONE_CHANGE_TIME_ZONE: "Change Time Zone",
  ADD_DELIVERY_STEP_TWO_DELIVERY_LOCATION_QUESTION: "Where do you want to get it delivered?",
  ADD_DELIVERY_STEP_TWO_FREE_DELIVERY_WITHIN_10_MILES: "Free delivery within 10 miles radius from the dealership.",
  ADD_DELIVERY_STEP_THREE_DELIVERY_PREFERENCE_QUESTION: "What is your preference for the delivery?",
  ADD_DELIVERY_STEP_THREE_OPEN_TRANSIT: "Open Transit",
  ADD_DELIVERY_STEP_THREE_ENCLOSED_TRANSIT: "Enclosed Transit",
  ADD_DELIVERY_STEP_THREE_PAYMENT_METHOD_QUESTION: "How do you want to pay for this?",
  ADD_DELIVERY_STEP_THREE_YOU_HAVE_TO_PAY: "You have to pay",
  ADD_DELIVERY_STEP_THREE_FOR_ENCLOSED_TRANSIT: "for enclosed transit.",
  ADD_DELIVERY_STEP_THREE_PAY_UPFRONT: "Pay Upfront",
  ADD_DELIVERY_STEP_THREE_GET_IT_FINANCED: "Get It Financed",
  ADD_ONS_TITLE: "Now, choose add-ons for your vehicle",
  ADD_ONS_SUB_TITLE_PART_1: "Recommended coverage options based on owning your vehicle for 0.00 years while driving more than 00k miles per year. If you want to personalize the your coverage, please fill out the ",
  ADD_ONS_SUB_TITLE_PART_2: "questionnaire",
  ADD_ONS_SEARCH_PLACEHOLDER: "Search product by name or a keyword...",
  ADD_ONS_VIEW_PRODUCT_DETAILS: "View Product Details",
  ADD_ONS_ADD_PRODUCT: "Add Product",
  ADD_ONS_PRICES_NOT_GUARANTEED: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  ADD_ONS_STORAGE: "Storage",
  ADD_ONS_DESIGN: "Design",
  ADD_ONS_ERGONOMICS_COMFORT: "Ergonomics & Comfort",
  ADD_ONS_SOUND: "Sound",
  ADD_ONS_SPORTS_DESIGN: "Sports & Design",
  ADD_ONS_PRE_INSTALLED: "Pre-Installed",
  ADD_ONS_FINANCE_FOR: "Finance for",
  ADD_ONS_VIEW_PRICE_BREAKDOWN: "View Price Breakdown",
  ADD_ONS_NEXT_TRADE_IN: "Next: Trade-In",
  BROWSE_VEHICLE_BY_BRAND_TITLE: "Browse Vehicles by Brand",
  BROWSE_VEHICLE_BY_BRAND_NAME: "Brand Name",
  PAYMENT_BREAKDOWN_POPUP_TITLE: "Deal Summary",
  CONTRACTING_CONFUSION_HELP: "if there is any confusion, we are always here to help",
  PAYMENT_BREAKDOWN_POPUP_PER_MONTH: "mo",
  PAYMENT_BREAKDOWN_POPUP_DUE_AT_SIGNING: "DUE AT SIGNING",
  PAYMENT_BREAKDOWN_POPUP_TOTAL_VEHICLE_PURCHASE_PRICE: "Total Vehicle Purchase Price",
  PAYMENT_BREAKDOWN_POPUP_TOTAL_FINANCED_AMOUNT: "Total Financed Amount",
  PAYMENT_BREAKDOWN_POPUP_INTEREST_RATE: "Interest Rate",
  PAYMENT_BREAKDOWN_POPUP_TERM: "Term",
  PAYMENT_BREAKDOWN_POPUP_MSRP_STICKER_PRICE: "MSRP (Sticker Price)",
  PAYMENT_BREAKDOWN_POPUP_DEALER_PRICE: "Dealer Price",
  PAYMENT_BREAKDOWN_POPUP_AMOUNT_DUE_AT_SIGNING: "Amount Due At Signing",
  PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT: "First Month Payment",
  PAYMENT_BREAKDOWN_POPUP_CAPITALIZED_COST_REDUCTION: "Capitalized Cost Reduction",
  PAYMENT_BREAKDOWN_POPUP_ACQUISITION_FEE: "Acquisition Fee",
  PAYMENT_BREAKDOWN_POPUP_ESTIMATED_REGISTRATION_FEES: "Estimated Registration & Fees",
  PAYMENT_BREAKDOWN_POPUP_NET_CAPITALIZED_COST: "Net Capitalized Cost",
  PAYMENT_BREAKDOWN_POPUP_SECURITY_DEPOSIT: "Security Deposit",
  PAYMENT_BREAKDOWN_POPUP_TITLE_FEE: "Title Fee",
  PAYMENT_BREAKDOWN_POPUP_REGISTRATION_FEES: "Registration Fees",
  PAYMENT_BREAKDOWN_POPUP_PRIVATE_TAG_AGENCY_FEE: "Private Tag Agency Fee",
  PAYMENT_BREAKDOWN_POPUP_TIRE_FEE: "Tire Fee",
  PAYMENT_BREAKDOWN_POPUP_PERMAPLATE: "Permaplate",
  PAYMENT_BREAKDOWN_POPUP_AIR_QUALITY_FEE: "Air Quality Fee",
  PAYMENT_BREAKDOWN_POPUP_DOC_FEE: "Doc Fee",
  PAYMENT_BREAKDOWN_POPUP_BATTERY_FEE: "Battery Fee",
  PAYMENT_BREAKDOWN_POPUP_MVWEA: "MVWEA",
  PAYMENT_BREAKDOWN_POPUP_LICENSE_FEE: "License Fee",
  PAYMENT_BREAKDOWN_POPUP_ESTIMATED_TAXES: "Estimated Taxes",
  PAYMENT_BREAKDOWN_POPUP_SERVICE_PROTECTION_PRODUCTS: "Service & Protection Products",
  PAYMENT_BREAKDOWN_POPUP_REBATES_INCENTIVES: "Rebates & Incentives",
  PAYMENT_BREAKDOWN_POPUP_ADDITIONAL_CHARGES: "Additional Charges",
  PRICING_QUESTION_DIALOG_CONFIGURE_PRICING: "Configure Pricing",
  PRICING_QUESTION_DIALOG_ENTER_ZIP_CODE: "Enter Zip Code",
  QUESTIONNAIRE_POPUP_PREPARE_YOUR_COVERAGE_OPTIONS: "Prepare Your Coverage Options",
  PAYMENT_BREAKDOWN_POPUP_TRADE_IN_VALUE: "Net Trade-In Value",
  PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT_TERMS: "First month's payment will be due 30 days after contract signing date. Offer available to eligible, qualified customers who meet credit requirements in select states only. Monthly payments of $000.00 per month is based on well-qualified customers with above average credit tier 1. Advertised payment does not include applicable taxes. Total due at signing includes $0,000.00 down payment, $000.00 first month’s payment, and $000.00 refundable security deposit. Customer is responsible for: insurance on the vehicle during the lease term, excess wear and tear as defined in the lease, $0.00/mile for excess mileage, and a turn-in fee of $000.00 at lease end. Some customers will qualify for a security deposit waiver. Actual monthly payment may vary based on credit profile. Any offer is valid for 30 days upon pre-qualification and start of check-out and may be combined with other offers unless otherwise stated. Offer is not transferable and is only open only to legal residents who have a valid driver’s license. The APR shown may vary based on credit worthiness, age of vehicle and term of the loan. Your payment maybe higher or lower depending on discounts such as rebates and incentives, state of residency, taxes, fees and credit qualification. See dealership for details. Expires",
  PAYMENT_BREAKDOWN_POPUP_VEHICLE_OFFER_PRICE_FINAL_APPROVAL: "All vehicle offer prices are subject to final approval by the buyer and dealer. Vehicles are subject to prior sale by dealer if sale to buyer is not consummated.",
  PRICING_QUESTION_DIALOG_TELL_US_YOUR_ZIP_CODE: "Tell Us Your Zip Code",
  PRICING_QUESTION_DIALOG_SKIP_FOR_NOW: "Skip For Now",
  PAYMENT_BREAKDOWN_POPUP_FINANCE_LABEL:" Months Finance Summary",
  PAYMENT_BREAKDOWN_POPUP_LEASE_LABEL:" Months Lease Summary",
  PAYMENT_BREAKDOWN_POPUP_TAXES_ESTIMATION_DEALER:"Taxes are estimated based on dealer address.",
  DOT_TEXT: ".",
  QUESTIONNAIRE_POPUP_IM_FLEXIBLE: "I’m Flexible",
  QUESTIONNAIRE_POPUP_CONTINUE: "Continue",
  REVIEW_ORDER_YOUR_ORDER_DETAILS: "Your order details",
  REVIEW_ORDER_PLEASE_REVIEW_YOUR_ORDER_AND_COMPLETE_ANY_OUTSTANDING_TASKS_BEFORE_SUBMISSION_MAKE_SURE_TO_REVIEW_YOUR_PRODUCTS: "Please review your order and complete any outstanding tasks before submission. Make sure to review your products.",
  REVIEW_ORDER_PRICE_BREAKDOWN_CONTROL_FROM_LANE_WILL_LOADS_HERE: "Price Breakdown Control From Lane Will Loads Here",
  REVIEW_ORDER_THE_PRICES_DISPLAYED_ARE_NOT_GUARANTEED_AND_ARE_SUBJECT_TO_CHANGE_RATES_ARE_SUBJECT_TO_FINAL_APPROVAL_NOTHING_ON_THIS_SITE_IS_AN_OFFER_TO_CONTRACT_THE_PRODUCTS_ABOVE_ARE_OPTIONAL_AND_CONTAIN_ADDITIONAL_BENEFITS_LIMITATIONS_AND_EXCLUSIONS_FROM_COVERAGE: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  REVIEW_ORDER_WHATS_NEXT: "What’s Next?",
  REVIEW_ORDER_SUBMIT_YOUR_ORDER: "Submit your order",
  REVIEW_ORDER_CHECK_EACH_STEP_TO_MAKE_SURE_EVERYTHING_IS_TO_YOUR_LIKING_ONCE_DONE_DOUBLE_CHECK_AND_SUBMIT_YOUR_ORDER: "Check each step to make sure everything is to your liking. Once done, double-check and submit your order.",
  REVIEW_ORDER_ORDER_RECEIVED: "Order received",
  REVIEW_ORDER_WE_WILL_REVIEW_AND_REACH_OUT_TO_CONFIRM_ANY_CHANGES_TO_FINALISE_YOUR_ORDER_AND_SUBMIT_YOUR_CREDIT_APPLICATION: "We will review and reach out to confirm any changes to finalise your order and submit your credit application.",
  REVIEW_ORDER_FINALISE_PURCHASE: "Finalise purchase",
  REVIEW_ORDER_YOUR_PAPERWORK_IS_READY_TO_STREAMLINE_YOUR_PURCHASE_SIGN_THE_CONTRACTS_AND_PAY_ONLINE: "Your paperwork is ready to streamline your purchase. Sign the contracts and pay online.",
  REVIEW_ORDER_RECEIVE_YOUR_VEHICLE: "Receive your vehicle",
  REVIEW_ORDER_WE_WILL_PROCESS_YOUR_PAYMENT_FINALISE_THE_APPOINTMENT_WITH_YOU_AND_THEN_GIVE_YOU_THE_KEYS: "We will process your payment, finalise the appointment with you and then give you the keys!",
  ADD_TRADE_IN_POPUP_GREAT_LETS_GET_A_QUICK_ESTIMATE: "Great! Lets get a quick estimate",
  ADD_TRADE_IN_POPUP_WOULD_YOU_LIKE_TO_START_WITH_LICENSE_PLATE_VIN_OR_ANSWER_A_FEW_QUESTIONS_ALTERNATIVELY_YOU_CAN_PROVIDE_MANUAL_ESTIMATE_AS_WELL: "Would you like to start with license plate, VIN or answer a few questions? Alternatively, you can provide manual estimate as well.",
  ADD_TRADE_IN_POPUP_ENTER_LICENSE_PLATE: "Enter License Plate",
  ADD_TRADE_IN_POPUP_ENTER_MAKE_MODEL: "Enter Make/Model",
  ADD_TRADE_IN_POPUP_DONT_HAVE_YOUR_LICENSE_PLATE: "Don’t have your license plate?",
  ADD_TRADE_IN_POPUP_ENTER_YOUR_VIN: "Enter your VIN",
  ADD_TRADE_IN_POPUP_OR: "or",
  ADD_TRADE_IN_POPUP_PROVIDE_MANUAL_ESTIMATE: "provide manual estimate",
  CONDITION_RATING_POPUP_WHAT_IS_THE_CONDITION_OF_YOUR_2024_BMW_4_SERIES_I4_EDRIVE_35: "What is the condition of your 2024 BMW 4 Series i4 eDrive 35?",
  CONDITION_RATING_POPUP_VEHICLE_CONDITION_DESCRIPTION: "This means that the vehicle looks new and is in excellent mechanical condition. This vehicle has never had any paint or bodywork and does not need reconditioning. The engine compartment is clean and free of fluid leaks. This vehicle is free of rust. The body and interior are free of wear or visible defects. The tires all match and are like new. This vehicle has a clean title history and will pass a safety and smog inspection. This vehicle has complete and verifiable service records.",
  LICENSE_PLATE_POPUP_LICENSE_PLATE_NUMBER_QUESTION: "What is your license plate number?",
  MAKE_MODEL_POPUP_2024_BMW_4_SERIES_TRIM_LEVEL_QUESTION: "The 2024 BMW 4 Series came in a couple different trim levels. Which is yours?",
  MAKE_MODEL_POPUP_TRIM_LEVEL_QUESTION: "What is your trim level?",
  MILES_POPUP_MILES_QUESTION: "How many miles are on your 2024 BMW 4 Series i4 eDrive 35?",
  MILES_POPUP_VEHICLE_YEAR_MAKE_MODEL_QUESTION: "Type your vehicle year, make, model…",
  MILES_POPUP_STILL_MAKING_PAYMENTS_QUESTION: "Are you still making payments on your vehicle?",
  MILES_POPUP_YES_OPTION: "Yes",
  MILES_POPUP_NO_OPTION: "No",
  MILES_POPUP_LOAN_TYPE_QUESTION: "What kind of loan is it?",
  MILES_POPUP_ESTIMATED_PAYOFF_QUESTION: "Estimated Payoff Amount",
  PROVIDE_MANUAL_ESTIMATE_POPUP_VEHICLE_QUESTION: "What is your vehicle?",
  PROVIDE_MANUAL_ESTIMATE_POPUP_VEHICLE_YEAR_MAKE_MODEL_QUESTION: "Type your vehicle year, make, model…",
  PROVIDE_MANUAL_ESTIMATE_POPUP_ESTIMATED_VALUE_QUESTION: "Estimated Value",
  PROVIDE_MANUAL_ESTIMATE_POPUP_HOW_MANY_MILES_QUESTION: "How many miles have you driven your vehicle",
  PROVIDE_MANUAL_ESTIMATE_POPUP_MILES_QUESTION: "Miles",
  TRIM_LEVEL_POPUP_TRIM_LEVEL_DESCRIPTION: "A trim level identifies which equipment and features are included with the vehicle. Higher trim levels come with better features at a higher price versus basic features in an entry-level trim. Identifying a vehicle’s trim level influences the price of the vehicle.",
  TRIM_LEVEL_POPUP_WHAT_IS_A_TRIM_LEVEL_QUESTION: "What is a trim level?",
  TRIM_LEVEL_POPUP_WHERE_TO_FIND_TRIM_LEVEL_INFORMATION_DESCRIPTION: "If you are not familiar with your trim level, it can be found in the owner’s manual, original sales invoice, or on the side or rear of the vehicle itself.",
  VIN_ONE_POPUP_TELL_US_YOUR_VIN_QUESTION: "Tell us your VIN",
  VIN_ONE_POPUP_17_LETTERS_OR_NUMBERS_DESCRIPTION: "17 letters or numbers",
  VIN_ONE_POPUP_DONT_KNOW_YOUR_VIN_DESCRIPTION: "Don’t know your VIN? You can find the VIN on driver’s side dashboard, driver’s side door jam, under the hood near the engine block, or on your registration, insurance, title, bill of sale.",
  OTHERS_NEXT: "Next",
  DANISH_IRFAN: "Danish Irfan",
  NETSOL_TECHNOLOGIES_INC: "Netsol Technologies Inc.",
  ADDONS_TOTAL: "Total",
  ADD_ONS_VIEW_PRODUCT_BROCHURE: "View Product Brochure",
  ADD_ONS_VIEW_PRODUCT_VIDEO: "View Product Video",
  ADD_ONS_REMOVE_PRODUCT: "Remove Product",
  INSURANCE_EXPIRATION: "Expiration",
  INSURANCE_PROVIDER_ADDRESS: "Insurance Provider Address",
  INSURANCE_PROOF: "Proof of Insurance",
  INSURANCE_SUPPORTED_FORMATS: "Supported formats: JPG, JPEG, PNG.",
  INSURANCE_COMPANY_NAME: "Insurance Company Name",
  INSURANCE_CONTACT: "Insurance Contact",
  INSURANCE_POLICY_NUMBER: "Policy Number",
  INSURANCE_EDIT_INSURANCE_DETAILS: "Edit Insurance Details",
  INSURANCE_REMOVE_INSURANCE: "Remove Insurance",
  INSURANCE_REMOVE_INSURANCE_DETAILS_CONFIRMATION: "Are you sure you want to remove insurance details?",
  INSURANCE_UNDO_ACTION_CONFIRMATION: "Once confirmed, you cannot undo this action.",
  OTHERS_CONFIRM: "Confirm",
  OTHERS_CANCEL: "Cancel",
  PRICE_CHANGE_DISCLAIMER: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  FINANCE_FOR: "Finance for",
  PER_MONTH: "mo",
  CONTRACTING_VIEW_SIGN_CONTRACTS: "View and digitally sign contracts",
  VIEW_PRICE_BREAKDOWN: "View Price Breakdown",
  FOOTER_LINK_TEXT: "View Price Breakdown",
  FOOTER_SUBTITLE: "mo",
  FOOTER_TITLE: "Finance for",
  FOOTER_TITLE_LEASE: "Lease for",
  FOOTER_BUTTON_TEXT: "Next: F&I Products",
  RESET_PASSWORD_TITLE: "Reset Your Password",
  RESEND_PASSWORD_EMAIL_NOT_RECEIVED_TEXT: "Didn’t receive reset password email?",
  FILE_DRAG_DROP_HOVER_LABEL_2: "browse",
  ADDRESS_LAYOUT_MOVE_IN_DATE_VALIDATION: "Please enter a valid Move-In date",
  ADDRESS_LAYOUT_MOVE_IN_DATE_FUTURE_DATE_INVALID: "Move-In Date can not be a future date",
  ADDRESS_LAYOUT_SELECT_COUNTRY: "Select Country",
  ADDRESS_LAYOUT_SAME_AS: "Same As",
  ADDRESS_LAYOUT_ADDRESS_LINE_1: "Address Line 1",
  ADDRESS_LAYOUT_ADDRESS_LINE_2_OPTIONAL: "Address Line 2 (Optional)",
  ADDRESS_LAYOUT_ONLY_ALPHABETS_ALLOWED: "Only alphabets are allowed",
  ADDRESS_LAYOUT_CITY: "City",
  ADDRESS_LAYOUT_IS_REQUIRED: "is required",
  ADDRESS_LAYOUT_POSTAL_CODE: "Postal Code",
  ADDRESS_LAYOUT_STATE_PROVINCE_REGION: "State/Province/Region",
  ADDRESS_LAYOUT_CONTACT_NUMBER: "Contact Number",
  ADDRESS_LAYOUT_MM_YYYY: "MM/YYYY",
  ADDRESS_LAYOUT_MOVE_IN_DATE: "Move-In Date",
  ADDRESS_LAYOUT_DURATION: "Duration",
  ADDRESS_LAYOUT_DURATION_YEARS: "year(s)",
  ADDRESS_LAYOUT_VERIFY_ADDRESS: "Verify Address",
  ADDRESS_LAYOUT_CANCEL: "Cancel",
  ADDRESS_LAYOUT_CONFIRM: "Confirm",
  ADDRESS_LAYOUT_ADDRESS_LINE_2: "Address Line 2",
  ADDRESS_LAYOUT_STATE: "State",
  ADDRESS_LAYOUT_ZIP_CODE: "Zip Code",
  ADDRESS_LAYOUT_COUNTY: "County",
  INSURANCE_PROVIDER_NAME_IS_REQUIRED: "Insurance Provider Name is required",
  INSURANCE_AGENT_NAME_IS_REQUIRED: "Insurance Agent Name is required",
  INSURANCE_POLICY_NUMBER_IS_REQUIRED: "Policy Number is required",
  INSURANCE_EXPIRATION_IS_REQUIRED: "Expiration is required",
  INSURANCE_EXPIRATION_DATE_CANNOT_BE_EQUAL_OR_LESS_THAN_CURRENT_DATE: "Expiration Date cannot be equal to or lesser than current date.",
  INSURANCE_ATLEAST_ONE_IMAGE_IS_REQUIRED: "Atleast one Image is required",
  INSURANCE_ADDRESS_LINE_1_IS_REQUIRED: "Address Line 1 is required",
  INSURANCE_CITY_IS_REQUIRED: "City is required",
  INSURANCE_STATE_NAME_IS_REQUIRED: "State Name is required",
  INSURANCE_ZIP_CODE_IS_REQUIRED: "Zip Code is required",
  PAYMENT_MONTHLY_PAYMENT_FIELD_LABEL: "Monthly Payment",
  PAYMENT_DUE_AT_SIGNING_AMOUNT: "Due at Signing Amount",
  PAYMENT_60_MONTHS_TERM: "60 Months",
  PAYMENT_72_MONTHS_TERM: "72 Months",
  PAYMENT_YOUR_APR: "Your APR will be",
  OTHERS_MONTHS: "Months",
  PAYMENT_APR_TEXT: "Your APR will be",
  PAYMENTS_VALUE_CANNOT_BE_LESS_THAN: "Value can not be less than",
  PAYMENTS_VALUE_CANNOT_BE_GREATER_THAN: "Value can not be greater than",
  NEXT_TEXT: "Next",
  CONFIRM_TEXT: "Confirm",
  CANCEL_TEXT: "Cancel",
  MONTHS_TEXT: "Months",
  FNI_SUB_TITLE_PART_1: "Recommended coverage options based on owning your vehicle for 0.00 years while driving more than 00k miles per year. If you want to personalize the your coverage, please fill out the",
  FNI_SUB_TITLE_PART_2: "questionnaire",
  FNI_SEARCH_PLACEHOLDER: "Search product by name or a keyword...",
  FNI_VIEW_PRODUCT_DETAILS: "View Product Details",
  PAYMENT_BREAKDOWN_POPUP_EXPIRES: "Expires",
  FNI_ADD_PRODUCT: "Add Product",
  FNI_ADDED: "Added",
  FNI_HEADER_TITLE: "Now, let’s help protect your new investment",
  FNI_HEADER_SUB_TITLE: "Recommended coverage options based on owning your vehicle for 0.00 years while driving more than 00k miles per year. If you want to personalize the your coverage, please fill out the",
  FNI_QUESTIONNAIRE: "questionnaire",
  FNI_VIEW_PRODUCT_TEXT: "View Product Details",
  FNI_ADD_PRODUCT_TEXT: "Add Product",
  FNI_REMOVE_PRODUCT_TEXT: "Remove Product",
  FNI_VIEW_PRODUCT_BROCHURE: "View Product Brochure",
  FNI_ADD_TEXT: "Add",
  FNI_ADDED_TEXT: "Added",
  FNI_FOOTER_TEXT: "The prices displayed are not guaranteed and are subject to change. Rates are subject to final approval. Nothing on this site is an offer to contract. The products above are optional and contain additional benefits, limitations and exclusions from coverage.",
  FNI_TOTAL_PRICE_TEXT: "Total Price",
  FINANCING_CUSTOMER_DETAILS_TEXT: "Tell us about yourself",
  RESET_PASSWORD_PASSWORD_HELPER_TEXT: "The password should be 8 to 15 characters long, which must include uppercase, lowercase, digit and special character.",
  FINANCING_PREFIX_TEXT: "Prefix",
  FINANCING_MIDDLE_NAME_OPTIONAL_TEXT: "Middle Name (Optional)",
  FINANCING_SUFFIX_TEXT: "Suffix",
  FINANCING_EMAIL_TEXT: "Email",
  UPLOAD_FILE_CLICK_OR_DRAG_TEXT: "Click or drag to upload file",
  FINANCING_DURATION_YEARS_LOWERCASE: "year(s)",
  FINANCING_DURATION_MONTHS_LOWERCASE: "month(s)",
  JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE: "Payments",
  JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE: "F&I Products",
  JOURNEY_LAYOUT_STEP_ITEMS_REBATES_TITLE: "Rebates",
  MODEL_NO_TEXT: "Model No",
  STOCK_NO_TEXT: "Stock No",
  VIN_TEXT: "VIN",
  WINDOW_STICKER_TEXT: "Window Sticker",
  PAYMENT_BREAKDOWN_POPUP_MONTHLY_PAYMENT: "MONTHLY PAYMENT",
  PAYMENT_BREAKDOWN_POPUP_RATE_TEXT: "Rate",
  PAYMENT_BREAKDOWN_POPUP_ANNUAL_MILEAGE_TEXT: "Annual Mileage",
  PAYMENT_BREAKDOWN_POPUP_SELLING_PRICE_TEXT: "Selling Price",
  PAYMENT_BREAKDOWN_POPUP_DOWN_PAYMENT_TEXT: "Down Payment",
  PAYMENT_BREAKDOWN_POPUP_SALES_TAX: "Sales Tax",
  OPTIONS_TEXT: "Options",
  PAYMENT_BREAKDOWN_POPUP_DAYS_AFTER_CONTRACT_SIGNING: "days after contract signing date.",
  PAYMENT_BREAKDOWN_POPUP_OFFER_AVAILABLE_ELIGIBLE_QUALIFIED_CUSTOMERS: "Offer available to eligible, qualified customers who meet credit requirements in select states only.",
  PAYMENT_BREAKDOWN_POPUP_MONTHLY_PAYMENTS_OF: "Monthly payments of",
  PAYMENT_BREAKDOWN_POPUP_PER_MONTH_IS_BASED_ON: "per month is based on well-qualified customers with above average credit tier 1.",
  PAYMENT_BREAKDOWN_POPUP_ADVERTISED_PAYMENT_NOT_INCLUDING_TAXES: "Advertised payment does not include applicable taxes.",
  PAYMENT_BREAKDOWN_POPUP_TOTAL_DUE_AT_SIGNING_INCLUDES: "Total due at signing includes",
  PAYMENT_BREAKDOWN_POPUP_DOWN_PAYMENT_LOWERCASE: "down payment",
  PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT_LOWERCASE: "first month’s payment",
  AND_TEXT_LOWERCASE: "and",
  PAYMENT_BREAKDOWN_POPUP_REFUNDABLE_SECURITY_DEPOSIT: "refundable security deposit.",
  PAYMENT_BREAKDOWN_POPUP_CUSTOMER_IS_RESPONSIBLE_FOR: "Customer is responsible for: insurance on the vehicle during the lease term, excess wear and tear as defined in the lease,",
  PAYMENT_BREAKDOWN_POPUP_EXCESS_MILEAGE: "for excess mileage,",
  MILE_TEXT_LOWERCASE: "mile",
  PAYMENT_BREAKDOWN_POPUP_TURN_IN_FEE: "and a turn-in fee of",
  PAYMENT_BREAKDOWN_POPUP_AT_LEASE_END: "at lease end.",
  PAYMENT_BREAKDOWN_POPUP_CUSTOMERS_QUALIFY_DEPOSIT_WAIVER: "Some customers will qualify for a security deposit waiver.",
  PAYMENT_BREAKDOWN_POPUP_PAYMENT_VARY_BY_CREDIT_PROFILE: "Actual monthly payment may vary based on credit profile.",
  PAYMENT_BREAKDOWN_POPUP_OFFER_VALID_FOR: "Any offer is valid for",
  PAYMENT_BREAKDOWN_POPUP_DAYS_PRE_QUALIFICATION_CHECK_OUT_START: "days upon pre-qualification and start of check-out and may be combined with other offers unless otherwise stated.",
  PAYMENT_BREAKDOWN_POPUP_OFFER_NOT_TRANSFERABLE: "Offer is not transferable and is only open only to legal residents who have a valid driver’s license.",
  PAYMENT_BREAKDOWN_POPUP_PAYMENT_MAY_VARY_WITH_DISCOUNTS: "Your payment maybe higher or lower depending on discounts such as rebates and incentives, state of residency, taxes, fees and credit qualification.",
  PAYMENT_BREAKDOWN_POPUP_DEALERSHIP_DETAILS: "See dealership for details.",
  PAYMENT_BREAKDOWN_POPUP_VEHICLE_PRICES_SUBJECT_TO_APPROVAL: "All vehicle offer prices are subject to final approval by the buyer and dealer.",
  PAYMENT_BREAKDOWN_POPUP_VEHICLES_SUBJECT_TO_PRIOR_SALE: "Vehicles are subject to prior sale by dealer if sale to buyer is not consummated.",
  PAYMENT_BREAKDOWN_POPUP_APR_MAY_VARY: "The APR shown may vary based on credit worthiness, age of vehicle and term of the loan.",
  IS_REQUIRED_TEXT: "is required",
  TEST_PLACEHOLDER: "Test Placeholder",
  TEST_PLACEHOLDER_1: "Test Placeholder 1",
  TEST_PLACEHOLDER_2: "TEST_PLACEHOLDER_2",
  TEST_PLACEHOLDER_3: "Test Placeholder 33",
  RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED: "Address Line 1 is required",
  RESIDENCE_DETAILS_CITY_IS_REQUIRED: "City is required",
  RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED: "State Name is required",
  RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED: "Zip Code is required",
  RESIDENCE_DETAILS_MOVE_IN_DATE_IS_REQUIRED: "Move-In Date is required",
  RESIDENCE_DETAILS_MOVE_IN_DATE_REQUIRED_FORMAT: "Move-In Date should be in MM/YYYY format",
  RECORD_UPDATED_SUCCESSFULLY_TEXT: "Record updated successfully",
  HEADER_MENU_HOME_TEXT: "Home",
  HEADER_MENU_NEW_INVENTORY_TEXT: "Inventory",
  HEADER_MENU_PRE_OWNED_INVENTORY_TEXT: "Pre-Owned Inventory",
  HEADER_MENU_CONTACT_US_TEXT: "Contact Us",
  HEADER_MENU_LOGIN_TEXT: "Login",
  HEADER_MENU_CREATE_ACCOUNT_TEXT: "Create Account",
  HEADER_MENU_SEARCH_VEHICLE_PLACEHOLDER_TEXT: "Search vehicle by name or a keyword...",
  ONE_1: "1",
  RESET_PASSWORD_SEO_BUTTON_TEXT: "Save Password",
  TWO_2: "2",
  TWENTY_FOUR_NUMBER: "24",
  JUST_TESTING: "JUST_TESTING EN",
  ELEVEN_NUMBER: "11",
  TWELVE_NUMBER: "12",
  THIRTEEN_NUMBER: "13",
  FOURTEEN_NUMBER: "14",
  FIFTEEN_NUMBER: "15",
  SIXTEEN_NUMBER: "16",
  SEVENTEEN_NUMBER: "17",
  EIGHTEEN_NUMBER: "18",
  NINETEEN_NUMBER: "19",
  TWENTY_NUMBER: "20",
  TWENTY_ONE_NUMBER: "21",
  TWENTY_TWO_NUMBER: "22",
  TWENTY_THREE_NUMBER: "23",
  TWENTY_FIVE_NUMBER: "25",
  TWENTY_SIX_NUMBER: "26",
  TWENTY_SEVEN_NUMBER: "27",
  TWENTY_EIGHT_NUMBER: "28",
  TWENTY_NINE_NUMBER: "29",
  THIRTY_NUMBER: "30",
  THIRTY_ONE_NUMBER: "31",
  THIRTY_TWO_NUMBER: "32",
  THIRTY_THREE_NUMBER: "33",
  THIRTY_FOUR_NUMBER: "34",
  THIRTY_FIVE_NUMBER: "35",
  THIRTY_SIX_NUMBER: "36",
  THIRTY_SEVEN_NUMBER: "37",
  THIRTY_EIGHT_NUMBER: "38",
  THIRTY_NINE_NUMBER: "39",
  FORTY_NUMBER: "40",
  FORTY_ONE_NUMBER: "41",
  FORTY_TWO_NUMBER: "42",
  FORTY_THREE_NUMBER: "43",
  THIRTY_THREE_NUMBER_II: "33",
  THIRTY_FOUR_NUMBER_II: "34",
  THIRTY_FIVE_NUMBER_II: "35",
  THIRTY_SIX_NUMBER_II: "36",
  THIRTY_SEVEN_NUMBER_II: "37",
  THIRTY_EIGHT_NUMBER_II: "38",
  THIRTY_NINE_NUMBER_II: "39",
  FORTY_NUMBER_II: "40",
  FORTY_ONE_NUMBER_II: "41",
  FORTY_TWO_NUMBER_II: "42",
  FORTY_THREE_NUMBER_II: "43",
  FORGOT_PASSWORD_TITLE: "Forgot Password?",
  EXPIRED_LINK_TITLE: "Link Expired!",
  ORDER_UPDATE_POPUP_TITLE:"Your order has been updated",
  ORDER_UPDATE_POPUP_SUB_TITLE:"There has been some updates in this order and the payment amount may be updated. Please review the price breakdown before proceeding with this order.",
  FORGOT_PASSWORD_EMAIL_FIELD_LABEL: "Enter Your Registered Email Address",
  FORGOT_PASSWORD_HELP_TEXT: "Please enter your registered email address for security purposes. When you receive the reset password email, please follow the instructions.",
  FORGOT_PASSWORD_BUTTON_TEXT: "Send Reset Link",
  FORGOT_PASSWORD_SEO_BUTTON_TEXT: "Send Reset Link",
  CONTRACTING_EMAIL_SENT: "An email has been sent to",
  USER_PROFILE_CONTACT_NUMBER: "Contact Number",
  EXPIRED_LINK_BUTTON_TEXT: "Resend Verification Link",
  USER_PROFILE_FIRST_NAME: "First Name",
  EXPIRED_LINK_SUBTITLE: "This link has been expired. Please click the button below to regenerate the link.",
  USER_PROFILE_LAST_NAME: "Last Name",
  EXPIRED_LINK_SEO_BUTTON_TEXT: "Resend Verification Link",
  USER_PROFILE_PASSWORD: "Password",
  USER_PROFILE_CONFIRM_PASSWORD: "Confirm Password",
  USER_PROFILE_TITLE: "Let’s Get To Know",
  MILAGE_TEXT: "Milage",
  EMPLOYMENT_INFO_ANNUAL_OTHER_INCOME_TEXT: "Annual Other Income",
  FOURTY_FOUR_NUMBER: "44",
  RESEND_PASSWORD_RESET_LINK_TEXT: "The reset link is valid for",
  RESEND_PASSWORD_TITLE: "Password Reset Link Sent",
  MINUTES_TEXT: "minutes",
  CONTRACTING_VIEW_SIGN_LINK_INSTRUCTIONS: "with a link to view and sign the contracts. Please open the link and add signatures where required. You can continue with your order once all the signatories have signed these contracts.",
  RESEND_PASSWORD_RESET_EMAIL_TEXT: "A password reset email has been sent to",
  RESEND_PASSWORD_UPDATE_LINK_TEXT: "Please click the link in the email to update your password.",
  FINISH_PROFILE_SETUP_BUTTON_TEXT: "Finish Profile Setup",
  SIGN_IN_FORGET_LINK_LABEL: "Forgot your password?",
  JOURNEY_LAYOUT_STEP_ITEMS_REVIEW_ORDER_TITLE: "Review Order",
  FINANCING_ADDRESS_LINE_1_IS_REQUIRED: "Address Line 1 is required",
  FINANCING_CITY_IS_REQUIRED: "City is required",
  FINANCING_STATE_NAME_IS_REQUIRED: "State Name is required",
  FINANCING_ZIP_CODE_IS_REQUIRED: "Zip Code is required",
  FINANCING_EMPLOYER_NAME_REQUIRED: "Employer Name is required",
  FINANCING_EMPLOYER_NAME_MAX_LENGTH: "Employer Name maximum length is up to 60 characters",
  FINANCING_EMPLOYER_PHONE_NUMBER_REQUIRED: "Employer Phone Number is required",
  FINANCING_VALID_EMPLOYER_PHONE_NUMBER_REQUIRED: "Valid Employer Phone Number is required",
  FINANCING_OCCUPATION_REQUIRED: "Occupation is required",
  FINANCING_OCCUPATION_MAX_LENGTH: "Occupation maximum length is up to 60 characters",
  FINANCING_EMPLOYED_SINCE_DATE_REQUIRED: "Employed Since Date is required",
  FINANCING_EMPLOYED_SINCE_DATE_FORMAT: "Employed Since Date should be in MM/YYYY format",
  FINANCING_ANNUAL_EMPLOYMENT_INCOME_REQUIRED: "Annual Employment Income is required",
  FINANCING_SOURCE_OF_OTHER_INCOME_MAX_LENGTH: "Source Of Other Income maximum length is up to 100 characters",
  FINANCING_VALID_EMPLOYED_SINCE_DATE: "Please enter a valid Employed Since Date",
  FINANCING_EMPLOYED_SINCE_FUTURE_DATE_INVALID: "Employed Since Date can not be a future date",
  LICENSE_DETAILS_FIRST_NAME_REQUIRED: "First Name is required",
  LICENSE_DETAILS_LAST_NAME_REQUIRED: "Last Name is required",
  LICENSE_DETAILS_MAX_CHAR_LIMIT: "Maximum Character Limit Is 50",
  LICENSE_DETAILS_LICENSE_NUMBER_REQUIRED: "License Number is required",
  LICENSE_DETAILS_STATE_REQUIRED: "State is required",
  LICENSE_DETAILS_EXPIRY_DATE_REQUIRED: "Expiry Date is required",
  LICENSE_DETAILS_EXPIRY_DATE_VALIDATION: "Expiry date cannot be less than or equal to current date.",
  LICENSE_DETAILS_DATE_OF_BIRTH_REQUIRED: "Date of birth is required",
  LICENSE_DETAILS_DATE_OF_BIRTH_VALIDATION: "Date Of Birth cannot be equal to or greater than current date.",
  PAYMENT_BREAKDOWN_POPUP_NET_FINANCED_AMOUNT: "Net Financed Amount",
  MONDAY_22_APRIL_2024_1: "22nd April, 2024",
  PAYMENT_BREAKDOWN_POPUP_SUBMIT_AGREE_READ_INFO: "By submitting this application, I agree that I have read and understand the Information and Notices.",
  USER_PROFILE_SUBTITLE: "",
  PAYMENT_BREAKDOWN_POPUP_UNDERSTAND_SUBMIT_CREDIT: "I understand that I am submitting a credit application to",
  PAYMENT_BREAKDOWN_POPUP_TRANSMIT_CREDIT_FINANCIAL_SERVICES: "is transmitting a full credit application to Financial Services.",
  REVIEW_ORDER_TITLE: "Your order details",
  REVIEW_ORDER_SUB_TITLE: "Please review your order and complete any outstanding tasks before submission. Make sure to review your products.",
  APR_TEXT: "APR",
  KILOMETERS_TEXT: "Kilometers",
  REVIEW_ORDER_CHECK_ENSURE_SUBMIT_DOUBLE_CHECK: "Check each step to make sure everything is to your liking. Once done, double-check and submit your order.",
  REVIEW_ORDER_REVIEW_FINALIZE_SUBMIT_CREDIT: "We will review and reach out to confirm any changes to finalise your order and submit your credit application.",
  REVIEW_ORDER_SIGN_CONTRACTS_PAY: "Your paperwork is ready to streamline your purchase. Sign the contracts and pay online.",
  REVIEW_ORDER_PAYMENT_FINALISE_APPOINTMENT_KEYS: "We will process your payment, finalise the appointment with you and then give you the keys!",
  REVIEW_ORDER_SUBMIT_ORDER: "Submit Order",
  PAYMENT_BREAKDOWN_POPUP_SUBMIT_AGREE_READ: "By submitting this application, I agree that I have read and understand the",
  PAYMENT_BREAKDOWN_POPUP_INFO_AND_NOTICES: "Information and Notices",
  PERSONAL_FINANCE_RESIDENCE_TEXT: "Residence",
  PERSONAL_FINANCE_MONTHLY_PAYMENT_TEXT: "Monthly Payment",
  PERSONAL_FINANCE_BANKRUPTCY_TEXT: "Bankruptcy",
  PASSWORD_TEXT: "Password",
  CONFIRM_PASSWORD_TEXT: "Confirm Password",
  ENTER_NEW_PASSWORD_TEXT: "Enter New Password",
  CONFIRM_PASSWORD_NOT_MATCHING: "Confirm Password does not match",
  CONTRACTING_APPLICANT: "(Applicant)",
  CONTRACTING_SIGNED_STATUS: "Signed",
  CONTRACTING_NOT_SIGNED_STATUS: "Not Signed",
  CONTRACTING_DOWNLOAD_RE_UPLOAD: "Download and re-upload signed contracts",
  CONTRACTING_MANUAL_SIGNATURE_INSTRUCTIONS: "Download and print the contracts below to add signatures manually. Once done, scan them or take a clear picture and re-upload them with the respective contracts.",
  CONTRACTING_CO_APPLICANT_NOT_SIGNED: "Your co-applicant has not signed the contracts yet to notify him/her to proceed with your order.",
  CONTRACTING_REVIEW_OPTION: "Review",
  CONTRACTING_DELETE_OPTION: "Delete",
  LAST_NAME_TEXT: "Last Name",
  APPOINTMENT_DATE_IS_REQUIRED: "Appointment Date is required",
  SCHEDULE_ZIP_CODE_IS_REQUIRED: "Zip Code is required",
  SCHEDULE_COUNTY_IS_REQUIRED: "County is required",
  SCHEDULE_OPTIONS_PICKUP_TITLE: "In-Store Pickup",
  SCHEDULE_OPTIONS_PICKUP_SUBTITLE: "Free",
  SCHEDULE_OPTIONS_PICKUP_CAPTION: "at 18800 Hawthorne Blvd, Torrance, CA 90504, United States",
  SCHEDULE_LIST_ITEM_SCHEDULED_ON: "Scheduled On",
  SCHEDULE_LIST_ITEM_CONTACT_NUMBER: "Contact Number",
  SCHEDULE_LIST_ITEM_EXPIRATION: "Expiration",
  SCHEDULE_EDIT_SCHEDULE: "Edit Schedule",
  SCHEDULE_REMOVE_SCHEDULE: "Remove Schedule",
  SCHEDULE_OPTIONS_DELIVERY_QUESTION: "I would like to get it delivered",
  SCHEDULE_OPTIONS_DELIVERY_TITLE: "Delivery",
  SCHEDULE_OPTIONS_DELIVERY_SUBTITLE: "Free",
  SCHEDULE_OPTIONS_DELIVERY_CAPTION: "at 18800 Hawthorne Blvd, Torrance, CA 90504, United States",
  SCHEDULE_APPOINTMENT_QUESTION: "I will schedule the appointment later",
  SCHEDULE_APPOINTMENT_TITLE: "Schedule Appointment",
  CHOOSE_DATE_TIME_LABEL_TEXT: "Choose Date & Time",
  SCHEDULE_CHOOSE_DELIVERY_LOCATION: "Choose Delivery Location",
  SCHEDULE_ADDRESS_LINE_1_IS_REQUIRED: "Address Line 1 is required",
  LOGIN_TEXT: "Login",
  CREATE_ACCOUNT_TEXT: "Create Account",
  LOGOUT_TEXT: "Logout",
  CONTACT_US_TEXT: "Contact Us",
  PRE_OWNED_INVENTORY_TEXT: "Pre-Owned Inventory",
  NEW_INVENTORY_TEXT: "Inventory",
  HOME_TEXT: "Home",
  SEARCH_VEHICLE_PLACEHOLDER: "Search vehicle by name or a keyword...",
  EMAIL_VERIFICATION_PRIMARY_DESCRIPTION: "A verification email has been sent to",
  EMAIL_ADDRESS_TEXT: "Email address",
  EMAIL_SENT_MESSAGE_TEXT: "Email Verification link has been sent.",
  EMAIL_VERIFICATION_TITLE: "Verification Email Sent",
  VALID_EMAIL_IS_REQUIRED: "Valid email is required.",
  OTP_PROMPT: "Please provide the 6-digit code below to verify. The code is valid for",
  SIGN_IN_OTP_TITLE: "What’s the code?",
  OTP_VERIFICATION_EMAIL: "Didn’t receive the email?",
  TERMS_AND_CONDITIONS_TEXT: "By continuing, you agree to the",
  MARKETING_MATERIAL_TEXT: "terms and conditions",
  PRODUCT_RELATED_TEXT: "and to receive marketing material related to product(s).",
  FIRST_NAME_HELP_TEXT: "First Name length should not be greater than 20 characters.",
  LAST_NAME_HELP_TEXT: "Last Name length should not be greater than 20 characters.",
  CONTACT_NUMBER_TEXT: "Contact Number",
  PASSWORD_HELP_TEXT: "The password should be 8 to 15 characters long, which must include uppercase, lowercase, digit and special character.",
  ONLY_CHARACTERS_ARE_ALLOWED: "Only characters are allowed",
  INVALID_CONTACT_NUMBER_TEXT: "Contact Number is not valid",
  USER_PROFILE_COMPONENT_TITLE: "Let’s save your account",
  STRIPE_PAYMENT_BASE_MONTHLY_PAYMENT: "Base Monthly Payment",
  STRIPE_PAYMENT_SALES_TAX: "Sales Tax",
  STRIPE_PAYMENT_TITLE: "Let’s get started with payments",
  STRIPE_PAYMENT_SUBTITLE: "To start with your hassle free online payments, go through the due at signing amount summary and continue to pay. You can choose how would you like to pay the amount.",
  STRIPE_PAYMENT_OUTSTANDING_BALANCE: "Outstanding Balance",
  STRIPE_PAYMENT_CONTINUE_PAYMENT: "Continue Payment",
  STRIPE_PAYMENT_AMOUNT_PAID: "Amount Paid",
  STRIPE_PAYMENT_TOTAL_PAYABLE_AMOUNT: "Total Payable Amount",
  STRIPE_PAYMENT_DUE_AT_SIGNING: "Due At Signing",
  STRIPE_PAYMENT_FIRST_MONTH_PAYMENT: "First Month’s Payment",
  STRIPE_PAYMENT_DOWN_PAYMENT: "Down Payment",
  STRIPE_PAYMENT_CONFIRM_PROCEED: "I Confirm & Proceed",
  STRIPE_PAYMENT_GET_STARTED_PAYMENTS: "Lets get started on the payments!",
  STRIPE_PAYMENT_PAYMENT_AUTHORIZATION_PROMPT: "By tapping the button to proceed, you confirm you are authorized to use the credit or debit card provided and authorize us or our third party vendor to make a one-time charge for the total down payment. If your card cannot be charged, your order may be suspended or cancelled, and you will need to resolve the issue to proceed.",
  STRIPE_PAYMENT_ORDER_CANCELLATION_POLICY: "The dealership reserves the right to cancel your order and contract if you do not schedule your appointment for pickup or delivery within 48 hours of receiving available timeslots from the dealership.",
  STRIPE_PAYMENT_CARD_NUMBER_REQUIRED: "Card Number is required",
  STRIPE_PAYMENT_DIGITS_ONLY: "Must be only digits",
  STRIPE_PAYMENT_EXACTLY_16_DIGITS: "Must be exactly 16 digits",
  STRIPE_PAYMENT_CARD_HOLDER_NAME_REQUIRED: "Card Holder Name is required",
  STRIPE_PAYMENT_INVALID_EXPIRATION_DATE: "Not a valid expiration date. Example: MM/YY",
  STRIPE_PAYMENT_EXPIRY_DATE_REQUIRED: "Expiry Date is required",
  STRIPE_PAYMENT_EXACTLY_3_DIGITS: "Must be exactly 3 digits",
  STRIPE_PAYMENT_CVC_REQUIRED: "CVC is required",
  STRIPE_PAYMENT_AMOUNT_TO_PAY_REQUIRED: "Amount To Pay is required",
  STRIPE_PAYMENT_POSITIVE_AMOUNT_ONLY: "Amount must be positive",
  STRIPE_PAYMENT_ADD_NEW_CARD: "Add New Card",
  STRIPE_PAYMENT_CARD_HOLDER_NAME: "Card Holder Name",
  STRIPE_PAYMENT_CARD_NUMBER: "Card Number",
  STRIPE_PAYMENT_EXPIRY_DATE: "Expiry Date",
  STRIPE_PAYMENT_CVC: "CVC",
  STRIPE_PAYMENT_AMOUNT_PAYMENT_FROM_CARD: "Amount To Pay From This Card",
  STRIPE_PAYMENT_MAX_AMOUNT_LIMIT: "Amount must be less than or equal to",
  STRIPE_PAYMENT_PAYMENT_SUCCESSFULLY_PROCESSED: "Your payment has been successfully processed.",
  STRIPE_PAYMENT_TOKEN_CREATION_FAILED: "Token Creation Failed",
  STRIPE_PAYMENT_ERROR_OCCURRED: "Some Thing Went Wrong",
  STRIPE_PAYMENT_ADD_CARD_DETAILS: "Add your card details",
  STRIPE_PAYMENT_PAY_NOW: "Pay Now",
  STRIPE_PAYMENT_TOTAL_DUE_AT_SIGNING: "Total Due At Signing",
  STRIPE_PAYMENT_TYPE_HERE: "Type here…",
  STRIPE_PAYMENT_EXPIRY_DATE_FORMAT: "MM/YY",
  STRIPE_PAYMENT_MAXIMUM_ALLOWED_LIMIT: "Maximum Allowed Limit",
  ORDER_SAVING_IN_PROGRESS: "Order saving in progress",
  ORDER_VALIDATION_IN_PROGRESS: "Order validation in progress",
  ORDER_UPDATE_SUCCESS: "Order updated successfully",
  ORDER_UPDATE_FAIL: "Order updation failed",
  ORDER_SAVE_SUCCESS: "Order Saved successfully",
  ORDER_SAVE_FAILED: "Order saving failed",
  d: "",
  END_USER_LICENSE_AGREEMENT: "Terms and Conditions of Use and End User License Agreement",
  AGREED_TERMS_AND_CONDITIONS: "I Agree to the terms and conditions",
  INVALID_CREDENTIALS_MESSAGE: "Username or password is not valid",
  ZIP_CODE_TELL_US: "Tell Us Your Zip Code",
  ZIP_CODE_REQUIRED: "Zip Code is required",
  ZIP_CODE_LENGTH_5: "Zip Code must be exactly 5 characters",
  ZIP_CODE_ENTER: "Enter the Zip code.",
  ZIP_CODE: "Zip Code",
  ZIP_CODE_SELECT_DEALERSHIP: "Select your preferred dealership",
  ZIP_CODE_RANGE: "Range",
  ZIP_CODE_NO_DEALERSHIP_FOUND: "No Dealership Found!",
  ZIP_CODE_NO_DEALERSHIP_AVAILABLE: "Looks like there is no dealership available in this area. Please try again with another one.",
  ZIP_CODE_MILES_AWAY: "miles away",
  ZIP_CODE_CALCULATE_PRICING: "This will help us to identify and calculate the rates and taxes for you, and to provide you the precise pricing.",
  TRADE_IN_POPUP_YEAR_LABEL: "Year",
  UNITED_STATES_TEXT: "United States",
  TRADE_IN_MANUAL_ESTIMATE_VEHICLE_QUESTION: "What is your vehicle?",
  ZIP_CODE_DEALER_DISTANCE: "Zip Code Dealer Distance",
  TRADE_IN_MANUAL_ESTIMATE_YEAR_MAKE_MODEL_INPUT: "Type your vehicle year, make, model…",
  TRADE_IN_MANUAL_ESTIMATE_GENERIC_INPUT: "Type here…",
  TRADE_IN_MANUAL_ESTIMATE_PAYMENTS_QUESTION: "Are you still making payments on your vehicle?",
  TRADE_IN_MANUAL_ESTIMATE_PAYMENTS_YES: "Yes",
  TRADE_IN_MANUAL_ESTIMATE_PAYMENTS_NO: "No",
  TRADE_IN_MANUAL_ESTIMATE_LOAN_TYPE_QUESTION: "What kind of loan is it?",
  TRADE_IN_MANUAL_ESTIMATE_PAYMENT_TYPE_LEASE: "Lease",
  TRADE_IN_MANUAL_ESTIMATE_PAYMENT_TYPE_FINANCE: "Finance",
  TRADE_IN_ADD_POPUP_MESSAGE: "Great! Lets get a quick estimate",
  SIGN_IN_SUBTITLE_STARTED: "",
  TRADE_IN_ADD_POPUP_PROMPT: "Would you like to start with license plate, VIN or answer a few questions? Alternatively, you can provide manual estimate as well.",
  TRADE_IN_ADD_POPUP_LICENSE_PLATE_PROMPT: "Enter License Plate",
  TRADE_IN_ADD_POPUP_MAKE_MODEL_PROMPT: "Enter Make/Model",
  TRADE_IN_ADD_POPUP_NO_LICENSE_PLATE_PROMPT: "Don’t have your license plate?",
  TRADE_IN_ADD_POPUP_VIN_PROMPT: "Enter your VIN",
  TRADE_IN_ADD_POPUP_OR_TEXT: "or",
  TRADE_IN_ADD_POPUP_ESTIMATE_CONFIRMATION: "provide manual estimate",
  TRADE_IN_CONDITION_POPUP_EXCELLENT_DESCRIPTION: "This means that the vehicle looks new and is in excellent mechanical condition. This vehicle has never had any paint or bodywork and does not need reconditioning. The engine compartment is clean and free of fluid leaks. This vehicle is free of rust. The body and interior are free of wear or visible defects. The tires all match and are like new. This vehicle has a clean title history and will pass a safety and smog inspection. This vehicle has complete and verifiable service records.",
  TRADE_IN_FINAL_POPUP_ESTIMATED_VALUE_LABEL: "The estimated value of your vehicle is",
  TRADE_IN_FINAL_POPUP_FIRM_OFFER_LABEL: "We can give you a firm offer on your",
  TRADE_IN_FINAL_POPUP_CONFIRMATION_MESSAGE: "when we see it in person. Would you like to save the trade-in and proceed or do you want to edit?",
  TRADE_IN_FINAL_POPUP_PROCEED_BUTTON: "Proceed with trade-in",
  TRADE_IN_FINAL_POPUP_EDIT_DETAILS_BUTTON: "Edit details",
  EDIT_TEXT: "Edit",
  TRADE_IN_FINAL_POPUP_DECLINE_LABEL: "Don’t want to trade?",
  TRADE_IN_FINAL_POPUP_REMOVE_TEXT: "Remove",
  TRADE_IN_LICENSE_PLATE_POPUP_PROMPT_LABEL: "What is your license plate number?",
  TRADE_IN_LICENSE_PLATE_POPUP_INPUT_PLACEHOLDER: "Type here…",
  TRADE_IN_LICENSE_PLATE_POPUP_STATE_LABEL: "State",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_THE_TEXT: "The",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_TRIM_PROMPT_LABEL: "came in a couple different trim levels. Which is yours?",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_TRIM_LABEL: "Trim",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_TRIM_LEVEL_PROMPT: "What is your trim level?",
  TRADE_IN_MAKE_MODEL_POPUP_ONE_YEAR_PROMPT_LABEL: "What year is your vehicle?",
  TRADE_IN_MAKE_MODEL_POPUP_ONE_YEAR_LABEL: "Year",
  TRADE_IN_MAKE_MODEL_POPUP_THREE_MODEL_PROMPT_LABEL: "Perfect. And what model?",
  TRADE_IN_MAKE_MODEL_POPUP_THREE_MODEL_LABEL: "Model",
  NO_DATA_IS_FOUND_TEXT: "No Data is Found.",
  TRADE_IN_MAKE_MODEL_POPUP_TWO_MAKE_PROMPT_LABEL: "Thanks! What make is it?",
  TRADE_IN_MAKE_MODEL_POPUP_TWO_MAKE_LABEL: "Make",
  TRADE_IN_MILES_POPUP_MILES_PROMPT_LABEL: "How many miles are on your",
  QUESTION_MARK_TEXT: "?",
  TRADE_IN_MILES_POPUP_VEHICLE_INFO_PLACEHOLDER: "Type your vehicle year, make, model... here...",
  TRADE_IN_MILES_POPUP_PAYMENTS_PROMPT_LABEL: "Are you still making payments on your vehicle?",
  TRADE_IN_MILES_POPUP_PAYMENTS_YES_OPTION: "Yes",
  TRADE_IN_MILES_POPUP_PAYMENTS_NO_OPTION: "No",
  TRADE_IN_MILES_POPUP_LOAN_TYPE_PROMPT_LABEL: "What kind of loan is it?",
  TRADE_IN_MILES_POPUP_LOAN_TYPE_LEASE_OPTION: "Lease",
  TRADE_IN_MILES_POPUP_LOAN_TYPE_FINANCE_OPTION: "Finance",
  TRADE_IN_MILES_POPUP_TYPE_HERE_PLACEHOLDER: "Type here…",
  TRADE_IN_TRIM_LEVEL_POPUP_QUESTION_ONE: "What is a trim level?",
  TRADE_IN_POPUP_MAKE_LABEL: "Make",
  TRADE_IN_TRIM_LEVEL_POPUP_EXPLANATION: "A trim level identifies which equipment and features are included with the vehicle. Higher trim levels come with better features at a higher price versus basic features in an entry-level trim. Identifying a vehicle’s trim level influences the price of the vehicle.",
  TRADE_IN_TRIM_LEVEL_POPUP_INSTRUCTIONS: "If you are not familiar with your trim level, it can be found in the owner’s manual, original sales invoice, or on the side or rear of the vehicle itself.",
  TRADE_IN_VIN_ONE_POPUP_PROMPT_LABEL: "Tell us your VIN",
  TRADE_IN_VIN_ONE_POPUP_FORMAT_LABEL: "17 letters or numbers",
  TRADE_IN_VIN_ONE_POPUP_INPUT_PLACEHOLDER: "Type here…",
  TRADE_IN_VIN_ONE_POPUP_INSTRUCTIONS_LABEL: "Don’t know your VIN? You can find the VIN on driver’s side dashboard, driver’s side door jam, under the hood near the engine block, or on your registration, insurance, title, bill of sale.",
  TRADE_IN_MANUAL_ESTIMATE_INPUT_PLACEHOLDER: "Type here…",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_INPUT_PLACEHOLDER: "Type here…",
  TRADE_IN_MAKE_MODEL_POPUP_ONE_INPUT_PLACEHOLDER: "Type here…",
  LOCATION_TEXT: "Location",
  TRADE_IN_MAKE_MODEL_POPUP_THREE_INPUT_PLACEHOLDER: "Type here…",
  TRADE_IN_MAKE_MODEL_POPUP_TWO_INPUT_PLACEHOLDER: "Type here…",
  FOR_LOWERCASE_TEXT: "for",
  CONTINUE_ORDER_TEXT: "Continue Order",
  TRADE_IN_TRIM_LEVEL_POPUP_QUESTION_TWO: "What is your trim level?",
  TRADE_IN_POPUP_YEAR_MAKE_MODEL_LABEL: "Year, Make, Model",
  TRADE_IN_POPUP_ESTIMATED_VALUE_LABEL: "Estimated Value",
  TRADE_IN_POPUP_MILES_DRIVEN_PROMPT_LABEL: "How many miles have you driven your vehicle",
  TRADE_IN_POPUP_ESTIMATED_PAYOFF_AMOUNT_LABEL: "Estimated Payoff Amount",
  TRADE_IN_POPUP_LENDER_NAME_LABEL: "Lender Name",
  TRADE_IN_POPUP_LOAN_LEASE_BALANCE_LABEL: "Loan Lease Balance",
  TRADE_IN_POPUP_PAYMENT_TYPE_LABEL: "Payment Type",
  TRADE_IN_POPUP_VIN_LABEL: "VIN",
  TRADE_IN_POPUP_MODEL_LABEL: "Model",
  TRADE_IN_POPUP_YEAR_MAKE_MODEL_REQUIRED: "Year, Make and Model are required",
  TRADE_IN_POPUP_VIN_REQUIRED: "VIN is required",
  TRADE_IN_POPUP_VIN_LENGTH_ERROR: "VIN must be exactly 17 characters long",
  TRADE_IN_POPUP_VIN_ERROR: "Something is wrong with your Vin",
  TRADE_IN_POPUP_INVALID_VIN: "Invalid VIN",
  TRADE_IN_POPUP_ESTIMATED_VALUE_REQUIRED: "Estimated Value is required",
  TRADE_IN_POPUP_MILES_REQUIRED: "Miles are required",
  TRADE_IN_POPUP_CONDITION_REQUIRED: "Condition is required",
  TRADE_IN_POPUP_PAYOFF_AMOUNT_REQUIRED: "Payoff Amount is required",
  TRADE_IN_POPUP_LENDER_NAME_REQUIRED: "Lender Name is required",
  TRADE_IN_POPUP_LOAN_LEASE_BALANCE_REQUIRED: "Loan or Lease Balance is required",
  TRADE_IN_POPUP_PAYMENT_TYPE_REQUIRED: "Payment Type is required",
  RATING_FAIR: "Fair",
  TRADE_IN_POPUP_TRIM_REQUIRED: "Trim is required",
  TRADE_IN_POPUP_TRIM_ERROR: "Something is wrong with your Trim",
  TRADE_IN_POPUP_YEAR_REQUIRED: "Year is required",
  TRADE_IN_POPUP_MAKE_REQUIRED: "Make is required",
  TRADE_IN_POPUP_MODEL_REQUIRED: "Model is required",
  TRADE_IN_ESTIMATE_TEXT: "Your vehicle's trade-in estimate is",
  TRADE_IN_PROMPT_GET_ESTIMATE: "Do you have a vehicle to trade-in? Get estimate",
  TRADE_IN_DISCLAIMER_INSPECTION: "This is not an offer to purchase your vehicle. Inspection of your vehicle is required. Final offer may vary depending on condition",
  TRADE_IN_DISCLAIMER_MARKET_VALUE: "and real-time market value.",
  TRADE_IN_BENEFITS_TEXT: "Get credit toward a new vehicle when you trade-in your eligible vehicle to save time and money. See your trade-in value in seconds.",
  TRADE_IN_YES_OPTION: "Yes, I have a trade-in",
  VEHICLE_TEXT: "Vehicle",
  TRADE_IN_NO_OPTION: "No, I don’t have a trade-in",
  TRADE_IN_OFFER_LABEL: "Our Offer",
  TRADE_IN_PAYOFF_AMOUNT_LABEL: "Payoff Amount",
  TRADE_IN_ESTIMATED_MILEAGE_LABEL: "Estimated Mileage",
  TRADE_IN_EQUITY_LABEL: "Trade-In Equity/Inequity",
  TRADE_IN_CONDITION_LABEL: "Condition",
  TRADE_IN_EDIT_DETAILS_BUTTON: "Edit Trade-In Details",
  TRADE_IN_REMOVE_BUTTON: "Remove Trade-In",
  TRADE_IN_PROCEED_WITHOUT_TEXT: "Don’t want to trade? Proceed without trade-in",
  RATING_EXCELLENT: "Excellent",
  RATING_VERY_GOOD: "Very Good",
  RATING_GOOD: "Good",
  TRADE_IN_ESTIMATING_TEXT: "Estimating!",
  TRADE_IN_ESTIMATION_MESSAGE: "Just wait a moment! We are estimating your trade-in.",
  TRADE_IN_ESTIMATION_PROMPT: "Let’s get your trade-in estimate",
  SCHEDULE_REMOVE_APPOINTMENT_DETAILS_CONFIRMATION: "Are you sure you want to remove?",
  SCHEDULE_UNDO_ACTION_CONFIRMATION: "Once deleted, you cannot undo this action.",
  SUBMIT_ORDER_CONTACT_UNAVAILABLE: "The dealer's contact number is not available at the moment. Please contact the dealer through other means.",
  SUBMIT_ORDER_ADDRESS_UNAVAILABLE: "Dealer address not available. Please try again later.",
  SUBMIT_ORDER_SUBMIT_YOUR_ORDER: "Submit your order",
  SUBMIT_ORDER_CHECK_AND_SUBMIT: "Check each step to make sure everything is to your liking. Once done, double-check and submit your order.",
  SUBMIT_ORDER_ORDER_RECEIVED: "Order received",
  SUBMIT_ORDER_REVIEW_AND_CONFIRM: "We'll review and reach out to confirm any changes to finalize your order and submit your credit application.",
  SUBMIT_ORDER_FINALIZE_PURCHASE: "Finalize purchase",
  SUBMIT_ORDER_READY_TO_PURCHASE: "Your paperwork is ready to streamline your purchase. Sign the contracts and pay online.",
  SUBMIT_ORDER_RECEIVE_VEHICLE: "Receive your vehicle",
  SUBMIT_ORDER_PROCESS_PAYMENT_AND_APPOINTMENT: "We'll process your payment, finalize the appointment with you and then give you the keys!",
  SUBMIT_ORDER_CONGRATS_ON_PURCHASE_COMPLETION: "Congratulations on completing your purchase!",
  SUBMIT_ORDER_PROCESSING_ORDER_CONFIRM_APPOINTMENT: "We are processing and finalizing your order. We will confirm your appointment and let you know as soon as we are done and your vehicle is ready for you.",
  SUBMIT_ORDER_MORE_DETAILS_REQUIRED_FOR_ORDER: "We need some more detail regarding your order",
  SUBMIT_ORDER_CANNOT_PROCEED_DIGITALLY: "We cannot proceed with your application digitally. If you want to proceed, please contact our customer care team.",
  SUBMIT_ORDER_CONGRATULATIONS: "Congratulations!",
  SUBMIT_ORDER_CREDIT_APP_APPROVED: "Your Credit Application Has been Approved",
  SUBMIT_ORDER_PAPERWORK_READY_FOR_REVIEW_AND_SIGNING: "Your paperwork is ready for you to review and sign. Once done, pay your due at signing amount and schedule.",
  SUBMIT_ORDER_CREDIT_APP_DECLINED: "Sorry! Your credit application has been declined",
  SUBMIT_ORDER_NEED_MORE_INFO_FOR_CREDIT_APP: "We cannot proceed with your application digitally. If you want to proceed, please contact our customer care team. We need some more information regarding your credit application.",
  SUBMIT_ORDER_CONGRATS_ON_PURCHASE_START: "Congratulations on starting your purchase",
  SUBMIT_ORDER_REVIEW_CONFIRM_CHANGES_FINALIZE_ORDER: "We'll review and reach out to confirm any changes to finalise your order. Your credit application will be submitted after the review. We will let you know as soon as we are done and submit your application.",
  SUBMIT_ORDER_VIEW_ORDER_SUMMARY: "View Order Summary",
  SUBMIT_ORDER_CONTINUE_ORDER: "Continue Order",
  SUBMIT_ORDER_START_CHAT: "Start Chat",
  SUBMIT_ORDER_GET_DIRECTIONS: "Get Directions",
  SUBMIT_ORDER_FREE: "Free",
  SUBMIT_ORDER_WHATS_NEXT: "What’s Next?",
  SUBMIT_ORDER_COMPLETED_STEPS: "Completed Steps",
  SUBMIT_ORDER_PAYMENT: "Payment",
  SUBMIT_ORDER_MONTH: "Mo.",
  SUBMIT_ORDER_F_AND_I_PRODUCTS: "F&I Products",
  SUBMIT_ORDER_PRODUCTS_ADDED: "Products Added",
  SUBMIT_ORDER_ADD_ONS: "Add-Ons",
  SUBMIT_ORDER_TRADE_IN: "Trade-In",
  SUBMIT_ORDER_NONE_ADDED: "None Added",
  SUBMIT_ORDER_INSURANCE: "Insurance",
  SUBMIT_ORDER_SCHEDULE: "Schedule",
  SUBMIT_ORDER_ENCLOSED_TRANSIT_DELIVERY: "Enclosed Transit Delivery",
  SUBMIT_ORDER_FINANCING: "Financing",
  INV_ASSET_FILTER_CONTROL_MAX: "Max",
  SUBMIT_ORDER_CREDIT_APP_SUBMITTED: "Credit App Submitted",
  SUBMIT_ORDER_MONTHS: "Month(s)",
  SUBMIT_ORDER_NEED_HELP: "Need Help?",
  COMMA_TEXT: ",",
  PROFILE_LAYOUT_WELCOME_BACK: "Welcome back",
  PROFILE_LAYOUT_YOUR_ZIP_CODE: "Your Zip Code",
  PROFILE_LAYOUT_YOUR_PROFILE: "Your Profile",
  PROFILE_LAYOUT_ORDERS: "Orders",
  PROFILE_LAYOUT_FAVORITES: "Favorites",
  PROFILE_LAYOUT_APPOINTMENTS: "Appointments",
  PROFILE_LAYOUT_PREFERENCES: "Preferences",
  ABOUT_CARD_ABOUT_YOU: "About you",
  ABOUT_CARD_TYPE_HERE: "Type here…",
  ABOUT_CARD_FULL_NAME: "Full Name",
  ABOUT_CARD_LAST_NAME: "Last Name",
  ABOUT_CARD_EMAIL_ADDRESS: "Email Address",
  ABOUT_CARD_MOBILE_PHONE_NUMBER: "Mobile Phone Number",
  ABOUT_CARD_SAVE_CHANGES: "Save Changes",
  CHANGE_PASSWORD_CHANGE_YOUR_PASSWORD: "Change your password",
  ADDRESS_CARD_YOUR_CURRENT_ADDRESS: "Your current address",
  DOCUMENTS_CARD_YOUR_DOCUMENTS: "Your documents",
  DOCUMENTS_CARD_FRONT_SIDE_DRIVER_LICENSE: "Front side of your driver's license",
  DOCUMENTS_CARD_BACK_SIDE_DRIVER_LICENSE: "Back side of your driver's license",
  DOCUMENTS_CARD_PROOF_OF_INSURANCE: "Proof of Insurance",
  PROFILE_TEXT: "Profile",
  HELLO_TEXT: "Hello!",
  ORDER_ID_TEXT: "Order ID",
  FILTERS_TEXT: "Filters",
  TRADE_IN_POPUP_NOT_FOUND: "No results found against this VIN",
  LOADER_TEXT: "Loader",
  INV_ASSET_FILTER_CONTROL_NEW: "New",
  INV_ASSET_FILTER_CONTROL_PRE_OWNED: "Pre-Owned",
  INV_ASSET_FILTER_CONTROL_CERTIFIED_PRE_OWNED: "Certified Pre-Owned",
  INV_ASSET_FILTER_CONTROL_NEW_INVENTORY: "INVENTORY",
  INV_ASSET_FILTER_CONTROL_ALL_BRANDS: "All Brands",
  INV_ASSET_FILTER_CONTROL_STARTING_AT: "Starting at",
  INV_ASSET_FILTER_CONTROL_MSRP: "MSRP",
  INV_ASSET_FILTER_CONTROL_ASSET_CONDITION: "Asset Condition",
  INV_ASSET_FILTER_CONTROL_MIN: "Min",
  INV_ASSET_FILTER_CONTROL_SELLING_PRICE: "Selling Price",
  INV_ASSET_FILTER_CONTROL_FROM: "From",
  INV_ASSET_FILTER_CONTROL_TO: "To",
  INV_ASSET_FILTER_CONTROL_YEAR: "Year",
  INV_ASSET_FILTER_CONTROL_MAKE: "Make",
  INV_ASSET_FILTER_CONTROL_MODEL: "Model",
  INV_ASSET_FILTER_CONTROL_TRIM: "Trim",
  INV_ASSET_FILTER_CONTROL_MAKE_MODEL_TRIM: "Make / Model / Trim",
  INV_ASSET_FILTER_CONTROL_VIN: "VIN",
  INV_ASSET_FILTER_CONTROL_STOCK: "Stock",
  INV_ASSET_FILTER_CONTROL_STOCK_NUMBER: "Stock Number",
  INV_ASSET_FILTER_CONTROL_RESET_FILTERS: "Reset Filters",
  INV_ASSET_FILTER_CONTROL_APPLY_FILTERS: "Apply Filters",
  INV_ASSET_LIST_VIEW_CONTROL_RECOMMENDED: "Recommended",
  INV_ASSET_LIST_VIEW_CONTROL_COLOR: "Color",
  INV_ASSET_LIST_VIEW_CONTROL_TRANSMISSION: "Transmission",
  INV_ASSET_LIST_VIEW_CONTROL_TEST_DRIVE: "Test Drive",
  INV_ASSET_LIST_VIEW_CONTROL_PRICE: "Price",
  INV_ASSET_LIST_VIEW_CONTROL_CLEAR_ALL: "Clear All",
  INV_ASSET_LIST_VIEW_CONTROL_RESULTS_FOR: "results for",
  PENDING_TRADE_IN_ASSETS: "Great news! We are reviewing all the details of your deal to ensure everything is perfect. Please bear with us as we fine-tune everything for you.",
  PENDING_TRADE_IN_DESCRIPTION: "Feel free to call or drop by the dealership if you would like more detailed information about your order. We are here to make sure you have all the information you need! Dealership Address and phone detail should be displayed here",
  TRADE_IN_REMOVAL_WARNING: "Are you sure you want to remove?",
  INSPECTION_TRADE_IN_ASSETS_DESCRIPTION: "Unfortunately, we're unable to provide an online quote for your trade-in at the moment. Don't worry though, simply give us a call or swing by the dealership with your trade-in asset, and we'll be delighted to assist you in person.",
  INV_ASSET_LIST_VIEW_CONTROL_BLANK: "Blank",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_FINDING_YOUR_VIN: "Finding your VIN!",
  TRADE_IN_MAKE_MODEL_POPUP_FOUR_VIN_LOOKUP_MESSAGE: "Just wait a moment! We are looking for your VIN.",
  OTP_GO_BACK_PROMPT: "Go Back",
  TRADE_IN_REMOVAL_CONFIRMATION: "Once removed, the details you have added will be lost and you have to add the details again if needed.",
  TRADE_IN_REMOVAL_ACTION: "Remove",
  USER_PROFILE_CONTACT_INFO_PROMPT: "Let us know how to reach you",
  USER_PROFILE_AGREEMENT_PROMPT: "By continuing, you agree to the",
  TRADE_IN_IMAGE_UPLOAD_PROMPT: "Upload your trade-in vehicle images",
  TRADE_IN_IMAGE_UPLOAD_INSTRUCTIONS: "Please upload photos of your vehicle from all sides. Make sure the photos are clear. You can upload multiple photos, so the dealership can view and respond accordingly.",
  TRADE_IN_IMAGE_UPLOAD_MAX_LIMIT: "You can only upload a maximum of",
  IMAGES_LOWERCASE_TEXT: "images",
  OTP_INVALID_PASSWORD_MESSAGE: "Invalid Password",
  ORDER_STATUS_COMPLETED: "Completed",
  ORDER_STATUS_DRAFT: "Draft",
  ORDER_STATUS_CANCELLED: "Cancelled",
  ORDER_STATUS_APPROVED: "Approved",
  ORDER_STATUS_DECLINED: "Declined",
  ORDER_STATUS_SUBMITTED: "Submitted",
  ORDER_STATUS_CONDITIONED: "Conditioned",
  ORDER_STATUS_RE_SUBMITTED: "Re Submitted",
  ORDER_STATUS_PENDING_CONFIRMATION: "Pending Confirmation",
  OTP_SENT_MESSAGE: "A one-time passcode has been sent to",
  OTP_VALIDITY_DURATION: "30 minutes.",
  OTP_RESEND_PROMPT: "Didn’t receive one-time passcode?",
  OTP_RESEND_TEXT: "Resend",
  OTP_EMAIL_CONFIRMATION: "Otp Email has been sent.",
  OTP_VERIFICATION_SUCCESS: "Otp verified successfully.",
  OTP_LOGIN_COMPLETION_MESSAGE: "Finish Logging In",
  OTP_VERIFICATION_CODE_SENT: "Verification Passcode Sent",
  OTP_SENDING_MESSAGE: "Sending OTP …",
  OTP_VERIFYING_MESSAGE: "Verifying OTP …",
  OTP_INVALID_OTP_MESSAGE: "Invalid OTP",
  OTP_ACCOUNT_BLOCKED_MESSAGE: "Your account has been blocked. Please contact your administrator!",
  PASSWORD_LOGIN_PROMPT: "Please provide the password below to login.",
  PASSWORD_FIELD_LABEL: "Password",
  PASSWORD_FORGOT_PROMPT: "Forgot your password?",
  PASSWORD_CONTINUE: "Continue",
  PASSWORD_REQUIRED_MESSAGE: "is required",
  USER_PROFILE_SAVE_PROMPT: "Let's save your account",
  USER_PROFILE_ZIP_CODE: "Zip Code",
  USER_PROFILE_PREFERRED_CONTACT_METHOD: "Preferred Contact Method",
  USER_PROFILE_FIRST_NAME_LENGTH: "First Name length should not be greater than 20 characters.",
  USER_PROFILE_LAST_NAME_LENGTH: "Last Name length should not be greater than 20 characters.",
  USER_PROFILE_CONTINUE: "Continue",
  USER_PROFILE_PASSWORD_REQUIREMENT: "The password should be 8 to 15 characters long, which must include uppercase, lowercase, digit and special character.",
  USER_PROFILE_CONFIRM_PASSWORD_MISMATCH: "Confirm Password does not match",
  USER_PROFILE_REQUIRED_MESSAGE: "is required",
  USER_PROFILE_ONLY_CHARACTERS_ALLOWED: "Only characters are allowed",
  USER_PROFILE_INVALID_CONTACT_NUMBER: "Contact Number is not valid",
  USER_PROFILE_TCPA_DISCLOSURE: "TCPA disclosure",
  USER_PROFILE_TCPA_DISCLOSURE_LABEL: "TCPA Disclosure",
  TCPA_POPUP_COMPONENT_DISCLOSURE: "TCPA Disclosure",
  TCPA_POPUP_COMPONENT_AGREE_TO_DISCLOSURE: "I Agree to the tcpa disclosure",
  TERM_MONTHS: "months",
  INV_ASSET_LIST_VIEW_CONTROL_SEARCH: "Search here...",
  INFORMATION_AND_NOTICES: "Information & Notices",
  I_AGREE_AND_CONTINUE: "I Agree & Continue",
  ASCENDING_TEXT: "Ascending",
  DESCENDING_TEXT: "Descending",
  PAYMENT_POPUP_BANK_ACCOUNT: "Pay from a bank account",
  PAYMENT_POPUP_NO_ACCOUNT_NUMBERS: "Account numbers arent needed",
  PAYMENT_POPUP_CREDIT_DEBIT_CARD: "Pay from a credit/debit card",
  PAYMENT_POPUP_CONVENIENT_CARD_PAYMENT: "Convenient payment through your card",
  PAYMENT_POPUP_APPLE_PAY: "Pay using ApplePay",
  PAYMENT_POPUP_MOBILE_PAYMENT: "Hassle free payment from your phone",
  PAYMENT_POPUP_CHOOSE_PAYMENT_METHOD: "How would you like to pay?",
  PAYMENT_POPUP_CONTINUE: "Continue",
  PAYMENT_POPUP_SELECT_METHOD: "Select a payment method to proceed with your down payment. You can pay the down payment in multiple transactions using different payment methods.",
  USER_PROFILE_LAST_NAME_OPTIONAL: "Last Name (Optional)",
  SIGN_IN_TITLE: "Let’s create your account!",
  TRADE_IN_CONTRACT_NUMBER_IS_REQUIRED: "Contract No. / Credit Reference is required",
  CCPA_STATIC_TEXT: ", to the",
  CCPA_DISCLOSURE_TEXT: "CCPA disclosure",
  LOGIN_TITLE: "Let’s sign you in",
  LOGIN_BUTTON_TEXT: "Continue",
  LOGIN_OTP_TITLE: "Finish Logging In",
  NO_RESULTS_FOUND_TEXT: "No Results Found!",
  ASSET_UNAVAILABLE_LOOK_FOR_OTHER_ASSET: "The asset you are looking for is not available. Please look for some other asset to proceed",
  MANDATORY_TAB_REQUIRED_TEXT: "Mandatory Tab is required",
  LOGIN_WITH_OTP_INSTEAD: "Login with OTP instead",
  LOGIN_WITH_PASSWORD_INSTEAD: "Login with password instead",
  RESET_TEXT: "Reset",
  TRADE_IN_MILES_POPUP_TITLE_QUERY: "Is vehicle title in your name?",
  TRADE_IN_MILES_POPUP_TITLE_YES: "Yes",
  TRADE_IN_MILES_POPUP_TITLE_NO: "No",
  TRADE_IN_MANUAL_ESTIMATE_TITLE_QUERY: "Is vehicle title in your name?",
  TRADE_IN_MANUAL_ESTIMATE_YES: "Yes",
  TRADE_IN_MANUAL_ESTIMATE_NO: "No",
  TRADE_IN_MANUAL_ESTIMATE_CONTRACT_NUMBER: "Contract Number",
  SUBMIT_ORDER_PAPERWORK_READY_MESSAGE: "We're beaming you all your paperwork so it's ready for you to review and sign. Your order has been approved with below conditions. Please update to fix these conditions and re-submit your order.",
  TRADE_IN_POPUP_CONTRACT_NUMBER: "Contract Number",
  DOCUMENT_UPLOADED_SUCCESSFULL: "is uploaded successfully",
  CONTRACTING_SIGN_AND_VIEW_OPTION: "View & Sign",
  USER_PROFILE_SAVE_CHANGE: "Save Changes",
  PROFILE_DATA_NOT_SAVE: "Profile Data Fail",
  CONTRACTING_VIEW_AND_SIGN: "View & Sign Contract",
  USER_PROFILE_CHANGE_PASSWORD: "Change your password",
  CUSTOMER_PHONE_NUMBER_REQUIRED: "Mobile phone number is required",
  TRADE_IN_MILES_POPUP_CONTRACT_NUMBER: "Contract No. / Credit Reference",
  FOOTER_ALL_RIGHT_RESERVED: "All Rights Reserved",
  vinNumber: "VIN",
  SUBMIT_ORDER_TERM_BETWEEN: "The term should be between",
  TH_FINANCED_AMOUNT_IS: "The Financed Amount should be in between",
  IS_DOCUMENT_UPLOADED_SUCCESSFULLY: "The Proof of Residence is not uploaded",
  CONTRACTING_SIGN_INITIALS: "Sign Your Initials",
  CHANGE_PASSWORD_TEXT: "Your password has been changed successfully.",
  currentPasswordLabel: "Current Password",
  INSPECTION_TRADE_IN_ASSETS: "We need some more information on your trade-in",
  GOOGLE_SIGNUP_BUTTON_TEXT: "Sign Up With Google",
  GOOGLE_LOGIN_BUTTON_TEXT: "Login With Google",
  USER_PROFILE_CONTACT_NUMBER_FIELD_LABEL: "Contact Number",
  HORSEPOWER_TEXT: "Horsepower:",
  CONTRACTING_SIGN_AND_SAVE: "Sign and Save Contract",
  USER_PROFILE_HYPER_TEXT: "The password should be 8 to 15 characters long, which must include uppercase, lowercase, digit and special character.",
  ADJUST_FINANCED_PAYMENT: "Adjust your payment",
  DOCUMENT_UPLOADED_SUCCESSFULLY: "The Proof of Residence is uploaded successfully",
  PROFILE_DATA_SAVE: "Data Saved successfully",
  stockNumber: "Stock",
  FINANCING_ACTIVE_PERSONAL_FINANCE_QUESTION: "Do you have any active personal finance?",
  VEHICLE_DETAILS_TEXT: "Vehicle Details",
  DEFAULT_TEXT: "Default",
  BUY_NOW_TEXT: "Buy Now",
  CHANGE_TERM: "Change Term",
  PROFILE_ADDRESS_SAVE: "Address Saved successfully",
  newPasswordLabel: "New Password",
  FINANCING_PERSONAL_FINANCE_QUESTION: "Do you have any active personal finance?",
  CONTRACTING_SIGNATURE_SUB_HEADING: "Make sure to view contract and add signatures at the bottom to complete your contract submission.",
  THE_DOCUMENT_UPLOADED_SUCCESSFULLY: "The Proof of Residence are uploaded successfully",
  confirmPasswordLabel: "Confirm New Password",
  VEHICLE_ASSET_DETAILS: "Asset Details",
  THE_DOCUMENT_UPLOADED_NOT_SUCCESSFULLY: "The Proof of Residence are not uploaded",
  oldpassword: "Current password is not correct",
  VEHICLE_SELECT_CONTINUE: "Dettagli risorsa",
  PLEASE_UPLOAD: "Please upload again",
  confirmpassword: "Confirm Password does not match",
  VEHICLE_SELLING_PRICE: "Selling Price",
  VEHICLE_EXTERIOR_COLOR: "Exterior Color",
  DOCUMENT_UPLOAD: "Upload Document(s)",
  passworderror: "New password cannot be same as current password",
  VEHICLE_FEATURES_SPECS: "Vehicle Features & Specs",
  THE_FINANCED_AMOUNT_IS: "The Financed Amount in between",
  VEHICLE_DRIVETRAIN: "Drivetrain",
  DOCUMENT_UPLOAD_SUCCESSFULLY: "are uploaded successfully",
  VEHICLE_ENGINE: "Engine",
  THE: "The",
  VEHICLE_INTERIOR_COLOR: "Interior Color",
  MILES_TEXT: "Miles",
  ARE_NOT_UPLOAD: "are not uploaded",
  VEHICLE_TRANSMISSION_TYPE: "Transmission Type",
  VEHICLE_PRICE_FOR_YOU: "Price For You",
  IS_NOT_UPLOAD: "is not uploaded",
  VEHICLE_STOCK_NUMBER: "Stock Number",
  VEHICLE_MSRP: "Dettagli",
  FINANCING_SOURCE_OF_OTHER_INCOME_OPTIONAL_FIELD_LABEL: "Source Of Other Income (Optional)",
  FILE_DRAG_DROP_HOVER_LABEL_1: "Drag and drop file, or",
  TRADE_IN_IMAGE_REQUIRED_ERROR: "Vehicle Image is required",
  TRADE_IN_IMAGE_UPLOAD_MAX_LIMIT_ERROR: "You can only upload a maximum of 10 images",
  EMPLOYMENT_DETAILS_EMPLOYMENT_TYPE_REQUIRED: "Employment Type is required",
  EMPLOYMENT_DETAILS_EMPLOYER_NAME_REQUIRED: "Employer Name is required",
  EMPLOYMENT_DETAILS_EMPLOYER_NAME_MAX_LENGTH: "Employer Name maximum length is up to 60 characters",
  EMPLOYMENT_DETAILS_EMPLOYER_PHONE_REQUIRED: "Employer Phone Number is required",
  EMPLOYMENT_DETAILS_VALID_EMPLOYER_PHONE_REQUIRED: "Valid Employer Phone Number is required",
  EMPLOYMENT_DETAILS_OCCUPATION_REQUIRED: "Occupation is required",
  EMPLOYMENT_DETAILS_OCCUPATION_MAX_LENGTH: "Occupation maximum length is up to 60 characters",
  EMPLOYMENT_DETAILS_EMPLOYED_SINCE_DATE_REQUIRED: "Employed Since Date is required",
  EMPLOYMENT_DETAILS_EMPLOYED_SINCE_DATE_FORMAT: "Employed Since Date should be in MM/YYYY format",
  EMPLOYMENT_DETAILS_ANNUAL_EMPLOYMENT_INCOME_REQUIRED: "Annual Employment Income is required",
  EMPLOYMENT_DETAILS_ANNUAL_EMPLOYMENT_INCOME_NUMBER: "Annual Employment Income must be a number",
  EMPLOYMENT_DETAILS_ANNUAL_OTHER_INCOME_REQUIRED: "Annual Other Income is required",
  EMPLOYMENT_DETAILS_ANNUAL_OTHER_INCOME_NUMBER: "Annual Other Income must be a number",
  EMPLOYMENT_DETAILS_SOURCE_OF_OTHER_INCOME_REQUIRED: "Source of Other Income is required",
  EMPLOYMENT_DETAILS_SOURCE_OF_OTHER_INCOME_MAX_LENGTH: "Source of Other Income maximum length is up to 100 characters",
  EMPLOYMENT_DETAILS_ADDRESS_LINE_1_REQUIRED: "Address Line 1 is required",
  EMPLOYMENT_DETAILS_CITY_REQUIRED: "City is required",
  EMPLOYMENT_DETAILS_STATE_NAME_REQUIRED: "State Name is required",
  EMPLOYMENT_DETAILS_ZIP_CODE_REQUIRED: "Zip Code is required",
  SEARCH_PLACEHOLDER: "Search here...",
  VIN_NUMBER_TEXT: "VIN",
  TRADE_IN_OFFER_DEALER_MESSAGE: "The dealer offer for your trade-in vehicle is",
  TRADE_IN_OFFER_REVISED: "The dealership has revised the offer on your",
  TRADE_IN_OFFER_FROM_TEXT: "   from",
  TRADE_IN_OFFER_TO_TEXT: "to",
  TRADE_IN_OFFER_ACCEPT_PROMPT: "Would you like to accept this offer and proceed?",
  TRADE_IN_OFFER_ACCEPT: "Accept Trade-in Offer",
  TRADE_IN_OFFER_DECLINE: "Don't want to trade?",
  TRADE_IN_OFFER_REMOVED_TEXT: "Remove",
  SUBMIT_ORDER_MAX_TERMS: "The text should be maximum 36 terms",
  SUBMIT_ORDER_CHANGE_TERM: "Change Term",
  SUBMIT_ORDER_APPROVED_AMOUNT: "The approved financed amount is",
  SUBMIT_ORDER_ADJUST_PAYMENT: "Adjust your payment",
  SUBMIT_ORDER_ADJUST_DOWN_PAYMENT: "Adjust Down Payment",
  SUBMIT_ORDER_UPLOAD_SAMPLE_DOCUMENT: "Please upload sample document name",
  SUBMIT_ORDER_UPLOAD_DOCUMENTS: "Upload Document(s)",
  SUBMIT_ORDER_RE_SUBMIT_ORDER: "Re-Submit Order",
  SUBMIT_ORDER_EDIT_ORDER: "Edit Order",
  MONTHS_LOWERCASE_TEXT: "months",
  SUBMIT_ORDER_FINANCED_AMOUNT_BETWEEN: "The Financed Amount should be in between",
  SUBMIT_ORDER_DOCUMENT_UPLOADED_SUCCESSFULLY: "Document uploaded successfully",
  STRIPE_PAYMENT_REMAINING_AMOUNT: "Remaining amount",
  STRIPE_PAYMENT_BELOW_MINIMUM: "is less than the minimum allowed",
  STRIPE_PAYMENT_MAKE_FULL_PAYMENT: "Please make full payment of",
  OTP_SENT_SUCCESSFULLY: "OTP Sent Successfully",
  CONTRACTING_CO_APPLICANT: "(Co-Applicant)",
  CONTRACTING_GUARANTOR: "(Guarantor)",
  CONTRACTING_VIEW_AND_SIGN_CONTRACTS: "View and sign contracts",
  CONTRACTING_OPEN_AND_VIEW_CONTRACTS: "Make sure to open and view each contract and add signatures at the bottom to complete your contract submission.",
  CONTRACTING_GENERAL_DOCUMENTS: "General Documents",
  CONTRACTING_COAPPLICANT_UNSIGNED_CONTRACTS: "Your co-applicant has not signed the contracts yet.",
  CONTRACTING_CLICK_HERE: "Click here",
  CONTRACTING_NOTIFY_TO_PROCEED_WITH_ORDER: "to notify him/her to proceed with your order.",
  BUTTON_TEXT_CLEAR: "Clear",
  JOURNEY_LAYOUT_STEP_ITEMS_CONTRACTING_TITLE: "Contracting",
  CONTRACTING_VIEW_OPTION: "View",
  CONTRACTING_SIGN_OPTION: "Sign",
  SUBMIT_ORDER_CREDIT_APP_CONDITIONED: "Your Credit Application has been Conditioned",
  CONTRACTING_FULL_SIGNATURES: "Full Signatures",
  CONTRACTING_INITIALS: "Initials",
  CONTRACTING_SIGNATORIES_STATUS: "Signatories Status",
  PAYMENT_BREAKDOWN_POPUP_BASE_MONTHLY_PAYMENT: "Base Monthly Payment",
  PAYMENT_BREAKDOWN_POPUP_TAXES: "Taxes",
  PAYMENT_BREAKDOWN_POPUP_TAXES_ESTIMATION: "Taxes are estimated based on your garaging address.",
  PAYMENT_BREAKDOWN_POPUP_UPFRONT_F_AND_Is: "Upfront F&Is",
  PAYMENT_BREAKDOWN_POPUP_UPFRONT_PAYMENT_BREAKDOWN_POPUP_UPFRONT_ADD_ONS: "Upfront Add-Ons",
  PAYMENT_BREAKDOWN_POPUP_UPFRONT_PAYMENT_BREAKDOWN_POPUP_UPFRONT_FEES: "Upfront Fees",
  PAYMENT_BREAKDOWN_POPUP_FIRST_MONTH_PAYMENT_DUE: "First Month's payment will be due 30 days after contract signing date.",
  PAYMENT_BREAKDOWN_POPUP_ADJUSTED_CAPITALIZED_COST: "Adjusted Capitalized Cost",
  PAYMENT_BREAKDOWN_POPUP_GROSS_CAPITALIZED_COST: "Gross Capitalized Cost",
  PAYMENT_BREAKDOWN_POPUP_ADDITIONAL_FEES: "Additional fees may be applied by the dealership and will be added to the price breakdown once the dealer has confirmed that the vehicle is available.",
  PAYMENT_BREAKDOWN_POPUP_ESTIMATED_FEES: "Estimated Dealer Fees",
  PAYMENT_BREAKDOWN_POPUP_UPFRONT_TEXT: "Upfront",
  PAYMENT_BREAKDOWN_POPUP_FINANCED_TEXT: "Financed",
  PAYMENT_BREAKDOWN_POPUP_ADD_ONS_TEXT: "These add-ons have been included in the financed amount.",
  CONTRACTING_VIEW_CONTRACT: "View Contract",
  DMS_CUSTOMER_POPUP_TEXT : "Do you wish to update information received from Dealer Management System?",

}
}