import { Endpoint } from '@constants';
import { http } from '../utilities'
import {getDealerCode} from "@helpers";

export const DMS_BASE_URL = process.env.DMS_BASE_URL;

export namespace CustomerManagement {

    export const getCustomerByEmail = async ({ email }: { email: string }) => {
        return http(DMS_BASE_URL).get(`${Endpoint.GET_CUSTOMER_BY_EMAIL}?email=${email}`).then((res) => {
            return res.data
        })
    }
    export const getLicenseInformation = (reference_id: string) => {
        return http(DMS_BASE_URL).get(`${Endpoint.CUSTOMER_LICENSE_INFO}?reference_id=${reference_id}&is_presigned_url_required=true`).then((res) => {
            return res.data
        })
    }
    export const addLicenseDetails = (body): any => {
        return http(DMS_BASE_URL).post(`${Endpoint.CUSTOMER_LICENSE_INFO}`, body).then((res) => {
            return res.data;
        });
    }

    export const addLicenseDetailsToMitek = (body): any => {
        return http(DMS_BASE_URL).post(`${Endpoint.LICENSE_VERIFICATION}`, body).then((res) => {
            return res.data;
        });
    }

    export const updateCustomerLicenseInfo = (body) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

        return http(DMS_BASE_URL).patch(`${Endpoint.CUSTOMER_LICENSE_INFO}?reference_id=${body?.reference_id}&dealer_code=${dealer_code}`, body).then((res) => {
            return res.data;
        });
    }

    export const addCustomerSignature = (body) => {
        return http(DMS_BASE_URL).post(Endpoint.CUSTOMER_SIGNATURE, body).then((res) => {
            return res.data;
        });
    }

    export const getCustomerSignature = (referenceId) => {
        return http(DMS_BASE_URL).get(`${Endpoint.CUSTOMER_SIGNATURE}?reference_id=${referenceId}`).then((res) => {
            return res.data;
        });
    }

    export const getCheckDmsCustomer = ({reference_id, dealer_code}: { reference_id: string; dealer_code: string; }) => {
        return http(DMS_BASE_URL).get(`${Endpoint.CHECK_DMS_CUSTOMER}?reference_id=ref-U-A000B354&dealer_code=00001`).then((res) => {
            return res.data
        })
    }

    export const updateDmsCustomer = (body) => {
        return http(DMS_BASE_URL).patch(Endpoint.UPDATE_DMS_CUSTOMER, body).then((res) => {
            return res.data;
        });
    }

}

