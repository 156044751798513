import React, { useContext, useEffect, useState } from 'react'
import {  useTheme } from '@mui/material'
import { HeaderMenuRightWrap } from './header-menu-right.style'
import { HeaderSearch, NavLink } from '@components'
import { Box, Icon, Menu } from '@ntpkunity/controls'
import { Navigation } from '@constants'
import { AppContext, changeUserLoggedInState, updateSocialMediaIcons } from '@app/context-provider'
import { useAuthentication } from 'hooks/useAuthenticationHook'
import { useWorkflowContext } from '@app/router-workflow'
import clsx from 'clsx'
import {useQueryClient} from "react-query";

export const HeaderMenuRight = () => {
  const theme = useTheme()
  const { navigateNext } = useWorkflowContext()
  const queryClient = useQueryClient();
  const [openSearch, setOpenSearch] = useState(true)
  const [menuOptions, setMenuOptions] = useState([])

  const defaultValues = {
    logout: 'Logout',
    profile: 'Profile',
    login: 'Login',
    createAccount: 'Create Account',
  }

  const { authenticationStatus } = useAuthentication()

  // using useContext to get the state and dispatch from the context
  const appState = useContext(AppContext);

  useEffect(() => {
    if (!authenticationStatus) {
      setMenuOptions([
        { optionText: appState.state.language.placeholders?.LOGIN_TEXT ?? defaultValues.login, optionkey: 'Login' },
        { optionText: appState.state.language.placeholders?.CREATE_ACCOUNT_TEXT ?? defaultValues.createAccount, optionkey: 'Create Account' },
        { optionText: '', className: 'divider'},
        {
          optionText: (
            <>
              <Box theme={theme} display={'flex'}>
                <Icon className="menu-icon" name="AmericaLangIcon" />
              </Box>
              <Box theme={theme} className='selected-icon'>
                {'English'}
                <Icon className="tick-icon" name="TickIcon" />
              </Box>
            </>
          ),
          optionValue: 'English',
          className: 'selected'
        },
      ])
    } else {
      setMenuOptions([
        { optionText: appState.state.language.placeholders?.PROFILE_TEXT ?? defaultValues.profile, optionkey: 'Profile' },
        { optionText: 'My Orders', optionkey: 'orders' },
        { optionText: 'Appointments', optionkey: 'appointments' },
        { optionText: '', className: 'divider'},
        {
          optionText: (
            <>
              <Box theme={theme} display={'flex'}>
                <Icon className="menu-icon" name="AmericaLangIcon" />
              </Box>
              <Box theme={theme} className='selected-icon'>
                {'English'}
                <Icon className="tick-icon" name="TickIcon" />
              </Box>
            </>
          ),
          optionValue: 'English',
          className: 'selected'
        },
        { optionText: '', className: 'divider'},
        { optionText: appState.state.language.placeholders?.LOGOUT_TEXT ?? defaultValues.logout, optionkey: 'Logout' },
      ])
    }

  }, [authenticationStatus, appState.state.language])


  return (
    <>
      <HeaderMenuRightWrap theme={theme} className='header-menu-right-wrap'>
        <ul className="navbar-nav nav-right">
          <li className="nav-item icon-item">
            <NavLink
              icon={'SearchIcon'}
              onClick={() => setOpenSearch(!openSearch)}
            />
          </li>
          <li className={clsx('nav-item icon-item dropdown', authenticationStatus?  'is-login' : 'is-logout')}>
            <Menu
              theme={theme}
              disablePortal={true}
              options={menuOptions}
              render={(cb) => (
                <>
                  <NavLink
                    icon={'PersonIcon'}
                    onClick={cb as any}
                  />
                </>
              )}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              handleOptionClick={(e, key, value) => {
                switch (key) {
                  case 'Login':
                    navigateNext(Navigation.AUTHENTICATION.LOG_IN_PAGE)
                    break;
                  case 'Create Account':
                    navigateNext(Navigation.AUTHENTICATION.SIGN_IN_PAGE)
                    break;
                  case 'Profile':
                    navigateNext(Navigation.PROFILE.USER_PROFILE)
                    break;
                  case 'orders':
                    navigateNext(Navigation.PROFILE.ORDERS)
                    break;
                  case 'Logout':
                    localStorage.clear();
                    appState.dispatch(changeUserLoggedInState(false))
                    appState.dispatch(updateSocialMediaIcons({}));
                    queryClient.clear();
                    break;
                }
              }}
            />
          </li>
        </ul>

      </HeaderMenuRightWrap>
      <HeaderSearch
        searchOpen={openSearch}
        onClose={() => { setOpenSearch(!openSearch) }}
      />
    </>
  )
}
