import React, { ReactNode } from 'react'
import { CjInputWrap } from './input.style'
import { useTheme } from '@mui/material'

export const Textbox = ({ children }: { children: ReactNode }) => {
    const theme = useTheme()
  return (
    <CjInputWrap className='cj-input-wrap' theme={theme}>
        {children}
    </CjInputWrap>
  )
}
