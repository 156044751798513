import { user_session } from "@constants";

let payload: any = {};

export const mapPayload = (
    dealer_code?: number,
    slug?: string,
    customerReference?: string,
    keyName?: string,
    obj?: any,
    keyName2?: string,
    status?: string
) => {
    payload.slug = slug;
    payload.reference_id = customerReference;
    payload.dealer_id = dealer_code;
    payload.session = user_session

    if (status) {
        payload.status = status;
    }

    if (!payload.data) {
        payload.data = {
            datenTime: new Date(),
            session_id: user_session,
            customer_details: {},
            vehicle_search_criteria: []
        };
    }

    if (keyName === 'vehicle_search_criteria' && obj) {
        if (!Array.isArray(payload.data.vehicle_search_criteria)) {
            if (payload.data.vehicle_search_criteria) {
                payload.data.vehicle_search_criteria = [payload.data.vehicle_search_criteria];
            } else {
                payload.data.vehicle_search_criteria = [];
            }
        }
        payload.data.vehicle_search_criteria.push(obj);
    } else if (keyName && obj) {
        if (!payload.data[keyName]) {
            payload.data[keyName] = {};
        }

        if (keyName2) {
            payload.data[keyName][keyName2] = obj;
        } else {
            payload.data[keyName] = obj;
        }
    }

    return payload;
};
