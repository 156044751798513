import { useTheme } from '@mui/material'
import { Typography, Box, Icon, Grid, Button, Dialog } from '@ntpkunity/controls'
import { Stack } from '@components'
import clsx from 'clsx'
import React from 'react'
import { Payment } from './payment-stack.props'
import { PaymentStackWrap } from './payment-stack.style'

export const PaymentStack = ({ items }: Payment) => {
    const theme = useTheme()

    return (
        <PaymentStackWrap theme={theme} className='payment-card-wrap'>
            {items?.map((item, index) => (
                <Stack key={index} className={clsx({ 'payment': true, 'selected': item.selected })} paddingXs={3} paddingMd={3} marginTopXs={2}onClick={() => item.onClick()}>
                    {item.icon}
                    {item.title &&
                        <Box theme={theme} className='w-100'>
                            <Typography theme={theme} variant='subtitle1' component={'p'} className='text-truncated'>{item.title}</Typography>
                            {item.subtitle &&
                                <Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted text-truncated'>
                                    {item.subtitle}
                                </Typography>
                            }
                        </Box>
                    }
                </Stack>
            ))}
        </PaymentStackWrap>
    )
}


