import React, { Dispatch, ReactNode, createContext, useContext, useEffect, useReducer, useState } from "react";
import { IAddress, IOrder, IOrderFnI, IOrderOption, IVehicle, IVehicleDetail } from "interfaces";
import {FinanceTypes, OrderStatus, QueryKeys, Tabs} from "@constants";
import { IOrderTradeIn } from "../../../interfaces/order-trade-in";
import { useParams } from "react-router-dom";
import { useCalculateNfa, useCalculateOrderQuotation, useGetCustomerByEmail, useGetDealerPreference, useGetDealerProfileByDealerCode, useGetOrderInformation, useGetVehicleByVin, useGetVehicleDetailByVin, useSubmitOrder, useUpdateOrderRequest, useUpdateOrderStatus } from "@hooks";
import { AvailabilityCheck, EstimateProvider, Status, VehicleStatus } from "constants/enums";
import { useQueryClient } from "react-query";
import { getCustomerAddress } from "helpers/methods";
import { useGetConfiguration } from "hooks/trade-in-management";
import { useAppContext } from "@app/context-provider";
import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
import {useCreateOrderDocument, useCreateOrderFniDocument} from "../../../hooks/order-management";

export enum OrderActionType {
   UPDATE_CONTRACT_TERM = 'UPDATE_CONTRACT_TERM',
   UPDATE_NET_FINANCE_AMOUNT = 'UPDATE_NET_FINANCE_AMOUNT',
   UPDATE_FINANCE_TYPE = 'UPDATE_FINANCE_TYPE',
   UPDATE_ANNUAL_USAGE = 'UPDATE_ANNUAL_USAGE',
   UPDATE_CREDIT_RATING = 'UPDATE_CREDIT_RATING',
   UPDATE_LENDER_ID = 'UPDATE_LENDER_ID',
   UPDATE_ORDER_ASSET = 'UPDATE_ORDER_ASSET',
   UPDATE_ORDER_FNI = 'UPDATE_ORDER_FNI',
   UPDATE_ORDER_OPTION = 'UPDATE_ORDER_OPTION',
   UPDATE_INSURANCE = 'UPDATE_INSURANCE',
   UPDATE_SCHEDULE_ORDER = 'UPDATE_SCHEDULE_ORDER',
   UPDATE_ACTIVE_TAB = 'UPDATE_ACTIVE_TAB',
   UPDATE_ORDER_FEE = 'UPDATE_ORDER_FEE',
   PROGRAM = 'PROGRAM',
   QUOTATION_DATA = 'QUOTATION_DATA',
   ADD_CUSTOMER_REFERENCE_ID = 'ADD_CUSTOMER_REFERENCE_ID',
   UPDATE_DUE_AT_SIGNING = 'UPDATE_DUE_AT_SIGNING',
   UPDATE_ORDER = 'UPDATE_ORDER',
   UPDATE_DOWN_PAYMENT = 'UPDATE_DOWN_PAYMENT',
   GET_ORDER = 'GET_ORDER',
   UPDATE_ORDER_TRADE_IN = 'UPDATE_ORDER_TRADE_IN',
   UPDATE_SETUP_VALUES = 'UPDATE_SETUP_VALUES',
   UPDATE_FLOW_EXUCUTION_STATUS = 'UPDATE_FLOW_EXUCUTION_STATUS',
   UPDATE_FINANCE_AMOUNT = 'UPDATE_FINANCE_AMOUNT',
}

// Initial state
export const initialState: { activeTab: string, programUpdate: boolean, isFlowExecuted: boolean, order: IOrder } = {
   activeTab: Tabs.PAYMENTS,
   programUpdate: false,
   isFlowExecuted: false,
   order: {
      contract_term: 0,
      finance_type: FinanceTypes.LEASE,
      apr: 0,
      net_finance_amount: 0,
      annual_usage: 0,
      allowed_usage: 0,
      credit_rating: '',
      due_at_signing: 0,
      selling_price: 0,
      down_payment: 0,
      estimated_monthly_payment: 0,
      dealer_id: 0,
      lender_id: 0,
      vehicle_id: 0,
      fp_id: 0,
      rv_balloon_percentage: 0,
      rv_balloon_value: 0,
      base_rate: 0,
      calcuation_method: '',
      margin: 0,
      reference_id: '',   // customer reference id
      rental_frequency: 'Monthly',
      down_payment_lease: 0,
      identifier: '',
      reference_number: '',
      rental_mode: '',
      order_asset: {} as any,
      order_fnI: [],
      order_options: [],
      insurance: null,
      appointment_details: null,
      order_fees: [],
      order_tradein: null,
      is_payment_updated: false,
      company_id: null,
      finance_amount:null,
      monthly_sales_use_tax:0
   }
}

// Action methods
export const updateContractTerm = (contract_term: number) => ({ type: OrderActionType.UPDATE_CONTRACT_TERM, payload: contract_term })
export const updateFinanceType = (finance_type: string) => ({ type: OrderActionType.UPDATE_FINANCE_TYPE, payload: finance_type })
export const updateFinanceAmount = (net_finance_amount: number) => ({ type: OrderActionType.UPDATE_NET_FINANCE_AMOUNT, payload: net_finance_amount })
export const updateAnnualUsage = (annual_usage: number) => ({ type: OrderActionType.UPDATE_ANNUAL_USAGE, payload: annual_usage })
export const updateCreditRating = (credit_rating: string) => ({ type: OrderActionType.UPDATE_CREDIT_RATING, payload: credit_rating })
export const updateLenderId = (lender_id: number) => ({ type: OrderActionType.UPDATE_LENDER_ID, payload: lender_id })
export const updateOrderAsset = (order_asset: IVehicle) => ({ type: OrderActionType.UPDATE_ORDER_ASSET, payload: order_asset })
export const updateFnI = (order_fnI: Array<IOrderFnI>) => ({ type: OrderActionType.UPDATE_ORDER_FNI, payload: order_fnI })
export const updateOption = (order_options: IOrderOption) => ({ type: OrderActionType.UPDATE_ORDER_OPTION, payload: order_options })
export const updateInsurance = (insurance: any) => ({ type: OrderActionType.UPDATE_INSURANCE, payload: insurance })
export const updateScheduleOrder = (appointment_details: any) => ({ type: OrderActionType.UPDATE_SCHEDULE_ORDER, payload: appointment_details })
export const updateActiveTab = (activeTab: string) => ({ type: OrderActionType.UPDATE_ACTIVE_TAB, payload: activeTab })
export const updateOrderFee = (order_fees: any) => ({ type: OrderActionType.UPDATE_ORDER_FEE, payload: order_fees })
export const programData = (program: Object) => ({ type: OrderActionType.PROGRAM, payload: program })
export const quotationData = (quotation: Object) => ({ type: OrderActionType.QUOTATION_DATA, payload: quotation })
export const addCustomerReferenceId = (customerId: Object) => ({ type: OrderActionType.ADD_CUSTOMER_REFERENCE_ID, payload: customerId })
export const updateDueAtSigning = (due_at_signing: number) => ({ type: OrderActionType.UPDATE_DUE_AT_SIGNING, payload: due_at_signing })
export const updateDownPayment = (down_payment: number) => ({ type: OrderActionType.UPDATE_DOWN_PAYMENT, payload: down_payment })
export const updateOrder = (order: object) => ({ type: OrderActionType.UPDATE_ORDER, payload: order })
export const getOrder = (order: IOrder) => ({ type: OrderActionType.GET_ORDER, payload: order })
export const updateOrderTradeIn = (order_tradein: IOrderTradeIn) => ({ type: OrderActionType.UPDATE_ORDER_TRADE_IN, payload: order_tradein })
export const updateSetupValues = (setup_data: any) => ({ type: OrderActionType.UPDATE_SETUP_VALUES, payload: setup_data })
export const updateFlowExecutionStatus = (status: boolean) => ({ type: OrderActionType.UPDATE_FLOW_EXUCUTION_STATUS, payload: status })
export const updateFinanceAmountOrder =(finance_amount:number)=>({type:OrderActionType.UPDATE_FINANCE_AMOUNT,payload:finance_amount})

// Reducer
export const orderReducer = (state: { activeTab: string, programUpdate: boolean, isFlowExecuted: boolean, order: IOrder } = initialState, action: { type: OrderActionType; payload: any }) => {
   switch (action.type) {
      case OrderActionType.UPDATE_CONTRACT_TERM:
         return { ...state, order: { ...state.order, contract_term: action.payload } }
      case OrderActionType.UPDATE_FINANCE_TYPE:
         return { ...state, order: { ...state.order, finance_type: action.payload } }
      case OrderActionType.UPDATE_NET_FINANCE_AMOUNT:
         return { ...state, order: { ...state.order, net_finance_amount: action.payload } }
      case OrderActionType.UPDATE_ANNUAL_USAGE:
         return { ...state, order: { ...state.order, annual_usage: action.payload } }
      case OrderActionType.UPDATE_CREDIT_RATING:
         return { ...state, order: { ...state.order, credit_rating: action.payload } }
      case OrderActionType.UPDATE_LENDER_ID:
         return { ...state, order: { ...state.order, lender_id: action.payload } }
      case OrderActionType.UPDATE_ORDER_ASSET:
         return {
            ...state,
            order: {
               ...state.order,
               order_asset: action.payload,
               vehicle_id: action.payload?.id,
               dealer_id: action.payload?.dealer_id,
               selling_price: action.payload?.internet_price
            }
         }
      case OrderActionType.UPDATE_FINANCE_AMOUNT:
         return { ...state, order: { ...state.order, finance_amount: action.payload } }
      case OrderActionType.UPDATE_ORDER_FNI:
         return { ...state, order: { ...state.order, order_fnI: action.payload } }
      case OrderActionType.UPDATE_ORDER_OPTION:
         return { ...state, order: { ...state.order, order_options: action.payload } }
      case OrderActionType.UPDATE_INSURANCE:
         return { ...state, order: { ...state.order, insurance: action.payload } }
      case OrderActionType.UPDATE_SCHEDULE_ORDER:
         return { ...state, order: { ...state.order, appointment_details: action.payload } }
      case OrderActionType.UPDATE_ACTIVE_TAB:
         return { ...state, activeTab: action.payload }
      case OrderActionType.UPDATE_ORDER_FEE:
         return { ...state, order: { ...state.order, order_fees: action.payload } }
      case OrderActionType.PROGRAM:
         const downPayment = (action.payload?.downpayment_chart?.[0]?.default_down_payment * state.order.selling_price) / 100
         return {
            ...state,
            programUpdate: true,
            order: {
               ...state.order,
               fp_id: action.payload?.fp_id,
               rv_balloon_percentage: action.payload?.rv_balloon_percentage ?? action.payload?.rv_chart?.[0]?.rv_value,
               rv_balloon_value: action.payload?.rv_balloon_value ?? action.payload?.rv_chart?.[0]?.rv_value,
               apr: action.payload?.final_customer_rate,
               base_rate: action.payload?.base_rate_chart?.base_rate || 0,
               calcuation_method: action.payload?.calculation_method,
               margin: action.payload?.interest_chart?.[0]?.margin,
               rental_mode: action.payload?.rental_mode,
               down_payment: downPayment
            }
         }
      case OrderActionType.QUOTATION_DATA:
         const { estimated_monthly_payment, due_at_signing, capitalized_cost_reduction, monthly_sales_use_tax, adjusted_capitalized_cost, gross_capitalized_cost, rv_amount,tax_amount,base_monthly_payment } = action.payload;
         return {
            ...state,
            programUpdate: false,
            order: {
               ...state.order,
               due_at_signing: due_at_signing,
               estimated_monthly_payment,
               capitalized_cost_reduction: capitalized_cost_reduction,
               monthly_sales_use_tax: monthly_sales_use_tax,
               adjusted_capitalized_cost: adjusted_capitalized_cost,
               gross_capitalized_cost: gross_capitalized_cost,
               rv_amount: rv_amount,
               tax_amount:tax_amount,
               base_monthly_payment:base_monthly_payment


            }
         }

      case OrderActionType.ADD_CUSTOMER_REFERENCE_ID:
         return { ...state, order: { ...state.order, reference_id: action.payload } }
      case OrderActionType.UPDATE_DOWN_PAYMENT:
         return { ...state, order: { ...state.order, down_payment: action.payload } }
      case OrderActionType.UPDATE_ORDER:
         return {
            ...state, order: {
               ...state.order,
               identifier: action.payload?.identifier,
               reference_number: action.payload?.reference_number
            }
         }
      case OrderActionType.GET_ORDER:
         const { Insurance_Inforation, ...restOrder } = action.payload || {};
         const {expiration_date, insurance_address, ...restInsurance} = Insurance_Inforation || {}
         const formattedExpirationDate = expiration_date?.split('T')[0] || null;
         const isInsuranceNotEmpty = Object.keys(restInsurance).length > 0;
         const updatedInsurance = isInsuranceNotEmpty
                 ? {
                     ...restInsurance,
                     expiration_date: formattedExpirationDate,
                     insurer_address: insurance_address,
                     insurance_documents: state.order?.insurance?.insurance_documents
                 }
                 : null;
         return {
            ...state,
            isFlowExecuted: false,
            order: {
               ...restOrder,
               reference_id: action.payload?.customer_info?.reference_id,
               down_payment: (action.payload?.down_payment * action.payload?.selling_price) / 100,
               insurance: updatedInsurance,
            }
         }
      case OrderActionType.UPDATE_ORDER_TRADE_IN:
         return { ...state, order: { ...state.order, order_tradein: action.payload } }
      case OrderActionType.UPDATE_SETUP_VALUES:
         return {
            ...state, order: {
               ...state.order,
               contract_term: action.payload?.defaultTerms,
               annual_usage: action.payload?.defaultMiles?.value,
               credit_rating: action.payload?.defaultCreditRating,
               company_id: action.payload?.company_id
            }
         }
      case OrderActionType.UPDATE_FLOW_EXUCUTION_STATUS:
         return {
            ...state,
            isFlowExecuted: action.payload
         }


      default:
         return state
   }
}


// Create context
export const OrderCreateContext = createContext<{
   state: { activeTab: string, programUpdate: boolean, isFlowExecuted: boolean, order: IOrder };
   dispatch: Dispatch<any>;
   handleSubmitOrder: () => void;
   handleUpdateOrder: () => void;
   calculateQuotation: () => void;
   loading: boolean;
   vehicle: IVehicle
}>({
   state: initialState,
   dispatch: () => null,
   handleSubmitOrder: () => null,
   handleUpdateOrder: () => null,
   loading: false,
   calculateQuotation: () => null,
   vehicle: {} as IVehicle
});

// Create provider
export const OrderContextProvider = ({ children }: { children: ReactNode }) => {
   const [state, dispatch] = useReducer(orderReducer, initialState);
   const { vin, orderId } = useParams();
   const dealer = JSON.parse(localStorage.getItem('dealer_information')).dealer
   const { state: appState } = useAppContext()

   const queryClient = useQueryClient()
   const order = state?.order
   const { mutate: calculateOrderQuote } = useCalculateOrderQuotation();
   const { mutate: calculateNfa } = useCalculateNfa();
   const { mutate: updateOrderRequest, isLoading: updateOrderLoading } = useUpdateOrderRequest()
   const { data: vehicle }: { data: IVehicle, isLoading: boolean } = useGetVehicleByVin(vin);
   const { data: vehicle_detail }: { data: any } = useGetVehicleDetailByVin(vin);
   const { data: dealerPreference } = useGetDealerPreference(vehicle?.dealer_code)
   const { mutate: updateOrderStatus, updateOrderStatusLoading } = useUpdateOrderStatus()
   const { mutate: submitOrder, isLoading: submitOrderLoading } = useSubmitOrder()
   const { data: marketScanbConfiguration, isLoading: MarketScanbConfigurationLoading , isFetched: marketScanFetched} = useGetConfiguration(dealer?.dealer_code,LenderIntegrationType.CALCULATION_AND_TAXATION,IntegrationProvider.MARKETSCAN, appState?.tenant)
   const {mutate: createDocument} = useCreateOrderDocument();
   const {mutate: createFniDocument} = useCreateOrderFniDocument();

   const loading = submitOrderLoading || updateOrderLoading || updateOrderStatusLoading

   const handleSubmitOrder = () => {
      if (dealerPreference && vehicle_detail) {
         if (dealerPreference?.availability_check == AvailabilityCheck.MANUAL) {
            updateOrderStatus({
               status: Status.PendingConfirmation,
               reference_number: orderId,
               user_id: 0
            }, {
               onSuccess() {
                  dispatch(updateFlowExecutionStatus(true))
               }
            })
         }
         else if (dealerPreference?.availability_check == AvailabilityCheck.AUTO && vehicle_detail?.vehicle_status == VehicleStatus.AVAILABLE) {
            submitOrder({ orderId }, {
               onSuccess(response) {
               if (response?.status === OrderStatus.APPROVED) {
                try {
                         createDocument(response.credit_approval_data, {
                           onSuccess() {
                              queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, orderId])
                           }
                         });
                         createFniDocument(response.credit_approval_data);
                      } catch (error) {
                        console.error('Error creating documents:', error);
                      }
                  }
                  dispatch(updateFlowExecutionStatus(true))
               }
            })
         }
         else {
            updateOrderStatus({
               status: Status.Cancelled,
               reference_number: orderId,
               user_id: 0,
               reason: "Vehicle not Available"
            }, {
               onSuccess() {
                  dispatch(updateFlowExecutionStatus(true))
               }
            })
         }
      }
   }

   const handleUpdateOrder = () => {
      const payload = {
         ...order,
         down_payment: (order?.down_payment * 100) / order?.selling_price
      };
      updateOrderRequest(payload, {
         onSuccess: (data) => {
            dispatch(updateOrder(data))
            handleSubmitOrder()
         },
      });
   }
   const  calculateFinanceAmount = () => {
      let tradeInAmount = (order?.order_tradein?.trade_in_amount ?? 0) - (order?.order_tradein?.payoff_amount ?? 0);

      if (tradeInAmount > 0 || tradeInAmount == null) {
        tradeInAmount = 0;
      }

      const fniAmount = (order?.order_fnI || []).reduce((sum, fni) => sum + (fni.applied_price || 0), 0);
      const optionsAmount = (order?.order_options || []).reduce((sum, option) => sum + (option.applied_price || 0), 0);
      const feesAmount = (order?.order_fees || []).reduce((sum, fee) => sum + (fee.applied_price || 0), 0);
      const sellingPrice = order?.selling_price || 0;
      const taxAmount = state?.order?.tax_amount || order?.tax_amount || 0;

      return sellingPrice + fniAmount + optionsAmount + feesAmount + taxAmount + (-tradeInAmount) ;
    }


    useEffect(() => {
      if (order) {
        const calculatedFinanceAmount = calculateFinanceAmount();
        dispatch(updateFinanceAmountOrder(calculatedFinanceAmount));
      }
    }, [order?.order_tradein, order?.order_fnI, order?.order_options, order?.order_fees, order?.selling_price, state?.order?.tax_amount]);


   const calculateQuotation = () => {
      const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
      const dealerData: any = queryClient.getQueryData(QueryKeys.DEALER_PROFILE);
      const vehicleData: IVehicle = queryClient.getQueryData([QueryKeys.VEHICLE, vin]);
      const dealerAddress = {
         street_address: dealerData?.dealer_address?.address_line_1,
         city: dealerData?.dealer_address?.city,
         state: dealerData?.dealer_address?.state_name,
         zip_code: dealerData?.dealer_address?.zip_code,
         address_type: 'Dealer'
      } as any;

      const mappedVehicleData = {
         vin: vehicleData?.vin,
         is_new: vehicleData?.type === 'New',
         odometer_mileage: vehicleData?.mileage,
         msrp: vehicleData?.msrp,
         year: vehicleData?.year,
         transmission_type: vehicleData?.transmission_type
      }
      const payoffAmount = order?.order_tradein?.payoff_amount ?? 0;
         const tradeInAmount = order?.order_tradein?.trade_in_amount ?? 0;
         let trade_in_amount = typeof tradeInAmount === 'number' ? tradeInAmount : parseInt(tradeInAmount);
         let payoff_amount = typeof payoffAmount === 'number' ? payoffAmount : parseInt(payoffAmount);
         const adjustedTradeInAmount = payoff_amount > 0 ? trade_in_amount - payoff_amount : trade_in_amount;

      const customerAddress = getCustomerAddress(customerData, order, dealerData);
      const rv_option = (order?.order_options || []).reduce((sum, item) => {
         if (!(item?.installation_mode == 'Pre-Installed' && item?.is_price_inclusive)) {
            return sum + (item?.rv_balloon_value || 0);
         }
         return sum;
      }, 0);
      const payload = {
         customer_address: {
            ...customerAddress,
            state: customerAddress?.state_name || customerAddress?.state || order?.dealer_address?.state,
            street_address: customerAddress?.address_line_1 || customerAddress?.street_address || order?.dealer_address?.street_address,
         },
         dealer_address: dealerAddress,
         vehicle: mappedVehicleData,
         dealer_id: order?.dealer_id,
         asset_condition: 63,
         credit_rating_id: order?.credit_rating,
         terms: order?.contract_term,
         per_unit_selling_price: order?.selling_price,
         annual_usage: order?.annual_usage,
         is_rv_guaranteed: 'False',
         rv_amount: 0,
         down_payment: order?.down_payment,
         net_financed_amount: marketScanbConfiguration?.is_active ? order?.adjusted_capitalized_cost || 0 : order?.net_finance_amount,
         base_rate: order?.base_rate,
         apr: order?.apr,
         finance_type: order?.finance_type,
         interest_chart_margin: order?.margin,
         rental_mode: order?.rental_mode,
         rental_frequency: 'Monthly',
         trade_in_amount:adjustedTradeInAmount,
         trade_ins: order?.order_tradein?.trade_in_amount ? ([{
            "allowance_amount": Number(order?.order_tradein?.trade_in_amount) ?? 0,
            "offer_amount": Number(order?.order_tradein?.trade_in_amount) ?? 0,
            "pay_off_amount": Number(order?.order_tradein?.payoff_amount) ?? 0

         }]) : [],
         calculation_method: order?.calcuation_method,
         rv_value: (order?.rv_balloon_value * vehicle?.internet_price) / 100 + rv_option,
         fees: order?.order_fees?.map((fee: any) => ({
            fee_name: fee?.fee_name || fee?.dealer_fee_name,
            fee_amount: fee?.applied_price || 0,
            fee_handling: fee?.fee_handling || "Other",
            is_taxable: fee?.taxable || true
         })) || [],
         fni_products: order?.order_fnI?.map(fni => {
            return {
               fni_product_name: fni.product_name,
               fni_product_amount: fni.applied_price
            }
         }) || [],
         options: order?.order_options?.map(option => {
            return {
               option_name: option.product_name,
               option_amount: option.applied_price,
               rv_amount_on_option: option.rv_balloon_value,
               installation_mode: option.installation_mode
            }
         }) || [],
         insurance: [],
      };
      calculateOrderQuote(payload, {
         onSuccess(data) {
            dispatch(quotationData(data))
         }
      })
   };

   useEffect(() => {
      if (order?.net_finance_amount > 0)
         calculateQuotation()
   }, [order?.contract_term, state?.programUpdate])

   useEffect(() => {
      if (order?.rental_mode && order?.calcuation_method) {
         const payoffAmount = order?.order_tradein?.payoff_amount ?? 0;
         const tradeInAmount = order?.order_tradein?.trade_in_amount ?? 0;
         let trade_in_amount = typeof tradeInAmount === 'number' ? tradeInAmount : parseInt(tradeInAmount);
         let payoff_amount = typeof payoffAmount === 'number' ? payoffAmount : parseInt(payoffAmount);
         const adjustedTradeInAmount = payoff_amount > 0 ? trade_in_amount - payoff_amount : trade_in_amount;

         const payload = {
            down_payment: order?.down_payment,
            dealer_id: order?.dealer_id,
            per_unit_selling_price: order?.selling_price,
            fees: order?.order_fees?.map((fee: any) => ({
               fee_name: fee.fee_name || fee?.dealer_fee_name,
               fee_amount: fee.applied_price || 0,
               fee_handling: fee.fee_handling || "Other",
               is_taxable: fee.taxable
            })) || [],
            insurance: [],
            taxes: [],
            trade_in_amount: adjustedTradeInAmount,
            fni_products: order?.order_fnI?.map(fni => {
               return {
                  fni_product_name: fni.product_name,
                  fni_product_amount: fni.applied_price
               }
            }),
            options: order?.order_options?.map(option => {
               return {
                  option_name: option.product_name,
                  option_amount: option.applied_price,
                  rv_amount_on_option: option?.is_price_inclusive ? 0 : option.rv_balloon_value,
                  installation_mode: option.installation_mode
               }
            }),
         }
         calculateNfa(payload, {
            onSuccess(data) {
               let net_finance_amount = marketScanbConfiguration?.is_active ? state?.order?.adjusted_capitalized_cost : undefined;
               net_finance_amount = net_finance_amount ? net_finance_amount : data?.net_financed_amount;
               dispatch(updateFinanceAmount(net_finance_amount))
               if(net_finance_amount > 0){
                  calculateQuotation()
               }
            }
         })
      }
   }, [order?.down_payment, order?.order_tradein, order?.order_fnI, order?.order_options, order?.order_fees, marketScanbConfiguration,order?.adjusted_capitalized_cost, order?.net_finance_amount])

   return <OrderCreateContext.Provider value={{ state, dispatch, handleSubmitOrder, handleUpdateOrder, calculateQuotation, loading, vehicle }}>{children}</OrderCreateContext.Provider>;
};

export const useOrderContext = () => useContext(OrderCreateContext)