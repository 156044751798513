import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const FinalPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.final-popup-wrap': {
    '.product-img': {
      '.aspect-ratio-image-wrap': {
        'img': {
          borderRadius: '16px'
        }
      }
        
    }
  }
}))
