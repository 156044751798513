import { useTheme } from "@mui/material";
import { OrderConfirmationLayout } from "../order-confirmation-layout/order-confirmation.component";
import { IDealerProfile, IVehicle } from "@interfaces";
import { useOrderContext } from "@pages";
import { Navigation, OrderStatus } from "@constants";
import { useAppContext } from "@app/context-provider";
import { useMemo, useRef, useState } from "react";
import { Stipulation } from "./stipulation.component";
import { Box, Button } from "@ntpkunity/controls";
import { useWorkflowContext } from "@app/router-workflow";
import { useParams } from "react-router-dom";
import { formatUSPhoneNumberWithCountryCode } from "@helpers";
import DisableLoader from '../../src/shared/assets/loader-disabled.gif'

export const OrderInformation = ({
    vehicle, setOpenPopup, currency, setPaymentBreakdownPopup, dealerProfile, handleCallButtonClick, handleGetDirectionsClick,
    handleDealerNameLink, dealerByDealerCode }: {
        vehicle: IVehicle, setOpenPopup: any, currency: string, setPaymentBreakdownPopup: any,
        dealerProfile: IDealerProfile, handleCallButtonClick: any, handleGetDirectionsClick: any, handleDealerNameLink: any, dealerByDealerCode: any
    }) => {
    const theme = useTheme();
    const reSubmitRef = useRef<any>(null);
    const { state: { order }, loading } = useOrderContext()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const { navigateNext } = useWorkflowContext()
    const { vin, orderId } = useParams();
    const [isReSubmitEnabled, setIsReSubmitEnabled] = useState(false)
    const generateOrderText = (status) => {
        switch (status) {
            case OrderStatus.COMPLETED:
                return {
                    heading: PLACEHOLDERS.SUBMIT_ORDER_CONGRATS_ON_PURCHASE_COMPLETION,
                    description: PLACEHOLDERS.SUBMIT_ORDER_PROCESSING_ORDER_CONFIRM_APPOINTMENT
                }
            case OrderStatus.CANCELLED:
                return {
                    heading: PLACEHOLDERS.SUBMIT_ORDER_MORE_DETAILS_REQUIRED_FOR_ORDER,
                    description: PLACEHOLDERS.SUBMIT_ORDER_CANNOT_PROCEED_DIGITALLY
                }
            case OrderStatus.APPROVED:
                return {
                    heading: PLACEHOLDERS.SUBMIT_ORDER_CONGRATULATIONS,
                    congrats: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_APPROVED,
                    description: PLACEHOLDERS.SUBMIT_ORDER_PAPERWORK_READY_FOR_REVIEW_AND_SIGNING
                }
            case OrderStatus.DECLINED || OrderStatus.Withdrawn:
                return {
                    heading: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_DECLINED,
                    description: PLACEHOLDERS.SUBMIT_ORDER_NEED_MORE_INFO_FOR_CREDIT_APP
                }
            case OrderStatus.CONDITIONED:
                return {
                    heading: '',
                    congrats: PLACEHOLDERS.SUBMIT_ORDER_CREDIT_APP_CONDITIONED,
                    description: PLACEHOLDERS.SUBMIT_ORDER_PAPERWORK_READY_MESSAGE
                }
            case OrderStatus.TradeInInspection:
                return {
                    heading: PLACEHOLDERS.INSPECTION_TRADE_IN_ASSETS,
                    description: PLACEHOLDERS.INSPECTION_TRADE_IN_ASSETS_DESCRIPTION
                }
            default:
                return {
                    heading: PLACEHOLDERS.SUBMIT_ORDER_CONGRATS_ON_PURCHASE_START,
                    description: PLACEHOLDERS.SUBMIT_ORDER_REVIEW_CONFIRM_CHANGES_FINALIZE_ORDER
                }
        }
    }

    const { heading, congrats, description } = useMemo(() => generateOrderText(order?.status), [order?.status]);

    const handleContinueOrderClick = () => {
        navigateNext(Navigation.ORDER_ID_PAGE, { vin, orderId })
    };

    const setReSubmitRef = (ref) => {
        reSubmitRef.current = ref;
    };

    const handleReSubmitOrder = () => {
        if (reSubmitRef?.current) {
            reSubmitRef.current.reSubmitOrder();
        }
    }
    return (
        <OrderConfirmationLayout
            title={vehicle?.trim_description}
            subtitle={`${vehicle?.year ? vehicle?.year : ''} ${vehicle?.make ? vehicle?.make : ''} ${vehicle?.model ? vehicle?.model : ''}`}
            onClick={() => setOpenPopup(true)}
            productImg={vehicle?.photo_urls?.[0]?.location}
            heading={heading}
            congrats={congrats}
            description={description}
            terms={order?.status === OrderStatus.CONDITIONED && <Stipulation currency={currency} setReSubmitRef={setReSubmitRef} setIsReSubmitEnabled={setIsReSubmitEnabled} isReSubmitEnabled={isReSubmitEnabled} />}
            actionButtons={
                <Box theme={theme} className='action-btns'>
                    {(order?.status === OrderStatus.APPROVED || order?.status === OrderStatus.CONDITIONED) && (
                        <Button
                            theme={theme}
                            primary
                            text={order?.status === OrderStatus.APPROVED ? PLACEHOLDERS.SUBMIT_ORDER_CONTINUE_ORDER : PLACEHOLDERS.SUBMIT_ORDER_RE_SUBMIT_ORDER}
                            onClick={order?.status === OrderStatus.APPROVED ? handleContinueOrderClick : handleReSubmitOrder}
                            disabled={order?.status === OrderStatus.CONDITIONED ? (isReSubmitEnabled || loading) : false}
                            startIcon={loading && <img src={DisableLoader} alt="Loader" />}
                        />
                    )}
                    {!(order?.status === OrderStatus.CANCELLED || order?.status === OrderStatus.Withdrawn) &&
                        <Button theme={theme} secondary text={PLACEHOLDERS.SUBMIT_ORDER_VIEW_ORDER_SUMMARY} onClick={() => setPaymentBreakdownPopup(true)} />
                    }
                </Box>
            }


            {...(order?.status === OrderStatus.CANCELLED ||
                order?.status === OrderStatus.DECLINED
                ? {
                    chatBtnText: PLACEHOLDERS.SUBMIT_ORDER_START_CHAT,
                    onChatClick: () => { },
                    callBtnText: formatUSPhoneNumberWithCountryCode(
                        dealerProfile?.contact_number
                    ),
                    onCallClick: handleCallButtonClick,
                    directionBtnText: PLACEHOLDERS.SUBMIT_ORDER_GET_DIRECTIONS,
                    onDirectionClick: handleGetDirectionsClick,
                    linkDesc:
                        dealerProfile &&
                            dealerProfile?.contact_number &&
                            dealerProfile?.dealer_address
                            ? `${dealerProfile.dealer_address.address_line_1}, ${dealerProfile.dealer_address.city}, ${dealerProfile.dealer_address.state_name}, ${dealerProfile.dealer_address.zip_code}, ${PLACEHOLDERS.UNITED_STATES_TEXT}${PLACEHOLDERS.DOT_TEXT}`
                            : null,
                    linkText: `${dealerByDealerCode?.dealer_name},  `,
                    onLinkClick: () => { }
                }
                : {})
            }
        />
    )
}