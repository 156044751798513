import React, {useEffect, useReducer, useState} from 'react'
import {PaymentPopup, StripPayment, StripPaymentCardDetailPopup, StripPaymentConfirmationPopup} from '@libraries'
import {useGetPlaidToken, useGetPaymentAllowedLimitDetails} from "../../../hooks/stripe-payments";
import {generateUUID, scrollOnTop} from "../../../helpers/methods";
import {useOrderContext} from "../../(order-management)/order/order-context-provider";
import {Snackbar} from "@ntpkunity/controls";
import {SnackbarUtility} from "@utilities";
import {useTheme} from "@mui/material";
import {useParams} from "react-router-dom";
import {useDisclaimers, useGetCustomerConsent} from "../../../hooks/authentication-management";
import {DisclaimerNames} from "../../../constants/consent-status";
import {useGetCustomerByEmail} from "@hooks";
import {useGetConfiguration} from "../../../hooks/trade-in-management";
import {EstimateProvider,Integration_type} from "../../../constants/enums";
import { useAppContext } from '@app/context-provider';
import {useQueryClient} from "react-query";
import {QueryKeys} from "@constants";
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags, PageType } from 'constants/enums';
import { user_session } from '@constants'
import {LenderIntegrationType} from "../../../constants/providers";

export const PaymentWithStripePage = () => {
    const theme = useTheme()
    const { orderId } = useParams()
    const { state:{order} } = useOrderContext()
    const {state: appState } = useAppContext()

    const dealer = JSON.parse(localStorage.getItem('dealer_information')).dealer

    const {data: paymentAllowdLimitDetails } = useGetPaymentAllowedLimitDetails(dealer?.dealer_code)
    const queryClient = useQueryClient();
    const paymentDetails: any = queryClient.getQueryData([QueryKeys.STRIPE_PAYMENT_DETAILS, orderId]);
    const [paymentDetailsLoading, setPaymentDetailsLoading] = useState(true)
    const {mutate:getPlaidToken, data: plaidToken, isLoading:getPlaidTokenLoading} = useGetPlaidToken()
    const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false)
    const [openPaymentPopup, setOpenPaymentPopup] = useState(false)
    const [openPaymenTypePopup, setOpenPaymentTypePopup] = useState(false)
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const [paymentTypes, setPaymentTypes] = useState(['card'])
    const [linkToken, setLinkToken] = useState('')
    const [selectedPaymentType, setSelectedPaymentType] = useState('card')
    const [plaidConfig, setPlaidConfig] = useState({'publicToken': null, 'metadata': null})
    const { disclaimers, disclaimersLoading } = useDisclaimers([DisclaimerNames.PAYMENT_CONSENT])
    const { data: customerData, isLoading:customerDataLoading } = useGetCustomerByEmail(JSON.parse(localStorage.getItem("settings"))?.email);

    const {data:getCustomerConsents, isLoading:getCustomerConsentsLoading} = useGetCustomerConsent(customerData?.reference_id)
    const { tenant } = useParams()
    const user_profile = JSON.parse(localStorage.getItem("user_profile"))
    const { mutate: createTags } = useTags(tenant);
    const { data: kbbConfiguration, isLoading: checkingKbbConfiguration } = useGetConfiguration(dealer?.dealer_code,LenderIntegrationType.PAYMENT_PROCESSING,EstimateProvider.PLAID, appState?.tenant)

    const handleOpenConfirmationDialog = () => {
        setOpenConfirmationPopup(true)
    }
    const handleOpenPaymentTypeDialog = () => {

        setOpenConfirmationPopup(false)
        setOpenPaymentTypePopup(true)
    }
    const handleOpenPaymentDialog = () => {

        setOpenPaymentTypePopup(false)
        setOpenPaymentPopup(true)
    }
    useEffect(() => {
        const event: Event = {
            session: user_session,
            slug:tenant,
            event_name:Tags.PAYMENT_CALC,
            user_id: user_profile?.user_id,
            dealer_id: +order?.dealer_id,
            vehicle_make: order?.order_asset?.make,
            vehicle_model: order?.order_asset?.model,
            order_id:order?.identifier,
            vin:order?.vin,
            page_type:PageType.ScheduleAppointments
            }
        createTags(event)

    }, [])
    const handleCardDetailBackButton = () => {
        setOpenPaymentTypePopup(true)
        setOpenPaymentPopup(false)
    }

        useEffect(() => {
            if (kbbConfiguration?.is_active) {
                const payload ={
                    user_id: generateUUID()
                }
                getPlaidToken(payload,{
                    onSuccess(response){
                        setPaymentTypes(prevState => (
                            [ ...prevState,
                                'ach_debit']
                        ))
                        setLinkToken(response?.link_token)
                    }
                })
            }
        }, [kbbConfiguration]);


    useEffect(() => {
        if(paymentDetails){
            setPaymentDetailsLoading(false)
        }
    }, [paymentDetails]);


    return (
        <>
            <StripPayment
                orderDetails={order}
                handleOpenConfirmationDialog={getCustomerConsents?.find(item => item?.order_reference_id == orderId && item.disclaimer_name == DisclaimerNames.PAYMENT_CONSENT) ? handleOpenPaymentTypeDialog : handleOpenConfirmationDialog}
                paymentDetails={paymentDetails}
                paymentDetailsLoading={paymentDetailsLoading}
            />
            <StripPaymentConfirmationPopup
                openPopup={openConfirmationPopup}
                setOpenPopup={setOpenConfirmationPopup}
                setConfirmation={handleOpenPaymentTypeDialog}
                disclaimers={disclaimers}
                referenceNumber={order?.reference_number ? order.reference_number: orderId}
                customerData={customerData}
            />
            <StripPaymentCardDetailPopup
                openPopup={openPaymentPopup}
                setOpenPopup={setOpenPaymentPopup}
                signingAmount={order?.due_at_signing}
                referenceNumber={order?.reference_number ? order.reference_number: orderId}
                paymentDetails={paymentDetails}
                handleCardDetailBackButton={handleCardDetailBackButton}
                snackbardispatch={snackbardispatch}
                selectedPaymentType={selectedPaymentType}
                plaidConfig={plaidConfig}
                paymentAllowdLimitDetails={paymentAllowdLimitDetails}
            />
                <PaymentPopup
                openPopup={openPaymenTypePopup}
                paymentTypes={paymentTypes}
                setOpenPopup={setOpenPaymentTypePopup}
                handleOpenPaymentTypeDialog={handleOpenPaymentDialog}
                linkToken={linkToken}
                setSelectedPaymentType={setSelectedPaymentType}
                setPlaidConfig={setPlaidConfig}
                />

            <Snackbar
                theme={theme}
                message={snackbarState.message}
                open={snackbarState.open}
                onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
                anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
            />

        </>
    )
}