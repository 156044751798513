import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
    '.payment-breakdown': {
      '.stack-wrap': {
        '&.bg-light': {
          backgroundColor: theme.palette.common.white
        },
        '.line': {
          border: '1px solid ' + theme.palette.grey[100],
        },
      },
      '.cj-accordian-wrap': {
        '.u-accordian-wrap': {
          backgroundColor: theme.palette.common.white
        }
      }
    }
  }
}))
