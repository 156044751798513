import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductShoppingCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-shopping-card': {
        height: '100%',
        '.cj-card': {
            padding: '16px',
            height: '100%',
            position: 'relative',
            '.prd-tag': {
                background: theme.palette.grey[700],
                color: theme.palette.common.white,
                borderRadius: +theme.shape.borderRadius * 2,
                padding: '3px 8px',
                position: 'absolute',
                top: 8,
                left: 8,
                '&.oem': {
                    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #FFF',
                    color: '#666666'
                },
                '&.dealer': {
                    background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #FFF',
                    color: '#666666'
                },
            },
            '.product-img': {
                '.aspect-ratio-image-wrap': {
                    borderRadius: theme.shape.borderRadius,
                    overflow: 'hidden',
                    marginBottom: 8
                },
                'img': {
                    borderRadius: theme.shape.borderRadius
                }
            },
            '.cj-card-content': {
                padding: '8px',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                '.title': {
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden'
                },
                '.desc': {
                    width: '100%',
                    overflow: 'hidden',
                    display: '-webkit-box',
                    '-webkitLineClamp': '2',
                    '-webkitBoxOrient': 'vertical',
                    minHeight: 32
                },
                '.link': {
                    cursor: 'pointer',
                    display: 'inline-block'
                },
                '.listing': {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#F5F5F7',
                    borderRadius: '8px',
                    padding: '12px 16px',
                    gap: '8px',
                    '.nav-link': {
                        width: 'auto',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        span: {
                            fontSize: theme.typography.subtitle1.fontSize,
                            fontWeight: theme.typography.subtitle1.fontWeight,
                            color: theme.palette.primary.main
                        }
                    },
                }
            }
        }
    }
}))
