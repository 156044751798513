import { http } from '../utilities'

export const WORKFLOW_BASE_URL = process.env.WORKFLOW_BASE_URL

export namespace WorkFlowManagement {

  // https://workflow-api-dev.netsolapp.io/execute-workflow/bmw  => here bmw is slug
  // https://workflow-api-dev.netsolapp.io/get-current-task/34  => replace with your running id
  // https://workflow-api-dev.netsolapp.io/move-step/34 => replace with your running id

  export const getWorkflowBySlug = async ({ slug, target }: { slug: string, target?: string }) => {
    if (target) {
      return http(WORKFLOW_BASE_URL).post(`/execute-workflow/${slug}?target=${target}`).then((res) => { return res.data })
    }
    return http(WORKFLOW_BASE_URL).post(`/execute-workflow/${slug}`).then((res) => { return res.data })
  }

  export const getWorkflowStatus = async ({ running_id }: { running_id: number }) => {
    return http(WORKFLOW_BASE_URL).get(`/get-current-task/${running_id}`).then((res) => { return res.data })
  }

  export const getWorkflowNextStep = async ({ running_id }: { running_id: number }) => {
    return http(WORKFLOW_BASE_URL).post(`/move-step/${running_id}`, {}).then((res) => { return res.data })
  }

}