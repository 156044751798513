import { useTheme } from '@mui/material'
import { IConfirmationPopUp } from './ConfirmationDialog.type'
import { Dialog, Typography, Box, Button } from '@ntpkunity/controls'
import { Popup } from '@components'
import { useContext } from 'react'
import { AppContext } from '@app/context-provider'

export const ConfirmationDialog = <T extends any>({
  openPopUp,
  setPopUpState,
  confirmationText,
  confirmationTitle,
  onConfirm,
  onCancel,
  hideCancelButton,
  icon,
  primaryBtnText,
  secondaryBtnText,
  isButtonTypeDanger
}: IConfirmationPopUp<T>) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const LANGUAGE_PLACEHOLDERS = appState.state.language.placeholders;

  const onClose = () => {
    setPopUpState(false)
  }

  const onCancelClick = (e: any) => {
    onClose()
    onCancel(e)
  }

  const onConfirmClick = (e: any) => {
    setPopUpState(false)
    onConfirm(e)
  }

  return (
    <Popup>
      <Dialog
      disablePortal
      size="xs"
      variant="confirmation"
      open={openPopUp}
      noFooter={false}
      onCloseDialog={onClose}
      theme={theme}
      customFooter={
        <>
          {!hideCancelButton && (
            <Button
              theme={theme}
              secondary
              text={secondaryBtnText ? secondaryBtnText : LANGUAGE_PLACEHOLDERS.CANCEL_TEXT}
              onClick={onCancelClick}
            ></Button>
          )}
          <Button
            theme={theme}
            onClick={onConfirmClick}
            primary={isButtonTypeDanger ? false : true}
            text={primaryBtnText}
            danger={isButtonTypeDanger}
          ></Button>
        </>
      }  
    >
        <Box theme={theme} className="dialog-icon icon-alert">
            {icon}
          </Box>

          <Box theme={theme}>
            <Typography theme={theme} mb={2} className="content-title" variant="h2" component="h2">
              {confirmationTitle}
            </Typography>

            <Typography theme={theme} className="text-muted" variant="body2" component="span">
              {confirmationText}
            </Typography>
          </Box>
      </Dialog>
    </Popup>
  )
}
