import { CustomerManagement } from "../apis"
import { useMutation, useQuery } from "react-query"
import { QueryKeys } from "../constants"
import { useQueryClient } from 'react-query'

export const useGetCustomerByEmail = (email: string) => {
    return useQuery(QueryKeys.GET_CUSTOMER_BY_EMAIL, () => {
        return CustomerManagement.getCustomerByEmail({ email })
    }, { enabled: !!email })
}

export const useAddLicenseDetails = (): any => {
    const { data, isLoading, error, mutate } = useMutation((body) => {
        return CustomerManagement.addLicenseDetails(body);
    });
    return { data, isLoading, error, mutate };
};


export const useAddLicenseDetailsToMitek = (): any => {
    const { data, isLoading, error, mutate } = useMutation((body) => {
        return CustomerManagement.addLicenseDetailsToMitek(body);
    });
    return { data, isLoading, error, mutate };
};

export const useUpdateCustomerLicenseInfo = (): any => {
    const queryClient = useQueryClient()
    const { data, isLoading, error, mutate } = useMutation((body) => {
        return CustomerManagement.updateCustomerLicenseInfo(body);
    },
        {
            onSuccess: async (_responce: any) => {
                queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_BY_EMAIL)
                queryClient.invalidateQueries(QueryKeys.GET_LICENSE_INFO)
            }
        });
    return { data, isLoading, error, mutate };
};

export const useGetLicenseInformation = (reference_id: string) => {
    return useQuery(QueryKeys.GET_LICENSE_INFO, () => {
        return CustomerManagement.getLicenseInformation(reference_id)
    }, { enabled: !!reference_id })
}

export const useGetCustomerSignature = (referenceId: string) => {
    return useQuery(QueryKeys.GET_CUSTOMER_SIGNATURE, () => {
        return CustomerManagement.getCustomerSignature(referenceId)
    }, { enabled: !!referenceId })
}

export const useSaveCustomerSignatures = (): any => {
    const queryClient = useQueryClient()
    const { data, isLoading, mutate } = useMutation<any>(
      (body: any) => {
        return CustomerManagement.addCustomerSignature(body);
      }, {
        onSuccess: async (_responce: any) => {
            queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_SIGNATURE)
        }
      }
    )
    return { data, isLoading, mutate }
}

export const useGetCheckDMSCustomer = (reference_id: string, dealer_code: string) => {
    return useQuery(QueryKeys.GET_CHECK_DMS_CUSTOMER, () => {
        return CustomerManagement.getCheckDmsCustomer({reference_id, dealer_code} )
    }, { enabled: !!reference_id && !!dealer_code })
}

export const useUpdateDMSCustomer = (): any => {
  return useMutation<any>((data: any) => {
    return CustomerManagement.updateDmsCustomer(data);
  });
};
