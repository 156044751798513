import { DocumentPackageStatus, DocumentPackageTypes } from "constants/enums";
import React, {
  Dispatch,
  ReactNode,
  createContext,
  useContext,
  useReducer,
} from "react";

export interface IPages {
  addons: {
    success: boolean,
    error: boolean
  };
  payments: {
    success: boolean,
    error: boolean
  };
  fnI: {
    success: boolean,
    error: boolean
  };
  insurance: {
    success: boolean,
    error: boolean
  };
  tradeIn: {
    success: boolean,
    error: boolean
  }
  appointment: {
    success: boolean,
    error: boolean
  };
  contracting: {
    success: boolean,
    error: boolean
  };
  finance: {
    personal_details: {
      success: boolean,
      error: boolean
    },
    residence_details: {
      success: boolean,
      error: boolean
    },
    license_details: {
      success: boolean,
      error: boolean
    },
    employment_details: {
      success: boolean,
      error: boolean
    },
    personal_finance_details: {
      success: boolean,
      error: boolean
    },

  }
  stripePayment: {
    success: boolean,
    error: boolean
  };
}

const initialState: IPages = {
  addons: {
    success: false,
    error: false
  },
  tradeIn: {
    success: false,
    error: false
  },
  payments: {
    success: false,
    error: false
  },
  fnI: {
    success: false,
    error: false
  },
  insurance: {
    success: false,
    error: false
  },
  appointment: {
    success: false,
    error: false
  },
  contracting: {
    success: false,
    error: false
  },
  finance: {
    personal_details: {
      success: false,
      error: false
    },
    residence_details: {
      success: false,
      error: false
    },
    license_details: {
      success: false,
      error: false
    },
    employment_details: {
      success: false,
      error: false
    },
    personal_finance_details: {
      success: false,
      error: false
    },

  },
  stripePayment: {
    success: false,
    error: false
  }
};

export enum pagesActionType {
  UPDATE_ADDONS = "UPDATE_ADDONS",
  UPDATE_FNI = "UPDATE_FNI",
  UPDATE_FINANCING = "UPDATE_FINANCING",
  UPDATE_INSURANCE = "UPDATE_INSURANCE",
  UPDATE_PAYMENTS = "UPDATE_PAYMENTS",
  UPDATE_REBATE = "UPDATE_REBATE",
  UPDATE_SCHEDULE = "UPDATE_SCHEDULE",
  UPDATE_TRADEIN = "UPDATE_TRADEIN",
  UPDATE_PERSONAL_DETAILS = "UPDATE_PERSONAL_DETAILS",
  UPDATE_RESIDENCE_DETAILS = "UPDATE_RESIDENCE_DETAILS",
  UPDATE_LICENSE_DETAILS = "UPDATE_LICENSE_DETAILS",
  UPDATE_EMPLOYMENT_DETAILS = "UPDATE_EMPLOYMENT_DETAILS",
  UPDATE_PERSONAL_FINANCE_DETAILS = "UPDATE_PERSONAL_FINANCE_DETAILS",
  UPDATE_CONTRACTING = "UPDATE_CONTRACTING",
  UPDATE_TABS_STATUS = "UPDATE_TABS_STATUS",
  UPDATE_STRIPE_PAYMENT = "UPDATE_STRIPE_PAYMENT"
}

export const updateAddons = (addons: boolean) => ({
  type: pagesActionType.UPDATE_ADDONS,
  payload: addons,
});

export const updatePayments = (payments: any) => ({
  type: pagesActionType.UPDATE_PAYMENTS,
  payload: payments,
});

export const UpdateTabsStatus = (order: any) => ({
  type: pagesActionType.UPDATE_TABS_STATUS,
  payload: order
})

export const pagesReducer = (
  state: IPages = initialState,
  action: { type: pagesActionType; payload: any }
) => {
  switch (action.type) {
    case pagesActionType.UPDATE_PAYMENTS:
      return { ...state, payments: action.payload };
    case pagesActionType.UPDATE_INSURANCE:
      return { ...state, insurance: action.payload };
    case pagesActionType.UPDATE_SCHEDULE:
      return { ...state, appointment: action.payload };
    case pagesActionType.UPDATE_ADDONS:
      return { ...state, addons: action.payload };
    case pagesActionType.UPDATE_TRADEIN:
      return { ...state, tradeIn: action.payload }
    case pagesActionType.UPDATE_FNI:
      return { ...state, fnI: action.payload };
    case pagesActionType.UPDATE_STRIPE_PAYMENT:
      return { ...state, stripePayment: action.payload };
    case pagesActionType.UPDATE_PERSONAL_DETAILS:
      return { ...state, finance: { ...state.finance, personal_details: action.payload } };
    case pagesActionType.UPDATE_RESIDENCE_DETAILS:
      return { ...state, finance: { ...state.finance, residence_details: action.payload } };
    case pagesActionType.UPDATE_LICENSE_DETAILS:
      return { ...state, finance: { ...state.finance, license_details: action.payload } };
    case pagesActionType.UPDATE_EMPLOYMENT_DETAILS:
      return { ...state, finance: { ...state.finance, employment_details: action.payload } };
    case pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS:
      return { ...state, finance: { ...state.finance, personal_finance_details: action.payload } };
    case pagesActionType.UPDATE_CONTRACTING:
      return { ...state, contracting: action.payload };
    case pagesActionType.UPDATE_TABS_STATUS:
      return {
        ...state,
        contracting: {
          success: action.payload?.documents?.find(f => f.type === DocumentPackageTypes.CONTRACT && f.status === DocumentPackageStatus.Signed),
          error: action.payload?.documents?.find(f => f.type === DocumentPackageTypes.CONTRACT && f.status !== DocumentPackageStatus.Signed)
        },
        appointment: {
          success: !!action.payload?.appointment_details,
          error: false
        },
        fnI: {
          success: action.payload?.order_fnI?.length ? true : false,
          error: false
        },
        addons: {
          success: action.payload?.order_options?.length ? true : false,
          error: false
        },
        tradeIn: {
          success: !!action.payload?.order_tradein,
          error: false
        },
        insurance: {
          success: action.payload?.insurance?.insurer_name,
          error: false
        }
      };
  }

};

// Create context
export const JourneyLayoutContext = createContext<{
  state: IPages;
  dispatch: Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

// Create provider
export const JourneyLayoutContextProvider = ({ children, }: { children: ReactNode }) => {
  const [state, dispatch] = useReducer(pagesReducer, initialState);
  return (
    <JourneyLayoutContext.Provider value={{ state, dispatch }}>
      {children}
    </JourneyLayoutContext.Provider>
  );
};

export const useJourneyLayoutContext = () => useContext(JourneyLayoutContext);
