import styled from '@emotion/styled'
import { Theme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Grid, IBoxProps, Box, IGridProps } from '@ntpkunity/controls'
import AssetListViewControl from '../AssetListViewControl'
import AssetFilterControl from '../AssetFilterControl'
import clsx from 'clsx'
import React from 'react'
type IAssetSelectionControlProps = {
  theme: Theme;
  dealerCode: string;
  defaultCurrency: string;
  onClick: () => void;
  onListViewClick: (vin: string) => void;
  getInventoryPath: string;
  getReferenceByDealerCode: string;
  accessToken: string;
};

const CustomGrid = styled(
  Grid,
  {}
)<Partial<IGridProps>>(() => ({
  '&.main-content-section': {
    position: 'relative'
  }
}))

const FilterWrap = styled(
  Grid,
  {}
)<Partial<IGridProps>>(({ theme }) => ({
  '&.filters-toggle-wrap': {
    transition: 'all 0.5s',
    position: 'fixed',
    maxWidth: 350,
    left: 0,
    '&.hide': {
      left: '-100%',
      [theme.breakpoints.down('md')]: {
        left: 0,
        position: 'absolute',
        transform: 'scale(0)'
      }
    },
    [theme.breakpoints.down('md')]: {
      backgroundColor: theme.palette.common.white,
      zIndex: 10,
      transform: 'scale(1)',
      position: 'relative',
      maxWidth: '100%'
    }
  }
}))

export const MultiSelectWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0
  },
  '.u-dropdown-item': {
    '.custom-checkbox-wrap': {
      width: '100%',
      '.u-custom-control-label': {
        margin: 0,
        display: 'inline-block',
        width: '100%',
        '.MuiButtonBase-root': {
          marginRight: 0,
          position: 'absolute',
          width: '97%',
          textAlign: 'left',
          display: 'inline-block'
        },
        '.MuiFormControlLabel-label': {
          marginLeft: '30px',
          paddingTop: '24px',
          '&:hover': {
            '&:after': {
              display: 'none !important'
            }
          }
        }
      }
    }
  }
}))

const AssetSelectionControl: FC<IAssetSelectionControlProps> = ({
  theme,
  dealerCode,
  defaultCurrency,
  onClick,
  onListViewClick,
  getInventoryPath,
  getReferenceByDealerCode,
  accessToken,
}) => {
  const [openFilter, setOpenFilter] = useState(true);
  const [orderByState, setOrderByState] = useState<any>(null);
  const [referenceData, setReferenceData] = useState<any>(null);
  const [inventoryData, setInventoryData] = useState<any>(null);
  const filterData = (data: any) => {
    const xhr = new XMLHttpRequest();
    xhr.open(
      "GET",
      `${getInventoryPath}?dealer_code=${dealerCode}&page_number=${0}&page_size=${100}&${data}` +
        (orderByState ? `&order_by_price=${orderByState}` : ``)
    );
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    xhr.onload = function () {
      if (xhr.status === 200) {
        setInventoryData(JSON.parse(xhr.response));
      } else {
        console.error("Error fetching reference data:", xhr.statusText);
      }
    };
    xhr.onerror = function () {
      console.error("Error fetching reference data:", xhr.statusText);
    };
    xhr.send();
  };
  useEffect(() => {
    const xhr = new XMLHttpRequest();
    xhr.open("GET", `${getReferenceByDealerCode}/${dealerCode}`);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", `Bearer ${accessToken}`);
    xhr.onload = function () {
      if (xhr.status === 200) {
        setReferenceData(JSON.parse(xhr.response));
      } else {
        console.error("Error fetching reference data:", xhr.statusText);
      }
    };
    xhr.onerror = function () {
      console.error("Error fetching reference data:", xhr.statusText);
    };
    xhr.send();
  }, []);
  return (
    <>
      <CustomGrid
        className="main-content-section"
        theme={theme}
        container
        item
        spacing={1}
      >
        <FilterWrap
          theme={theme}
          item
          xs={12}
          md={12}
          lg={3}
          className={clsx("filters-toggle-wrap", openFilter ? "" : "hide")}
        >
          {referenceData && (
            <AssetFilterControl
              theme={theme}
              referenceData={referenceData}
              orderBy={orderByState}
              defaultCurrency={defaultCurrency}
              onSave={(data: any) => {
                filterData(data);
              }}
            ></AssetFilterControl>
          )}
        </FilterWrap>
        <AssetListViewControl
          theme={theme}
          openFilter={openFilter}
          setOpenFilter={setOpenFilter}
          inventoryData={inventoryData}
          setOrderByState={setOrderByState}
          defaultCurrency={defaultCurrency}
          onClick={onClick}
          onListViewClick={onListViewClick}
        />
      </CustomGrid>
    </>
  );
};

export default AssetSelectionControl
