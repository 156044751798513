import React, { useState } from "react";
import { useTheme } from "@mui/material";
import { ProfileLayoutWrap } from "./profile-layout.style";
import {Box, Icon, List, Skeleton, Typography} from "@ntpkunity/controls";
import { GradiantText } from "@components";
import {
  AppointmentsPage,
  FavoritesPage,
  MyOrdersPage,
  PrefrencesPage,
  ProfilePage,
} from "pages";
import { profileTabs } from "@constants";
import { useAppContext } from "@app/context-provider";
import {useGetCustomerByEmail, useGetCustomerContact, useGetLicenseInformation} from "@hooks";

export const ProfileLayout = () => {
  const theme = useTheme();
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders;
  const [activeTab, setActiveTab] = useState<string>(profileTabs.PROFILE);
  const customer_data = JSON.parse(localStorage.getItem("user_profile"))
  const getTab = (tab: string) => {
    switch (tab) {
      case profileTabs.PROFILE:
        return <ProfilePage />;
      case profileTabs.ORDERS:
        return <MyOrdersPage />;
      case profileTabs.FAVORITES:
        return <FavoritesPage />;
      case profileTabs.APPOINTMENTS:
        return <AppointmentsPage />;
      case profileTabs.PREFERENCES:
        return <PrefrencesPage />;
      default:
        return <></>;
    }
  };
  return (
    <ProfileLayoutWrap theme={theme} className="profile-layout-wrap">
      <Box theme={theme} className="aside">
        <Box theme={theme} className="welcome-area">
          <GradiantText bgText={PLACEHOLDERS.HELLO_TEXT} />
          <Box theme={theme} className="text-area">
            <Typography
              theme={theme}
              className="text-muted fw-medium"
              variant="body1"
              component={"p"}
            >
              {PLACEHOLDERS.PROFILE_LAYOUT_WELCOME_BACK}
            </Typography>
            <Typography theme={theme} variant="h3" component={"h3"}>
              {!customer_data ?
              <Skeleton theme={theme} width={'100%'} height={'40px'}/> :
              `${customer_data?.first_name} ${customer_data?.last_name}`}
            </Typography>
            <Typography
              theme={theme}
              className="text-muted fw-medium"
              variant="caption"
              component={"small"}
              display={"block"}
              mt={1}
            >
              {customer_data?.zip_code ? <>{PLACEHOLDERS.PROFILE_LAYOUT_YOUR_ZIP_CODE}: <span className="text-primary">{customer_data?.zip_code}</span> </>: false}

            </Typography>
          </Box>
        </Box>
        <Box theme={theme} className="left-menu">
          <List
            theme={theme}
            options={[
              {
                icon: <Icon name="UserOutlineIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_YOUR_PROFILE,
                onClick: () => {
                  setActiveTab(profileTabs.PROFILE);
                },
                selected: activeTab == profileTabs.PROFILE,
              },
              {
                icon: <Icon name="ListRoundedIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_ORDERS,
                onClick: () => {
                  setActiveTab(profileTabs.ORDERS);
                },
                selected: activeTab == profileTabs.ORDERS,
              },
              {
                icon: <Icon name="BookmarkOutlineIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_FAVORITES,
                onClick: () => {
                  setActiveTab(profileTabs.FAVORITES);
                },
                selected: activeTab == profileTabs.FAVORITES
              },
              {
                icon: <Icon name="CalendarOutlineIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_APPOINTMENTS,
                onClick: () => {
                  setActiveTab(profileTabs.APPOINTMENTS);
                },
                selected: activeTab == profileTabs.APPOINTMENTS
              },
              {
                icon: <Icon name="PrefrencesIcon" />,
                label: PLACEHOLDERS.PROFILE_LAYOUT_PREFERENCES,
                onClick: () => {
                  setActiveTab(profileTabs.PREFERENCES);
                },
                selected: activeTab == profileTabs.PREFERENCES
              },
            ]}
          />
        </Box>
      </Box>
      <Box theme={theme} className="right-content">
        {getTab(activeTab)}
      </Box>
    </ProfileLayoutWrap>
  );
};
