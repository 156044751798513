import { transform } from '@babel/core'
import { styled } from '@mui/material/styles'
import zIndex from '@mui/material/styles/zIndex'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const StepCountWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.step-count-wrap': {
        boxShadow: '0px 16px 16px -8px rgba(0, 0, 0, 0.16)',
        backgroundColor: theme.palette.common.white,
        padding: '20px 24px 0px 24px',
        borderRadius: 16,
        border: '1px solid #E5E5E5',
        marginBottom: 8,
        position: 'relative',
        zIndex: 1,
        '.card': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            gap: '24px',
            paddingBottom: 20,
            cursor: 'pointer',
            '.card-content': {
                display: 'flex',
                gap: 12,
                '.counter': {
                    position: 'relative',
                    width: 40,
                    height: 40,
                    '.loader-wrap': {
                        '.loader': {
                            width: 40,
                            height: 40,
                            top: '54%',
                            transform: 'translateY(-48%)',
                            '.lds-ring': {
                                '.ring-item': {
                                    border: '2px solid' + theme.palette.primary.main,
                                    borderColor: theme.palette.primary.main + ' transparent transparent transparent'
                                }
                            }
                        }
                    },
                    '.MuiTypography-root': {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }
                },
                 '.title': {
                    display: 'flex',
                    alignItems: 'center',
                    'span, small': {
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        width: 100
                    },
                    '&.title-with-subtitle': {
                        display: 'block'
                    },
                }
            },
            '.MuiButtonBase-root': {
                '&.icon-only': {
                    padding : 0,
                },
            },
            '.card-action': {
                display: 'flex',
                gap: '24px',
                '.action-icon': {
                    '.desktop-icon': {
                        display: 'block',
                        [theme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    },
                    '.chevron-up-icon': {
                        transform: 'rotate(180deg)'
                    }
                }
            }
        },
        '.card-progress-bar': {
            '.MuiLinearProgress-root': {
                '&.u-progress-bar': {
                    '&.progress-header': {
                        height: 2,
                        backgroundColor: theme.palette.grey[100],
                    }
                }
            }
        }
    }
}))
