import { useContext, useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

import { AppContext } from "./context-provider";
import { Navigation } from '../constants'

const useAuthentication = () => {
  const [authenticationStatus, setAuthenticationStatus] = useState(!!localStorage.getItem('access_token'));

  useEffect(() => {
    // Function to check if token is present
    const checkToken = () => {
      const token = localStorage.getItem('access_token');
      setAuthenticationStatus(!!token);
    };

    // Check token on mount
    checkToken();

    // Set up event listener for localStorage changes
    window.addEventListener('storage', checkToken);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, []);

  return { authenticationStatus }
}

type ProtectedRouteProps = { Component: () => JSX.Element }

export const ProtectedRoute = ({ Component }: ProtectedRouteProps) => {
  const { authenticationStatus } = useAuthentication()

  // using useContext to get the state and dispatch from the context
  const appState = useContext(AppContext);

  if (!authenticationStatus) {
    return <Navigate to={`${Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', appState.state.slug)}`} />
  }

  return <Component />
}

export const Unprotected = ({ Component }: ProtectedRouteProps) => {
  const { authenticationStatus } = useAuthentication()

  // using useContext to get the state and dispatch from the context
  const appState = useContext(AppContext);

  if (authenticationStatus) {
    return <Navigate to={`${Navigation.INVENTORY_PAGE.replace(':tenant', appState.state.slug)}`} />
  }

  return <Component />
}