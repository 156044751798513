import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CompletedStepsWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.completed-steps-wrap': {
        ul: {
            paddingLeft: 0,
            listStyleType: 'none',
            '.step-item': {
                paddingBottom: '24px',
                display: 'flex',
                gap: '8px',
                '&.error': {
                    svg: {
                        path: {
                            stroke: theme.palette.error.main
                        }
                    }
                },
                '&:last-child': {
                    paddingBottom: 0
                }
            }
        }
    }
}))
