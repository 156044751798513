import React, { ReactNode } from 'react'
import { CjButtonWrap } from './button-wrap.style'
import { useTheme } from '@mui/material'

export const CjButton = ({ children }: { children: ReactNode }) => {
    const theme = useTheme()
  return (
    <CjButtonWrap className='cj-button-wrap' theme={theme}>
      {children}
    </CjButtonWrap>
  )
}
