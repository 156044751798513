import React, { useContext } from 'react'
import { FC, useState, useEffect } from 'react'
import { Link, useTheme } from '@mui/material'
import {  Dialog, Box, Button, Grid, Typography  } from '@ntpkunity/controls'
import { VehicleCardUi } from '../../../inventory-controls/index'
import DummyImage from "../../../public/assets/images/dummy-img-ph.svg"
import  {ProductDetailtPopup}  from '../productdetailtpopup/productdetailtpopup'
import { Popup, ProductShoppingCard } from '@components'
import { AppContext } from '@app/context-provider'

export const OptionsListPopup: FC<{
  data: any
  callBack: any
  addedItems: any
  setAddedItems: any
  preInstalledOptions?: any
  defaultCurrency?: any
}> = ({ data, callBack, addedItems, setAddedItems, preInstalledOptions ,defaultCurrency}) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  const [cardDetails, setCardDetails] = useState({ product_name: '' })
  const [openProductDialog, setOpenProductDialog] = useState(false)

  const handleProductDetailsView = (cardData: any) => {
    setOpenProductDialog(true)
    setCardDetails(cardData)
    }

  const handleCloseProductDetails = () => {
    setOpenProductDialog(false)
    }
  const calculateSum = (obj: any) => {
    if (!addedItems.includes(obj.option_id || obj.id)) {
      setAddedItems([...addedItems, obj.option_id || obj.id])
      callBack('add', obj)
        }
    }

  const removeItem = (data: any) => {
    setAddedItems(addedItems.filter((id: any) => id !== (data.option_id ?? data.id)))
    callBack('remove', data)
    }
  const isAdded = (id: any) => addedItems.includes(id)

  const handleBtn = (cardData: any, isPreInstalled: any) => {
    if (!isPreInstalled) {
      if (isAdded(cardData.option_id ?? cardData.id)) {
        removeItem(cardData);
      } else {
        calculateSum(cardData);
      }
    }
  };

  const handleBtnText = (cardData: any, isPreInstalled: any) => {
    if (!isPreInstalled) {
      if (isAdded(cardData.option_id ?? cardData.id)) {
        return PLACEHOLDERS.ADD_ONS_REMOVE_PRODUCT;
      } else {
        return PLACEHOLDERS.ADD_ONS_ADD_PRODUCT;
      }
    }
    return ""
  };

  const VehicleCard: FC<{ cardData: any }> = ({ cardData }) => {
    const isPreInstalled = preInstalledOptions
    return (
      <Grid theme={theme} item xs={12} lg={6}>
        <ProductShoppingCard
          productImg={cardData?.image?.[0]?.location || DummyImage}
          productTag={cardData?.offered_by}
          title={cardData?.product_name}
          productPrice={cardData
              ? `${defaultCurrency}${parseFloat(cardData.price).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2
                        })} ${preInstalledOptions ? (cardData.is_price_inclusive ? '(Inc.) ' : '(Exc.)') : ''
                }`
              : ''}
          desc={cardData?.description}
          linkText={PLACEHOLDERS.ADD_ONS_VIEW_PRODUCT_DETAILS}
          onLinkClick={() => handleProductDetailsView(cardData)}
          onBtnClick={() => handleBtn(cardData, isPreInstalled)}
          buttonText={handleBtnText(cardData, isPreInstalled)}
          buttonStatePrimary={handleBtnText(cardData, isPreInstalled) === PLACEHOLDERS.ADD_ONS_ADD_PRODUCT ? true : false}
          buttonStateSecondary={handleBtnText(cardData, isPreInstalled) === PLACEHOLDERS.ADD_ONS_REMOVE_PRODUCT ? true : false}
        />
      </Grid>
    )
    }

  return (
    <>
      <Grid theme={theme} container item spacing={{xs:3, md: 2}} alignItems={'stretch'}>
        {data?.map((object: any, index: any) => (
          <VehicleCard cardData={object} key={index} />
        ))}
      </Grid>
      <Popup>
        <Dialog
          size="md"
          onCloseDialog={handleCloseProductDetails}
          title={cardDetails && cardDetails.product_name}
          open={openProductDialog}
          theme={theme}
          disablePortal
          customFooter={
            <Button theme={theme} primary text={handleBtnText(cardDetails, preInstalledOptions)} onClick={() => handleBtn(cardDetails, preInstalledOptions)} />
          }
        >
          <ProductDetailtPopup details={cardDetails && cardDetails} defaultCurrency={defaultCurrency} />
        </Dialog>
      </Popup>
    </>
  )
}
