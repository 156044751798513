import { FC, useState } from 'react'
import { HeaderProps } from './header.types'
import Toolbar from '@mui/material/Toolbar'
import { HeaderWrap } from './header.style'
import { HeaderMenu } from '@components'
import { ClickAwayListener } from '@mui/material'

export const Header: FC<HeaderProps> = ({ theme }) => {

  const [openMenu, setOpenMenu] = useState(true)
  const [openSearch, setOpenSearch] = useState(true)

  const handleClickAway = () => {
    setOpenMenu(true);
    setOpenSearch(true);
  };

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <HeaderWrap theme={theme} className='header-wrap'>
        <Toolbar disableGutters className="u-toolbar">
            <HeaderMenu 
              openMenu={openMenu}
              setOpenMenu={setOpenMenu}
              openSearch={openSearch}
              setOpenSearch={setOpenSearch}
              />
        </Toolbar>
      </HeaderWrap>
    </ClickAwayListener>
  )
}
