import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProfileLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.profile-layout-wrap': {
    minHeight: '100vh',
    backgroundColor: '#F5F5F7',
    margin: -8,
    padding: 8,
    display: 'flex',
    gap: 8,
    '.aside': {
      maxWidth: '100%',
      width: '100%',
      flexShrink: 0,
      [theme.breakpoints.up('md')]: {
        maxWidth: 350,
        padding: 32,
      },
      '.welcome-area': {
        '.order-confirmation-wrap': {
          '.product-bg-text': {
            padding: '0 4px',
            top: 0,
            '.bg-text': {
              fontSize: 96,
              lineHeight: '120px',
              maxWidth: '100%',
              [theme.breakpoints.up('md')]: {
                textAlign: 'left',
              }
            },
            [theme.breakpoints.up('md')]: {
              alignItems: 'flex-start',
              justifyContent: 'flex-start',
            }
          }
        },
        '.text-area': {
          padding: '0 24px 24px 24px',
          marginTop: -36,
          [theme.breakpoints.down('md')]: {
            padding: '0 24px 40px 24px',
          }
        },
        [theme.breakpoints.down('md')]: {
          textAlign: 'center'
        }
      },
      '.left-menu': {
        marginTop: 16,
        '.u-nav-wrap': {
          '.u-list-item': {
            padding: '20px 24px',
            marginBottom: 8,
            borderRadius: 16,
            '.u-list-item-icon': {
              'svg': {
                'path': {
                  stroke: theme.palette.grey[300],
                  fill: theme.palette.grey[300]
                }
              },
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            },
            '&:last-child': {
              marginBottom: 0
            },
            '&:focus, &:active': {
              backgroundColor: theme.palette.common.white + ' !important',
              [theme.breakpoints.down('md')]: {
                backgroundColor: 'transparent !important',
              },
              '.u-list-item-icon': {
                'svg': {
                  'path': {
                    stroke: theme.palette.grey[300],
                    fill: theme.palette.grey[300]
                  }
                }
              },
              '.u-list-item-text': {
                color: theme.palette.grey[600]
              }
            },
            '&:hover': {
              backgroundColor: theme.palette.grey[50],
              '.u-list-item-icon': {
                'svg': {
                  'path': {
                    stroke: theme.palette.grey[500],
                    fill: theme.palette.grey[500]
                  }
                }
              },
              '.u-list-item-text': {
                color: theme.palette.grey[900]
              },
              [theme.breakpoints.down('md')]: {
                backgroundColor: 'transparent'
              }
            },
            '&.selected': {
              backgroundColor: theme.palette.common.white,
              '.u-list-item-icon': {
                'svg': {
                  'path': {
                    stroke: theme.palette.primary.main,
                    fill: theme.palette.primary.main
                  }
                }
              },
              '.u-list-item-text': {
                color: theme.palette.grey[900],
                '.MuiTypography-root': {
                  fontWeight: theme.customVariables.fontWeightSemiBold
                }
              },
              '&:focus, &:active': {
                backgroundColor: theme.palette.common.white,
                '.u-list-item-icon': {
                  'svg': {
                    'path': {
                      stroke: theme.palette.primary.main,
                      fill: theme.palette.primary.main
                    }
                  }
                },
                '.u-list-item-text': {
                  color: theme.palette.grey[900],
                },
                [theme.breakpoints.down('md')]: {
                  backgroundColor: 'transparent'
                }
              },
              [theme.breakpoints.down('md')]: {
                backgroundColor: 'transparent',
                '&::before': {
                  width: '100%'
                }
              }
            },
            [theme.breakpoints.down('md')]: {
              padding: '16px 0 12px',
              marginBottom: 0,
              marginLeft: 20,
              marginRight: 20,
              position: 'relative',
              '&::before': {
                display: 'block',
                content: "' '",
                backgroundColor: theme.palette.primary.main,
                position: 'absolute',
                width: 0,
                height: 4,
                bottom: 0,
                borderRadius: 4,
                transition: 'all 0.3s ease-in-out 0s',
                opacity: 1
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            display: 'flex',
            flexShrink: 0
          }
        },
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          borderBottom: '1px solid' + theme.palette.grey[100],
          padding: 0,
          flexDirection: 'row',
          flexWrap: 'nowrap',
          marginLeft: -8,
          marginRight: -8,
          marginTop: 0,
          minHeight: 'auto',
          borderRadius: 0,
          overflowX: 'scroll',
          overflowY: 'hidden',
          scrollbarWidth: 'none',
          transition: 'all 0.3s ease-in-out 0s',
          position: 'relative',
          top: 0,
          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          },
          '&.menu-fixed': {
            position: 'fixed',
            width: '100%',
            backgroundColor: theme.palette.common.white,
            zIndex: 10,
            top: 65
          }
        }
      }
    },
    '.right-content': {
      flexGrow: 1,
      maxWidth: '1145px',
      marginLeft: 'auto',
      marginRight: 'auto',
      padding: '24px 16px',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '56px 80px',
      }
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      gap: 0
    }
  }
}))
