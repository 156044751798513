import { FC } from 'react'
import { Box, IBoxProps, Icon } from '@ntpkunity/controls'
import Carousel from 'react-multi-carousel'
import { IconButton, styled, useTheme } from '@mui/material'
// require('./responsive-carousel.css')
import { CarouselButtons, CarouselWrap } from './responsive-carousel.style'
import React from 'react'

interface IResponsiveCarouselProps {
  children: any
  infinite?: boolean
  removeArrowOnDeviceType?: string | Array<string>
  keyBoardControl?: boolean
  containerClass?: string
  itemClass?: string
  slidesToSlide?: number
  autoPlay?: boolean
  responsive: ResponsiveType
  swipeable?: boolean
  draggable?: boolean
  pauseOnHover?: boolean
  autoPlaySpeed?: number
}

export interface ResponsiveType {
  [key: string]: {
    breakpoint: {
      max: number
      min: number
    }
    items: number
    partialVisibilityGutter?: number
    paritialVisibilityGutter?: number
    slidesToSlide?: number
  }
}

const CarouselButtonGroup = ({ next, previous }: any) => {
  const theme = useTheme()
  return (
    <CarouselButtons theme={theme} className="carousel-button-group">
      <IconButton className="prev-btn carousel-control" onClick={() => previous()}>
        <Icon name="LeftArrowCircle" />
      </IconButton>
      <IconButton className="next-btn carousel-control" onClick={() => next()}>
        <Icon name="RightArrowCircle" />
      </IconButton>
    </CarouselButtons>
  )
}

export const ResponsiveCarousel: FC<IResponsiveCarouselProps> = ({
  children,
  infinite = true,
  removeArrowOnDeviceType = ['xl', 'lg', 'md', 'sm', 'xs'],
  keyBoardControl = true,
  containerClass = 'carousel-container',
  itemClass = 'carousel-item',
  slidesToSlide = 1,
  autoPlay = true,
  responsive,
  pauseOnHover,
  autoPlaySpeed
}) => {
  const theme = useTheme()
  return (
    <CarouselWrap className='carousel-wrap' theme={theme}>
      <Carousel
        swipeable={true}
        draggable={true}
        showDots={true}
        pauseOnHover={pauseOnHover}
        responsive={responsive}
        infinite={infinite}
        autoPlay={autoPlay}
        autoPlaySpeed={autoPlaySpeed}
        keyBoardControl={keyBoardControl}
        containerClass={containerClass}
        removeArrowOnDeviceType={removeArrowOnDeviceType}
        itemClass={itemClass}
        slidesToSlide={slidesToSlide}
        renderButtonGroupOutside={true}
        additionalTransfrom={0}
        // customButtonGroup={<CarouselButtonGroup next={{}} previous={{}} />}
      >
        {children}
      </Carousel>
    </CarouselWrap>
  )
}
