import React from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from './rebates.style'
import { PageHeader } from '@components'
import { JourneyFooter } from '@libraries'

export const RebatesPage = () => {
  const theme = useTheme()
  return (
    <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title='Do you qualify for any incentive?'
        subTitle={
          <>
            Based on your zip code <span className='text-primary'>00000</span>, your purchase is eligible of the following incentives. Please add incentives for which you believe you qualify. We will run the eligibility checks when you submit your order.
          </>
        }
      />
    </PageWrap>
  )
}