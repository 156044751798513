import styled from '@emotion/styled'
import { Theme } from '@mui/material'
import { FC } from 'react'
import { Grid, IBoxProps, Icon, Box, Typography, Dialog, Button,Image } from '@ntpkunity/controls'
import ResponsiveCarousel from '../ResponsiveCarousel/ResponsiveCarousel'
import { VehicleCardUi } from '../VehicleCardUi'
import { Popup, Stack } from '@components'
import { useAppContext } from '@app/context-provider'
import { useQueryClient } from 'react-query'
import { QueryKeys } from '@constants'
import { IDealerProfile } from '@interfaces'

type IAssetDetailCardProps = {
  theme: Theme
  selectedVehicle: any
  defaultCurrency: string
  openPopup: boolean
  setOpenPopup: any
  onClick: () => void
  hideFooter?: boolean
}
export const MultiSelectWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0
  },
  '.u-dropdown-item': {
    '.custom-checkbox-wrap': {
      width: '100%',
      '.u-custom-control-label': {
        margin: 0,
        display: 'inline-block',
        width: '100%',
        '.MuiButtonBase-root': {
          marginRight: 0,
          position: 'absolute',
          width: '97%',
          textAlign: 'left',
          display: 'inline-block'
        },
        '.MuiFormControlLabel-label': {
          marginLeft: '30px',
          paddingTop: '24px',
          '&:hover': {
            '&:after': {
              display: 'none !important'
            }
          }
        }
      }
    }
  }
}))

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 1
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
}

const defaultValues = {
  assetDetails: 'Asset Details',
  selectContinue: 'Select & Continue',
  sellingPrice: 'Selling Price',
  vehicleFeaturesSpecifications: 'Vehicle Features and Specs',
  driveTrain: 'Drivetrain:',
  engine: 'Engine:',
  exteriorColor: 'Exterior Color:',
  interiorColor: 'Interior Color:',
  transmissionType: 'Transmission Type:',
  stockNumber: 'Stock Number:',
  msrp: 'MSRP',
  priceForYou: 'Price For You',
  horsepower: 'Horsepower:',
  vinNumber: 'VIN',
  stock: 'Stock'
}

const AssetDetailControl: FC<IAssetDetailCardProps> = ({
  theme,
  selectedVehicle,
  defaultCurrency,
  openPopup,
  setOpenPopup,
  onClick,
  hideFooter = false
}) => {
  const handleCloseDialog = () => {
    setOpenPopup(false)
  }

  const { state: appState } = useAppContext();
  const placeholders = appState.language.placeholders;

  const queryClient = useQueryClient();
  const dealerProfile: IDealerProfile = queryClient.getQueryData(
    QueryKeys.DEALER_PROFILE
  );

  const dealerInformation = JSON.parse(localStorage.getItem("dealer_information"))
  const { dealer_state_name, dealer_city, dealer_name } = dealerInformation?.dealer

  return (
    <Popup>
      <Dialog
        size='lg'
        theme={theme}
        title={placeholders.INVENTORY_ASSET_DETAILS}
        open={openPopup}
        disablePortal
        onCloseDialog={handleCloseDialog}
        noFooter={hideFooter}
        customFooter={!hideFooter && (
          <>
            <Box theme={theme} className='footer-btns'>
              <Button secondary theme={theme} text={placeholders?.BUTTON_TEXT_SELECT_CONTINUE ?? defaultValues.selectContinue} onClick={onClick}></Button>
            </Box>
          </>)
        }
      >
        <VehicleCardUi theme={theme} className="vehicle-card full-width vd-popup m-dialog">
          <Box theme={theme} className='carousel-area'>
            <Box theme={theme} className="vc-type-name" textAlign={'center'} display={{ xs: 'block', sm: 'none' }} mb={3}>
              <Typography
                theme={theme}
                className="vc-type text-muted fw-medium"
                display={'block'}
                variant="body1"
                component="p"
              >
                {selectedVehicle?.vehicle?.year} {selectedVehicle?.vehicle?.make}
                {selectedVehicle?.vehicle?.model}
              </Typography>
              <Typography
                theme={theme}
                className="vc-name text-dark"
                display={'block'}
                variant="h3"
                component="h3"
              >
                {selectedVehicle?.vehicle?.trim_description}
              </Typography>
              <Box theme={theme} className='links' mt={1}>
                <a className='link' href={dealerProfile?.website} target='_blank'>{dealer_name}</a>
                <span> {dealer_city} {dealer_state_name ? `, ${dealer_state_name}` : ''}</span>
              </Box>
            </Box>
            {selectedVehicle?.vehicle?.photo_urls?.every(
              (photo: any) => photo.location !== '' && photo.location?.split('//')?.[1] !== ''
            )
              ? (
                <ResponsiveCarousel responsive={responsive}>
                  {selectedVehicle?.vehicle?.photo_urls?.length > 0 &&
                    selectedVehicle?.vehicle?.photo_urls?.map((obj: any, index: any) => (
                      <Box theme={theme} key={index} className='carousel-img' display={'flex'} alignItems={'center'} justifyContent={'center'}>
                        <Image theme={theme} aspectRatio={'16:9'}
                          src={obj?.location === '' ? <Icon name='NoPreviewAvailable' /> : obj?.location}
                        />
                      </Box>
                    ))}
                </ResponsiveCarousel>
              )
              : (
                <Box theme={theme} className="vc-img-wrap blank-img-wrap">
                  <Icon name="NoPreviewAvailable" />
                </Box>
              )}
            {selectedVehicle?.vehicle?.photo_urls?.length <= 0 && <Icon name="NoPreviewAvailable" />}
            <Box theme={theme} className="price-info" textAlign={'center'} display={{ xs: 'block', sm: 'none' }} mt={3}>
              <Typography
                theme={theme}
                className="price-title text-muted fw-medium"
                display={'block'}
                variant="body2"
                component="span"

              >
                {placeholders.INVENTORY_ASSET_DETAILS_SELLING_PRICE_FIELD_LABEL}
              </Typography>
              <Typography
                theme={theme}
                className="vc-price text-primary"
                display={'block'}
                variant="h3"
                component="h3"
                mb={1}
              >
                {defaultCurrency}
                {selectedVehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}
              </Typography>
              <Typography
                theme={theme}
                className="msrp"
                display={'block'}
                variant="body2"
                component="span"
              >
                {placeholders?.INV_ASSET_FILTER_CONTROL_MSRP ?? defaultValues.msrp} <span className='text-muted'>{defaultCurrency}{selectedVehicle?.vehicle?.msrp?.toLocaleString(undefined, {
                  maximumFractionDigits: 2,
                  minimumFractionDigits: 2
                })}</span>
              </Typography>
            </Box>
          </Box>
          <Stack bgLight marginTopMd={2} marginTopXs={2} className='info-head-stack'>
            <Box
              theme={theme}
              className="vc-info-head"
              display={'flex'}
              justifyContent={'space-between'}
              flexWrap={'wrap'}
              gap={1}
            >
              <Box theme={theme} className="vc-type-name">
                <Typography
                  theme={theme}
                  className="vc-type text-muted fw-medium"
                  display={'block'}
                  variant="body1"
                  component="p"
                >
                  {selectedVehicle?.vehicle?.year} {selectedVehicle?.vehicle?.make}
                  {selectedVehicle?.vehicle?.model}
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-name text-dark"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {selectedVehicle?.vehicle?.trim_description}
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-name text-dark"
                  display={'block'}
                  variant="body2"
                  component="span"
                >
                  <Box theme={theme} className='links' mt={1}>
                    <a className='link' href={dealerProfile?.website} target='_blank'>{dealer_name}</a>
                    <span> {dealer_city} {dealer_state_name ? `, ${dealer_state_name}` : ''}</span>
                  </Box>
              </Typography>
              </Box>
              <Box theme={theme} className="price-info">
                <Typography
                  theme={theme}
                  className="price-title text-muted fw-medium"
                  display={'block'}
                  variant="body2"
                  component="span"
                >
                  {placeholders?.VEHICLE_PRICE_FOR_YOU ?? defaultValues.priceForYou}
                </Typography>
                <Typography
                  theme={theme}
                  className="vc-price text-primary"
                  display={'block'}
                  variant="h3"
                  component="h3"
                >
                  {defaultCurrency}
                  {selectedVehicle?.vehicle?.internet_price?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}
                </Typography>
                <Typography
                  theme={theme}
                  className="msrp fw-sbold"
                  display={'block'}
                  mt={1}
                  variant="body2"
                  component="span"
                >
                  {placeholders?.INV_ASSET_FILTER_CONTROL_MSRP ?? defaultValues.msrp}  <span className='text-muted fw-normal'>{defaultCurrency}{selectedVehicle?.vehicle?.msrp?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                  })}</span>
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Stack bgLight marginTopMd={2} marginTopXs={2}>
            <Box
              theme={theme}
              className="stock dFlex justifyContentBetween flexWrap gap-2"
            >
              <Box theme={theme} display={'flex'} gap={1}>
                <Typography
                  theme={theme}
                  className="vin fw-sbold"
                  display={'block'}
                  variant="body2"
                  component="p"
                >
                  {placeholders?.INVENTORY_ASSET_FILTER_STOCK_FIELD_LABEL ?? defaultValues.stock}
                </Typography>
                <Typography
                  theme={theme}
                  display={'block'}
                  className='text-muted'
                  variant="body2"
                  component="p"
                >
                  {selectedVehicle?.vehicle?.stock_number}
                </Typography>
              </Box>
              <Box theme={theme} display={'flex'} gap={1}>
                <Typography
                  theme={theme}
                  className="vin fw-sbold"
                  variant="body2"
                  component="p"
                >
                  {placeholders?.INVENTORY_ASSET_FILTER_VIN_FIELD_LABEL ?? defaultValues.vinNumber}
                </Typography>
                <Typography
                  theme={theme}
                  variant="body2"
                  className='text-muted'
                  component="p"
                >
                  {selectedVehicle?.vehicle?.vin}
                </Typography>
              </Box>
            </Box>
          </Stack>
          <Stack bgLight marginTopMd={2} marginTopXs={2}>
            <Box theme={theme} className="vc-features">
              <Typography
                theme={theme}
                className="vf-title text-dark text-h3"
                display={'block'}
                variant="h3"
                component="h3"
                mb={3}
              >
                {placeholders?.INVENTORY_ASSET_DETAILS_VEHICLE_FEATURES_SPECIFICATIONS_FIELD_LABEL ?? defaultValues.vehicleFeaturesSpecifications}
              </Typography>
              <Grid theme={theme} container item spacing={3}>
                <Grid theme={theme} item xs={6} md={4} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {placeholders?.INVENTORY_ASSET_DETAILS_DRIVE_TRAIN_FIELD_LABEL ?? defaultValues.driveTrain}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark fw-medium"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      {selectedVehicle?.vehicle?.drive_train}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={4} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {placeholders?.INVENTORY_ASSET_DETAILS_EXTERIOR_COLOR_FIELD_LABEL ?? defaultValues.exteriorColor}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark fw-medium"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      {selectedVehicle?.vehicle?.exterior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={4} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {placeholders?.HORSEPOWER_TEXT ?? defaultValues.horsepower}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark fw-medium"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      -
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={4} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {placeholders?.INVENTORY_ASSET_DETAILS_ENGINE_FIELD_LABEL ?? defaultValues.engine}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark fw-medium"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      {selectedVehicle?.vehicle?.engine_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={4} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {placeholders?.INVENTORY_ASSET_DETAILS_INTERIOR_COLOR_FIELD_LABEL ?? defaultValues.interiorColor}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark fw-medium"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      {selectedVehicle?.vehicle?.interior_color_description}
                    </Typography>
                  </Box>
                </Grid>
                <Grid theme={theme} item xs={6} md={4} lg={4}>
                  <Box theme={theme} className="feat-item">
                    <Typography
                      theme={theme}
                      className="text-muted"
                      display={'block'}
                      variant="body2"
                      component="span"
                    >
                      {placeholders?.INVENTORY_ASSET_DETAILS_TRANSMISSION_TYPE_FIELD_LABEL ?? defaultValues.transmissionType}
                    </Typography>
                    <Typography
                      theme={theme}
                      className="text-dark fw-medium"
                      display={'block'}
                      variant="body1"
                      component="p"
                    >
                      {selectedVehicle?.vehicle?.transmission_type}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Stack>
        </VehicleCardUi>
      </Dialog>
    </Popup>
  )
}

export default AssetDetailControl
