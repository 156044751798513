import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const OrderConfirmationWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.order-confirmation-wrap': {
      position: 'relative',
      '.product-card': {
        padding: 80,
        marginBottom: 40,
        transition: '0.4s',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          padding: '64px 16px',
          marginBottom: 0
        },
        '.product-card-bg': {
            maxWidth: '100%',
            maxHeight: 242,
            height: '100%',
            position: 'absolute',
            top: 85,
            left: 0,
            width: '100%',
            right: 0,
            margin: 'auto',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            [theme.breakpoints.down('md')]: {
              top: 70
            },
            [theme.breakpoints.down('sm')]: {
              top: 48
            },
          'img': {
            maxWidth: 801,
            maxHeight: 242,
            width: '100%',
            height: '100%',
            objectFit: 'fill',
            objectPosition: 'center center'
          },
        },
        '.product-bg-text': {
          maxWidth: '100%',
          maxHeight: 242,
          height: '100%',
          position: 'absolute',
          top: 86,
          left: 0,
          width: '100%',
          right: 0,
          margin: 'auto',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          [theme.breakpoints.down('md')]: {
            top: 120
          },
          [theme.breakpoints.down('sm')]: {
            top: 125
          },
          '.bg-text': {
            fontSize: 200,
            whiteSpace: 'nowrap',
            overFlow: 'hidden',
            textOverflow: 'ellipses',
            maxWidth: 801,
            maxHeight: 242,
            width: '100%',
            height: '100%',
            textAlign: 'center',
            background: 'linear-gradient(180deg, #AEB1BF 0%, rgba(174, 177, 191, 0.00) 80.17%)',
            '-webkitBackgroundClip': 'text',
            '-webkitTextFillColor': 'transparent',
            lineHeight: 'normal',
            [theme.breakpoints.down('md')]: {
              fontSize: 130,
            },
            [theme.breakpoints.down('sm')]: {
              fontSize: 76,
            },
          }
        },
        '.card-content': {
          display: 'flex',
          justifyContent: 'center',
          '.text-items': {
            textAlign: 'center',
            position: 'relative',
            zIndex: 2,
            '.title': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 8,
              '.MuiButtonBase-root': {
                '&.icon-only': {
                    padding : 0,
                },
                '&.btn-default': {
                    'svg': {
                        'path':{
                            stroke: theme.palette.primary.main,
                        }
                    }
                }
            },
            },
          },
          '.product-card-fg': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'relative',
            maxWidth: 642,
            marginTop: 24,
            marginLeft: 'auto',
            marginRight: 'auto',
            'img': {
              borderRadius: '16px'
            }
          },
          '.card-detail': {
            textAlign: 'center',
            maxWidth: '896px',
            display: 'flex',
            margin: 'auto',
            width: '100%',
            flexDirection: 'column',
            paddingTop: 24,
            gap: 24,
            '.items': {
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
              gap: 8,
              [theme.breakpoints.down('md')]: {
                alignItems: 'flex-start',
              },
              '.term': {
                display: 'flex',
                alignItems: 'center',
                gap: 8,
                '&.icon-green':{
                  svg: {
                    path: {
                      stroke: theme.palette.success.main
                    }
                  }
                },
                '&.icon-error':{
                  svg: {
                    path: {
                      stroke: theme.palette.error.main
                    }
                  }
                },
              },
              '.term-text': {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                width: '100%',
                '.MuiTypography-root': {
                  textAlign: 'left',
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  width: '75%',
                  [theme.breakpoints.down('md')]: {
                    overFlow: 'auto',
                    whiteSpace: 'wrap',
                    textOverflow: 'auto',
                    width: '100%',
                  }
                },
                '.MuiButtonBase-root': {
                  whiteSpace: 'nowrap'
                }
              },
              '.MuiButtonBase-root': {
                '&.btn.btn-default': {
                  color: theme.palette.primary.main
                }
              }
            },
            '.action-btns': {
                display: 'flex',
                justifyContent: 'center',
                gap: 16,
                [theme.breakpoints.down('sm')]: {
                  flexDirection: 'column'
                }
            },
            '.link-text': {
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              '.MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
                textDecoration: 'none',
                '.link': {
                  padding: '0 3px'
                }
              },
              '.MuiLink-root': {
                cursor: 'pointer',
                display: 'inline'
              }
            }
          },
        },
      }
    }
}))
