import { createContext, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { updateSocialMediaIcons, useAppContext } from './context-provider';
import { useExistingWorkFlowMutation, useGetDealerProfileByDealerCode, useWorkFlowMutation, useWorkFlowNextStep } from '@hooks';
import { Navigation } from '@constants';
import {RESET_PASSWORD_ROUTE} from "constants/navigation";
import {useAuthentication} from "../hooks/useAuthenticationHook";
type MetaData = {
  tabs?: string[];
};

type Workflow = {
  workflow_id: number;
  running_id: number;
  current_state: string;
  next_state: string;
  task_id: number;
  task_status: string;
  api_response: null | string;
  api_url: null | string;
  meta_data: null | MetaData;
};

// ================================== Workflow Context ================================= //

type WorkflowContextType = { navigateNext: (path?: string, payload?: any) => void, getOrderWorkflow: (vin?: string, referece_number?: string, running_id?: string) => void };
const WorkflowContext = createContext<WorkflowContextType>({ navigateNext: () => null, getOrderWorkflow: () => null });
const useWorkflowContext = () => useContext(WorkflowContext);

const WorkflowProvider = ({ children }: { children: JSX.Element }) => {

  const navigate = useNavigate()
  const location = useLocation();
  const { pathname, search } = location
  const { authenticationStatus } = useAuthentication()
  const { state: app_state } = useAppContext()

  const { mutate: fetch_workflow } = useWorkFlowMutation();
  const { mutate: fetch_workflow_status} = useExistingWorkFlowMutation()
  const { mutate: move_next } = useWorkFlowNextStep()
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const [dealerCode, setDealerCode] = useState(null);

  const defaultRoutes = [Navigation.PROFILE.USER_PROFILE, Navigation.PROFILE.APPOINTMENTS, Navigation.PROFILE.FAVORITES, Navigation.PROFILE.ORDERS, Navigation.PROFILE.PREFRENCES]


  //socaial media icons implementation
  const { data: dealerProfile, isLoading, isError, error } = useGetDealerProfileByDealerCode(dealerCode);

  useEffect(() => {
    if (dealerProfile) {
      const socialLinks = {
        twitter: dealerProfile?.twitter || '',
        linkedin: dealerProfile?.linkedin || '',
        youtube: dealerProfile?.youtube || '',
        google: dealerProfile?.google || '',
        instagram:dealerProfile?.instagram || '',
        facebook: dealerProfile?.facebook || '',
      };
      appDispatch(updateSocialMediaIcons(socialLinks));
    }
  }, [dealerProfile,dealerCode]);

  useEffect(() => {
    if (!dealerCode) {
      const dealerObject = JSON.parse(localStorage.getItem('dealer_information') || '{}')
      setDealerCode(dealerObject?.dealer?.dealer_code)
    }

  })

  useEffect(() => {


    // handle local storage event
    const handleLocalStorageEvent = (event: StorageEvent) => {
      if (event.key === 'running_id') {
        fetchState({ slug: app_state.slug })
      }
    }

     if(!authenticationStatus){
      fetchState({ slug: app_state.slug, target: 'Sign In' })
    }
     else{
       // check local storage for workflow running id
    const running_id = !!localStorage.getItem('running_id') || false
    const path =  pathname.includes(app_state.slug) ? pathname.replace(app_state.slug, ':tenant') : pathname

    // if running id is not found in local storage, fetch workflow
    if (!running_id && app_state.slug) {
      fetchState({ slug: app_state.slug })
    }

    // if running id is found in local storage, fetch workflow
    if (running_id && app_state.slug && !defaultRoutes.includes(path)) {
      if(path === '/drift/:tenant/' || path === '/drift/:tenant'){
        if(authenticationStatus){
          fetchState({ slug: app_state.slug, target: 'Inventory Page' })
        }
    }
        const workflow = JSON.parse(localStorage.getItem('workflow'))
        if(path == Navigation.INVENTORY_PAGE && workflow.next_state !== 'Inventory Page' ){
            fetchState({ slug: app_state.slug, target: 'Inventory Page' })
        }
      else{
        fetch_workflow_status({ running_id: parseInt(localStorage.getItem('running_id')) }, {
          onSuccess: (data) => {
            localStorage.setItem('workflow', JSON.stringify(data))
            navigateTo(data)
          }
        });
      }
    }
     }


    // add event listener for local storage
    window.addEventListener('storage', handleLocalStorageEvent);

    // remove event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleLocalStorageEvent);
    }

  }, [app_state.slug, authenticationStatus]);

  const navigateTo = (data: Workflow, payload?: any) => {
    switch (data.current_state) {
      case 'Sign In':
        navigateNext(Navigation.AUTHENTICATION.SIGN_IN_PAGE)
        break
      case 'Inventory Page':
        navigateNext(Navigation.INVENTORY_PAGE, payload)
        break
      case 'Profile':
        navigateNext(Navigation.PROFILE.USER_PROFILE, payload)
        break
      case 'Pricing':
        navigateNext(Navigation.ORDER_PAGE, payload)
        break
      case 'Order Submitted':
        navigateNext(Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE, payload)
        break
      case 'Contracting':
        navigateNext(Navigation.ORDER_ID_PAGE, payload)
        break
    }

  }
  const fetchState = (state: any, payload=null) => {
    fetch_workflow(state, {
      onSuccess: (data) => {
        localStorage.setItem('running_id', data.running_id.toString())
        localStorage.setItem('workflow', JSON.stringify(data))
        navigateTo(data, payload)
      },
    })
  }
  const fetchStateWithRunningId = (running_id: any, payload=null) => {
    fetch_workflow_status({ running_id: running_id }, {
      onSuccess: (data) => {
        localStorage.setItem('running_id', data.running_id.toString())
        localStorage.setItem('workflow', JSON.stringify(data))
        navigateTo(data, payload)
      }
    });
  }

  const getOrderWorkflow = (vin=null, reference_number=null, running_id=null) => {
    if(running_id){
      fetchStateWithRunningId(running_id,{vin:vin, orderId:reference_number} )
    }
    else if(vin){
      fetchState({ slug: app_state.slug, target: 'Pricing' }, {vin: vin})
    }
    else{
            fetchState({ slug: app_state.slug, target: 'Inventory Page' })
    }

  }
  function replaceTenantWithSlug(path:string) {
    return path.includes(':tenant')? path.replace(':tenant', app_state.slug) : path;
  }

  const navigateNext = (path?: string, payload?: any) => {
    if (!path) {

      const state_based_on_url: string = GET_WORKFLOW_STATE_BASED_ON_URL(pathname.replace(app_state.slug, ':tenant'))
      const work_flow: Workflow = JSON.parse(localStorage.getItem('workflow'))

      if (state_based_on_url === work_flow?.current_state) {

        move_next({ running_id: parseInt(localStorage.getItem('running_id')) }, {
          onSuccess: (data) => {
            localStorage.setItem('workflow', JSON.stringify(data))
            navigateTo(data, payload)
          }
        });

      } else {
          fetchState({ slug: app_state.slug, target: work_flow?.current_state })
      }

    } else {

      const redirectPath = routeToDefaultURL(path, payload)

      if (redirectPath)
        return navigate(redirectPath)


      const state_based_on_url: string = GET_WORKFLOW_STATE_BASED_ON_URL(path)
      const work_flow: Workflow = JSON.parse(localStorage.getItem('workflow'))

      if (state_based_on_url === work_flow?.current_state || state_based_on_url === work_flow?.next_state) {

        if (state_based_on_url === work_flow.next_state && (path === Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE || path === Navigation.ORDER_ID_PAGE)) {
          move_next({ running_id: parseInt(localStorage.getItem('running_id')) }, {
            onSuccess: (data) => {
              localStorage.setItem('workflow', JSON.stringify(data))
              navigateTo(data, payload)
            }
          });
        }

        switch (path) {
          case Navigation.AUTHENTICATION.FORGOT_PASSWORD:
            path = `${Navigation.AUTHENTICATION.FORGOT_PASSWORD}?email=${payload.email}`
            break
          case Navigation.ORDER_PAGE:
            path = payload?.orderId ? Navigation.ORDER_ID_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId) : Navigation.ORDER_PAGE.replace(':vin', payload.vin)
            break
          case Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE:
            path = Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId)
            break
          case Navigation.ORDER_ID_PAGE:
            path = Navigation.ORDER_ID_PAGE.replace(':vin', payload.vin).replace(':orderId', payload.orderId)
            break
        }

        if (pathname.includes(RESET_PASSWORD_ROUTE)) {
          path = pathname + search
        }

        const PATH_WITH_TENANT = replaceTenantWithSlug(path);
        return navigate(PATH_WITH_TENANT)

      } else {
        fetchState({ slug: app_state.slug, target: state_based_on_url })
      }
    }

  }
  const routeToDefaultURL = (path: string, payload=null) => {
    if (path.replace(app_state.slug, ':tenant').includes(Navigation.AUTHENTICATION.RESEND_PASSWORD_LINK))
      return path

    switch (path) {
      case Navigation.AUTHENTICATION.FORGOT_PASSWORD:
        path = `${Navigation.AUTHENTICATION.FORGOT_PASSWORD}?email=${payload.email}`;
        return replaceTenantWithSlug(path);


      case Navigation.PROFILE.USER_PROFILE:
      case Navigation.PROFILE.APPOINTMENTS:
      case Navigation.PROFILE.FAVORITES:
      case Navigation.PROFILE.ORDERS:
      case Navigation.PROFILE.PREFRENCES:
        return  replaceTenantWithSlug(path);

      default:
        return
    }
  }

  return (
    <WorkflowContext.Provider value={{ navigateNext, getOrderWorkflow }}>
      {children}
    </WorkflowContext.Provider>
  );
};

// ================================== Workflow Context ================================= //

export { WorkflowProvider, useWorkflowContext };

const GET_WORKFLOW_STATE_BASED_ON_URL = (URL: string) => {
  switch (URL) {
    case Navigation.AUTHENTICATION.EMAIL_VERIFICATION:
    case Navigation.AUTHENTICATION.OTP_PAGE:
    case Navigation.AUTHENTICATION.RESET_PASSWORD:
    case Navigation.AUTHENTICATION.USER_PROFILE:
    case Navigation.AUTHENTICATION.RESET_PASSWORD_LINK_EXPIRED:
    case Navigation.AUTHENTICATION.SIGNUP_CONSENT:
    case Navigation.AUTHENTICATION.SIGN_IN_PAGE:
    case Navigation.AUTHENTICATION.LOG_IN_PAGE:
      return 'Sign In'
    case Navigation.INVENTORY_PAGE:
      return 'Inventory Page'
    case Navigation.ORDER_PAGE:
      return 'Pricing'
    case Navigation.ORDER_ID_PAGE:
      return 'Contracting'
    case Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE:
      return 'Order Submitted'
  }
}
