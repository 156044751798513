import { useTheme } from '@mui/material'
import { Stack, SelectionButton } from '../../../components'
import { Skeleton, Typography } from '@ntpkunity/controls'
import { FC, useEffect, useContext, useState } from 'react'
import { IContractCreditRating } from "../../../interfaces"
import { useOrderContext, usePaymentContext } from 'pages'
import { AppContext } from '@app/context-provider'
import { updateCreditRating } from 'pages/(order-management)/order/order-context-provider'

export const CreditScore: FC<{ creditScores: IContractCreditRating[], setupDataLoading: boolean }> = ({ creditScores, setupDataLoading }) => {
  const theme = useTheme()
  const { dispatch } = usePaymentContext()
  const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
  const [selectedRating, setSelectedRating] = useState(orderState.credit_rating || creditScores?.[0]?.description)
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  useEffect(() => {
    setSelectedRating(orderState.credit_rating  || creditScores?.[0]?.description)
  }, [orderState.credit_rating, creditScores?.[0]?.description])

  return (
    <Stack marginTopXs={3} className='scroll-horizontal'>
      <Typography theme={theme} component={'p'} variant='subtitle1' marginBottom={'12px'}>{PLACEHOLDERS.PAYMENT_CREDIT_SCORES_TEXT}</Typography>
      {setupDataLoading ? <Skeleton theme={theme} width={'100%'} height={56} /> :
        <SelectionButton
          buttonItems={creditScores?.map((creditScore: IContractCreditRating) => (
            {
              title: `${creditScore?.description}`,
              subtitle: `${creditScore?.score_from}-${creditScore?.score_to}`,
              bgLight: true,
              isSelected: creditScore?.description === (selectedRating),
              onClick: () => {
                setSelectedRating(creditScore?.description)
                orderDispatch(updateCreditRating(creditScore?.description))
              }
            }
          ))}
        />
      }
    </Stack>
  )
}
