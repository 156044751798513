import {Navigation, QueryKeys} from "@constants"
import { useAddCustomerConsent, useGetDisclaimerByName } from "@hooks"
import { useTheme } from "@mui/material"
import { Box, Button, Dialog } from "@ntpkunity/controls"
import { useContext, useEffect, useState } from "react"
import { useQueryClient } from "react-query"
import { SnackbarUtility } from "@utilities"
import { DisclaimerPopup, PopupContent } from './credit-request-disclaimer-popup.style'
import { Popup } from "@components"
import { AppContext } from "@app/context-provider"
import { useWorkflowContext } from "@app/router-workflow"
import { useOrderContext } from "@pages"

declare interface ICreditRequestDisclaimerPopup {
  openPopup: boolean
  setOpenPopup: any
  snackbardispatch: any
  vin: string
}

export const CreditRequestDisclaimerPopup = ({
  openPopup,
  setOpenPopup,
  snackbardispatch,
  vin
} : ICreditRequestDisclaimerPopup) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;
  const queryClient = useQueryClient();

  const { navigateNext } = useWorkflowContext()

  const { mutate: addCustomerConsent, isLoading: addConsentLoading } = useAddCustomerConsent()

  const { data: disclaimer, isLoading: disclaimerLoading } = useGetDisclaimerByName("credit-application-disclaimer")
  const [disclaimerDescription, setDisclaimerDescription] = useState('')
  const { state: { order } } = useOrderContext()

  const [showAgreementBtn, setShowAgreementBtn] = useState(false)
  const[isDisclaimerReady,setisDisclaimerReady]=useState(false)

  const tenant_configuration: any = queryClient.getQueryData(
      QueryKeys.TENANT_CONFIGURATION
    );

    const customer: any = queryClient.getQueryData(
      QueryKeys.GET_CUSTOMER_BY_EMAIL
    );

    useEffect(() => {
        const data = tenant_configuration?.data;
        const disclaimerText = disclaimer?.[0]?.description;
        if(data && disclaimerText) {
            const address = data?.addresses?.find(address => address?.address_type === "Mailing")
            setDisclaimerDescription(
                disclaimerText
                    .replace(/@lender_name/g, data?.name)
                    .replace(/@lender_address/g, `${address?.address_line_1}, ${address?.city}, ${address?.state_name}, ${address?.zip_code}, ${address?.county}`)
                    .replace(/@lender_physical_location/g, data?.name))
                    setisDisclaimerReady(true)
        }

    }, [disclaimer, tenant_configuration])

    const handleConsentAgreementClick = () => {

      addCustomerConsent({
        order_reference_id: order?.reference_number,
        customer_reference_id: customer?.reference_id,
        disclaimer_name: disclaimer?.[0]?.name,
        disclaimer_version: disclaimer?.[0]?.version
      }, {
          onSuccess() {
            navigateNext(Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE, {
              vin,
              orderId: order?.reference_number
            })
          },
          onError(error) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(error?.detail?.message))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
          }
        })

    }

    const handleScroll = (e: any): any => {
      const bottom = (e.target.scrollHeight - Math.ceil(e.target.scrollTop) - 1) <= e.target.clientHeight
      setShowAgreementBtn(bottom);
    }

  return (
    <Popup>
      <DisclaimerPopup theme={theme} className="disclaimer-popup">
        <Dialog
            theme={theme}
            size="lg"
            open={isDisclaimerReady && openPopup}
            title={PLACEHOLDERS.INFORMATION_AND_NOTICES}
            onCloseDialog={() => setOpenPopup(false)}
            disablePortal
            noFooter
        >
          <PopupContent theme={theme} className="popup-content">
            <Box className="viewport" theme={theme} onScroll={handleScroll} onTouchMove={handleScroll} width="100%" height="100%">
              <Box theme={theme} dangerouslySetInnerHTML={{ __html: disclaimerDescription }} width="100%" height="100%"></Box>
                <Box theme={theme} className="sticky-btn">
                  <Button theme={theme} disabled={addConsentLoading} primary onClick={handleConsentAgreementClick} text={PLACEHOLDERS.I_AGREE_AND_CONTINUE} />
                </Box>
              </Box>
          </PopupContent>
        </Dialog>
      </DisclaimerPopup>
    </Popup>
  )
}