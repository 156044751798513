import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PricingQuestionnairePopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.pricing-questionnaire-popup-wrap': {
    width: '480px',
    [theme.breakpoints.down('md')]:{
        width: 'auto',
    },
    '&.has-header-footer': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 'calc(100vh - 240px)',
        maxHeight: 512,
        '.stack-wrap': {
            '&:last-child': {
                flexGrow: 1
            }
        }
    },
    '.menu-with-title': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.menu': {
            display: 'flex',
            alignItems: 'center',
            gap: 4,
            '.select-title': {
                marginTop: -4
            },
            '.u-form-group': {
                textAlign: 'right',
                '.u-form-control': {
                    height: '24px',
                    '&.u-select': {
                        '.MuiInputBase-input': {
                            padding: '0px 9px 0px 0px',
                            fontSize: theme.typography.fontSize,
                            fontWeight: theme.typography.fontWeightMedium,
                            color: theme.palette.grey[900]
                        },
                        '.MuiSelect-icon': {
                            right: 0,
                            top: 0
                        },
                        '.adornment-icon': {
                            fontSize: theme.typography.body2.fontSize,
                            lineHeight: theme.typography.body2.lineHeight,
                            fontWeight: theme.typography.fontWeightMedium,
                            userSelect: 'none',
                            pointerEvents: 'none',
                            color: theme.palette.grey[900],
                            '&.end-icon': {
                                right: 13,
                                position:'relative'
                            }
                        }
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                },
                '.MuiPopover-root': {
                    '.MuiPaper-root': {
                        top: '42px !important',
                        textAlign: 'left'
                    }
                }
            }
        }
    },
  }
}))
