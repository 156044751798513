import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const UserProfileWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.user-profile': {
    '.change-password': {
      '.control-wrap': {
        '.MuiTypography-root': {
          fontSize: 18
        }
      }
    }
  },
  '&.profile-address': {
    '.MuiGrid-container': {
        '.MuiGrid-item': {
          [theme.breakpoints.down('md')]: {
            '&:nth-child(4), &:nth-child(5), &:nth-child(6), &:nth-child(7)': {
              maxWidth: '50%',
              flexBasis: '50%'
            }
          }
        }
      }
  }
}))
