import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const HeaderSearchWrap = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(({ theme }) => ({
    '&.header-search-wrap': {
      boxShadow: '0px 32px 64px rgba(0, 0, 0, 0.16)',
      position: 'fixed',
      width: '100%',
      backgroundColor: theme.palette.common.white,
      transition: 'all 0.3s ease-in-out 0s',
      borderRadius: '0px 0px 16px 16px',
      top: '-100%',
      zIndex: 1024,
      left: 0,
      paddingBottom: 16,
      paddingLeft: 40,
      paddingRight: 40,
      '&.search-open': {
        top: 0
      },
      '.u-toolbar': {
        padding: '20px 0',
        [theme.breakpoints.down('md')]: {
          padding: 0
        }
      },
      '.header-nav-wrap': {
        flexGrow: 1.1,
        '.navbar-nav': {
          '&.nav-center': {
            [theme.breakpoints.down('md')]: {
              flexGrow: 1,
              scrollbarWidth: 'none',
              justifyContent: 'center',
              '&::-webkit-scrollbar': {
                width: 0,
                height: 0
              }
            }
          },
          '&.nav-right': {
            '.nav-item': {
              '&.icon-item': {
                '.nav-link': {
                  [theme.breakpoints.down('md')]: {
                    height: 'auto'
                  }
                }
              }
            },
            [theme.breakpoints.down('md')]: {
              display: 'block'
            }
          },
          [theme.breakpoints.down('md')]: {
            flexDirection: 'row',
            display: 'flex',
            padding: 0,
            margin: 0,
            '.nav-item': {
              marginRight: 40,
              marginBottom: 0,
              flexShrink: 0,
              '.nav-link': {
                padding: 0,
                '&.active': {
                  backgroundColor: 'transparent'
                },
                '.active-indicator': {
                  top: 27,
                  width: '100%',
                  left: 'unset',
                  padding: '0 8px',
                  '&::after': {
                    minWidth: 24,
                    maxWidth: 48,
                    height: 4,
                    margin: 'auto'
                  }
                }
              },
              '&:last-child': {
                marginRight: 0
              }
            }
          }
        },
        [theme.breakpoints.down('md')]: {
          display: 'flex',
          alignItems: 'center',
          padding: 0,
          position: 'relative',
          height: 'auto !important',
          transform: 'none',
          boxShadow: 'none'
        }
      },
      '.search-area': {
        maxWidth: 628,
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
          maxWidth: '100%'
        }
      },
      [theme.breakpoints.down('md')]: {
        paddingBottom: 0,
        paddingLeft: 24,
        paddingRight: 24
      }
    }
  }))
