import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PopupContent = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.popup-content': {
    
   fontFamily: theme.typography.fontFamily,
    p: {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.grey[900],
        marginTop: 24,
        marginBottom: 0,
        '&:first-child': {
          marginTop: 0
        },
        b: {
          fontSize: theme.typography.h4.fontSize,
          lineHeight: theme.typography.h4.lineHeight,
          fontWeight: theme.typography.fontWeightBold,
        }
    },
    h4: {
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.h4.fontSize,
      lineHeight: theme.typography.h4.lineHeight,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.grey[900],
      marginTop: 24,
      '&:first-child': {
        marginTop: 0
      }
    },
    'ul': {
      paddingLeft: 15,
      marginTop: 24,
      marginBottom: 8,
      'li': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: theme.typography.fontWeightRegular,
        color: theme.palette.grey[900]
      }
    }
  }
}))

export const DisclaimerPopup = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.disclaimer-popup': {
    '.MuiDialog-root': {
      '&.u-dialog:not(.confirmation-dialog)': {
        '.MuiDialog-paper': {
          '.u-dialog-content': {
            padding: 0,
            overflow: 'hidden',
            '.viewport': {
              overflowY: 'auto',
              maxHeight: 'calc(100vh - 150px)',
              padding: '0 60px 0 60px',
              '@supports (-moz-appearance:none)': {
                scrollbarWidth: 'thin'
              },
              '&::-webkit-scrollbar': {
                  width: 7,
                  height: 7,
                  borderRadius: 8
                },
                '&::-webkit-scrollbar-track': {
                  padding: '0 1px',
                  backgroundColor: '#f0f0f0',
                  borderRadius: 8
                },
                '&::-webkit-scrollbar-thumb': {
                  transition: 'all 0.3s',
                  backgroundColor: '#cdcdcd',
                  borderRadius: 8
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  backgroundColor: '#a6a6a6'
                },
                [theme.breakpoints.down('sm')]: {
                  padding: '0 24px 0 24px'
                },
                '.sticky-btn': {
                  paddingBottom: 60,
                  paddingTop: 24,
                  textAlign: 'center',
                  [theme.breakpoints.down('md')]: {
                    paddingBottom: 24,
                    marginBottom: 48,
                    paddingTop: 24,
                    '.MuiButtonBase-root': {
                      width: '100%',
                    }
                  }
                }
            }
          },
          
        }
      }
    }
    
  }
}))

