import { useTheme } from '@mui/material'
import { Typography } from '@ntpkunity/controls'
import { PageHeaderProps } from './page-header.props'
import { PageHeaderWrap } from './page-header.style'


export const PageHeader = ({ title, subTitle, marginBottom, titleVariant = 'h2', titleComponent = 'h2' } : PageHeaderProps) => {
    const theme = useTheme()
  return (
    <PageHeaderWrap className='page-header-wrap' theme={theme} mb={marginBottom}>
        {title &&
         <Typography className='page-title text-h2' theme={theme} variant={titleVariant as any} component={titleComponent as any}>{title}</Typography>
        }
        {subTitle &&
            <Typography className='sub-title text-muted text-body2' theme={theme} variant={'body2'} component={'span'} display={'block'} mt={1}>{subTitle}</Typography>
        }
    </PageHeaderWrap>
  )
}
