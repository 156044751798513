import {useMutation, useQuery} from "react-query";
import {QueryKeys} from "@constants";
import {StripePayment} from "@apis";



export const useStripePaymentDetails = (ref: any) => {
    return useQuery(QueryKeys.STRIPE_ORDER_DETAILS , () => {
        return StripePayment.getOrderDetails(ref)
    })
}

export const useStripeCreateToken = () => {
    return useMutation(
        async (cardData: any) => {
            return StripePayment.createStripeToken(cardData)
        }
    );
}


export const useStripePaymentIntent = (): any => {
    return useMutation<any>(
        (body: any) => {
            return StripePayment.makePayment(body)
        }
    );
};

export const useGetStripePaymentDetails = (ref: any) => {
    return useQuery([QueryKeys.STRIPE_PAYMENT_DETAILS, ref] , () => {
        return StripePayment.getPayment(ref)
    })
}

export const useGetPlaidToken = (): any => {
    return useMutation<any>(
        (body) => {
            return StripePayment.getPlaidToken(body)
        }
    );
}

export const useGetPaymentAllowedLimitDetails = (lender_id: any) => {
    return useQuery(QueryKeys.GET_PAYMENT_ALLOWED_LIMIT , () => {
        return StripePayment.getPaymentAllowedLimit(lender_id)
    })
}