import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SearchTagWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.search-tag-wrap': {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    gap: 16,
    '.tag-list': {
      display: 'flex',
      alignItems: 'center',
      alignSelf: 'stretch',
      width: '100%',
      gap: 8,
      overflow: 'auto',
      scrollbarWidth: 'none',
      '&::-webkit-scrollbar': {
        width: 0,
        height: 0
      },
      '.tag': {
        backgroundColor: '#F5F5F7',
        borderRadius: 8,
        display: 'flex',
        padding: '8px 16px',
        justifyContent: 'center',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 8,
        svg: {
          cursor: 'pointer',
          width: 18,
          height: 18,
          path: {
              stroke: theme.palette.grey[300]
          }
        }
      }
    },
    '.clear': {
      display: 'block',
      '.link': {
        cursor: 'pointer',
        whiteSpace: 'nowrap'
      },
      [theme.breakpoints.down('sm')]:{
        display: 'none'
      }
    }
  }
}))
