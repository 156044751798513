import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'
import arrowImg from 'public/assets/icons/chevron-up.svg'

export const CjAccordianWrap = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(({ theme }) => ({
    '&.cj-accordian-wrap': {
      '.u-accordian-wrap': {
        backgroundColor: '#F5F5F7',
        borderRadius: 16,
        padding: 24,
        '.u-accordion-header': {
          padding: 0,
          minHeight: 'auto',
          '.MuiAccordionSummary-content': {
            alignItems: 'center',
            margin: 0,
            span: {
              fontWeight: theme.typography.fontWeightMedium,
            },
            '.main-title': {
              display: 'flex',
              alignItems: 'center',
              gap: 4,
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              '&:after': {
                content: '" "',
                backgroundImage: `url(${arrowImg})`,
                display: 'inline-block',
                width: 24,
                height: 24,
                rotate: '180deg'
              }
            },
            '&.Mui-expanded': {
              '.main-title': {
                
                '&:after': {
                  rotate: '0deg'
                }
              },
            }
          },
          '.MuiAccordionSummary-expandIconWrapper': {
            display: 'none',
          }
        },
        '.MuiAccordionDetails-root': {
          border: 'none',
          padding: 0,
          '.summary-list': {
            '.MuiTypography-root': {
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden'
            }
          }
        },
        '&:last-of-type': {
          marginBottom: 0,
        },
        '&.Mui-expanded': {
          '&:last-of-type': {
            marginTop: 0
          }
        }
      },
      '&.d-none': {
        display: 'none',
      },
      '&.child': {
        '.u-accordian-wrap': {
          padding: '16px 0 0 16px',
        '.u-accordion-header': {
          '.MuiAccordionSummary-content': {
            span: {
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.grey[600]
            }
        }
      },
      }
    }
  }
    // display: 'flex',
    // alignItems: 'center',
    // flexDirection: 'column',
    // padding: 24,
    // gap: 16,
    // alignSelf: 'stretch'
  }))
