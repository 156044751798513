import { Tabs } from "@constants";
import { useAppContext } from "@app/context-provider";
import { RegisterOptions } from "react-hook-form";

export const validation = (
  name: string,
  required: boolean,
  matchPattern: boolean = false,
  passwordRegex: RegExp = new RegExp(""),
  regexMessage: string = ""
): RegisterOptions => {
  const { state: appState } = useAppContext();
  const PLACEHOLDERS = appState.language.placeholders;

  return {
    required: {
      value: required,
      message: `${name} ${PLACEHOLDERS.IS_REQUIRED_TEXT}`,
    },
    validate: {
      matchesPattern: (value: string | undefined) => {
        if (matchPattern && value && !passwordRegex.exec(value)) {
          return `${regexMessage}`;
        }
        return true;
      },
    },
  };
};

export const getGoogleKey = (integrationsData: any) => {
  let googleKey = null;
  const integarationObj = integrationsData?.find(
    (x: any) =>
      x?.integration_type === "Address Validation" &&
      x?.provider_name === "Google" &&
      x?.is_active
  );
  if (
    integarationObj &&
    integarationObj?.lender_integration_user_inputs?.length > 0
  ) {
    googleKey =
      integarationObj?.lender_integration_user_inputs[0]?.user_input_value;
  }
  return googleKey;
};

export const getNextTabTitle = (orderTabs, activeTab, PLACEHOLDERS) => {
  const currentTabIndex = orderTabs?.findIndex((item) => item == activeTab);
  const nextTab =
    orderTabs?.length > currentTabIndex + 1
      ? orderTabs[currentTabIndex + 1]
      : "";
  switch (nextTab) {
    case Tabs.PAYMENTS:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE;
    case Tabs.FNI:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE;
    case Tabs.ADD_ONS:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_ADD_ONS_TITLE;
    case Tabs.TRADE_IN:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_TRADE_IN_TITLE;
    case Tabs.INSURANCE:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE;
    case Tabs.SCHEDULE:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE;
    case Tabs.FINANCING:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FINANCING_TITLE;
    case Tabs.REVIEW_ORDER:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_REVIEW_ORDER_TITLE;
    case Tabs.CONTRACTING:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_CONTRACTING_TITLE;
    case Tabs.STRIPE_PAYMENTS:
      return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE;
    default:
      return "";
  }
};

export const isEmpty = (value: any) =>
  value === undefined ||
  value === null ||
  (typeof value === "string" && !value.trim().length) ||
  (typeof value === "object" && !Object.keys(value).length);

export const getDealerCode = () => {
  let dealerCode = null;
  const dealer_information = localStorage.getItem("dealer_information");
  if (dealer_information) {
    const dealerData = JSON.parse(dealer_information);
    dealerCode = dealerData?.dealer?.dealer_code;
  }
  return dealerCode;
};

const isValidUrl = (url: any) => {
  return /^(https?:\/\/)?([\da-z\.-]+)\.([a-z\.]{2,6})([\/\w \.-]*)*\/?(\?.*)?$/.test(
    url
  );
};

export const licenseImage = (image: any) => {
  let image_url;
  const validUrl = isValidUrl(image);
  if (!validUrl) {
    image_url = URL.createObjectURL(image as unknown as File);
  }
  const url = validUrl ? image : image_url ?? "";
  return url;
};

export const formatUSPhoneNumberWithCountryCode = (phoneNumberString) => {
  const cleaned = ("" + phoneNumberString).replace(/\D/g, "");
  if (!phoneNumberString) {
    return "";
  }
  if (cleaned.startsWith("1") && cleaned.length === 11) {
    const match = cleaned.match(/^(1)(.{3})(.{3})(.{4})$/);
    if (match) {
      return (
        "+" + match[1] + " (" + match[2] + ") " + match[3] + "-" + match[4]
      );
    }
  } else {
    const match = cleaned.match(/^(.{3})(.{3})(.{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
  }
  return phoneNumberString;
};
