import { http } from '../utilities/http-api';
import { Endpoint } from '../constants/endpoint';
export const UMS_BASE_URL = process.env.UMS_BASE_URL;
type Payload = string | any;
export type Event = {
    session: string;
    order_id?:string
    user_id?: number;
    slug?: string;
    tenant_id?: number;
    dealer_name?:string;
    dealer_id?: number;
    deal_type?:string;
    vin?: string;
    vehicle_make?:string;
    vehicle_model?:string;
    vehicle_status?:string;
    vehicle_year?:string;
    event_name?: string;
    page_type?:string
    data?: Payload
}

export namespace Events {
    export const createEvent = (slug:string,payload:Event): Promise<any> => {
        const body = {...payload};
        const fullBody = {
            url:Endpoint.EP_EVENT_ANALYTICS,
            payload:body,
            method_type:'post'
        }
        return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, fullBody)
           .then((response) => {
                return response.data;
            });
    }
};