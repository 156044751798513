import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CjInputWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-input-wrap': {
    '.u-form-group': {
      '.u-form-control': {
        '&.MuiInputBase-adornedStart': {
          '~': {
            '.u-input-label': {
              maxWidth: 'calc(133% - 95px)',
              '&.Mui-focused': {
                maxWidth: '100%'
              }
            }
          }
        }
      },
      '.MuiInputAdornment-root': {
        svg: {
          cursor: 'pointer'
        },
      }
    }
  }
}))
