import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CreateNewOrderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.create-new-order-wrap': {
    display: 'flex',
    width: '100%',
    height: '100%',
    padding: '20px 24px',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    gap: 16,
    borderRadius: 16,
    border: '1px dashed',
    borderColor: theme.palette.grey[100],
    svg: {
      width: '30.3px',
      height: '33.2px',
    }
  }
}))
