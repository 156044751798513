import React, { useContext, useEffect, useState } from 'react'
import { Slider, useTheme } from '@mui/material'
import { CjSliderWrap } from './slider.style'
import { AppContext } from '@app/context-provider'

export const SliderComponent = (
    { value, min, max, disabled, onChangeCommitted }: { value: number, min?: number, max?: number, disabled?: boolean, onChangeCommitted?: (value?: any) => any }) => {
    const theme = useTheme()
    const [internalValue, setInternalValue] = useState<number>(value);
    const appState = useContext(AppContext);

    useEffect(() => {
        setInternalValue(value);
    }, [value]);

    const handleChange = (event: Event, newValue: number | number[]) => {
        setInternalValue(newValue as number);
    };

    const handleChangeCommitted = (event: Event, newValue: number | number[]) => {
        onChangeCommitted(newValue as number);
    };

    const valueLabelFormat = (value: number) => {
        return value?.toLocaleString("en-US", {
            style: "currency",
            currency: appState.state?.default_currency_code || "USD"
        })
    }

    return (
        <CjSliderWrap className='cj-slider-wrap' theme={theme}>
            <Slider
                aria-label="Small"
                valueLabelDisplay="auto"
                max={max}
                min={min}
                disabled={disabled}
                value={internalValue}
                onChange={handleChange}
                valueLabelFormat={valueLabelFormat}
                onChangeCommitted={handleChangeCommitted}
            />
        </CjSliderWrap>
    )

}

