export const Endpoint = {
  GET_TENANT_DISCLAIMERS: "/dms/configure/disclaimer-by-name",
  CUSTOMER_MANDATORY_DISCLAIMERS: "/signup/customer-mandatory-disclaimers",
  GET_DISCLAIMER_BY_NAME: "/configure/disclaimer-by-name",
  DISCLAIMER_AGREEMENT: "/configure/disclaimer-agreement",
  CUSTOMER: "/customer/",
  USER_API_PUBLIC_DMS_URL: "/signup/dms",
  DMS_CREATE_CUSTOMER: "dms/customer/",
  DMS_CUSTOMER_DISCLAIMER_AGREEMENT: "dms/configure/disclaimer-agreement",
  DEALER: "/dealer",
  VEHICLE_BY_VIN: "/vehicle",
  VEHICLE_DETAIL_BY_VIN: "/vehicle-detail",
  GET_SETUP_DATA: "/get-setup-data",
  OPTION_TRIM_CODE: "/dealer/option-trim-code",
  DEALER_PREFERENCE_BY_DEALER_CODE: "/dealer/preference-by-dealer-code",
  EP_GET_STATES: "state",
  EP_GET_COUNTRIES: "countries",
  VEHICLE_BY_TRIM_CODE: "configure/assets-by-trim-code",
  GET_ALL_PROGRAMS: "programs/all/",
  CALCULATE_ORDER_QUOTATION: "quote/calculation/V2",
  CUSTOMER_EMAIL_CHECK: "check-customer-by-email",
  EP_GET_CUSTOMER: "customer",
  EP_UPDATE_CONTACT_DETAILS: "customer",
  EP_GET_CURRENCY: "currencies",
  EP_GET_COMPANY_INTEGRATIONS: "integration/company",
  INTEGRATION_BY_PROVIDER_NAME: "integration/by_provider_name",
  INTEGRATION_CONFIGURATION_BY_PROVIDER_NAME: "integration/configuration",
  FNI_PRODUCT: "/financial-insurances/filter",
  GET_RATES: "get-rates",
  VERIFY_ADDRESS: "api/verify-address",
  DEALER_PROFILE: "dealer/profile",
  EP_GET_ORDER_INFORMATION: "order/order_information",
  GET_CUSTOMER_BY_EMAIL: "/customer-by-email",
  SAVE_ORDER_QUOTATION: "/configure/save-quotation",
  GET_DEALER_FEE_BY_STATE: "/dealer/fee/dealer-code",
  CONVERT_QUOTATION_TO_APPLICATION: "/configure/quotation-to-order",
  CONFIGURE_QUOTATION: "configure/quotation",
  CUSTOMER_LICENSE_INFO: "customer/license-info",
  LICENSE_VERIFICATION: "customer/license-verification",
  CALCULATE_NFA: "quote/calculate-nfa",
  EP_SAVE_EMPLOYMENT_DETAILS: "customer/employment-info",
  EP_UPDATE_EMPLOYMENT_DETAILS: "customer/employment-info",
  EP_GET_EMPLOYMENT_INFO: "customer/employment-info",
  EP_GET_PERSONAL_FINANCE: "customer/personal-finance",
  MAKE_STRIPE_PAYMENT: "create-payment",
  GET_STRIPE_PAYMENT: "get-payment-methods",
  EP_PERSONAL_FINANCE: "customer/personal-finance",
  EP_SUBMIT_ORDER: "submit-order",
  EP_UPDATE_PERSONAL_FINANCE_DETAILS: "customer/personal-finance",
  EP_CREATE_CUSTOMER_DETAILS: "customer",
  EP_APPOINTMENT: "/configure/appointment",
  EP_UPDATE_ORDER_STATUS: "configure/order/update-status",
  GET_INVENTORY: "inventory/filter",
  GET_REFERENCE_BY_DEALER_CODE: "inventory/reference-data-by-dealer-code",
  UPDATE_ORDER_STATUS: "/configure/order/update-status",
  EP_GET_LENDER_CONFIGURATION_RADIUS: "/radius",
  GET_DEALER_DISTANCE_BY_CUSTOMER: "/dealer/all-dealer-distance",
  GET_CHECKLIST_QUESTIONS: "/config/get_event_associated_checklist/",
  CHECK_VEHICLE_ESTIMATION_PROVIDER: "/integration/by_provider_name/",
  GET_VEHICLE_DETAILS_BY_VIN: "/get-vehicle-detail-by-vin/",
  EP_GET_TRADE_IN_YEAR: "/get-trade-in-year/",
  EP_GET_TRADE_IN_MAKE: "/get-trade-in-make/",
  EP_GET_TRADE_IN_MODEL: "/get-trade-in-model/",
  EP_GET_TRADE_IN_TRIM: "/get-trade-in-trim/",
  EP_GET_TRADE_IN_VEHICLE_DETAIL: "/get-trade-in-vehicle_detail/",
  EP_GET_TRADE_IN_VEHICLE_VALUE: "/get-trade-in-vehicle_value/",
  GET_WORKQUEUE: "/configure/workqueue/search",
  EP_UPDATE_VEHICLE_DETAIL: "vehicle-detail",
  EP_GET_PLAID_TOKEN: "/plaid-link-token/",
  UMS_DMS_CALL: "/signup/dms",
  EP_EVENT_ANALYTICS: "dms/event_analytic",
  GET_PAYMENT_ALLOWED_LIMIT: "/config/dealer/payment-detail_by_dealer_code",
  EP_CREDIT_RESPONSE: "order/credit-decision",
  EP_UPLOAD_DOCUMENT: "customer/document",
  EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE: "customer/documents",
  EP_STIPULATION: "stipulation/stipulation-by-lender",
  GET_TRADE_IN_HISTORY: "get-trade_in_history",
  GET_ALL_DOCUMENT_PACKAGES: "/configure/all-document-packages-by-reference-id",
  GET_PRE_SIGNED_URL: "configure/document-pre-signed-url",
  CUSTOMER_SIGNATURE: "customer/signature",
  SIGN_DOCUMENT_BY_ROLE: "configure/by-role",
  GET_LENDER_BY_SLUG: "config/company/by-tenant-slug/",
  EP_LEAD: "lead",
  CHECK_DMS_CUSTOMER: "check-dms-customer",
  UPDATE_DMS_CUSTOMER: "update-dms-customer",
  DEFAULT_LENDER_CONFIGURATION: '/config/default/lender',
  ADD_WAY_INSURANCE: "insurance/create",
  ADD_TRANSACTION_ID: "insurance/add-insurance",
  ORDER_DOCUMENT: "order-document",
  ORDER_DOCUMENT_FNI: "/order-document-fni"
};
