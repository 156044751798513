import { useTheme } from '@mui/material'
import { Stack } from '../../components'
import { QuestionStackProps } from './question-stack.props'
import { QuestionStackWrap } from './question-stack.style'
import { Box, Button, Checkbox, Icon, Typography } from '@ntpkunity/controls'
import React from 'react'
import clsx from 'clsx'

export const QuestionStack = ({ title, description, paddingMd = 4, paddingXs = 3, marginTopXs, marginTopMd, actionArea, buttonText, dFlex, dColumn, onLinkClick, onCheckBoxClick }: QuestionStackProps) => {
    const theme = useTheme()
    return (
        <QuestionStackWrap className='question-stack-wrap' theme={theme} p={{ xs: paddingXs, md: paddingMd }} mt={{ xs: marginTopXs, md: marginTopMd }} onClick={onLinkClick}>
            <Box theme={theme} gap={'16px'} className={clsx({ 'stack-row': true, 'stack-column': description, 'd-flex': dFlex, 'd-column': dColumn })}>
                {title &&
                    <Typography theme={theme} className='title fw-sbold w-100' component={'p'} variant='subtitle1'>{title}</Typography>
                }
                {actionArea &&
                    <Box theme={theme} className='action-area'>
                        {onLinkClick &&
                            <Button theme={theme} defaultBtn text={buttonText} onClick={onLinkClick} iconText={<Icon name="IconRightArrow" />} />
                        }
                        {onCheckBoxClick &&
                            <Checkbox theme={theme} label=""  onChange={onCheckBoxClick}/>
                        }
                        {actionArea}
                    </Box>
                }
            </Box>
            {description && <Box theme={theme} >{description}</Box>
            }
        </QuestionStackWrap>
    )
}
