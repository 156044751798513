import { Link, useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { ProductDetailProps } from './product-detail-card.props'
import { ProductDetailWrap } from './product-detail-card.style'
import { useAppContext } from '@app/context-provider'

export const ProductDetail = ({ modal, stock, vin, onClick }: ProductDetailProps) => {
    const theme = useTheme()
    const {state: appState} = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders

    return(
        <ProductDetailWrap theme={theme} className='product-detail'>
            <Box theme={theme} className='cj-card' onClick={onClick}>
                <Box theme={theme} className='card-content'>
                    {modal &&
                        <Box theme={theme} className='title'>
                            <Typography theme={theme} variant='caption' component={'small'} display={'block'}><b>{PLACEHOLDERS.MODEL_NO_TEXT}</b></Typography>
                            <Typography theme={theme} variant='caption' component={'small'} display={'block'}>{modal}</Typography>
                        </Box>
                    }
                    {stock &&
                        <Box theme={theme} className='title'>
                            <Typography theme={theme} variant='caption' component={'small'} display={'block'}><b>{PLACEHOLDERS.STOCK_NO_TEXT}</b></Typography>
                            <Typography theme={theme} variant='caption' component={'small'} display={'block'}>{stock}</Typography>
                        </Box>
                    }
                    {vin &&
                        <Box theme={theme} className='title'>
                            <Typography theme={theme} variant='caption' component={'small'} display={'block'}><b>{PLACEHOLDERS.VIN_TEXT}</b></Typography>
                            <Typography theme={theme} variant='caption' component={'small'} display={'block'}>{vin}</Typography>
                        </Box>
                    }
                </Box>
                {onClick &&
                    <Box theme={theme} className='card-action'>
                        <Link variant='caption' component={'small'} display={'block'} className='link decoration-none fw-sbold' onClick={onClick}>{PLACEHOLDERS.WINDOW_STICKER_TEXT}</Link>
                    </Box>
                }
                
            </Box>
        </ProductDetailWrap>
        
    )
}