export const Tabs = {
  PAYMENTS: "Payment",
  INSURANCE: "Insurance",
  FNI: "F&I Products",
  ADD_ONS: "Add Ons",
  TRADE_IN: "Trade In",
  SCHEDULE: "Schedule",
  FINANCING: "Financing",
  REVIEW_ORDER: "Review Order",
  STRIPE_PAYMENTS: "Stripe Payment",
  CONTRACTING: "Contracting",
};

export const profileTabs = {
  PROFILE: "Profile",
  ORDERS: "Orders",
  FAVORITES: "Favorites",
  APPOINTMENTS: "Appointments",
  PREFERENCES: "Preferences"
}