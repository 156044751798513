import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const BrandCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.brand-card-wrap': {
    padding: 24,
    backgroundColor: theme.palette.common.white,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 16,
    textAlign: 'center',
    '.brand-icon': {
        maxHeight: 40,
        'img': {
            maxHeight: 40,
            maxWidth: '100%'
        }
    }
  }
}))
