import React, { ReactNode } from 'react'
import clsx from 'clsx'
import { CjTabWrap } from './tab.styles'
import { useTheme } from '@mui/material'

export const Tab = ({ children, hasBorder, bgLight, autoWidth }: { children: ReactNode, hasBorder?: boolean, bgLight?: boolean, autoWidth?: boolean }) => {
    const theme = useTheme()
  return (
    <CjTabWrap className={clsx({ 'cj-tab-wrap': true, 'has-border': hasBorder, 'bg-light': bgLight, 'auto-width': autoWidth })} theme={theme}>
        {children}
    </CjTabWrap>
  )
}
