import { useTheme } from '@mui/material'
import {Grid, Input, Skeleton, Typography,} from '@ntpkunity/controls'
import { Stack, Textbox, SelectionButton } from '@components'
import { MakeModelPopupWrap } from './make-modal-poup.style'
import React, {useEffect, useState} from 'react'
import { TradeInJourneySteps } from 'constants/enums'
import { useAppContext } from '@app/context-provider'


export const MakeModelPopupFour = (props) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders

    const { trims, messages, setValue, setNextPopup, trigger, clearErrors, getValues, setMakeModelYearTrim, tradeInType } = props
    const values = getValues()
    const {trim} = values
    const [filter, setFilter] = useState(trims || [])
    const [filterValue, setFilterValue] = useState('')
    const [selectedItem, setSelected] = useState(trim)
    const [vehicleDetail, setVehicleDetail] = useState(null)

    useEffect(() => {
        const item = filter.find(item => item?.trimName === selectedItem)

        if(item) {
            setMakeModelYearTrim(prevState => ({
                ...prevState,
                'trim_id': item?.trimId
            }))
            clearErrors()
            trigger()
            if(TradeInJourneySteps.VinOnePopup === tradeInType){
                setMakeModelYearTrim(prevstate => ({
                    ...prevstate,
                    'year_id': item?.yearId,
                    'make_id': item?.makeId,
                    'model_id': item?.modelId,
                    'vehicle_id': item?.vehicleId
                }))
            }
        }
    }, [selectedItem]);

    useEffect(() => {
        setFilter(trims)
        if(trims?.length > 0) {
            setVehicleDetail({yearId: trims[0].yearId, makeName: trims[0].makeName, modelName: trims[0].modelName})
        }
    }, [trims]);

    const handleFilter = (value) => {
        setFilter(trims?.filter(item => item.trimName.toLowerCase().includes(value.toLowerCase())))
        setFilterValue(value)
    }
    const handleSelection = (item) => {
        setSelected(item.trimName)
        setValue(messages.name.Trim, item.trimName)
        setValue(messages.name.Year, item?.yearId)
        setValue(messages.name.Make, item?.makeName)
        setValue(messages.name.Model, item?.modelName)

    }

    return (
        <MakeModelPopupWrap theme={theme} className='make-model-popup-two-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3} className='make-modal-popup'>
                
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2 }}
                >
                    <Grid item xs={12} theme={theme} className='sticky'>
                        {
                            vehicleDetail ?
                                <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>{PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_FOUR_THE_TEXT} {vehicleDetail?.yearId} {vehicleDetail?.makeName} {vehicleDetail?.modelName} {PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_FOUR_TRIM_PROMPT_LABEL}</Typography>
                                :
                                <Skeleton theme={theme} width={'100%'} height={40} />

                        }
                        <Textbox>
                            <Input value={filterValue} name='trimFilter' type={'text'} fullWidth theme={theme} placeholder={PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_FOUR_INPUT_PLACEHOLDER} onChange={(value) => handleFilter(value)}/>
                        </Textbox>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <SelectionButton
                            buttonItems={filter.map((item) => ({
                                title: item.trimName,
                                onClick: () => handleSelection(item),
                                isSelected: item?.trimName === selectedItem
                            }))}
                        />
                    </Grid>
                </Grid>
                <Typography theme={theme} className='fw-medium' component={'p'} variant='body2' mt={2}>
                    <a href='#' onClick={() => { setNextPopup(TradeInJourneySteps.TrimLevelPopup, false) }} className='text-primary decoration-none'>{PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_FOUR_TRIM_LEVEL_PROMPT}</a>
                </Typography>
            </Stack>
        </MakeModelPopupWrap>
    )
}