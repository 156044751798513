import { useTheme } from '@mui/material'
import { Button, Grid, Typography, } from '@ntpkunity/controls'
import { Stack } from '@components'
import { AddTradeInPopupWrap } from './add-tradeIn-popup.style'
import {TradeInJourneySteps} from "../../../constants/enums";
import { useAppContext } from '@app/context-provider';

export const AddTradeInPopup = (props) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders

    const {setNextPopup} = props;

    const handleNext = (value) => {
        setNextPopup(value, false)
    }

    return(
        <AddTradeInPopupWrap theme={theme} className='tradeIn-popup-wrap no-header-footer'>
            <Stack className='trade-in-popup text-center' paddingMd={0} paddingXs={0}>
                <Typography theme={theme} component={'h2'} variant='h2' marginBottom={'16px'}>{PLACEHOLDERS.TRADE_IN_ADD_POPUP_MESSAGE}</Typography>
                <Typography theme={theme} component={'small'} variant='body2' className='text-muted' display={'block'} marginBottom={'16px'}>
                    {PLACEHOLDERS.TRADE_IN_ADD_POPUP_PROMPT}
                </Typography>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={2}
                    columnSpacing={{ xs: 2 }}
                    sx={{ mb: 2 }}
                >
                    <Grid item xs={12} sm={6} theme={theme}>
                        <Button theme={theme} secondary text={PLACEHOLDERS.TRADE_IN_ADD_POPUP_LICENSE_PLATE_PROMPT} fullWidth disabled />
                    </Grid>
                    <Grid item xs={12} sm={6} theme={theme}>
                        <Button theme={theme} secondary text={PLACEHOLDERS.TRADE_IN_ADD_POPUP_MAKE_MODEL_PROMPT} fullWidth onClick={() => handleNext(TradeInJourneySteps.MakeModelPopupOne)}/>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                    <Typography theme={theme} component={'small'} variant='body2' className='text-muted' marginBottom={'16px'}>
                        {PLACEHOLDERS.TRADE_IN_ADD_POPUP_NO_LICENSE_PLATE_PROMPT}
                        <a href='#'  onClick={() => handleNext(TradeInJourneySteps.VinOnePopup)} className='text-primary decoration-none fw-medium'> {PLACEHOLDERS.TRADE_IN_ADD_POPUP_VIN_PROMPT} </a>
                        {PLACEHOLDERS.TRADE_IN_ADD_POPUP_OR_TEXT} 
                        <a href='#' onClick={() => handleNext(TradeInJourneySteps.ProvideManualEstimatePopup)} className='text-primary decoration-none fw-medium'> {PLACEHOLDERS.TRADE_IN_ADD_POPUP_ESTIMATE_CONFIRMATION}</a>{PLACEHOLDERS.DOT_TEXT}
                    </Typography>
                    </Grid>
                </Grid>
            </Stack>
        </AddTradeInPopupWrap>
    )
}


