import {AuthenticationManagement} from "../apis"
import { useMutation, useQuery } from "react-query"
import { useEffect, useState } from "react"
import { ConsentStatus, QueryKeys } from "@constants"
import { DisclaimerNames } from "constants/consent-status"
import { useParams } from "react-router-dom"

// AuthenticationManagement.getTenantConsent
export const useGetTenantConsent = () => {
    const { tenant } = useParams()

    return useQuery(QueryKeys.GET_TENEANT_CONSENT, () => {
        return AuthenticationManagement.getTenantConsent(tenant)
    })
}

export const useGetDisclaimerByName = (disclaimerName: string) => {
    return useQuery('disclaimer-by-name', () => {
        return AuthenticationManagement.getDisclaimerByName(disclaimerName)
    })
}

export const useAddCustomerConsent = (): any => {
    return useMutation<any>(
        (body: any) => {
            return AuthenticationManagement.addCustomerConsent(body)
        }
    );
};

export const useAddSignUpCustomerConsent = (slug: string): any => {
    return useMutation<any>(
        (body: any) => {
            return AuthenticationManagement.addSignUpCustomerConsent(slug, body)
        }
    );
};

export const useCreateCustomer = (slug: string): any => {
    return useMutation<any>(
        (body: any) => {
            return AuthenticationManagement.createCustomer(slug, body)
        }
    );
};

export const useGetCustomerConsent = (ref: any) => {
    return useQuery(QueryKeys.GET_CUSTOMER_CONSENTS, () =>{
            return AuthenticationManagement.getCustomerConsents(ref)
        }, { enabled: !!ref }
    )
}


export const useDisclaimers = (requiredDisclaimers) => {
    const { data: _disclaimers, isLoading: disclaimersLoading } = useGetTenantConsent();
    const [disclaimers, setDisclaimers] = useState<typeof DisclaimerNames>();

    useEffect(() => {
        if (Array.isArray(_disclaimers) && (_disclaimers ?? []).length > 0) {
            const filteredDisclaimers = _disclaimers
                ?.filter(disclaimer =>
                    disclaimer.status === ConsentStatus.PUBLISHED &&
                    requiredDisclaimers.includes(disclaimer.name)
                )
                ?.reduce((agg, disclaimer) => ({
                    ...agg,
                    [disclaimer.name]: disclaimer.description
                }), {});

            if (Object.keys(filteredDisclaimers).length > 0) {
                setDisclaimers(filteredDisclaimers);
            }
        }
    }, [_disclaimers]);

    return { disclaimers, disclaimersLoading };
}