import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ConditionPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.condition-question-popup-wrap': {
    '.condition-question-stack': {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      '.popup-row': {
        display: 'flex',
        justifyContent: 'space-between',
        gap: 24,
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column',
          gap: 8
        },
        '.radio-buttons': {
          '.custom-radio-wrap': {
            '.MuiFormGroup-root': {
              flexDirection: 'row',
              flexWrap: 'nowrap',
              gap: 24,
              '.u-custom-control-label': {
                marginBottom: 0,
              }
            }
          }
        },
        '&.rating': {
          flexDirection: 'column',
          gap: 8,
          '.radio-buttons': {
            '.custom-radio-wrap': {
              '.MuiFormGroup-root': {
                gap: 16,
                [theme.breakpoints.down('sm')]: {
                  flexWrap: 'wrap',
                  gap: 8
                }
              }
            }
          },
        }
      }
    }
  }
}))
