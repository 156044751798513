// CREATE CONTEXT PROVIDER FOR APPLICAITON STATE

import { defaultLanguages } from '@constants';
import React, { createContext, useReducer, ReactNode, useContext } from 'react';

// Application state

export interface IApplicationState {
  theme: any;
  language: any;
  tenant: number;
  lender_id: number;
  slug: string;
  default_currency: number;
  default_currency_symbol: string;
  default_currency_code: string;
  isUserLoggedIn: boolean;
  default_radius: number;
  mileage_unit: string;
  social_media_icons: any;
  collapse_state: boolean;
  dealer_code: string;
  tenant_name: string;
}

// Initial state

export const initialState: IApplicationState = {
  theme: {},
  language: {},
  tenant: 0,
  lender_id: 0,
  slug: '',
  default_currency: 0,
  default_currency_symbol: '',
  default_currency_code: '',
  isUserLoggedIn: !!localStorage.getItem('access_token'),
  default_radius: 0,
  mileage_unit: '',
  social_media_icons: {},
  collapse_state: false,
  dealer_code: '',
  tenant_name:''
}

// Action types
export enum ActionType {
  LOAD_TENANT_CONFIGURATIONS = 'LOAD_TENANT_CONFIGURATIONS',
  LOAD_CURRENCT_CONFIGURATIONS = 'LOAD_CURRENCT_CONFIGURATIONS',
  CHANGE_USER_LOGGED_IN_STATE = 'CHANGE_USER_LOGGED_IN_STATE',
  UPDATE_SOCIAL_MEDIA_ICONS = 'UPDATE_SOCIAL_MEDIA_ICONS',
  UPDATE_COLLAPSE_STATE = 'UPDATE_COLLAPSE_STATE',
  UPDATE_DEALER_CODE = 'UPDATE_DEALER_CODE',
  LOAD_DEFAULT_LENDER_CONFIGURATIONS = 'LOAD_DEFAULT_LENDER_CONFIGURATIONS'
}

// Action methods
export const loadTenantConfigurations = (configurations: object) => ({
  type: ActionType.LOAD_TENANT_CONFIGURATIONS,
  payload: configurations
})

export const loadDefaultLenderConfigurations = (configurations: object) => ({
  type: ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS,
  payload: configurations
})

export const loadDefaultCurrencyConfigurations = (curreny: object) => ({
  type: ActionType.LOAD_CURRENCT_CONFIGURATIONS,
  payload: curreny
})

export const changeUserLoggedInState = (isLoggedIn: boolean) => ({
  type: ActionType.CHANGE_USER_LOGGED_IN_STATE,
  payload: isLoggedIn
})

export const updateSocialMediaIcons = (icons: any) => ({
  type: ActionType.UPDATE_SOCIAL_MEDIA_ICONS,
  payload: icons
})

export const updateCollapseState = () => ({
  type: ActionType.UPDATE_COLLAPSE_STATE,
})

export const updateDealerCode = (dealerCode) => ({
  type: ActionType.UPDATE_DEALER_CODE,
  payload: dealerCode
})

// Reducer
export const applicationStateReducer = (state: IApplicationState = initialState, action: { type: ActionType; payload: any }) => {
  switch (action.type) {
    case ActionType.LOAD_TENANT_CONFIGURATIONS:
      // Load tenant configurations
      const head = document.head
      const link = document.createElement('link')
      link.type = 'text/css'
      link.rel = 'stylesheet'
      link.href = action?.payload?.theme?.stylesheet
      head.appendChild(link)
      return {
        ...state,
        theme: action?.payload?.theme,
        language: defaultLanguages,
        tenant: action?.payload?.id,
        lender_id: action?.payload?.lender_id,
        slug: action?.payload?.slug,
        default_currency: action?.payload?.default_currency,
        default_radius: action?.payload?.default_radius,
        mileage_unit: action?.payload?.mileage_unit,
        tenant_name: action?.payload?.name
      }
    case ActionType.LOAD_DEFAULT_LENDER_CONFIGURATIONS:
      return {
        ...state,
        lender_id: action.payload.id
      }
    case ActionType.LOAD_CURRENCT_CONFIGURATIONS:
      if (action.payload) {
        return {
          ...state,
          default_currency_symbol: action.payload.symbol,
          default_currency_code: action.payload.code
        }
      }
    case ActionType.CHANGE_USER_LOGGED_IN_STATE:
      return {
        ...state,
        isUserLoggedIn: action.payload
      }
    case ActionType.UPDATE_SOCIAL_MEDIA_ICONS:
      return {
        ...state,
        social_media_icons: action.payload,
        collapse_state: false
        };
    case ActionType.UPDATE_COLLAPSE_STATE:
     return {
       ...state,
      collapse_state: !state.collapse_state
    };

    case ActionType.UPDATE_DEALER_CODE:
      return {
        ...state,
        dealer_code: action.payload
      }

    default:
      return state
  }
}

// Create context
export const AppContext = createContext<{
  state: IApplicationState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null

});

// Create provider

export const ApplicationContextProvider = ({ children }: { children: ReactNode }) => {

  const [state, dispatch] = useReducer(applicationStateReducer, initialState);

  return <AppContext.Provider value={{ state, dispatch }}>{children}</AppContext.Provider>;

};

export const useAppContext = () => useContext(AppContext)