import { useContext, useEffect, useState } from 'react'
import { HeaderMenuWrap } from './header-menu.style'
import { IconButton, useTheme } from '@mui/material'
import clsx from 'clsx'
import { Icon, Box } from '@ntpkunity/controls'
import { HeaderMenuRight, HeaderSearch, Logo, NavLink } from '@components'
import { Navigation } from '@constants'
import {AppContext, changeUserLoggedInState, updateSocialMediaIcons} from '@app/context-provider'
import { useAuthentication } from 'hooks/useAuthenticationHook'
import { useWorkflowContext } from '@app/router-workflow'
import {useQueryClient} from "react-query";
import { logout } from '@ntpkunity/controls-ums'

declare interface IHeaderMenu {
  openMenu: boolean
  setOpenMenu: any
  openSearch: boolean
  setOpenSearch: any
}

export const HeaderMenu = ({openMenu, setOpenMenu, openSearch, setOpenSearch}: IHeaderMenu) => {
  const theme = useTheme()
  const { navigateNext, getOrderWorkflow } = useWorkflowContext()
  const queryClient = useQueryClient();
  // using useContext to get the state and dispatch from the context
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  const defaultValues = {
    homeText: 'Home',
    newInventory: 'Inventory',
    preOwnedInventory: 'Pre-Owned Inventory',
    contactUs: 'Contact Us',
    logout: 'Logout',
    login: 'Login',
    createAccount: 'Create Account',
    profile: 'Profile'
  }

  const { authenticationStatus } = useAuthentication()
  const [isAuthenticated, setIsAuthenticated] = useState(authenticationStatus)

  useEffect(() => { setIsAuthenticated(authenticationStatus) }, [authenticationStatus])

  return (
    <>
      <HeaderMenuWrap theme={theme} className='header-menu-wrap'>
        <Box theme={theme} display={'flex'} flexGrow={1}>
          <Box theme={theme} className='app-logo' flexGrow={1} order={{ xs: 2, md: 1 }}>
            <Logo onClick={() => { }} />
          </Box>
          <IconButton
            className={clsx('toggle-icon', openMenu ? '' : 'change')}
            disableRipple={true}
            disableTouchRipple={true}
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={() => {
              setOpenMenu(!openMenu)
            }}
          >
            <Box theme={theme} className="menu-icon">
              <Box theme={theme} className="toggle-bar"></Box>
            </Box>
          </IconButton>
          <IconButton
            className={'toggle-icon icon-search'}
            disableRipple={true}
            disableTouchRipple={true}
            aria-label="account of current user"
            aria-controls="searbar"
            aria-haspopup="true"
            onClick={() => setOpenSearch(!openSearch)}
          >
            <Icon name="SearchIcon" />
          </IconButton>
        </Box>
        <Box theme={theme} className={clsx('header-nav-wrap', openMenu ? '' : 'menu-open')} >
          <ul className="navbar-nav nav-center">
            <li className="nav-item no-decoration">
              <NavLink
                title={PLACEHOLDERS?.HOME_TEXT ?? defaultValues.homeText}
                onClick={() => { setOpenMenu(!openMenu) }}
              />
            </li>
            {isAuthenticated && (
              <li className="nav-item no-decoration">
                <NavLink
                    title={PLACEHOLDERS?.NEW_INVENTORY_TEXT ?? defaultValues.newInventory}
                    onClick={() => {
                      getOrderWorkflow()
                      setOpenMenu(!openMenu)
                    }}
                />
              </li>)}
            <li className="nav-item no-decoration">
              <NavLink
                title={PLACEHOLDERS?.CONTACT_US_TEXT ?? defaultValues.contactUs}
                onClick={() => { setOpenMenu(!openMenu) }}
              />
            </li>
          </ul>
          <Box theme={theme} className='responsive-user-menu' display={{ xs: 'block', md: 'none' }}>
            <ul className="navbar-nav">
              {
                isAuthenticated && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        title={PLACEHOLDERS?.PROFILE_TEXT ?? defaultValues.profile}
                        onClick={() => {
                          navigateNext(`${Navigation.PROFILE.USER_PROFILE}`)
                          setOpenMenu(!openMenu)
                        }}
                      />
                    </li>
                    
                    
                  </>
                )
              }
              {
                !isAuthenticated && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        title={PLACEHOLDERS?.LOGIN_TEXT ?? defaultValues.login}
                        onClick={() => {
                          navigateNext(Navigation.AUTHENTICATION.LOG_IN_PAGE)
                          setOpenMenu(!openMenu)
                        }}
                      />
                    </li>
                    <li className="nav-item">
                      <NavLink
                        title={PLACEHOLDERS?.CREATE_ACCOUNT_TEXT ?? defaultValues.createAccount}
                        onClick={() => {
                          navigateNext(`${Navigation.AUTHENTICATION.SIGN_IN_PAGE}`)
                          setOpenMenu(!openMenu)
                        }}
                      />
                    </li>
                  </>
                )
              }
            </ul>
            
          </Box>
          <Box theme={theme} className='responsive-user-menu' display={{ xs: 'block', md: 'none' }}>
            <ul className="navbar-nav">
              <li className="nav-item">
                <NavLink
                  title={'English'}
                    startIcon='AmericaLangIcon'
                    onClick={() => {
                    setOpenMenu(!openMenu)
                  }}
                  selected
                />
              </li>
            </ul>
          </Box>
          <Box theme={theme} className='responsive-user-menu' display={{ xs: 'block', md: 'none' }}>
            <ul className="navbar-nav">
              {
                isAuthenticated && (
                  <>
                    <li className="nav-item">
                      <NavLink
                        title={PLACEHOLDERS?.LOGOUT_TEXT ?? defaultValues.logout}
                        onClick={() => {
                          localStorage.clear();
                          appState.dispatch(changeUserLoggedInState(false))
                          appState.dispatch(updateSocialMediaIcons({}));
                          queryClient.clear();
                          setOpenMenu(!openMenu)
                        }}
                      />
                    </li>
                  </>
                )
              }
            </ul>
            
          </Box>
          <HeaderMenuRight />
        </Box>
      </HeaderMenuWrap>
      <HeaderSearch
        searchOpen={openSearch}
        onClose={() => { setOpenSearch(!openSearch) }}
      />
    </>
  )
}
