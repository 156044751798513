import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AuthLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.auth-layout-wrap': {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 'calc(-162px + 100vh)',
    maxWidth: 565,
    margin: 'auto',
    flexDirection: 'column',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 24,
      paddingRight: 24
    },
    '.scrollable': {
      scrollbarWidth: 'thin',
      maxHeight: 'calc(100vh - 280px)',
      overflowY: 'auto',
      overflowX: 'hidden',
      '&::-webkit-scrollbar': {
        width: 7,
        height: 7
      }
    },
    '.back-btn': {
      '.btn': {
        '&.btn-default': {
          color: theme.palette.grey[600],
          '&:hover': {
            color: theme.palette.grey[600],
            '&:focus': {
              color: theme.palette.grey[600]
            }
          },
          '&:focus': {
            color: theme.palette.grey[600]
          }
        }
      }
    },
  }
}))
