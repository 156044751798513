import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CjLogoWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-logo-wrap': {
    maxHeight: 24,
    'a': {
      display: 'block'
    },
    'img': {
      height: 24,
      maxWidth: '100%'
    }
  }
}))
