import { Link, useTheme } from '@mui/material'
import { Box, Button, Icon, Menu, Typography } from '@ntpkunity/controls'
import { ProductOrderCardProps } from './product-order-card.props'
import { ProductOrderCardWrap } from './product-order-card.style'
import clsx from 'clsx'
import { Status } from "constants/enums"
import { useAppContext } from '@app/context-provider'
import { Image } from '@ntpkunity/controls'

export const ProductOrderCard = ({ secondaryTextOne, secondaryTextTwo, primaryTextOne, primaryTextTwo, btnText, orderId, productImg, menu, status, vin, onClick }: ProductOrderCardProps) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const enableClickCallback = ![Status.Expired, Status.Cancelled ].includes(status)
    const handleClick = enableClickCallback ? onClick : null

    return(
        <ProductOrderCardWrap theme={theme} className='product-order-card' onClick={handleClick}>
            <Box theme={theme} className='card-head'>
                {status && <Typography theme={theme} variant='caption' component={'small'} display={'block'} className={clsx({ 'fw-sbold status': true, 'in-progress': (status == Status.InProgress || status == Status.Approved || status == Status.Submitted || status == Status.ReSubmitted || status == Status.Reviewed ), 'completed': (status == Status.Completed), 'draft': (status == Status.Draft), 'conditioned': (status == Status.Conditioned || status == Status.PendingConfirmation), 'cancelled': (status == Status.Cancelled || status == Status.Declined || status == Status.Expired) })}> {status}</Typography>}
                {productImg && 
                    <Box theme={theme} className='card-img'>
                        {/* <img src={productImg} className='img' /> */}
                        <Image  theme={theme}
                            src={productImg}
                            aspectRatio="16:9" 
                        />
                    </Box>
                }
            </Box>
                <Box theme={theme} className='card-content'>
                    <Box theme={theme} className='title'>
                        {primaryTextOne &&
                            <Box theme={theme} className='f-row'>
                                <Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted fw-medium'>{secondaryTextOne}</Typography>
                                <Typography theme={theme} variant='subtitle2' component={'p'} display={'block'} className='text-truncated'>{primaryTextOne}</Typography>
                            </Box>
                        }
                        {primaryTextTwo &&
                            <Box theme={theme} className='s-row'>
                                <Typography theme={theme} variant='caption' component={'small'} display={'block'} className='text-muted fw-medium'>{secondaryTextTwo}</Typography>
                                <Typography theme={theme} variant='subtitle2' component={'p'} display={'block'} className='text-truncated'>{primaryTextTwo}</Typography>
                            </Box>
                        }
                    </Box>
                    <Box theme={theme} className='id-vin'>
                        {orderId && <Typography theme={theme} variant='caption' component={'small'} mb={0.5} className='order-id'><span className='fw-sbold'>{PLACEHOLDERS.ORDER_ID_TEXT} </span> <span className='text-muted'>{orderId}</span></Typography>}
                        {vin && <Typography theme={theme} variant='caption' component={'small'} className='order-id'><span className='fw-sbold'>{PLACEHOLDERS.VIN_TEXT} </span> <span className='text-muted'>{vin}</span></Typography>}
                    </Box>
                </Box>
                {onClick &&
                        <Box theme={theme} className='card-action'>
                            <Button theme={theme} primary fullWidth onClick={handleClick} text={btnText } disabled={!enableClickCallback}/>
                        </Box>
                    }
        </ProductOrderCardWrap>
        
    )
}