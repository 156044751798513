import { styled } from '@mui/material/styles'
import { Box, IGridProps } from '@ntpkunity/controls'

export const CustomFilters = styled('div')(({ theme }) => ({
    width: 'calc(100% - 0px)',
  '.scroll': {
    maxHeight: 'calc(100vh - 325px)',
    paddingRight: 8,
    flexGrow: 1,
    overflowY: 'hidden',
    overflowX: 'hidden',
    position: 'relative',
    scrollbarGutter: 'stable',
    scrollbarWidth: 'thin',
    '&::-webkit-scrollbar': {
      width: 9,
      height: 9
    },
    '&::-webkit-scrollbar-track': {
      padding: '0 0px',
      backgroundColor: 'transparent'
    },
    '&::-webkit-scrollbar-thumb': {
      transition: 'all 0.3s',
      backgroundColor: 'rgba(0, 0, 0, 0.16)',
      borderRadius: 4,
      border: '3px solid transparent',
      backgroundClip: 'content-box'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.16)'
    },
    '&:hover': {
      overflowY: 'auto',
      '@-moz-document url-prefix()': {
        overflowY: 'auto'
      }
    },
    '.switch-label': {
      display: 'flex',
      width: '100%',
      '.span-col': {
        flex: 1
      },
      '.switch-control-span': {
        float: 'right',
        textAlign: 'right',
        '.span-col': {
          float: 'right',
          flex: 1
        }
      }
    },
    '.filter-item': {
      marginBottom: 8,
      '.u-accordian-wrap': {
        backgroundColor: '#F8F8F8',
        marginBottom: '8px !important',
        borderRadius: 8,
        '.MuiAccordionSummary-root': {
          padding: '16px 24px',
          '.MuiAccordionSummary-content': {
            margin: 0
          },
          '.sub-title .ind': {
            display: 'inline-block',
            width: 12,
            height: 12,
            borderRadius: 12,
            backgroundColor: theme.palette.primary.main
          },
          '&.Mui-expanded': {
            '.sub-title .ind': {
              display: 'none'
            }
          }
        },
        '.u-accordian-body': {
          border: 'none',
          padding: 24,
          paddingTop: 0,
          marginTop: 8,
          '.checks-wrap': {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            marginTop: '-8px',
            '.u-form-group': {
              width: '100%',
              '.MuiInputLabel-root': {
                backgroundColor: '#F8F8F8'
              }
            },
            '&.multi-select': {
              '.u-form-group': {
                marginBottom: 16,
                '&:last-child': {
                  marginBottom: 0
                }
              }
            },
            '.custom-checkbox-wrap': {
              flex: '0 0 100%',
              maxWidth: '100%',
              '&:last-child': {
                '.u-custom-control-label': {
                  marginBottom: 0
                }
              }
            },
            '.slider-wrap': {
              width: '100%'
            }
          }
        }
      },

      '.slider-wrap': {
        '.slider-values': {
          color: theme.palette.grey[600]
        }
      },
      '&.filters-cta': {
        '.btn:not(:first-of-type)': {
          marginLeft: 8,
          flexGrow: 1
        }
      },
      '&.btn-sticky': {
        backgroundColor: theme.palette.common.white,
        borderTop: '1px solid' + theme.palette.grey[100],
        position: 'sticky',
        paddingTop: 24,
        margin: 0,
        zIndex: 1,
        bottom: 0
      }
    }
  }
}))