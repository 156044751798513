import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductDetailWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-detail': {
        '.cj-card': {
            backgroundColor: theme.palette.grey[50],
            padding: '24px',
            borderRadius: 16,
            border: '2px solid transparent',
            marginBottom: 8,
            transition: '0.4s',
            '.card-content': {
                '.title': {
                    display: 'flex',
                    gap: 4,
                }
            },
            '.card-action': {
                marginTop: 16,
                '.link': {
                    cursor: 'pointer'
                }
            }
        }
    }
}))
