import { useContext, useEffect, useReducer, useState } from 'react'
import { useTheme } from '@mui/material'
import { UserProfileComponent } from '@ntpkunity/controls-ums'

import { AuthLayoutWrap } from "../layout.style"
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Endpoint, Navigation, QueryKeys } from '@constants'
import { AppContext, changeUserLoggedInState, useAppContext } from '@app/context-provider'
import { StatusCodes } from 'http-status-codes'
import { useAddSignUpCustomerConsent, useCreateCustomer, useDisclaimers } from 'hooks/authentication-management'
import { ConsentStatus, DisclaimerNames } from 'constants/consent-status'
import { useIsMutating, useQueryClient } from 'react-query'
import { Snackbar } from '@ntpkunity/controls'
import { SnackbarUtility } from '@utilities'
import { useWorkflowContext } from '@app/router-workflow'
import { user_session } from '@constants'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';


export const UserProfilePage = () => {
  const [response, setResponse] = useState<any>(null)

  const { tenant } = useParams()
  const navigate = useNavigate()
  const theme = useTheme()
  const location = useLocation()
  const email = location?.state?.email
  const otp = location?.state?.otp as boolean
  const provider = location?.state?.provider
  const profile = location?.state?.profile
  const { mutate: createTags } = useTags(tenant);
  if (!email) {
    navigate(`${Navigation.AUTHENTICATION.SIGN_IN_PAGE.replace(':tenant', tenant)}`)
  }

  const { navigateNext } = useWorkflowContext()
  const { dispatch: app_dispatch } = useAppContext()
  const queryClient = useQueryClient();
  const isWokflowMutating = useIsMutating()
  const { disclaimers } = useDisclaimers([DisclaimerNames.TCPA])
  const { mutate: createCustomer, isLoading: createCustonerLoading } = useCreateCustomer(tenant)
  const { mutateAsync: addCustomerConsent } = useAddSignUpCustomerConsent(tenant)

  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const appState = useContext(AppContext);
  const user_settings = JSON.parse(localStorage.getItem("settings"))

  const PLACEHOLDERS = appState.state.language.placeholders;

  const translations = {
    title: PLACEHOLDERS.USER_PROFILE_COMPONENT_TITLE,
    subTitle: PLACEHOLDERS.USER_PROFILE_SUBTITLE,
    firstNameFieldLabel: PLACEHOLDERS.USER_PROFILE_FIRST_NAME_FIELD_LABEL,
    lastNameFieldLabel: PLACEHOLDERS.USER_PROFILE_LAST_NAME,
    lastNameText: PLACEHOLDERS.LAST_NAME_TEXT,
    passwordFieldLabel: PLACEHOLDERS.USER_PROFILE_PASSWORD_FIELD_LABEL,
    zipCodeFieldLabel: PLACEHOLDERS.ZIP_CODE,
    contactNumberFieldLabel: PLACEHOLDERS.USER_PROFILE_CONTACT_NUMBER_FIELD_LABEL,
    preferredContactMethodFieldLabel: PLACEHOLDERS.USER_PROFILE_PREFERRED_CONTACT_METHOD,
    firstNameHelpText: PLACEHOLDERS.FIRST_NAME_HELP_TEXT,
    lastNameHelpText: PLACEHOLDERS.LAST_NAME_HELP_TEXT,
    contactNumberText: PLACEHOLDERS.CONTACT_NUMBER_TEXT,
    passwordText: PLACEHOLDERS.PASSWORD_TEXT,
    passwordHelpText: PLACEHOLDERS.PASSWORD_HELP_TEXT,
    passwordMismatchErrorText: PLACEHOLDERS.CONFIRM_PASSWORD_NOT_MATCHING,
    isRequiredText: PLACEHOLDERS.IS_REQUIRED_TEXT,
    onlyCharactersAllowedText: PLACEHOLDERS.ONLY_CHARACTERS_ARE_ALLOWED,
    invalidContactNumberText: PLACEHOLDERS.INVALID_CONTACT_NUMBER_TEXT,
    disclosureStaticText: PLACEHOLDERS.USER_PROFILE_AGREEMENT_PROMPT,
    disclosureLinkText: PLACEHOLDERS.USER_PROFILE_TCPA_DISCLOSURE,
    disclosureTitle: PLACEHOLDERS.USER_PROFILE_TCPA_DISCLOSURE_LABEL,
    contactTitle: PLACEHOLDERS.USER_PROFILE_CONTACT_INFO_PROMPT,
    buttonTextScreen1: PLACEHOLDERS.USER_PROFILE_BUTTON_TEXT,
    buttonTextScreen2: PLACEHOLDERS.FINISH_PROFILE_SETUP_BUTTON_TEXT
  }

  useEffect(() => {
    if (response) {
      const { status, data, message } = response
      const {
        first_name: firstName,
        last_name: lastName,
        user_name: email,
        access_token: accessToken,
        refresh_token: refreshToken,
        profile,
        signup_steps: signupSteps,
       } = data ?? {}

      if (status === StatusCodes.OK) {
        createCustomer({
          url: Endpoint.DMS_CREATE_CUSTOMER,
          method_type: 'post',
          payload: {
            customer_profile: {
              first_name: firstName,
              last_name: lastName,
              email,
              created_by: email
            }
          }
        }, {
          onSuccess: (data) => {
            const cached_disclaimers = queryClient.getQueryData(QueryKeys.GET_TENEANT_CONSENT) as any[]
            if(Array.isArray(cached_disclaimers) && (cached_disclaimers ?? []).length > 0) {
              const allDisclaimers = cached_disclaimers
                .filter(d => d.status === ConsentStatus.PUBLISHED &&
                    [DisclaimerNames.TERMS_AND_CONDITIONS,
                    DisclaimerNames.CCPA,
                    DisclaimerNames.TCPA].includes(d.name)
                  )
  
              allDisclaimers.forEach(disclaimer => {
                const disclaimerData = allDisclaimers.find(dsc => dsc.name === disclaimer.name)
                addCustomerConsent({
                  url: Endpoint.DMS_CUSTOMER_DISCLAIMER_AGREEMENT,
                  method_type: 'post',
                  payload: {
                    customer_reference_id: data?.reference_id,
                    disclaimer_name: disclaimer.name,
                    disclaimer_version: disclaimerData?.version
                  }
                })
              })
              const event: Event = {
                session: user_session,
                slug:tenant,
                event_name:Tags.PROFILE_INFO_SET,
                data:data,
                page_type:PageType.SetProfile
              }
              createTags(event)
            }

            localStorage.setItem("settings", JSON.stringify({ email , 'access_token':accessToken}));
            localStorage.setItem('access_token', accessToken)
            localStorage.setItem('refresh_token', refreshToken)
            localStorage.setItem('user_profile', JSON.stringify(profile))
            localStorage.setItem('signup_steps', `"${signupSteps.toLowerCase()}"`)
            app_dispatch(changeUserLoggedInState(true))

            navigateNext()
          },
          onError: (error) => {
            const { detail: { message } } = error
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(message))
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 5000);
          }
        })
      }
    }
  }, [response])

  useEffect(()=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.PROFILE_INFO,
      page_type:PageType.SetProfile
    }
    createTags(event)
  }, [])
  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <UserProfileComponent
        theme={theme}
        email={email}
        layoutText={translations}
        setResponse={setResponse}
        userKey={otp}
        tcpaContent={disclaimers?.TCPA}
        isLoading={createCustonerLoading || isWokflowMutating > 0}
        passwordless={!!provider}
        provider={provider}
        defaultFormValues={profile}
      />
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
      />
    </AuthLayoutWrap>
  )
}
