import { createTheme } from '@mui/material'
import { unityTheme } from '@ntpkunity/controls'
import "./font-family.css";
import "./theme-builder.css"


export const StyleVariables = {
  defaultFontFamily: 'UnityFontFamily'
}

export const CUSTOMER_JOURNEY_THEME = createTheme({
  ...unityTheme,
  palette: {
    ...unityTheme.palette,
    primary: { main: '#0033FD' }
  },
  typography: {
    ...unityTheme.typography,
    fontFamily: StyleVariables.defaultFontFamily
  }
})

export { CUSTOMER_JOURNEY_THEME_WRAP } from './global.styles'