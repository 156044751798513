import { useForm } from 'react-hook-form';
export const getDefaultPersonalFinanceValues = (personalFinanceData) => {
  if (!personalFinanceData) return {};

  const { residence, monthly_payment, bankruptcy } = personalFinanceData;

  return {
    residence,
    monthly_payment: monthly_payment > 0 ? monthly_payment : '',
    bankruptcy,
  };
};
export function usePersonalFinanceDetailsForm(personalFinanceData: any, formStates: any) {


  const personalFinanceFrom = useForm<any>({
    defaultValues: formStates?.['personalFinance']?.data || getDefaultPersonalFinanceValues(personalFinanceData),
    shouldUnregister: true,
    mode: 'onChange'
  });

  const watchPersonalFinanceFields = personalFinanceFrom.watch();

  return { personalFinanceFrom, watchPersonalFinanceFields };
}
