import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
    '.symbol': {
      display: 'block',
      marginRight: 4,
    },
  }
}))
