export enum CardType {
  VISA = "visa",
  MASTERCARD = "masterCard",
}
export enum Status {
  Inquiry = "Inquiry",
  AvailableInInventory = "Available In Inventory",
  InContarct = "In Contarct",
  Available = "Available",
  Draft = "Draft", //Orders
  Submitted = "Submitted", //Orders
  Approved = "Approved", //Orders
  Conditioned = "Conditioned", //Orders
  InReview = "In Review", //Orders
  Reviewed = "Reviewed", //Orders
  ReSubmitted = "Re Submitted", //Orders
  SchedulePickup = "Awaiting Scheduling", //Schedule Pickup/delivery
  Appointment = "Appointment Scheduled", //Appointment
  Cancelled = "Cancelled", //Cancelled
  Converted = "Converted", //Completed
  Completed = "Completed", //Completed
  InProgress = "In-Progress", //In-Progress
  Declined = "Declined",
  PendingConfirmation = "Pending Confirmation",
  Expired = "Expired",
  PendingTradeInConfirmation = "Pending Trade-In Confirmation",
}

export enum EstimateProvider {
  KBB = "KBB",
  PLAID = "Plaid",
}
export enum Integration_type {
  TRADE_IN = "Trade In Valuation",
  PLAID_PAYMENT = "Payment Processing",
}
export enum TradeInJourneySteps {
  AddTradeInPopup = "AddTradeInPopup",
  ProvideManualEstimatePopup = "ProvideManualEstimatePopup",
  MakeModelPopupOne = "MakeModelPopupOne",
  VinOnePopup = "VinOnePopup",
  LicensePlatePopup = "LicensePlatePopup",
  TrimLevelPopup = "TrimLevelPopup",
  FinalPopup = "FinalPopup",
  LoaderPopup = "LoaderPopup",
}

export enum PaymentType {
  Lease = "Lease",
  Finance = "Finance",
}

export enum Steps {
  REGISTERED = "registered",
  VERIFIED = "verified",
  TENANT = "tenant",
  TERMS = "terms",
}

export enum Tags {
  SIGNIN_PAGE = "Display Signin page",
  OTP = "Display OTP",
  SESSION_END = "Session End",
  ZIPCODE_POPUP = "Zipcode Pop Up",
  LANDING_ON_INVENTORY_SCREEN = "Landing on inventory screen",
  SEARCH_ZIPCODE = "Search Zip Code",
  SCROLL = "Scroll",
  DEALER_SELECTION = "Dealer Selection",
  DISPLAY_INVENTORY = "Display Inventory",
  APPLY_SORTING = "Apply sorting",
  APPLY_FILTERS = "Apply Filters",
  SELECT_VEHICLE = "Select Vehicle",
  VIEW_VEHICLE_DETAILS = "View Vehicle Details",
  INVENTORY_PAGE_END = "Inventory Page End",
  DISPLAY_PAYMENT = "Display Payment",
  PAYMENT_END = "Payment End",
  DISPLAY_FNI = "Display F&I",
  TAGS = "Tags",
  ADD_PRODUCT = "Add Product",
  VIEW_PRODUCT_DETAILS = "View Product Details",
  FNI_END = "F&I End",
  DISPLAY_ADDONS = "Display Addons",
  VIEW_DETAILS = "View Details",
  CARD_CLICK = "Card Click",
  ADDONS_END = "Addons End",
  DISPLAY_TRADE_IN = "Display Trade In",
  ENTER_MAKE_MODEL = "Enter Make Model",
  SELECT_YEAR = "Select Year",
  MAKE = "Make",
  SEARCH_EVENT = "Search Event",
  MODEL = "Model",
  SERIES = "Series",
  POP_TRADE_ESTIMATE = "Pop Trade Estimate Auto",
  POP_TRADE_ESTIMATE_MAN = "Pop Trade Estimate Manual",
  MILEAGE = "Milege",
  LOAN_KIND = "Loan Kind",
  PAY_OFF_AMOUNT = "Pay off Amount",
  CONDITION = "Condition",
  UPLOAD_PHOTOS = "upload photos",
  PROCEED_WITH_TRADING = "Proceed with Tradin",
  TRADE_IN_END = "Trade In End",
  DISPLAY_INSURANCE = "Display insurance",
  INSURANCE_POP_UP = "Insurance Pop Up",
  INPUT_VALUES = "Input Values",
  INSURANCE_SESSION_END = "Insurance Session End",
  DISPLAY_SCHEDULE_APPOINTMENTS = "Display Schedule Appointments",
  ADDRESS_ENTRY = "Address Entry",
  Schedule_END = "Schedule End",
  DISPLAY_FINANCING = "Display Financing",
  INFORMATION_FILL = "Information Fill",
  FINANCING_END = "Financing End",
  DISPLAY_REVIEW_ORDER = "Display Review Order",
  SUBMIT_ORDER = "Submit Order",
  REVIEW_ORDER_END = "Review Order End",
  ADD_PRODUCT_ADDON = "Add Product Addon",
  PAYMENT_CONTRACT = "Contract Payment",
  CONTRACTING = "Contract",
  PAYMENT_CALC ="Payment Calculator",
  PROFILE_INFO = "Profile Info Start",
  PROFILE_INFO_SET = "Profile Info Finish"
}

export enum PageType {
  SignIn = "Sign In Page",
  Otp = "OTP Page",
  InventoryImpression = "inventory Page",
  ZipCode = "Zip Code Popup",
  SelectVehicle = "Select Vehicle",
  ViewVehicle = "View Details",
  Payments = "Payments Page",
  FIPage = "F&I Page",
  Addons = "Addons Page",
  TradeIn = "TradeIn Page",
  Insurance = "Insurance Page",
  ScheduleAppointments = "Schedule Appointments Page",
  Financing = "Financing Page",
  ReviewOrder = "Review Order Page",
  SetProfile = "Set Profile"
}
export enum AvailabilityCheck {
  MANUAL = "Manual",
  AUTO = "Automatic",
}

export enum VehicleStatus {
  AVAILABLE = "Available",
  NOT_AVAILABLE = "Not Available",
}

export enum DocumentPackageTypes {
  CONTRACT = "Contract",
  GENERAL = "General",
}

export enum DocumentPackageStatus {
  SignatureNotRequired = "SignatureNotRequired",
  NotStarted = "NotStarted",
  InProgress = "InProgress",
  Signed = "Signed",
}

export enum SignTitle {
  SIGN_CONTRACT = "Sign Contract",
  SIGN_YOUR_INITIALS = "Sign Your Initials",
}

export enum PaymentCheck {
  CHECK = "checking",
  DEBIT = "ach_debit"
}
export enum AddOnsType {
  OEM = "OEM",
  Dealer = "Dealer"
}

export enum PaymentErrors {
    "card_declined"= "Your card was declined.",
    "insufficient_funds"= "Insufficient funds in the account.",
    "expired_card"= "Your card has expired.",
    "incorrect_cvc"= "The CVC code you entered is incorrect.",
    "invalid_card_number"= "The card number is invalid.",
    "card_not_supported"= "The card type is not supported.",
    "transaction_limit_exceeded"= "The transaction amount exceeds the card’s limit.",
    "bank_error"= "A bank error occurred. Please try again later.",
    "authentication_required"= "Additional authentication is required for this transaction.",
    "card_blocked"= "Your card has been blocked.",
    "invalid_expiry_date"= "The expiration date you entered is invalid.",
    "fraudulent_transaction"= "The transaction has been flagged as potentially fraudulent.",
    "payment_method_restricted"= "The payment method is restricted.",
    "transaction_declined"= "The transaction was declined.",
    "processing_error"= "An error occurred while processing the payment.",
    "duplicate_transaction"= "The transaction appears to be a duplicate.",
    "merchant_account_closed"= "The merchant's account is closed.",
    "currency_not_supported"= "The currency used is not supported by the card issuer."
}