import React, {useEffect, useState} from 'react';
import {useCustomDirtyForm} from "../../../../hooks/order-management";
import {ResidenceDetailsComponent} from "../../../../libraries/financing-control/ResidenceDetails";
import {useFormContext} from "../../../(order-management)/order/form-context-provider";
import {getDefaultValues} from "../../../../utilities/form/residence-details";

const ResidenceDetailsWrapper = ({
  customerReferenceId,
  contactData,
  setTab,
  residentForm,
  watchResidenceFields,
}) => {
      const [isGaraging, setIsGaraging] = useState(false)
      const {formStates} = useFormContext()

      useCustomDirtyForm('residence', watchResidenceFields, residentForm?.formState?.isValid);

      useEffect(() => {
          if(contactData){
              const mailAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Mailing');

              setIsGaraging(mailAddress?.is_garaging)
              residentForm?.reset(formStates?.['residence']?.data || getDefaultValues(contactData, customerReferenceId))
          }
      }, [contactData]);

  return (
    <ResidenceDetailsComponent
      customerReferenceId={customerReferenceId}
      contactData={contactData}
      setTab={setTab}
      form={residentForm}
      isGaraging={isGaraging}
      setIsGaraging={setIsGaraging}
    />
  );
};

export default ResidenceDetailsWrapper;
