import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const NeedHelpWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.need-help-wrap': {
        '&.bg-light': {
            backgroundColor: '#F5F5F7',
            borderRadius: 8,
        },
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.background.default,
        borderRadius: 16,
        gap: 24,
        '.action-btns': {
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            // '.MuiButtonBase-root': {
            //     backgroundColor: 'transparent',
            //     '&:hover': {
            //         backgroundColor: theme.palette.primary.main
            //     }
            // }
        },
        '.google-map': {
            display: 'flex',
            flexDirection: 'column',
            gap: 24,
            iframe: {
                border: '1px solid',
                borderColor: theme.palette.grey[50],
                borderRadius: '8px'
            }
        }
    }
}))
