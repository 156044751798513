import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const RatingWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.rating-wrap': {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    gap: 8,
    '.custom-radio-wrap': {
      flexDirection: 'row',
      width: '100%',
      '.MuiFormGroup-root': {
        flexDirection: 'row',
        width: '100%',
        gap: '8px',
        '.u-custom-control-label': {
          flexDirection: 'column',
          alignItems: 'center',
          whiteSpace: 'nowrap',
          width: '96px',
          [theme.breakpoints.down('sm')]: {
            width: '22.5%'
          },
          '.custom-radio': {
            paddingBottom: '6px',
            '&:hover': {
              '&::after': {
                display: 'none'
              },
            },
            '&.Mui-checked': {
              '~': {
                '.MuiFormControlLabel-label': {
                  fontWeight: theme.typography.fontWeightBold
                }
              },
              circle: {
                stroke: theme.palette.primary.main
              }
            },
            margin: 0,
            svg: {
              width: '60px',
              height: '60px',
              circle: {
                stroke: ''
              },
              path: {
                stroke: ''
              }
            }
          }
        }
      }
    }
  }
}))
