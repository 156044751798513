import { useContext, useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { type IResponse, ExpiredLinkComponent } from '@ntpkunity/controls-ums'

import { AuthLayoutWrap } from '../layout.style'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { HttpStatusCode } from 'axios'
import { Navigation } from '@constants'
import { AppContext } from '@app/context-provider'

interface IState {
  token: string
  priceId?: string
  verificationScreenUrl: string
  resendEmailEndPoint?: string
  type: 'verfication' | 'password'
  layoutText: {
    title: string
    subTitle: string
    buttonText: string
  }
}

export const ResetPasswordLinkExpiredPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { tenant } = useParams()
  const { state } = useLocation()
  const {
    token,
    verificationScreenUrl,
    type,
    layoutText,
    resendEmailEndPoint,
    priceId
  } = (state as IState) ?? {}
  const [response, setResponse] = useState<IResponse>()

  useEffect(() => {
    if (response?.status === HttpStatusCode.Ok) {
      navigate(`${Navigation.BASE_PATH.replace(':tenant', tenant)}/${Navigation.AUTHENTICATION.LOG_IN_PAGE}`, { replace: true })
    }
  }, [response])

  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

  const translations = {
    title: PLACEHOLDERS.EXPIRED_LINK_TITLE,
    subTitle: PLACEHOLDERS.EXPIRED_LINK_SUBTITLE,
    buttonText: PLACEHOLDERS.EXPIRED_LINK_BUTTON_TEXT,
    seoButtonText: PLACEHOLDERS.EXPIRED_LINK_SEO_BUTTON_TEXT,
    emailSentMessageText: PLACEHOLDERS.EMAIL_SENT_MESSAGE_TEXT
  }

  return (
    <AuthLayoutWrap theme={theme} className='auth-layout-wrap'>
      <ExpiredLinkComponent
        priceId={priceId}
        resendEmailEndPoint={resendEmailEndPoint}
        theme={theme}
        setResponse={setResponse}
        verificationScreenUrl={verificationScreenUrl}
        type={type}
        token={token}
        layoutText={translations}
      />
    </AuthLayoutWrap>
  )
}
