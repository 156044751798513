import styled from '@emotion/styled'
import { Theme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import {
  Grid,
  IBoxProps,
  Icon,
  MultiSelect,
  Accordion,
  Box,
  Button,
  DualSlider,
  Input,
  Checkbox,
  Tooltip,
  Typography,
  DuoTab
} from '@ntpkunity/controls'
import { Controller, useForm } from 'react-hook-form'
import React from 'react'
import { Stack, Tab } from '@components'
import { CustomFilters } from './AssetFilterControl.style'

type ICustomFilterControlProps = {
  theme: Theme
  referenceData: any
  defaultCurrency: string
  orderBy: string
  onSave: (data: any) => unknown
}
export const MultiSelectWrap: any = styled(
  Box,
  {}
)<Partial<IBoxProps>>(() => ({
  width: '100%',
  display: 'inline-block',
  marginBottom: 24,

  '&:last-child': {
    marginBottom: 0
  },
  '.u-dropdown-item': {
    '.custom-checkbox-wrap': {
      width: '100%',
      '.u-custom-control-label': {
        margin: 0,
        display: 'inline-block',
        width: '100%',
        '.MuiButtonBase-root': {
          marginRight: 0,
          position: 'absolute',
          width: '97%',
          textAlign: 'left',
          display: 'inline-block'
        },
        '.MuiFormControlLabel-label': {
          marginLeft: '30px',
          paddingTop: '24px',
          '&:hover': {
            '&:after': {
              display: 'none !important'
            }
          }
        }
      }
    }
  }
}))

interface IFormInputs {
  minSellingPrice: number
  maxSellingPrice: number
  year_from: number
  year_to: number
  years: number[]
  makes: string[]
  models: string[]
  asset_conditions: [x: boolean][]
  trims: string[]
  vin: string
  stock: string
}

const AssetFilterControl: FC<ICustomFilterControlProps> = ({
  theme,
  referenceData,
  defaultCurrency,
  orderBy,
  onSave
}) => {
  const assetConditionsArray = Array.from(new Set(referenceData?.asset_condition))
  const yearsArray = referenceData?.years || []
  const validYears = yearsArray.filter((year: number) => typeof year === 'number' && !isNaN(year))
  const defaultValue = {
    minSellingPrice: referenceData?.selling_price?.[0] || 0,
    maxSellingPrice: referenceData?.selling_price?.[1] || 0,
    asset_conditions: [],
    year_from: validYears.length > 0 ? Math.min(...validYears) : 0,
    year_to: validYears.length > 0 ? Math.max(...validYears) : 0,
    years: validYears,
    make: [],
    makes: [],
    models: [],
    trims: [],
    vin: '',
    stock: ''
  }

  const { handleSubmit, control, getValues, reset, setValue, watch } = useForm<IFormInputs>({
    defaultValues: defaultValue
  })

  const onSubmit = () => {
    let filterData = ``
    filterData = Object.entries(getValues('asset_conditions'))
      .map((condition) => {
        return condition[1] ? `multiple_asset_condition=${condition[0]}` : null
      })
      .filter(Boolean)
      .join('&')

    getValues('makes')?.map((make: string) => {
      filterData = (filterData ? `${filterData}&` : '') + `multiple_asset_make=${make}`
    })

    getValues('models')?.map((model: any) => {
      filterData = (filterData ? `${filterData}&` : '') + `multiple_asset_model=${model}`
    })

    getValues('trims')?.map((trim: any) => {
      filterData = (filterData ? `${filterData}&` : '') + `multiple_asset_trim=${trim}`
    })
    getValues('vin')
      ? (filterData =
          (filterData ? `${filterData}&` : '') + `vin=${getValues('vin').replace(/\s/g, '')}`)
      : ``

    getValues('stock')
      ? (filterData =
          (filterData ? `${filterData}&` : '') +
          `stock_number=${getValues('stock').replace(/\s/g, '')}`)
      : ``
    filterData =
      (filterData ? `${filterData}&` : '') +
      `vehicle_status=${'Available'}&listing_status=${'listed'}`
    filterData =
      filterData +
      `&internet_price_from=${getValues('minSellingPrice')}&internet_price_to=${getValues(
        'maxSellingPrice'
      )}`
    filterData = filterData + `&year_from=${getValues('year_from')}&year_to=${getValues('year_to')}`
    onSave(filterData)
  }
  const [isAssetConditionExpanded, setIsAssetConditionExpanded] = useState(true)
  const [isSellingPriceExpanded, setIsSellingPriceExpanded] = useState(true)
  const [isYearExpanded, setIsYearExpanded] = useState(true)
  const [isMakeModelTrimExpanded, setIsMakeModelTrimExpanded] = useState(true)
  const [isVinExpanded, setIsVinExpanded] = useState(true)
  const [isStockNumberExpanded, setIsStockNumberExpanded] = useState(true)
  const handleAssetConditionClose = (_event: any, _key: any) => {
    setIsAssetConditionExpanded(false)
  }
  const handleSellingPriceClose = (_event: any, _key: any) => {
    setIsSellingPriceExpanded(false)
  }
  const handleYearClose = (_event: any, _key: any) => {
    setIsYearExpanded(false)
  }
  const handleMakeModelClose = (_event: any, _key: any) => {
    setIsMakeModelTrimExpanded(false)
  }
  const handleVinClose = (_event: any, _key: any) => {
    setIsVinExpanded(false)
  }
  const handleStockNumClose = (_event: any, _key: any) => {
    setIsStockNumberExpanded(false)
  }

  const handleAssetConditionOpen = (_event: any, _key: any) => {
    setIsAssetConditionExpanded(true)
  }
  const handleSellingPriceOpen = (_event: any, _key: any) => {
    setIsSellingPriceExpanded(true)
  }
  const handleMakeModelTrimOpen = (_event: any, _key: any) => {
    setIsMakeModelTrimExpanded(true)
  }
  const handleYearOpen = (_event: any, _key: any) => {
    setIsYearExpanded(true)
  }
  const handleVinOpen = (_event: any, _key: any) => {
    setIsVinExpanded(true)
  }
  const handleStockNoOpen = (_event: any, _key: any) => {
    setIsStockNumberExpanded(true)
  }

  const [availableModels, setAvailableModels] = useState([])
  const [availableTrims, setAvailableTrims] = useState([])
  const [modelsAndTrimsDisabled, setModelsAndTrimsDisabled] = useState(true)

  const selectedMake = watch('makes')
  const selectedModel = watch('models')

  useEffect(() => {
    const availableMakes = referenceData?.result?.map((make: any) => make.make_name) || []
    const defaultMakeValue = availableMakes.length === 1 ? [availableMakes[0]] : []
    if (defaultMakeValue) setValue('makes', defaultMakeValue)
  }, [])
  useEffect(() => {
    if (selectedMake.length > 0) {
      const selectedMakeData = referenceData?.result.filter((make: any) =>
        selectedMake.includes(make.make_name)
      )
      const makeModels = selectedMakeData.flatMap((make: any) =>
        make.asset_models.map((model: any) => ({
          text: model.model_name,
          value: model.model_name
        }))
      )

      setAvailableModels(makeModels)
      setModelsAndTrimsDisabled(false)

      if (selectedModel.length > 0) {
        const selectedTrimData = selectedMakeData.flatMap((make: any) =>
          make.asset_models
            .filter((model: any) => selectedModel.includes(model.model_name))
            .flatMap((model: any) => model.asset_trims)
        )
        const makeTrims = Array.from(new Set(selectedTrimData)).map((trim) => ({
          text: trim,
          value: trim
        })) as never[]
        setAvailableTrims(makeTrims)
      } else {
        setAvailableTrims([])
        setModelsAndTrimsDisabled(false)
      }
    } else {
      setAvailableModels([])
      setAvailableTrims([])
      setModelsAndTrimsDisabled(true)
    }
  }, [selectedMake, selectedModel])

  useEffect(() => {
    onSubmit()
  }, [])

  useEffect(() => {
    if (orderBy) {
      onSubmit()
    }
  }, [orderBy])

  const formatCurrency = (number: any): any => {
    const options = {
      style: 'currency',
      currency: { defaultCurrency }
    }
    let numberConvertor = number?.toLocaleString(options)
    numberConvertor = numberConvertor !== undefined ? numberConvertor : `${defaultCurrency}0`
    const currencyAdded = `${defaultCurrency}${numberConvertor}`
    return currencyAdded
  }
  const [sliderValue, setSliderValue] = useState<any[]>([
    referenceData?.selling_price?.[0],
    referenceData?.selling_price?.[1]
  ])
  const [yearSliderValue, setYearSliderValue] = useState<number[]>([
    validYears.length > 0 ? Math.min(...validYears) : 0,
    validYears.length > 0 ? Math.max(...validYears) : 0
  ])
  function isAnyCheckboxChecked(conditions: Record<string, boolean> | undefined): boolean {
    if (!conditions) return false

    return Object.values(conditions).some((value) => value === true)
  }

  const tabItems = [
    {
      title: 'New',
      content: '',
    },
    {
      title: 'Pre-Owned',
      content: '',
    },
    {
      title: 'Certified Pre-Owned',
      content: ''
    }
  ]

  return (
    <Stack paddingMd={2}>
      <CustomFilters theme={theme}>
        <Stack paddingMd={3} paddingXs={2}>
          <Typography theme={theme} variant='caption' component={'span'} className='text-primary text-uppercase fw-sbold'>
            NEW INVENTORY
          </Typography>
          <Typography theme={theme} variant='h2' component={'h2'}>
            All Brands
          </Typography>
          <Typography theme={theme} variant='body2' component={'p'} className='text-muted'>
            Starting at $00,000 MSRP
          </Typography>
        </Stack>
        <Tab bgLight>
          <DuoTab
            varient='default'
            theme={theme}
            items={tabItems}
            defaultTabIndex={0}
          />
        </Tab>
        <Box theme={theme} className="scroll">
          <form onSubmit={handleSubmit(onSubmit)} id="vehicleForm">
            <>
              {/* Search Key word */}
              <Box className="filter-item" theme={theme}>
                <Accordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: isAssetConditionExpanded,
                      content: (
                        <Box theme={theme} className="checks-wrap">
                          {assetConditionsArray?.length > 0 &&
                            assetConditionsArray?.map((condition: any, index: number) => (
                              <Controller
                                key={index}
                                name={`asset_conditions.${condition}`}
                                control={control}
                                render={({ field }) => (
                                  <Checkbox
                                    label={condition}
                                    theme={theme}
                                    checkBoxChecked={field.value ? true : false}
                                    {...field}
                                    value={field.value ? true : false}
                                    onChange={(e) => {
                                      field.onChange(e)
                                      setIsAssetConditionExpanded(true)
                                    }}
                                  ></Checkbox>
                                )}
                              />
                            ))}
                        </Box>
                      ),
                      onCloseTab(event) {
                        handleAssetConditionClose(event, 'asset_conditions')
                      },
                      onOpenTab(event) {
                        handleAssetConditionOpen(event, 'asset_conditions')
                      },
                      title: 'Asset Condition',
                      key: 'string',
                      subtitle: isAnyCheckboxChecked(
                        watch('asset_conditions') as unknown as Record<string, boolean>
                      ) ? (
                        <>
                          <span className="ind" />
                        </>
                      ) : (
                        <></>
                      )
                    }
                  ]}
                />
              </Box>
              {/* Selling Price */}
              <Box className="filter-item" theme={theme}>
                <Accordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: isSellingPriceExpanded,
                      content: (
                        <Box theme={theme} className="checks-wrap">
                          <Grid theme={theme} container item spacing={2}>
                            <Grid theme={theme} item xs={6} md={6} lg={6}>
                              <Controller
                                name="minSellingPrice"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    theme={theme}
                                    fullWidth
                                    value={field.value ? field?.value.toLocaleString() : ''}
                                    label={'Min'}
                                    type="text"
                                    onChange={(e) => {
                                      const numericValue = parseFloat(e.replace(/[^0-9]/g, ''))
                                      if (!isNaN(numericValue)) {
                                        field.onChange(numericValue)
                                        setSliderValue([numericValue, getValues('maxSellingPrice')])
                                        setIsSellingPriceExpanded(true)
                                      } else {
                                        field.onChange(null)
                                        setSliderValue([0, getValues('maxSellingPrice')])
                                      }
                                    }}
                                    onBlur={() => {
                                      if (field.value === null) {
                                        field.onChange(null)
                                      }
                                    }}
                                    startAdornment={defaultCurrency}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid theme={theme} item xs={6} md={6} lg={6}>
                              <Controller
                                name="maxSellingPrice"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    theme={theme}
                                    fullWidth
                                    value={field.value ? field?.value.toLocaleString() : ''}
                                    label={'Max'}
                                    type="text"
                                    onChange={(e) => {
                                      const numericValue = parseFloat(e.replace(/[^0-9]/g, ''))
                                      if (!isNaN(numericValue)) {
                                        field.onChange(numericValue)
                                        setSliderValue([getValues('minSellingPrice'), numericValue])
                                        setIsSellingPriceExpanded(true)
                                      } else {
                                        field.onChange(null)
                                        setSliderValue([getValues('minSellingPrice'), 0])
                                      }
                                    }}
                                    onBlur={() => {
                                      if (field.value === null) {
                                        field.onChange(null)
                                      }
                                    }}
                                    startAdornment={defaultCurrency}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Box theme={theme} sx={{ mt: 2, width: '100%' }}>
                            <DualSlider
                              theme={theme}
                              label=""
                              value={sliderValue}
                              minValue={referenceData?.selling_price?.[0]}
                              maxValue={referenceData?.selling_price?.[1]}
                              defaultValue={sliderValue}
                              valuetext={() => ''}
                              template={(value) => `${formatCurrency(value)}`}
                              onChange={(e) => {
                                setSliderValue(e)
                                setValue('minSellingPrice', e[0])
                                setValue('maxSellingPrice', e[1])
                                setIsSellingPriceExpanded(true)
                              }}
                              steps={5}
                            ></DualSlider>
                          </Box>
                        </Box>
                      ),
                      onCloseTab(event) {
                        handleSellingPriceClose(event, 'minSellingPrice'),
                          handleSellingPriceClose(event, 'maxSellingPrice')
                      },
                      onOpenTab(event) {
                        handleSellingPriceOpen(event, 'minSellingPrice'),
                          handleSellingPriceOpen(event, 'maxSellingPrice')
                      },

                      title: 'Selling Price',
                      key: 'string',
                      // subtitle:
                      //   getValues("minSellingPrice") +
                      //   " - " +
                      //   getValues("maxSellingPrice"),
                      // actionBtn: "",
                      subtitle:
                        getValues('minSellingPrice') || getValues('maxSellingPrice') ? (
                          <>
                            <span className="ind" />
                          </>
                        ) : (
                          <></>
                        )
                    }
                  ]}
                />
              </Box>
              {/* Year */}
              <Box className="filter-item" theme={theme}>
                <Accordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: isYearExpanded,
                      content: (
                        <Box theme={theme} className="checks-wrap">
                          <Grid theme={theme} container item spacing={2}>
                            <Grid theme={theme} item xs={6} md={6} lg={6}>
                              <Controller
                                name="year_from"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    fullWidth
                                    theme={theme}
                                    label={'From'}
                                    value={field.value}
                                    type="number"
                                    onBlur={(e: any) => {
                                      if (parseInt(e.target.value) < getValues('year_to')) {
                                        var gettingYear: any = getValues('year_to')
                                        setYearSliderValue([+e.target.value, parseInt(gettingYear)])
                                        setIsYearExpanded(true)
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                            <Grid theme={theme} item xs={6} md={6} lg={6}>
                              <Controller
                                name="year_to"
                                control={control}
                                render={({ field }) => (
                                  <Input
                                    {...field}
                                    fullWidth
                                    theme={theme}
                                    label={'To'}
                                    type="number"
                                    value={field.value}
                                    onBlur={(e: any) => {
                                      if (
                                        parseInt(e.target.value) <=
                                        (validYears.length > 0 ? Math.max(...validYears) : 0)
                                      ) {
                                        var gettingYear: any = getValues('year_from')
                                        setYearSliderValue([parseInt(gettingYear), +e.target.value])
                                        setIsYearExpanded(true)
                                      }
                                    }}
                                  />
                                )}
                              />
                            </Grid>
                          </Grid>
                          <Box theme={theme} sx={{ mt: 2, width: '100%' }}>
                            <Controller
                              name={`years`}
                              control={control}
                              render={({ field }) => (
                                <DualSlider
                                  {...field}
                                  theme={theme}
                                  valuetext={() => ''}
                                  label=""
                                  value={yearSliderValue}
                                  minValue={validYears.length > 0 ? Math.min(...validYears) : 0}
                                  maxValue={validYears.length > 0 ? Math.max(...validYears) : 0}
                                  defaultValue={[
                                    validYears.length > 0 ? Math.min(...validYears) : 0,
                                    validYears.length > 0 ? Math.max(...validYears) : 0
                                  ]}
                                  onChange={(e) => {
                                    setYearSliderValue(e)
                                    setValue('year_from', e[0])
                                    setValue('year_to', e[1])
                                    setIsYearExpanded(true)
                                  }}
                                  steps={1}
                                ></DualSlider>
                              )}
                            />
                          </Box>
                        </Box>
                      ),
                      onCloseTab(event) {
                        handleYearClose(event, 'year_from')
                        handleYearClose(event, 'year_to')
                      },
                      onOpenTab(event) {
                        handleYearOpen(event, 'year_from')
                        handleYearOpen(event, 'year_to')
                      },
                      title: 'Year',
                      key: 'string',
                      subtitle:
                        getValues('year_from') || getValues('year_to') ? (
                          <>
                            <span className="ind" />
                          </>
                        ) : (
                          <></>
                        )
                    }
                  ]}
                />
              </Box>
              {/* Make / Model / Trim */}
              <Box className="filter-item" theme={theme}>
                <Accordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: isMakeModelTrimExpanded,
                      content: (
                        <Box theme={theme} className="checks-wrap multi-select">
                          <MultiSelectWrap>
                            <Controller
                              name="makes"
                              control={control}
                              render={({ field }) => (
                                <MultiSelect
                                  {...field}
                                  id={'make_id'}
                                  sxProps={{ m: 0, width: '100%' }}
                                  theme={theme}
                                  label={'Make'}
                                  items={referenceData?.result?.map((result: any) => {
                                    return {
                                      text: result.make_name,
                                      value: result.make_name
                                    }
                                  })}
                                  value={field.value || []}
                                  onChange={(e: any) => {
                                    field.onChange(e)
                                    setIsMakeModelTrimExpanded(true)
                                  }}
                                />
                              )}
                            />
                          </MultiSelectWrap>
                          <MultiSelectWrap>
                            <Controller
                              name="models"
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => (
                                <MultiSelect
                                  {...field}
                                  sxProps={{ m: 0, width: '100%' }}
                                  theme={theme}
                                  sx={{ mb: 2 }}
                                  label={'Model'}
                                  disabled={modelsAndTrimsDisabled}
                                  items={availableModels}
                                  value={field.value || []}
                                  onChange={(e) => {
                                    field.onChange(e)
                                    setIsMakeModelTrimExpanded(true)
                                  }}
                                />
                              )}
                            />
                          </MultiSelectWrap>
                          <MultiSelectWrap>
                            <Controller
                              name="trims"
                              control={control}
                              defaultValue={[]}
                              render={({ field }) => (
                                <MultiSelect
                                  {...field}
                                  sxProps={{ m: 0, width: '100%' }}
                                  theme={theme}
                                  label={'Trim'}
                                  disabled={!selectedModel.length || modelsAndTrimsDisabled}
                                  items={availableTrims}
                                  value={field.value || []}
                                  onChange={(e) => {
                                    field.onChange(e)
                                    setIsMakeModelTrimExpanded(true)
                                  }}
                                />
                              )}
                            />
                          </MultiSelectWrap>
                        </Box>
                      ),
                      onCloseTab(event) {
                        handleMakeModelClose(event, 'makes')
                        handleMakeModelClose(event, 'models')
                        handleMakeModelClose(event, 'trims')
                      },
                      onOpenTab(event) {
                        handleMakeModelTrimOpen(event, 'makes')
                        handleMakeModelTrimOpen(event, 'models')
                        handleMakeModelTrimOpen(event, 'trims')
                      },
                      title: 'Make / Model / Trim',
                      key: 'string',
                      subtitle:
                        (getValues('makes') && getValues('makes').length > 0) ||
                        (getValues('models') && getValues('models').length > 0) ||
                        (getValues('trims') && getValues('trims').length > 0) ? (
                          <>
                            <span className="ind" />
                          </>
                        ) : (
                          <></>
                        )
                    }
                  ]}
                />
              </Box>

              {/* VIN Number */}
              <Box className="filter-item" theme={theme}>
                <Accordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: isVinExpanded,
                      content: (
                        <Box theme={theme} className="checks-wrap">
                          <Controller
                            name="vin"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                {...field}
                                theme={theme}
                                fullWidth
                                label={'VIN'}
                                type={'text'}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e)
                                  setIsVinExpanded(true)
                                }}
                              />
                            )}
                          />
                        </Box>
                      ),
                      onCloseTab(event) {
                        handleVinClose(event, 'vin')
                      },
                      onOpenTab(event) {
                        handleVinOpen(event, 'vin')
                      },
                      title: 'VIN',
                      key: 'string',
                      subtitle: watch('vin') ? (
                        <>
                          <span className="ind" />
                        </>
                      ) : (
                        <></>
                      )
                    }
                  ]}
                />
              </Box>
              {/* Stock Number */}
              <Box className="filter-item" theme={theme}>
                <Accordion
                  theme={theme}
                  items={[
                    {
                      isExpanded: isStockNumberExpanded,
                      content: (
                        <Box theme={theme} className="checks-wrap">
                          <Controller
                            name="stock"
                            control={control}
                            defaultValue={''}
                            render={({ field }) => (
                              <Input
                                {...field}
                                theme={theme}
                                fullWidth
                                label={'Stock'}
                                type={'text'}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e)
                                  setIsStockNumberExpanded(true)
                                }}
                              />
                            )}
                          />
                        </Box>
                      ),
                      onCloseTab(event) {
                        handleStockNumClose(event, 'stock')
                      },
                      onOpenTab(event) {
                        handleStockNoOpen(event, 'stock')
                      },
                      title: 'Stock Number',
                      key: 'string',
                      subtitle: watch('stock') ? (
                        <>
                          <span className="ind" />
                        </>
                      ) : (
                        <></>
                      )
                    }
                  ]}
                />
              </Box>
            </>
            <Box
              mt={3}
              theme={theme}
              className="filter-item filters-cta btn-sticky"
              display={'flex'}
              alignItems={'center'}
              gap={1}
            >
              <Tooltip theme={theme} title="Reset Filters">
                <Button
                  theme={theme}
                  secondary
                  iconText={<Icon name="ResetIcon" />}
                  onClick={() => {
                    reset(defaultValue, {
                      keepValues: false,
                      keepIsSubmitted: false
                    })

                    setSliderValue([referenceData?.selling_price[0], referenceData?.selling_price[1]])
                    setYearSliderValue([
                      Math.min(...referenceData?.years),
                      Math.max(...referenceData?.years)
                    ])

                    onSubmit()
                  }}
                ></Button>
              </Tooltip>
              <Button theme={theme} type="submit" fullWidth primary text={'Apply Filters'}></Button>
            </Box>
          </form>
        </Box>
      </CustomFilters>
    </Stack>
  )
}

export default AssetFilterControl
