import React, { createContext, useState, useContext, useEffect } from 'react';
import { FormState, FormContextType } from "../../../interfaces/form-context";
import {useBeforeUnloadHandler} from "../../../hooks/order-management";
import {Blocker, useBlocker} from "react-router-dom";
import {useModal} from "react-modal-hook";
import {AlertDialog} from "@libraries";
import {useWorkflowContext} from "@app/router-workflow";

const initialState: { [key: string]: FormState } = {};

const FormContext = createContext<FormContextType>({
  formStates: initialState,
  updateFormState: () => null,
  resetFormState: () => null,
  setInitialFormState: () => null,
  finalSave: () => null,
  isAnyFormDirty: false,
  getDirtyForms: () => null,
  getFormData: () => [],
  isFormValid: () => false,
  setFormValidity: () => null,


});

export const FormProvider = ({ children }) => {
  const [formStates, setFormStates] = useState<{ [key: string]: FormState }>(initialState);
  const [isAnyFormDirty, setIsAnyFormDirty] = useState(false);
  const { getOrderWorkflow } = useWorkflowContext();

  const updateFormState = (formName: string, state: FormState) => {
    setFormStates(prevState => ({
      ...prevState,
      [formName]: { ...prevState[formName], ...state },
    }));
  };

  const resetFormState = (formName: string) => {
    setFormStates(prevState => ({
      ...prevState,
      [formName]: { ...prevState[formName], isDirty: false, data: prevState[formName].data, isValid: true, initialData: prevState[formName].data },
    }));
  };

  const setInitialFormState = (formName: string , initialData: any) => {
    blocker.proceed?.()

    setFormStates(prevState => ({
      ...prevState,
      [formName]: { ...prevState[formName], initialData, data: initialData },
    }));
  };

  const finalSave = () => {
    blocker.proceed?.()
    setFormStates({});
  };
  const getDirtyForms = () => {
    return Object.entries(formStates)
      .filter(([_, state]) => state.isDirty)
      .map(([formName, state]) => ({
        formName,
        state
      }));
  };

  const getFormData = (formName: string) => {
    return formStates[formName]?.data;
  };

  useEffect(() => {
    const anyFormDirty = Object.values(formStates).some(state => state?.isDirty);
    setIsAnyFormDirty(anyFormDirty);
  }, [formStates]);

  const setFormValidity = (formName: string, isValid: any) => {
    setFormStates(prevState => ({
      ...prevState,
      [formName]: { ...prevState[formName], isValid },
    }));
  };

  const isFormValid = (formName: string) => {
    return formStates[formName]?.isValid;
  };



    useBeforeUnloadHandler(isAnyFormDirty)
    const blocker: Blocker = useBlocker(isAnyFormDirty);
    const [showBackPopup, hideBackPopup] = useModal(
    () => (
      <AlertDialog
      title={'Are you sure you want to remove'}
      subtitle={'once removed, the details you have added will be and you have to add the details it again if needed.'}
      btnText={'Remove'}
      isOpen={true}
      onClick={confirmRemove}
      onClose={handleAlertClose}
    />
    ),
    [blocker]
    )
  useEffect(() => {
    // Reset the blocker if the user cleans the form
    if (blocker.state === 'blocked' && !isAnyFormDirty) {
      blocker?.reset();
    } else if (blocker.state === 'blocked') {
      showBackPopup();
    }
  }, [blocker, isAnyFormDirty]);
    const handleAlertClose = () => {
        blocker.reset?.()
        hideBackPopup()
    };
    const confirmRemove = () =>{
        blocker.proceed?.()
        hideBackPopup()
        getOrderWorkflow()

    }
  return (
    <FormContext.Provider value={{
      formStates,
      updateFormState,
      resetFormState,
      setInitialFormState,
      finalSave,
      isAnyFormDirty,
      getDirtyForms,
      getFormData,
      isFormValid,
      setFormValidity
    }}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => useContext(FormContext);
