import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
    '.product-info': {
      '.MuiButtonBase-root': {
        '&.icon-only': {
          padding: 0
        }
      }
    },
    '.order-confirmation-wrap': {
      '.product-card': {
        paddingBottom: 0,
        marginBottom: 24,
      }
    },
    '.pd-card': {
      marginTop: 72,
      '.product-detail-card-wrap': {
        marginBottom: 80
      }
    },
    '.card-detail': {
      '.section': {
        width: '75%',
        margin: 'auto',
        [theme.breakpoints.down('md')]: {
          width: '100%',
        }
      }
    }
  },
  '&.edit-dialog': {
    '.stack-wrap': {
      '.title-with-icon': {
        display: 'inline-block',
        marginBottom: 16,
        width: '100%',
        '.icon': {
          float: 'left',
          marginRight: 8,
          '&.icon-error': {
            path: {
              stroke: theme.palette.error.main,
            }
          }
        },
        '.MuiTypography-root': {
          float: 'left',
          marginTop: 4,
          width: 'calc(100% - 32px)',
          textAlign: 'left'
        },
      }
    }
  }
}))
