import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const LoaderPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.loader-popup-wrap': {
    height: '80vh',
    '.popup-loader': {
        position: 'relative',
        height: '50vh',
        [theme.breakpoints.down('md')]: {
          height: '50vh'
        }
    }
  }
}))
