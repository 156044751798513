import { useTheme } from '@mui/material'
import { Button, Dialog, Icon, Typography } from '@ntpkunity/controls'
import { PaymentStack, Popup, Stack } from '@components'
import { usePlaidLink } from 'react-plaid-link';
import {useContext, useEffect, useState} from "react";
import { AppContext } from '@app/context-provider';
export const PaymentPopup = (props) => {

  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;

    const options = [
        {
            id:'ach_debit',
            title: PLACEHOLDERS.PAYMENT_POPUP_BANK_ACCOUNT,
            subtitle: PLACEHOLDERS.PAYMENT_POPUP_NO_ACCOUNT_NUMBERS,
            icon: <><Icon name='LenderConfigurations'></Icon></>,
        },
        {

            id:'card',
            title: PLACEHOLDERS.PAYMENT_POPUP_CREDIT_DEBIT_CARD,
            subtitle: PLACEHOLDERS.PAYMENT_POPUP_CONVENIENT_CARD_PAYMENT,
            icon: <><Icon name='PayCardIcon'></Icon></>,
        },
        {

            id:'applePay',
            title: PLACEHOLDERS.PAYMENT_POPUP_APPLE_PAY,
            subtitle: PLACEHOLDERS.PAYMENT_POPUP_MOBILE_PAYMENT,
            icon: <><Icon name='AppleIcon' /></>,
        }
    ]
    const theme = useTheme()
    const  { openPopup, setOpenPopup, handleOpenPaymentTypeDialog, paymentTypes, linkToken, setSelectedPaymentType, setPlaidConfig} = props

    const [selectedItem, setSelected] = useState(undefined)
    const { open, ready } = usePlaidLink({
        token: linkToken,
        onSuccess: (public_token, metadata) => {
            setPlaidConfig({publicToken: public_token, metadata: metadata})
            handleOpenPaymentTypeDialog()
            setSelected(undefined)
        },
    });
    const [paymentOptions, setPaymentOptions] = useState(options)
    const handleOptionClick = (item) => {
        setSelected(item?.id ?? '')
        setSelectedPaymentType(item?.id ?? '')
    };



    useEffect(() => {
        const filteredPaymentOptions = options.filter(option =>
            paymentTypes.includes(option.id)
        );

        setPaymentOptions(filteredPaymentOptions);

    }, [paymentTypes]);

    const handleContinue = () => {
        switch (selectedItem) {

            case 'card':
                setPlaidConfig({'publicToken': null, 'metadata': null});
                handleOpenPaymentTypeDialog()
                setSelected(undefined)
                break;
            case 'ach_debit':
                if (ready && typeof open === 'function') {
                    open();
                }
                break;
            case 'applePay':
                // Handle Apple Pay click
                break;
            default:
                // Default action if none matches
                break;
        }
    }

    return (
        <>
        <Popup>
            <Dialog
                theme={theme}
                size="sm"
                title={PLACEHOLDERS.PAYMENT_POPUP_CHOOSE_PAYMENT_METHOD}
                open={openPopup}
                onCloseDialog={() => setOpenPopup(false)}
                disablePortal
                customFooter={
                    <>
                        <Button theme={theme} primary text={PLACEHOLDERS.PAYMENT_POPUP_CONTINUE} onClick={handleContinue} disabled={!selectedItem} />
                    </>
                }
                >
                <Stack bgLight paddingMd={3} paddingXs={3}>
                    <Typography theme={theme} className='text-muted' component={'small'} variant='caption'>
                        {PLACEHOLDERS.PAYMENT_POPUP_SELECT_METHOD}
                    </Typography>
                    <PaymentStack 
                        items={paymentOptions.map(item => ({
                            title: item.title,
                            subtitle: item.subtitle,
                            icon:item.icon,
                            onClick: (()=> handleOptionClick(item)),
                            selected: selectedItem === item.id
                        }))}
                    />
                </Stack>
                
            </Dialog>
        </Popup>
            
        </>
    )
}


