import { useEffect, useState } from "react";
import { useTheme } from "@mui/material";
import { PageWrap } from "./submit-order.style";
import { Box, Grid } from "@ntpkunity/controls";
import { OrderStatusLayout } from "@layouts";
import { useGetCurrencies, useGetDealerProfileByDealerCode, useVerifyAddress, useGetDealerByDealerCode } from "@hooks";
import { AssetDetailControl } from "inventory-controls";
import { useQueryClient } from "react-query";
import { OrderStatus, QueryKeys, ScheduleOptions } from "@constants";
import { ProductDetailCard } from "libraries/product-detail-card/product-detail-card.component";
import { useAppContext } from "@app/context-provider";
import { PaymentBreakDownPopup } from "libraries/payment-breakdown-popup/payment-breakdown-popup.component";
import { SnackbarUtility } from "@utilities";
import { TradeInOffer } from "./trade-in-offer/trade-in-offer.component";
import { useOrderContext } from "pages/(order-management)/order/order-context-provider";
import { NeedHelpOrder, OrderInformation, Stepper } from "@libraries";
import { useGetIntegrationByProviderName } from "hooks/order-management";
import { IntegrationProvider, LenderIntegrationType } from "constants/providers";
import dayjs from 'dayjs'

export const SubmitOrderControl = ({ snackbardispatch }: { snackbardispatch: any }) => {
  const theme = useTheme();
  const { data: currencyData } = useGetCurrencies();
  const { state: { order, isFlowExecuted }, vehicle } = useOrderContext()
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders;
  const queryClient = useQueryClient()
  const company_id = appState.tenant;
  const [currency, setCurrency] = useState<string>("");
  const [addressLocations, setAddressLocations] = useState<{ [key: string]: any }>({});
  const [tradeInPopUp, setTradeInPopUp] = useState(false)
  const [openPopup, setOpenPopup] = useState(false);
  const [openPaymentBreakdownPopup, setPaymentBreakdownPopup] = useState(false);
  const [showNeedHelp, setShowNeedHelp] = useState(false);
  const [appointmentType, setAppointmentType] = useState(null);
  const [appointmentDate, setAppointmentDate] = useState(null);
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)

  const { data: dealerProfile } = useGetDealerProfileByDealerCode(vehicle?.dealer_code);
  const { mutate: verifyAddress } = useVerifyAddress();
  const { mutate: getProvider } = useGetIntegrationByProviderName(IntegrationProvider.GOOGLE, LenderIntegrationType.ADDRESS_VALIDATION, company_id);
  const { data: dealerByDealerCode } = useGetDealerByDealerCode({ dealerCode: dealerProfile?.dealer_code, headers: { company_id } })

  useEffect(() => {
    if (order?.appointment_details) {
      const { appointment_type, appointment_date } = order.appointment_details;
      setAppointmentType(appointment_type);

      const formattedAppointmentDate = appointment_date.includes('Z')
        ? appointment_date
        : `${appointment_date}Z`;
      setAppointmentDate(dayjs(formattedAppointmentDate));
    }

    if (order) {
      setShowNeedHelp([OrderStatus.APPROVED, OrderStatus.SUBMITTED, OrderStatus.DRAFT, OrderStatus.COMPLETED, OrderStatus.CONDITIONED, OrderStatus.PendingConfirmation, OrderStatus.TradeInInspection, OrderStatus.REVIEWED].includes(order?.status as any));
    } else {
      setShowNeedHelp(false);
    }
    if (order?.status == OrderStatus.PendingTradeInConfirmation) {
      setTradeInPopUp(true)
    }
  }, [order]);

  useEffect(() => {
    if (company_id) {
      getProvider(
        {
          provider_name: IntegrationProvider.GOOGLE,
          integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
          company_id: company_id
        },
        {
          onSuccess(response: any) {
            setIsGoogleEnabled(response?.is_active)
          }
        }
      )
    }
  }, [company_id])


  useEffect(() => {
    const tenant_configuration: any = queryClient.getQueryData(
      QueryKeys.TENANT_CONFIGURATION
    );
    const defaultCurrency = currencyData?.find(
      (currency: any) =>
        currency.id === tenant_configuration?.data?.default_currency
    )?.symbol;
    setCurrency(defaultCurrency);
  }, [currencyData]);

  const getVerifyGoogleAddress = (address: string, addressType: string) => {
    verifyAddress(
      {
        address: address,
      },
      {
        onSuccess: (data) => {
          setAddressLocations(prevLocations => ({ ...prevLocations, [addressType]: data?.results?.[0]?.geometry?.location }));
        },
      }
    );
  };

  useEffect(() => {
    if (isGoogleEnabled) {

      if (order?.appointment_details?.address?.location) {
        const location = order?.appointment_details?.address?.location;
        setAddressLocations(prevLocations => ({ ...prevLocations, 'customer': location }));
      } else {
        const addressStr = `${order?.appointment_details?.address?.address_line_1} ${order?.appointment_details?.address?.address_line_2} ${order?.appointment_details?.address?.city} ${order?.appointment_details?.address?.state_name}`;
        getVerifyGoogleAddress(addressStr, 'customer');
      }

      if (dealerProfile?.dealer_address) {
        const dealerAddress = `${dealerProfile?.dealer_address?.address_line_1} ${dealerProfile?.dealer_address?.address_line_2} ${dealerProfile?.dealer_address?.city} ${dealerProfile?.dealer_address?.state_name}`;
        getVerifyGoogleAddress(dealerAddress, 'dealer');
      }

    }
  }, [order?.appointment_details, dealerProfile?.dealer_address, isGoogleEnabled]);

  const handleCallButtonClick = () => {
    const phoneNumber = dealerProfile?.contact_number;
    if (phoneNumber) {
      window.open(`tel:${phoneNumber}`, '_blank');
    } else {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.SUBMIT_ORDER_CONTACT_UNAVAILABLE))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
    }
  };

  const handleGetDirectionsClick = () => {
    if (addressLocations?.dealer) {
      window.open(`https://maps.google.com?q=${addressLocations?.dealer?.lat},${addressLocations?.dealer?.lng}`, '_blank');
    }
    else {
      snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.SUBMIT_ORDER_ADDRESS_UNAVAILABLE))
      setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()) }, 3000);
    }
  };

  const handleDealerNameLink = () => {
    let url = dealerProfile?.website;
    if (!url.startsWith('http')) {
      url = 'https://' + url;
    }
    window.open(url, '_blank');
  }

  useEffect(() => {
    if (isFlowExecuted)
      setTradeInPopUp(false)
  }, [isFlowExecuted])

  return (
    <>
      {
        tradeInPopUp ?
          <TradeInOffer
            open={tradeInPopUp}
            defaultCurrency={currency}
          /> :
          <OrderStatusLayout>
            <PageWrap theme={theme} className="page-wrap">
              <OrderInformation
                vehicle={vehicle}
                setOpenPopup={setOpenPopup}
                currency={currency}
                setPaymentBreakdownPopup={setPaymentBreakdownPopup}
                dealerProfile={dealerProfile}
                handleCallButtonClick={handleCallButtonClick}
                handleGetDirectionsClick={handleGetDirectionsClick}
                handleDealerNameLink={handleDealerNameLink}
                dealerByDealerCode={dealerByDealerCode}
              />
              <Box theme={theme} maxWidth={896} margin={"auto"} className="pd-card">
                {order?.status === OrderStatus.COMPLETED &&
                  (appointmentType == ScheduleOptions.PICKUP ? (
                    <ProductDetailCard
                      title={PLACEHOLDERS.SCHEDULE_OPTIONS_PICKUP_TITLE}
                      subTtile={PLACEHOLDERS.SUBMIT_ORDER_FREE}
                      caption={`${dealerProfile?.dealer_address.address_line_1}, ${dealerProfile?.dealer_address.city}, ${dealerProfile?.dealer_address.state_name}, ${dealerProfile?.dealer_address.zip_code}, ${PLACEHOLDERS.UNITED_STATES_TEXT}`}
                      listItems={[
                        {
                          title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_SCHEDULED_ON,
                          value:
                            dayjs(appointmentDate)?.format("MMMM") +
                            " " +
                            dayjs(appointmentDate)?.format("DD") +
                            "," +
                            dayjs(appointmentDate)?.format("YYYY") +
                            ", " +
                            dayjs(appointmentDate)?.format("hh:mm") +
                            " " +
                            dayjs(appointmentDate)?.format("A"),
                        },
                        {
                          title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_CONTACT_NUMBER,
                          value: dealerProfile?.contact_number || "-",
                        },
                      ]}
                    />
                  ) : (
                    <ProductDetailCard
                      title={PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_TITLE}
                      subTtile={PLACEHOLDERS.SUBMIT_ORDER_FREE}
                      caption={`${order?.appointment_details?.address?.address_line_1}, ${order?.appointment_details?.address?.city}, ${order?.appointment_details?.address?.state_name}, ${order?.appointment_details?.address?.zip_code}, ${PLACEHOLDERS.UNITED_STATES_TEXT}`}
                      listItems={[
                        {
                          title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_SCHEDULED_ON,
                          value:
                            dayjs(appointmentDate)?.format("MMMM") +
                            " " +
                            dayjs(appointmentDate)?.format("DD") +
                            "," +
                            dayjs(appointmentDate)?.format("YYYY") +
                            ", " +
                            dayjs(appointmentDate)?.format("hh:mm") +
                            " " +
                            dayjs(appointmentDate)?.format("A"),
                        }
                      ]}
                    />
                  ))}
                <Grid theme={theme} container item rowSpacing={3} columnSpacing={15}>
                  {!(order?.status === OrderStatus.CANCELLED || order?.status === OrderStatus.DECLINED)&& (
                    <>
                      <Stepper currency={currency} />
                      {showNeedHelp && (
                        <NeedHelpOrder
                          vehicle={vehicle}
                          setOpenPopup={setOpenPopup}
                          dealerProfile={dealerProfile}
                          handleCallButtonClick={handleCallButtonClick}
                          handleGetDirectionsClick={handleGetDirectionsClick}
                          handleDealerNameLink={handleDealerNameLink}
                          dealerByDealerCode={dealerByDealerCode}
                        />
                      )}
                    </>
                  )}
                </Grid>
              </Box>
              {openPopup && (
                <AssetDetailControl
                  theme={theme}
                  selectedVehicle={{ vehicle }}
                  defaultCurrency={currency}
                  openPopup={openPopup}
                  setOpenPopup={setOpenPopup}
                  hideFooter={true}
                  onClick={() => { }}
                />
              )}

              {openPaymentBreakdownPopup && (
                <PaymentBreakDownPopup
                  defaultCurrency={currency}
                  onCloseDialog={() => setPaymentBreakdownPopup(false)}
                  order_info={order}
                />
              )}

            </PageWrap>
          </OrderStatusLayout>
      }
    </>
  );
};