import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const GradiantTextWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.order-confirmation-wrap': {
      '.product-bg-text': {
        maxWidth: '100%',
        maxHeight: 242,
        height: '100%',
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('md')]: {
          top: 120
        },
        [theme.breakpoints.down('sm')]: {
          top: 125
        },
        '.bg-text': {
          fontSize: 200,
          whiteSpace: 'nowrap',
          overFlow: 'hidden',
          textOverflow: 'ellipses',
          maxWidth: 801,
          maxHeight: 242,
          width: '100%',
          height: '100%',
          textAlign: 'center',
          background: 'linear-gradient(180deg, #AEB1BF 0%, rgba(174, 177, 191, 0.00) 80.17%)',
          '-webkitBackgroundClip': 'text',
          '-webkitTextFillColor': 'transparent',
          lineHeight: 'normal',
          [theme.breakpoints.down('md')]: {
            fontSize: 130,
          },
          [theme.breakpoints.down('sm')]: {
            fontSize: 76,
          },
        }
      },
    }
}))
