import React, { FC, useEffect, useState } from 'react';
import { GoogleMap, LoadScript, Marker } from '@react-google-maps/api';
import { useGetIntegrationConfiguration } from '../../hooks/order-management';
import { IntegrationProvider, LenderIntegrationType } from '../../constants/providers';
import { useAppContext } from '../../app/context-provider';


export const MapContainer: FC<{ location?: any }> = ({ location }) => {
  const { mutateAsync: getProvider } = useGetIntegrationConfiguration();
  const { state: appState } = useAppContext();
  const [apiKey, setApiKey] = useState<string | null>(null);

  useEffect(() => {
    const fetchApiKey = async () => {
      try {
        const dealerInformation = localStorage.getItem('dealer_information');
        let dealerCode = '';
        if (dealerInformation) {
          const dealerData = JSON.parse(dealerInformation);
          dealerCode = dealerData.dealer.dealer_code;
        }

        const response = await getProvider({
          provider_name: IntegrationProvider.GOOGLE,
          integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
          company_id: appState.tenant,
          dealer_code: dealerCode
        });

        if (response?.api_key) {
          setApiKey(response.api_key);
        }
      } catch (error) {
        console.error('Error fetching API key:', error);
      }
    };

    fetchApiKey();
  }, [getProvider, appState.tenant]);

  const mapStyles = {
    aspectRatio: '16/9'
  };

  const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    fullscreenControl: false,
    streetViewControl: false,
    mapTypeControl: false
  };

  return (
    <>

       {apiKey ? (
        <LoadScript googleMapsApiKey={apiKey}>
          <GoogleMap
            mapContainerStyle={mapStyles}
            zoom={10}
            center={location}
            options={mapOptions}
          >
            <Marker position={location} />
          </GoogleMap>
        </LoadScript>
      ):null}
    </>
  );
};
