import React, {useEffect} from "react";
import {ContactDetailsComponent} from "../../../../libraries/financing-control/ContactDetails";
import {useCustomDirtyForm} from "../../../../hooks/order-management";
import {useFormContext} from "../../../(order-management)/order/form-context-provider";
const getContactData = (contactData: any) => {
  return {
    prefix: contactData?.prefix,
    first_name: contactData?.first_name,
    middle_name: contactData?.middle_name,
    last_name: contactData?.last_name,
    ssn: contactData?.ssn,
    suffix: contactData?.suffix,
    home_number: contactData?.home_number,
    mobile_number: contactData?.mobile_number,
    email: contactData?.email,
  };
};
const ContactDetailsWrapper = ({
      customerReferenceId,
      contactData,
      setTab,
      contactDetailForm,
      watchContactFields}) => {
    const { formStates } = useFormContext();

    useCustomDirtyForm('ContactDetails', watchContactFields, contactDetailForm?.formState?.isValid);

      useEffect(() => {

          if(contactData){
              contactDetailForm?.reset(formStates?.['ContactDetails']?.data || getContactData(contactData));
          }

  }, [contactData]);
  return (
    <ContactDetailsComponent
      customerReferenceId={customerReferenceId}
      contactData={contactData}
      setTab={setTab}
      form={contactDetailForm}
    />
  );
};

export default ContactDetailsWrapper;
