import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const TradeInPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.trade-in-popup-wrap': {
    '.has-header-footer': {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        height: 'calc(100vh - 240px)',
        maxHeight: 512,
        '.stack-wrap': {
            '&:last-child': {
                flexGrow: 1
            }
        },
        [theme.breakpoints.down('sm')]: {
          maxHeight: '100%'
        }
    },
    '.no-header-footer': {
        height: 'calc(100vh - 120px)',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        maxHeight: 630,
        [theme.breakpoints.down('sm')]: {
          maxHeight: '100%'
        }
    }
  }
}))
