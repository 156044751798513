import { Endpoint } from "@constants";
import { http } from "../utilities";
import { CONFIG_API_GATEWAY } from "./order-management";

export const DMS_BASE_URL = process.env.DMS_BASE_URL;

export namespace DealerManagement {
  export const getDealerPreference = async ({
    dealerCode,
  }: {
    dealerCode: string;
  }) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.DEALER_PREFERENCE_BY_DEALER_CODE}/${dealerCode}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getDealerByDealerCode = async(data: any) => {
    const {dealerCode, headers} = data;
    return http(CONFIG_API_GATEWAY)
      .get(`${Endpoint.DEALER}/${dealerCode}`, {
        headers
      })
      .then((res) => {
        return res.data;
      })
  };



  export const getDealerFeesByState = async ({
    dealerCode,
    stateCode,
  }: {
    dealerCode: string;
    stateCode: string;
  }) => {
    const queryParams = `state_code=${stateCode}`;
    return http(DMS_BASE_URL)
      .get(`${Endpoint.GET_DEALER_FEE_BY_STATE}/${dealerCode}?${queryParams}`)
      .then((res) => {
        return res.data;
      });
  };
}
