import { Endpoint } from '@constants';
import { http } from '@utilities';
import { EstimateProvider } from 'constants/enums';


export const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;
export const DMS_BASE_URL = process.env.DMS_BASE_URL;

export namespace TradeInManagement {
    export const getQuestionsAndAnswers = (body, company_id:{company_id: string} ): any => {
        const headers = {
            'company_id ': company_id
        }
        return http(CONFIG_BASE_URL, headers).post(`${Endpoint.GET_CHECKLIST_QUESTIONS}`,body).then((res) => {
            return res.data
        })
    }
	
    export const getConfiguration = (dealer_code:{dealer_code:string},integration_type:{integration_type:string}, provider_name: { provider_name: string }, company_id:{company_id: string}): any => {
        const LenderIDHeader = {
            'company_id ': company_id
        }
        return http(DMS_BASE_URL,LenderIDHeader).get(`${Endpoint.CHECK_VEHICLE_ESTIMATION_PROVIDER}${dealer_code}/${integration_type}/${provider_name}`).then((res) => {
            return res.data
        })
    }


    // optional header for KBB
    const KBBHeader = {
        'x-service-provider': 'KBB'
    }
    export const getTradeInThroughVin = (body): any => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.GET_VEHICLE_DETAILS_BY_VIN}`,body).then((res) => {
            return res.data
        })
    }

    export const getTradeInYear = (body) => {
                const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.EP_GET_TRADE_IN_YEAR}`, body).then((res) => {
            return res.data
        })
    }

    export const getTradinModel = (body) => {
                const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.EP_GET_TRADE_IN_MODEL}`, body).then((res) => {
            return res.data
        })
    }

    export const getTradeInMake = (body) => {
                const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.EP_GET_TRADE_IN_MAKE}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInTrim = (body) => {
                const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.EP_GET_TRADE_IN_TRIM}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInVehicleDetail = (body) => {
                const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.EP_GET_TRADE_IN_VEHICLE_DETAIL}`, body).then((res) => {
            return res.data
        })
    }
    export const getTradeInVehicleValue = (body) => {
                const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
        KBBHeader["x-dealer-code"] = dealer_code
        return http(DMS_BASE_URL, KBBHeader).post(`${Endpoint.EP_GET_TRADE_IN_VEHICLE_VALUE}`, body).then((res) => {
            return res.data
        })
    }

}
