import { useTheme } from '@mui/material'
import { Box, Button, Dialog, Typography, } from '@ntpkunity/controls'
import { Popup } from '@components'
import React, {FC, useContext} from 'react'
import { AppContext } from '@app/context-provider'
import {useQueryClient} from "react-query";
import {ConsentStatus, QueryKeys} from "@constants";
import {DisclaimerNames} from "../../constants/consent-status";
import DisableLoader from "../../public/assets/images/loader-disabled-btn.gif";
import { useParams } from 'react-router-dom'
import { useAddCustomerConsent } from 'hooks/authentication-management'

export const StripPaymentConfirmationPopup:FC<{
    openPopup: boolean,
    setOpenPopup: any,
    setConfirmation: any,
    disclaimers: any,
    referenceNumber: any,
    customerData: any
}> = ({openPopup, setOpenPopup, setConfirmation, disclaimers, referenceNumber, customerData}) => {
    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders;
    const { tenant } = useParams()

    const { mutate: addCustomerConsent, isLoading: addConsentLoading } = useAddCustomerConsent()
    const queryClient = useQueryClient();

    const handleCloseDialog = () => {
        setOpenPopup(false)
    }

    const handleConsentAcceptance = () => {

        const disclaimer = (queryClient
            .getQueryData(QueryKeys.GET_TENEANT_CONSENT) as any[])
            ?.find(d => d.status === ConsentStatus.PUBLISHED &&
                [DisclaimerNames.PAYMENT_CONSENT].includes(d.name)
            )
            addCustomerConsent({
                customer_reference_id: customerData?.reference_id,
                disclaimer_name: disclaimer.name,
                disclaimer_version: disclaimer?.version,
                order_reference_id: referenceNumber

            },{
                onSuccess:() => {
                    setConfirmation()
                    queryClient.invalidateQueries({ queryKey: [QueryKeys.GET_CUSTOMER_CONSENTS] });

                }
            })

    }

    return(
        <Popup>
            <Dialog
                theme={theme}
                size="sm"
                open={openPopup}
                onCloseDialog={() => handleCloseDialog()}
                disablePortal
                customFooter={
                    <>
                            <Button
                                theme={theme}
                                primary
                                text={PLACEHOLDERS.STRIPE_PAYMENT_CONFIRM_PROCEED}
                                onClick={() => handleConsentAcceptance()}
                                disabled={addConsentLoading}
                                startIcon={
                                    (addConsentLoading) &&
                                    <img src={DisableLoader} alt="Loader" />
                                }
                            />
                    </>
                }
                >
                <Box theme={theme} className='text-center' pt={3} pl={{xs: 2, md: 0}} pr={{xs: 2, md: 0}}>
                    <Typography theme={theme} component={'h2'} variant='h2'
                                marginBottom={'16px'}>{PLACEHOLDERS.STRIPE_PAYMENT_GET_STARTED_PAYMENTS}</Typography>
                    <Typography theme={theme} className='text-muted' component={'p'} variant='body2'>
                    <div
                        dangerouslySetInnerHTML
                            ={{__html: disclaimers?.[DisclaimerNames.PAYMENT_CONSENT] as string}}></div>
                    </Typography>
                </Box>
            </Dialog>
        </Popup>
    )
}


