import React, { FC } from 'react';
import { FooterWrap } from './footer.style'
import { useTheme } from '@mui/material'
import { Box, Grid, Icon, Typography } from '@ntpkunity/controls';
import { useAppContext } from '@app/context-provider';
import {formatUrl} from 'helpers/methods'
import clsx from 'clsx'

export const Footer: FC = () => {
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const PLACEHOLDERS = appState?.language?.placeholders
  const collapse = appState.collapse_state
  const theme = useTheme()
  const currentYear = new Date().getFullYear()
  const { social_media_icons } = appState;
  const hasSocialMedia = Object.values(social_media_icons).some(icon => icon);
  return (
    <>
      <FooterWrap theme={theme} className={clsx('footer-wrap', collapse ? 'collapse' : '')}>
        <Grid theme={theme} container item spacing={{ xs: 3, sm: 5 }}>
          <Grid theme={theme} item xs={12} sm={4}>
            <Typography theme={theme} component={'span'} variant='body2' className='text-muted' display={'block'}>
              &copy; {currentYear} AppexNow.   {PLACEHOLDERS?.FOOTER_ALL_RIGHT_RESERVED}
            </Typography>
          </Grid>
      {hasSocialMedia && (
          <Grid theme={theme} item xs={12} sm={8}>
            <Box theme={theme} display={'flex'} alignItems={'center'} gap={4} justifyContent={{ sm: 'flex-end' }} className='social-links'>
              {appState.social_media_icons.facebook && (
                <a href={formatUrl(appState.social_media_icons.facebook)} target='_blank' rel='noopener noreferrer'>
                  <Icon name="FacebookIcon" />
                </a>
              )}
              {appState.social_media_icons.instagram && (
                <a href={formatUrl(appState.social_media_icons.instagram)} target='_blank' rel='noopener noreferrer'>
                  <Icon name="InstagramIcon" />
                </a>
              )}
              {appState.social_media_icons.linkedin && (
                <a href={formatUrl(appState.social_media_icons.linkedin)} target='_blank' rel='noopener noreferrer'>
                  <Icon className='ic-16' name="LinkedInIcon" />
                </a>
              )}
              {appState.social_media_icons.twitter && (
                <a href={formatUrl(appState.social_media_icons.twitter)} target='_blank' rel='noopener noreferrer'>
                  <Icon className='ic-16' name="TwitterXIcon" />
                </a>
              )}
              {appState.social_media_icons.google && (
                <a href={formatUrl(appState.social_media_icons.google)} target='_blank' rel='noopener noreferrer'>
                  <Icon className='ic-16' name="GoogleIcon" />
                </a>
              )}
              {appState.social_media_icons.youtube && (
                <a href={formatUrl(appState.social_media_icons.youtube)} target='_blank' rel='noopener noreferrer'>
                  <Icon name="YoutubeIcon" />
                </a>
              )}
            </Box>
          </Grid>
        )}
        </Grid>
      </FooterWrap>

      <FooterWrap theme={theme} className='footer-wrap' display={'none'}>
        <Grid theme={theme} container item spacing={{ xs: 0, sm: 3 }}>
          <Grid theme={theme} item xs={12} sm={8}>
            <Box theme={theme} display={'flex'} alignItems={'center'} gap={3} className='nav-links'>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  About US
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  Meet Our Team
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  Service Info
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  Site Map
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  Contact Us
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  Privacy Policy
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  CCPA
                </a>
              </Typography>
              <Typography theme={theme} component={'span'} variant='body2' className='nav-link' display={'block'}>
                <a href='javascript:;' target='_blank' rel='noopener noreferrer'>
                  Accessibilty
                </a>
              </Typography>
            </Box>
          </Grid>
          <Grid theme={theme} item xs={12} sm={4}>
            <Typography theme={theme} component={'span'} variant='body2' className='text-muted all-right' display={'block'}>
              &copy; 2024 AppexNow. All Rights Reserved
            </Typography>
          </Grid>
          <Grid theme={theme} item xs={12}>
            <Box theme={theme} display={'flex'} alignItems={'center'} gap={4} className='social-links'>
                <a href="" target='_blank' rel='noopener noreferrer'>
                  <Icon name="FacebookIcon" />
                </a>
              
                <a href="" target='_blank' rel='noopener noreferrer'>
                  <Icon name="InstagramIcon" />
                </a>
              
                <a href="" target='_blank' rel='noopener noreferrer'>
                  <Icon className='ic-16' name="LinkedInIcon" />
                </a>
              
                <a href="" target='_blank' rel='noopener noreferrer'>
                  <Icon className='ic-16' name="TwitterXIcon" />
                </a>
              
                <a href="" target='_blank' rel='noopener noreferrer'>
                  <Icon className='ic-16' name="GoogleIcon" />
                </a>
              
                <a href="" target='_blank' rel='noopener noreferrer'>
                  <Icon name="YoutubeIcon" />
                </a>
              
            </Box>
          </Grid>
        </Grid>
      </FooterWrap>
    </>
  )
}
