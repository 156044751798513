import { useState, useEffect } from 'react';

export const useAuthentication = () => {
  const [authenticationStatus, setAuthenticationStatus] = useState(false);
  const token = localStorage.getItem('access_token');

  useEffect(() => {
    // Function to check if token is present
    const checkToken = () => {
      setAuthenticationStatus(!!token);
    };

    // Check token on mount
    checkToken();

    // Set up event listener for localStorage changes
    window.addEventListener('storage', checkToken);

    // Clean up event listener on unmount
    return () => {
      window.removeEventListener('storage', checkToken);
    };
  }, [token]);

  return { authenticationStatus };
}