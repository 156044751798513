import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PaymentBreakDownPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.payment-breakdown-popup-wrap': {
    '.ellipsis-group': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      '.text-ellipsis': {
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        display: 'block'
      }
    }
  }
}))
