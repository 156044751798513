import { styled } from '@mui/material/styles'
import { Box, IGridProps } from '@ntpkunity/controls'

export const RightPanel = styled('div')(({ theme }) => ({
    '&.right-content': {
      transition: 'all 0.5s',
      marginLeft: 350,
      maxWidth: 'calc(100% - 350px)',
      '&.expand': {
        marginLeft: 0,
        maxWidth: '100%'
      },
      [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        maxWidth: '100%'
      }
    }
}))

export const FilterControl = styled('div')(({ theme }) => ({
  '&.filters-control': {
    // borderBottom: '1px solid' + theme.palette.grey[100]
    paddingTop: '24px',
    paddingBottom: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: 24,
    '.location': {
      display: 'flex',
      alignItems: 'center',
      gap: 24,
    },
    '.select': {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      '.u-form-group': {
        '.u-form-control': {
            height: '24px',
            '&.u-select': {
                '.MuiInputBase-input': {
                    padding: '0px 32px 0px 0px',
                    fontSize: theme.typography.fontSize,
                    fontWeight: theme.typography.fontWeightMedium,
                    color: theme.palette.grey[900]
                },
                '.MuiSelect-icon': {
                    right: 0
                },
            },
            '.MuiOutlinedInput-notchedOutline': {
                border: 'none'
            }
        },
        '.MuiPopover-root': {
          '.MuiPaper-root': {
              top: '42px !important',
              textAlign: 'left'
          }
        },
        '.u-help-text': {
          marginTop: 0,
        },
      },
    }
  }
}))