import { useMutation, useQuery } from "react-query";
import { WorkFlowManagement } from '../apis'

export const useWorkFlowMutation = () => {
  return useMutation(WorkFlowManagement.getWorkflowBySlug)
}

export const useExistingWorkFlowMutation = () => {
  return useMutation(WorkFlowManagement.getWorkflowStatus)
}

export const useWorkFlowNextStep = () => {
  return useMutation(WorkFlowManagement.getWorkflowNextStep)
}