import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductAddCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-detail': {
        '.cj-card': {
            padding: '24px 24px 24px 32px',
            borderRadius: 16,
            border: '1px solid ' + theme.palette.grey[100],
            // transition: '0.4s',
            [theme.breakpoints.down('md')]:{
                padding: '24px',
            },
            '.card-action': {
                '.btn-mobile': {
                    display: 'none'
                },
                '.btn-desktop': {
                    display: 'flex',
                    width: '120px'
                },
                [theme.breakpoints.down('md')]: {
                    '.btn-mobile': {
                        display: 'flex'
                    },
                    '.btn-desktop': {
                        display: 'none'
                    },
                }
            },
            '&.selected': {
                border: '2px solid',
                borderColor: theme.palette.primary.main 
            }
        }
    }
}))
