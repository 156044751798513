import { http } from '../utilities'
import { Endpoint } from '@constants';

export const DMS_BASE_URL = process.env.DMS_BASE_URL;

export namespace ContractManagement {
  export const getDocumentPackages = async (payload) => {
    const query = `reference_id=${payload.referenceId}&external_order_id=${payload.orderId}&document_type=${payload.documentType[0]}&document_type=${payload.documentType[1]}`
    return http(DMS_BASE_URL).get(`${Endpoint.GET_ALL_DOCUMENT_PACKAGES}?${query}`).then((res) => { return res.data });
  };

  export const getPresSignedUrl = async (payload) => {
    return http(DMS_BASE_URL).get(`${Endpoint.GET_PRE_SIGNED_URL}/${payload.key}`).then((res) => { return res.data });
  };

  export const signDocumentPackage = async (payload) => {
    return http(DMS_BASE_URL).post(`${Endpoint.SIGN_DOCUMENT_BY_ROLE}/${payload?.document_package_identifier}/${payload?.document_identifier}/${payload?.signature_id}/${payload.signer_role}?date_time_format=YYYY%2FMM%2FDD&time_zone=UTC`).then((res) => { return res.data });
  };

  export const updateCustomerSignature = async (payload) => {
    const query = `reference_id=${payload.reference_id}`
    return http(DMS_BASE_URL).patch(`${Endpoint.CUSTOMER_SIGNATURE}?${query}`, payload).then((res) => { return res.data });
  };

}