import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CjTabWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-tab-wrap': {
    '.TabsUnstyled-horizontal': {
      '.tab-list-default': {
        padding: 0,
        borderRadius: 16,
        overflow: 'auto',
        justifyContent: 'flex-start',
        backgroundColor: theme.palette.common.white,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0
        }
      },
    },
    
    '.default-varient-btn': {
        padding: '12px 20px',
        color: theme.palette.grey[900],
        fontWeight: theme.typography.fontWeightMedium,
        borderRadius: 16,
        lineHeight: theme.typography.body1.lineHeight,
        fontFamily: theme.typography.fontFamily,
        whiteSpace: 'nowrap',
        position: 'relative',
        gap: 8,
        '&.Mui-selected': {
            backgroundColor: theme.palette.grey[700],
            fontWeight: theme.customVariables.fontWeightSemiBold,
            boxShadow: 'none',
            color: theme.palette.common.white
        },
    },
    '&.auto-width': {
      '.default-varient-btn': {
        width: 'auto'
      }
    },
    '&.has-border': {
      '.default-varient-btn': {
        '&::before': {
          display: 'block',
          content: '" "',
          backgroundColor: theme.palette.grey[50],
          width: 1,
          height: 20,
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '&.Mui-selected': {
          '&::before': {
            display: 'none'
          }
        },
        '&:first-child': {
          '&::before': {
            display: 'none'
          }
        }
      },
      '.u-tab-btn': {
        '&::before': {
          display: 'block',
          content: '" "',
          backgroundColor: theme.palette.grey[50],
          width: 1,
          height: 20,
          position: 'absolute',
          left: 0,
          top: '50%',
          transform: 'translateY(-50%)'
        },
        '&.Mui-selected': {
          '&::before': {
            display: 'none'
          }
        },
        '&:first-child': {
          '&::before': {
            display: 'none'
          }
        }
      }
    },
    '&.bg-light': {
      '.TabsUnstyled-horizontal': {
        '.tab-list-default': {
          backgroundColor: '#F5F5F7',
        },
      },
    },
    '.u-scrollable-tabs': {
      '.tab-list-underline': {
        borderBottom: 'none',
        backgroundColor: theme.palette.common.white,
        borderRadius: 16
      },
      '.u-tab-btn': {
        padding: '12px 20px',
        color: theme.palette.grey[900],
        fontWeight: theme.typography.fontWeightMedium,
        borderRadius: 16,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body1.lineHeight,
        fontFamily: theme.typography.fontFamily,
        whiteSpace: 'nowrap',
        position: 'relative',
        marginRight: 0,
        flexDirection: 'row',
        gap: 8,
        '.icon': {
          width: 8,
          height: 8,
          borderRadius: 16,
          'path': {
            stroke: 'none'
          },
          '&.icon-green': {
            backgroundColor: theme.palette.success.main
          },
          '&.icon-error': {
            backgroundColor: theme.palette.error.main
          }
        },
        '&.Mui-selected': {
            backgroundColor: theme.palette.grey[700],
            fontWeight: theme.customVariables.fontWeightSemiBold,
            boxShadow: 'none',
            color: theme.palette.common.white
        }
      },
      '.MuiTabs-indicator': {
        display: 'none'
      }
    }
  }
}))
