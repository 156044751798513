import React, { useContext } from 'react'
import { useTheme } from '@mui/material'
import { Stack, SelectionButton } from '../../../components'
import { Skeleton, Typography } from '@ntpkunity/controls'
import { FC } from 'react'
import { Translations } from '../../../constants'
import { AppContext } from '@app/context-provider'

export const Apr: FC<{ apr: number, setupDataLoading }> = ({ apr, setupDataLoading }) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  return (
    <Stack marginTopXs={3} className='scroll-horizontal'>
      <Typography theme={theme} component={'p'} variant='subtitle1' marginBottom={'12px'}>{PLACEHOLDERS.PAYMENT_APR_TEXT}</Typography>
      {setupDataLoading ? <Skeleton theme={theme} width={'100%'} height={40} /> :
        <SelectionButton
          buttonItems={
            [{
              title: `${apr}%`,
              bgLight: true,
              isSelected: true,
            }]
          }
        />
      }
    </Stack>
  )
}