import { useGetCustomerByEmail, useGetVehicleByVin, useGetCurrencies, useGetDealerProfileByDealerCode, useGetStates, useGetOrderInformation, useWorkFlowNextStep } from "hooks";
import { useParams} from "react-router-dom";
import { useEffect, useState } from 'react'
import { loadDefaultCurrencyConfigurations, useAppContext } from '@app/context-provider'
import { useOrderContext } from 'pages'
import { addCustomerReferenceId, getOrder} from './order-context-provider'

import { PreOrderJourneyLayoutComponent } from "../../../layouts/journey-layout/pre-order-journey-layout.component";
import { PostOrderJourneyLayoutComponent } from "../../../layouts/journey-layout/post-order-journey-layout.component";
import { CircleLoader } from "@ntpkunity/controls";
import { useTheme } from "@mui/material";
import { Status } from "../../../constants/enums";
import { JourneyLayoutContextProvider } from "../../../layouts/journey-layout/journey-layout-context-provider";
import {areArraysEqual} from "../../../helpers/methods";
import {FormProvider} from "./form-context-provider";

export const OrderPage = () => {
  const theme = useTheme()
  const { vin } = useParams()
  const { orderId } = useParams()
  const { state: appState, dispatch: appDispatch } = useAppContext()
  const { state: orderState, dispatch: orderDispatch } = useOrderContext()

  const data = JSON.parse(localStorage.getItem('workflow') || '{}')


  const [showPopup, setShowPopup] = useState(false);
  const [orderTabs, setOrderTabs] = useState([]);
  const [nextTab, setNextTab] = useState('');

  const { data: vehicle, isLoading: vehicleLoading } = useGetVehicleByVin(vin)
  const { data: orderData, isLoading: orderLoading } = useGetOrderInformation(orderId)
  const { data: currencyData } = useGetCurrencies()
  const { data: customerData } = useGetCustomerByEmail(JSON.parse(localStorage.getItem("settings"))?.email);
  const { data: dealerProfile } = useGetDealerProfileByDealerCode(vehicle?.dealer_code);

  useGetDealerProfileByDealerCode(vehicle?.dealer_code);
  useGetStates()

  useEffect(() => {
    if (orderData) {
      orderDispatch(getOrder(orderData))
    }
  }, [orderData])

  useEffect(() => {
    if (customerData) {
      orderDispatch(addCustomerReferenceId(customerData?.reference_id))
    }
  }, [customerData])

  useEffect(() => {
    const defaultCurrency = currencyData?.find(
      (currency: any) => currency.id === appState.default_currency
    );
    appDispatch(loadDefaultCurrencyConfigurations(defaultCurrency));
  }, [currencyData]);

  const handleLinkClick = () => {
    setShowPopup(true);
  };

  const handleCloseDialog = () => {
    setShowPopup(false);
  };

  const onNextTabClick = () => {
    const currentTabIndex = orderTabs?.findIndex((item) => item == orderState.activeTab);
    const nextTab = orderTabs?.length > currentTabIndex + 1 ? orderTabs[currentTabIndex + 1] : ''
    setNextTab(nextTab)
  }

  useEffect(() => {
    const workflow: any = JSON.parse(localStorage.getItem('workflow'))
    if (workflow?.meta_data?.tabs && !areArraysEqual(workflow?.meta_data?.tabs, orderTabs)) {
      setOrderTabs(workflow?.meta_data?.tabs)
    }
  } )




  const commonProps = {
    vehicle,
    showPopup,
    orderTabs,
    nextTab,
    handleLinkClick,
    handleCloseDialog,
    onNextTabClick,
    setNextTab
  };

  return (
    <JourneyLayoutContextProvider>
      {orderLoading ? <CircleLoader theme={theme} size='lg' /> :
        orderData?.status === Status.Approved ?
          <PostOrderJourneyLayoutComponent
            {...commonProps}
            children
          />
          :
            <FormProvider>
          <PreOrderJourneyLayoutComponent
            {...commonProps}
            children
          />
              </FormProvider>
      }
    </JourneyLayoutContextProvider>
  )
}
