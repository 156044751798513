import { useMutation, useQuery, useQueryClient } from "react-query";
import { InsuranceManagement } from "../apis";
import { QueryKeys } from "@constants";

export const useAddWayInsurance = (): any => {
  return useMutation<any>((body: any) => {
    return InsuranceManagement.addWayInsurance(body);
  });
};

export const useAddTransactionId = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
    return InsuranceManagement.addTransactionId(body);
  }, {
    onSuccess: (response: any) => {
      queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, response?.order_reference_id])
    }
  });
};
