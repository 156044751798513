import { useTheme } from '@mui/material'
import { StepWrap } from './step.style'
import { Box, Button, Icon, Typography } from '@ntpkunity/controls'
import { StepProps } from './step.props'
import clsx from 'clsx'

export const Steps = ({ stepItems}: StepProps) => {
    const theme = useTheme()

    return (
        <StepWrap theme={theme} className='step-wrap'>
            {stepItems.map((item, index) => (
                <Box theme={theme} key={index} className={clsx({ 'step-item': true, 'is-active': item?.isActive })} onClick={item?.onClick}>
                    <Box theme={theme} className='card-content'>
                        {item?.title &&
                            <Typography theme={theme} variant='subtitle1' component={'span'} display={'block'}>{item.title}</Typography>
                        }
                        {item?.subtitle &&
                            <Typography theme={theme} variant='caption' component={'small'} className='text-muted' display={'block'}>{item.subtitle}</Typography>
                        }
                    </Box>
                    <Box theme={theme} className='step-action'>
                        <Box theme={theme} className={clsx({ 'cj-icon': true, 'error': item?.error, 'success': item?.success })}>
                            {item?.error &&
                                <Icon name='InfoIcon' />
                            }
                            {item?.success &&
                                <Icon name='GreenCheckIcon' />
                            }
                            {item?.isActive &&
                                <Button defaultBtn theme={theme} onClick={item?.onClick} iconText={<Icon name="IconRightArrow" />} />
                            }
                        </Box>
                    </Box>
                </Box>
            ))}
        </StepWrap>

    )
}