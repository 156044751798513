import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-wrap': {
      position: 'relative',
      '.product-card': {
        backgroundColor: theme.palette.common.white,
        padding: '20px 24px',
        borderRadius: 16,
        marginBottom: 8,
        transition: '0.4s',
        position: 'relative',
        [theme.breakpoints.down('md')]: {
          padding: 8,
          backgroundColor: 'transparent'
        },
        '.product-card-bg': {
            maxWidth: 385,
            maxHeight: 80,
            height: '100%',
            position: 'absolute',
            top: 32,
            left: 0,
            width: '100%',
            [theme.breakpoints.down('md')]: {
              right: 0,
              margin: 'auto',
              top: 22,
            },
          'img': {
            maxHeight: 80,
            maxWidth: 385,
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center center'
          }
        },
        '.card-content': {
          display: 'flex',
          justifyContent: 'center',
          '.product-card-fg': {
            marginTop: 64,
            position: 'relative',
            '&.no-bg': {
              marginTop: 0,
            }
          },
          '.text-items': {
            marginTop: 24,
            padding: 16,
            '.title': {
              display: 'flex',
              alignItems: 'center',
              marginBottom: 8,
              gap: 8,
              '.MuiButtonBase-root': {
                '&.icon-only': {
                    padding : 0,
                },
                '&.btn-default': {
                    'svg': {
                        'path':{
                            stroke: theme.palette.primary.main,
                        }
                    }
                }
            },
            },
            '.catagories': {
              display: 'flex',
              flexWrap: 'wrap',
              '.MuiTypography-root': {
                fontWeight: theme.typography.fontWeightMedium,
                textDecoration: 'none',
                borderRight: '1px solid',
                paddingRight: 8,
                paddingLeft: 8,
                '&:first-child': {
                  paddingLeft: 0,
                },
                '&:last-child': {
                  borderRight: 0,
                },
              },
              
              
              
            }
          }
          
        },
        // '.card-action': {
        //     display: 'flex',
        //     gap: '12px',
        //     '.cj-icon': {
        //         display: 'flex',
        //         '&.error': {
        //             svg: {
        //                 path: {
        //                     stroke: theme.palette.error.main,
        //                 }
        //             }
        //         },
        //         '&.success': {
        //             svg: {
        //                 path: {
        //                     stroke: theme.palette.success.main,
        //                 }
        //             }
        //         },
        //     }
        // }
      }
    }
}))
