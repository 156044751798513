import { Endpoint } from '@constants';
import { http } from '../utilities'

export const DMS_BASE_URL = process.env.DMS_BASE_URL;
export const UMS_BASE_URL = process.env.UMS_BASE_URL;

export namespace AuthenticationManagement {
  export const getTenantConsent = async (slug: string) => {
    return http(UMS_BASE_URL).get(`${Endpoint.CUSTOMER_MANDATORY_DISCLAIMERS}/${slug}`).then((res) => {
      return res.data
    })
  }

  export const addSignUpCustomerConsent = async (slug, data) => {
    return http(UMS_BASE_URL).post(`${Endpoint.USER_API_PUBLIC_DMS_URL}/${slug}`, data).then((res) => {
      return res.data
    })
  }

  export const addCustomerConsent = async (data) => {
    return http(DMS_BASE_URL).post(Endpoint.DISCLAIMER_AGREEMENT, data).then((res) => {
      return res.data
    })
  }

  export const getDisclaimerByName = async (disclaimerName: string) => {
    return http(DMS_BASE_URL).get(`${Endpoint.GET_DISCLAIMER_BY_NAME}/${disclaimerName}`).then((res) => {
      return res.data;
    })
  }

  export const createCustomer = (slug, data) => {
    return http(UMS_BASE_URL).post(`${Endpoint.USER_API_PUBLIC_DMS_URL}/${slug}`, data).then((res) => {
      return res.data
    })
  }

  export const getCustomerDisclaimers = async (disclaimerName: string) => {
    return http(DMS_BASE_URL)
      .get(`Endpoint.GET_TENANT_DISCLAIMERS/${disclaimerName}`)
      .then((res) => {
        return res.data
      })
  }

  export const getCustomerConsents = async (ref: { ref: string }) => {
    return http(DMS_BASE_URL).get(`${Endpoint.DISCLAIMER_AGREEMENT}?customer_reference_id=${ref}`).then((res) => {
      return res.data
    })
  }
}
