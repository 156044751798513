import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const QuestionnairePopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.questionnaire-popup-wrap': {
    '.scroll-horizontal': {
      '.selection-button-wrap': {
        overflowX: 'auto',
        overflowY: 'hidden',
        marginLeft: -24,
        marginRight: -24,
        paddingLeft: 24,
        paddingRight: 24,
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          width: 0,
          height: 0
        }
      }
    }
  }
}))
