import { Link, useTheme } from '@mui/material'
import {
  Icon,
  Box,
  Typography,
  Button,
  Image
} from '@ntpkunity/controls'
import { GradiantTextWrap } from './gradiant-text.style'
import { GradiantTextProps } from './gradiant-text.props'
import React, { Fragment } from 'react'

export const GradiantText = ({bgText}: GradiantTextProps) => {
  const theme = useTheme()
  return (
    <GradiantTextWrap theme={theme} className='order-confirmation-wrap'>
      {bgText &&
        <Box theme={theme} className='product-bg-text'>
          <Typography theme={theme} className='bg-text' variant='h1' component={'h1'} display={'block'}>{bgText}</Typography>
        </Box>
      }
    </GradiantTextWrap>
  )
}
