import { imageListItemClasses, useTheme } from '@mui/material'
import { PageWrap } from './payment.style'
import { PageHeader, Tab } from '../../../components'
import { DuoTab } from '@ntpkunity/controls'
import { FC, useContext, useEffect, useState } from 'react'
import { FinanceTypes, QueryKeys, Translations } from '@constants'
import { TabContent } from '../tab-content/tab-content'
import { usePaymentContext, ActionType, useOrderContext, OrderActionType } from 'pages'
import { AppContext } from '@app/context-provider'
import { updateFinanceType } from 'pages/(order-management)/order/order-context-provider'
import {OrderUpdatePopup} from '../../order-update-popup/order-Update-popup'
import { useQueryClient } from 'react-query'
import { IVehicle } from '@interfaces'
import {useParams} from "react-router-dom";



export const Payment: FC<{
  isLoading: boolean,
  setupDataLoading: boolean,
}> = ({
  isLoading, setupDataLoading
}) => {
    const theme = useTheme()
    const { state } = usePaymentContext()
    const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const handlePopupClose = () => {
      setIsPopupOpen(false);
  }
  const {  vin } = useParams()
  const queryClient = useQueryClient();
  const vehicle: IVehicle = queryClient.getQueryData(
    [QueryKeys.VEHICLE, vin]
  );


const popupimage=vehicle?.photo_urls[0]?.location

useEffect(() => {
  if (orderState?.is_payment_updated) {
    setIsPopupOpen(true)
  }
}, [orderState?.is_payment_updated]);
    const tabItems = [
      {
        title: PLACEHOLDERS.PAYMENT_TITLE_LEASE,
        content: <TabContent
          program={state.lease_program}
          isLoading={isLoading}
          setupDataLoading={setupDataLoading}
        />
      },
      {
        title: PLACEHOLDERS.PAYMENT_TITLE_FINANCE,
        content: <TabContent
          program={state.finance_program}
          isLoading={isLoading}
          setupDataLoading={setupDataLoading}
        />
      },
    ]

    return (
      <>
      <PageWrap className='page-wrap' theme={theme}>
        <PageHeader
          marginBottom={3}
          title={PLACEHOLDERS.PAYMENT_TITLE}
        // subTitle={PLACEHOLDERS.PAYMENT_SUB_TITLE}
        />
        <Tab>
       { popupimage && <OrderUpdatePopup isOpen={isPopupOpen} onClose={handlePopupClose} popupimage={popupimage}/>}
        <DuoTab
            varient='default'
            theme={theme}
            items={tabItems}
            defaultTabIndex={0}
            value={orderState.finance_type === FinanceTypes.LEASE ? 0 : 1}
            onChange={(e, value) => {
              orderDispatch(updateFinanceType(value === 0 ? FinanceTypes.LEASE : FinanceTypes.FINANCE))
            }}
          />
        </Tab>

      </PageWrap>
      </>
    )
  }
