import { useForm } from 'react-hook-form';

export function useContactDetailsForm(contactData: any, formStates: any) {
  const contactDetailForm = useForm<any>({
    mode: 'onChange',
    defaultValues: {
      prefix: formStates?.['ContactDetails']?.data?.prefix || contactData?.prefix,
      first_name: formStates?.['ContactDetails']?.data?.first_name || contactData?.first_name,
      middle_name: formStates?.['ContactDetails']?.data?.middle_name || contactData?.middle_name,
      last_name: formStates?.['ContactDetails']?.data?.last_name || contactData?.last_name,
      ssn: formStates?.['ContactDetails']?.data?.ssn || contactData?.ssn,
      suffix: formStates?.['ContactDetails']?.data?.suffix || contactData?.suffix,
      home_number: formStates?.['ContactDetails']?.data?.home_number || contactData?.home_number,
      mobile_number: formStates?.['ContactDetails']?.data?.mobile_number || contactData?.mobile_number,
      email: formStates?.['ContactDetails']?.data?.email || contactData?.email
    }
  });

  const watchContactFields = contactDetailForm.watch();
  return { contactDetailForm, watchContactFields };
}
