import { useTheme } from '@mui/material'
import { PageWrap } from './insurance.style'
import { PageHeader, Popup } from '@components'
import dayjs from 'dayjs'
import { ConfirmationDialog, Insurance, ProductDetailCard, QuestionStack, WayInsurance } from '@libraries'
import { Box, Button, Dialog, Icon, Image } from '@ntpkunity/controls'
import { useForm } from 'react-hook-form'
import { FC, useContext, useEffect, useState } from 'react'
import { AppContext } from '@app/context-provider'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { OrderActionType, useOrderContext } from 'pages'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags, PageType } from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'

export const InsurancePage: FC<{
}> = () => {
  const theme = useTheme()
  const [openInsuranceDialog, setOpenInsuranceDialog] = useState(false)
  const [openWayInsuranceDialog, setOpenWayInsuranceDialog] = useState(false)
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const { state: { order }, dispatch } = useOrderContext()
  const [insuranceRequestData, setInsuranceRequestData] = useState<any>(order?.insurance)
  const appState = useContext(AppContext);
  const { dispatch: pageDispatch } = useJourneyLayoutContext()
  const PLACEHOLDERS = appState.state.language.placeholders
  const schema = yup.object().shape({
    insurer_name: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_PROVIDER_NAME_IS_REQUIRED),
    agent_name: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_AGENT_NAME_IS_REQUIRED),
    policy_number: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_POLICY_NUMBER_IS_REQUIRED),
    expiration_date: yup
      .string()
      .nullable()
      .required(PLACEHOLDERS.INSURANCE_EXPIRATION_IS_REQUIRED)
      .test(
        'notFutureOrCurrentDate',
        PLACEHOLDERS.INSURANCE_EXPIRATION_DATE_CANNOT_BE_EQUAL_OR_LESS_THAN_CURRENT_DATE,
        function (value) {
          const selectedDate = dayjs(value).startOf('day')
          const currentDate = dayjs().startOf('day')
          return selectedDate.isAfter(currentDate)
        }
      ),
    insurance_documents: yup.array().nullable().required(PLACEHOLDERS.INSURANCE_ATLEAST_ONE_IMAGE_IS_REQUIRED),
    insurer_address: yup.object().shape({
      address_line_1: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_ADDRESS_LINE_1_IS_REQUIRED),
      city: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_CITY_IS_REQUIRED),
      state_name: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_STATE_NAME_IS_REQUIRED),
      zip_code: yup.string().nullable().required(PLACEHOLDERS.INSURANCE_ZIP_CODE_IS_REQUIRED)
    })
  })

  const handleClickOpenInsurance = () => {
    insurance_value(PLACEHOLDERS.INSURANCE_ALREADY_HAVE)
    form.reset()
    setInsuranceRequestData(null)
    setOpenInsuranceDialog(true)
  }
  const form: {
    trigger: any
    handleSubmit: any
    reset: any
    formState: any
    getValues: any
  } = useForm({
    resolver: yupResolver(schema)
  })

  const formattedExpirationDate =
    form.getValues()?.expiration_date &&
    new Date(form.getValues().expiration_date).toLocaleDateString()
  const handleCloseInsuranceDialog = () => {
    setOpenInsuranceDialog(false)
    if (Object.keys(form.formState.errors)?.length > 0) {
      form.reset()
      setInsuranceRequestData(null)
    }
  }
  const { tenant } = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags(tenant);

  useEffect(() => {
    const page_event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.TRADE_IN_END,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id: order?.identifier,
      vin: order?.vin,
      page_type: PageType.TradeIn
    }
    createTags(page_event)
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.DISPLAY_INSURANCE,
      user_id: user_profile?.user_id,
      dealer_id: +order?.dealer_id,
      vehicle_make: order?.order_asset?.make,
      vehicle_model: order?.order_asset?.model,
      order_id: order?.identifier,
      vin: order?.vin,
      page_type: PageType.Insurance
    }
    createTags(event)
  }, [])
  const insurance_value = (val: any) => {
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: val,
      user_id: user_profile?.user_id,
      page_type: PageType.TradeIn
    }
    createTags(event)
    if (val == PLACEHOLDERS.INSURANCE_WANT_TO_BUY) {
      setOpenWayInsuranceDialog(true)
    }
  }
  const saveInsurance = () => {
    const formValues = form.getValues();
    const insuranceData = {
        ...formValues,
        created_by: 'null',
        expiration_date: dayjs(formValues.expiration_date).format('YYYY-MM-DD'),
        insurance_documents: form.getValues('insurance_documents')?.length > 0 ? form.getValues('insurance_documents') : null,
        agent_phone: formValues.contact_number,
    }

    delete insuranceData.contact_number;

    setInsuranceRequestData(insuranceData);
    const event: Event = {
      session: user_session,
      slug: tenant,
      event_name: Tags.INSURANCE_POP_UP,
      user_id: user_profile?.user_id,
      page_type: PageType.TradeIn,
      data: insuranceRequestData
    }
    createTags(event)
    handleCloseInsuranceDialog()
  }

  const removeFormData = () => {
    form.reset({
      insurance_documents: undefined
    })
    setInsuranceRequestData(null)
    dispatch({
        type: OrderActionType.UPDATE_INSURANCE,
        payload: null
      })
    setConfirmationPopupState(false)
  }
  function isFormContainValues(): boolean {
    let obj = form.getValues()
    let check = false
    for (var x in obj) {
      if (obj[x] !== null && obj[x] !== '' && obj[x] !== undefined) {
        check = true
        break
      }
    }
    return check
  }

  useEffect(() => {
    if (insuranceRequestData && Object.keys(insuranceRequestData)?.length) {
      dispatch({
        type: OrderActionType.UPDATE_INSURANCE,
        payload: {
          ...insuranceRequestData,
          expiration_date: insuranceRequestData?.expiration_date?.split('T')?.[0]
        }
      })
    } else {
      dispatch({
        type: OrderActionType.UPDATE_INSURANCE,
        payload: undefined
      })}
  }, [insuranceRequestData])

  useEffect(() => {
    if (Object.keys(form?.formState?.errors)?.length) {
      pageDispatch({
        type: pagesActionType.UPDATE_INSURANCE,
        payload: {
          success: false,
          error: true
        }
      })
    }
  }, [form?.formState?.errors])

  useEffect(() => {
    if (order?.insurance) {
      form.reset({
        ...order.insurance,
        contact_number: order?.insurance?.agent_phone ||  order?.insurance?.contact_number,
      });
    }
  }, [order?.insurance]);

  return (
    <>
      <PageWrap theme={theme} className='page-wrap'>
        <PageHeader
          marginBottom={3}
          title={PLACEHOLDERS.INSURANCE_TITLE}
          subTitle={PLACEHOLDERS.INSURANCE_SUB_TITLE}
        />
        {isFormContainValues() ? (
          <ProductDetailCard
            title={form.getValues()?.insurer_name}
            listItems={[

              ...(insuranceRequestData?.agent_phone || form.getValues()?.agent_phone ? [{
                title: PLACEHOLDERS.INSURANCE_CONTACT,
                value: insuranceRequestData?.agent_phone || form.getValues()?.agent_phone
              }] : []),
              {
                title: PLACEHOLDERS.INSURANCE_POLICY_NUMBER,
                value: form.getValues()?.policy_number
              },
              {
                title: PLACEHOLDERS.INSURANCE_EXPIRATION,
                value: formattedExpirationDate
              }
            ]}
            buttonText={PLACEHOLDERS.INSURANCE_EDIT_INSURANCE_DETAILS}
            onBtnClick={() => {
              form.reset(insuranceRequestData)
              setOpenInsuranceDialog(true)
            }}
            linkText={PLACEHOLDERS.INSURANCE_REMOVE_INSURANCE}
            onLinkClick={() => {
              setConfirmationPopupState(true)
            }}
            mediaContent={
              <>
                {form.getValues()?.insurance_documents?.map((document: any, index: number) => (
                  <Box theme={theme} key={index}>
                    <Image
                      theme={theme}
                      src={document.document}
                      aspectRatio='16:9'
                    />
                  </Box>
                ))}
              </>
            }
          />
        ) : (
          <QuestionStack
            dFlex
            title={PLACEHOLDERS.INSURANCE_ALREADY_HAVE}
            actionArea
            onLinkClick={handleClickOpenInsurance}
          />
        )}
        <Popup>
          <Dialog
            disablePortal
            size="sm"
            title={PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE}
            open={openInsuranceDialog}
            onCloseDialog={handleCloseInsuranceDialog}
            customFooter={
              <>
                <Button
                  theme={theme}
                  primary
                  text={'Save Insurance Details'}
                  onClick={form.handleSubmit(saveInsurance)}
                />
              </>
            }
            theme={theme}
          >
            <Insurance InsuranceForm={form} />
          </Dialog>
        </Popup>
        {confirmationPopupState && (
          <ConfirmationDialog
            openPopUp={confirmationPopupState}
            onConfirm={removeFormData}
            setPopUpState={setConfirmationPopupState}
            confirmationTitle={PLACEHOLDERS.INSURANCE_REMOVE_INSURANCE_DETAILS_CONFIRMATION}
            confirmationText={PLACEHOLDERS.INSURANCE_UNDO_ACTION_CONFIRMATION}
            primaryBtnText={PLACEHOLDERS.CONFIRM_TEXT}
            secondaryBtnText={PLACEHOLDERS.CANCEL_TEXT}
            icon={<Icon name="ErrorTriangle" />}
            onCancel={function (_action: any): void {
              setConfirmationPopupState(false)
            }}
            hideCancelButton={false}
          />
        )}
      </PageWrap>
      {openWayInsuranceDialog &&
        <WayInsurance openWayInsuranceDialog={openWayInsuranceDialog} setOpenWayInsuranceDialog={setOpenWayInsuranceDialog} />
      }
    </>
  )
}
