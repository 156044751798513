import { useEffect, useState } from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from './schedule.style'
import { PageHeader, Popup } from '@components'
import { ConfirmationDialog, MapContainer, ProductDetailCard, QuestionStack } from '@libraries'
import { Box, Button, Dialog, Icon } from '@ntpkunity/controls'
import productImg from '../../../public/assets/images/dummy-img-ph.svg'
import { RescheduleAppointmentDialog } from 'libraries/add-delivery-detail-popup/delivery-step-one.component'
import { OrderActionType, useOrderContext } from 'pages'
import { useForm } from 'react-hook-form'
import { IAddress } from '@models'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useAppContext } from '@app/context-provider'
import { pagesActionType, useJourneyLayoutContext } from 'layouts/journey-layout/journey-layout-context-provider'
import { IDealerProfile, IStates } from '@interfaces'
import { useQueryClient } from 'react-query'
import { QueryKeys, ScheduleOptions } from '@constants'
import dayjs from 'dayjs'
import { useGetCompanyIntegrations, useVerifyAddress } from '@hooks'
import { getGoogleKey } from '@helpers'
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags,PageType } from 'constants/enums';
import { user_session } from '@constants'
import { useParams } from 'react-router-dom'
import { useGetIntegrationByProviderName } from 'hooks/order-management'
import { IntegrationProvider, LenderIntegrationType } from 'constants/providers'

function createValidationSchema(placeholders) {
  const schema = yup.object().shape({
    appointment_date: yup.string().required(placeholders.APPOINTMENT_DATE_IS_REQUIRED),
    address: yup.object().shape({
      address_line_1: yup.string().nullable().required(placeholders.SCHEDULE_ADDRESS_LINE_1_IS_REQUIRED),
      city: yup.string().nullable().required(placeholders.SCHEDULE_CITY_IS_REQUIRED),
      state_name: yup.string().nullable().required(placeholders.SCHEDULE_STATE_NAME_IS_REQUIRED),
      zip_code: yup.string().nullable().required(placeholders.SCHEDULE_ZIP_CODE_IS_REQUIRED)
      // county: yup.string().nullable().required(placeholders.SCHEDULE_COUNTY_IS_REQUIRED)
    })
  })
  return schema
}
interface IFormInputs {
  appointment_date: string
  address: IAddress | null
}
export const SchedulePage = () => {
  const theme = useTheme()
  const { state, dispatch } = useOrderContext()
  const order_detail = state?.order
  const [openPopup, setOpenPopup] = useState(false)
  const [tab, setTab] = useState("")
  const { dispatch: pageDispatch } = useJourneyLayoutContext()
  const { mutate: verifyAddress, isLoading: isVerifyAddressLoading } = useVerifyAddress();
  const [confirmationPopupState, setConfirmationPopupState] = useState<boolean>(false)
  const [openAppointmentPopup, setOpenAppointmentPopup] = useState(false)
  const [scheduleRequestData, setScheduleRequestData] = useState<any>({})
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders;
  const company_id = appState.tenant
  const [addressLocation, setAddressLocation] = useState<any>()
  const [deliveryAddress, setdeliveryAddress] = useState<IAddress | null | undefined>()
  const [contactNumber, setContactNumber] = useState<string | undefined>(undefined);
  const queryClient = useQueryClient();
  const dealerProfile: IDealerProfile = queryClient.getQueryData(
    QueryKeys.DEALER_PROFILE
  );
  const states: any = queryClient.getQueryData(QueryKeys.GET_ALL_STATES)
  const customerData: any = queryClient.getQueryData(
    QueryKeys.GET_CUSTOMER_BY_EMAIL
  );

  const validationSchema = createValidationSchema(PLACEHOLDERS)
  const handleAppointmentDialog = () => {
    setOpenAppointmentPopup(false)
    setOpenPopup(false)
  }
  const form: {
    trigger: any
    handleSubmit: any
    reset: any
    formState: any
    watch: any
    getValues: any
    setValue: any
  } = useForm<IFormInputs | any>({
    ...(Object.keys(deliveryAddress ?? {}).length > 0 ? { defaultValues: {...deliveryAddress, 'contact_number': deliveryAddress?.contact_number} } : {}),
    resolver: yupResolver(validationSchema),
    mode: 'all'
  })
  const { mutate: getProvider} = useGetIntegrationByProviderName( IntegrationProvider.GOOGLE,
    LenderIntegrationType.ADDRESS_VALIDATION,company_id);
  const [isGoogleEnabled, setIsGoogleEnabled] = useState(false)
  const { tenant } = useParams()
  const user_profile = JSON.parse(localStorage.getItem("user_profile"))
  const { mutate: createTags } = useTags(tenant);
    useEffect(() => {
      if(company_id){
      getProvider(
        {
          provider_name: IntegrationProvider.GOOGLE,
          integration_type: LenderIntegrationType.ADDRESS_VALIDATION,
          company_id:company_id
        },
        {
          onSuccess(response: any) {
            setIsGoogleEnabled(response?.is_active)
          }
        }
      )
    }
    }, [company_id])

  useEffect(() => {
    if (Object.keys(form?.formState?.errors)?.length) {
      pageDispatch({
        type: pagesActionType.UPDATE_SCHEDULE,
        payload: {
          success: false,
          error: true
        }
      })
    }
  }, [form?.formState?.errors])
  useEffect(() => {
    if (openPopup) {
      setOpenAppointmentPopup(openPopup)
    }
  }, [openPopup])
  useEffect(() => {
    const page_event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.INSURANCE_SESSION_END,
      user_id: user_profile?.user_id,
      page_type:PageType.Insurance
      }
      createTags(page_event)
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.DISPLAY_SCHEDULE_APPOINTMENTS,
      user_id: user_profile?.user_id,
      dealer_id: +order_detail?.dealer_id,
      vehicle_make: order_detail?.order_asset?.make,
      vehicle_model: order_detail?.order_asset?.model,
      order_id:order_detail?.identifier,
      vin:order_detail?.vin,
      page_type:PageType.ScheduleAppointments
      }
      createTags(event)
  }, [])
  const schedule_value= (val:any)=>{
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:val,
      user_id: user_profile?.user_id,
      page_type:PageType.ScheduleAppointments
      }
      createTags(event)
  }
  useEffect(() => {
    if (order_detail?.appointment_details) {
      setScheduleRequestData(order_detail?.appointment_details)
      setTab(order_detail?.appointment_details?.appointment_type)
      if (order_detail?.appointment_details?.appointment_type == ScheduleOptions.PICKUP && isGoogleEnabled) {
        schedule_value(ScheduleOptions.PICKUP)
        getVerifyGoogleAddress(dealerProfile?.dealer_address?.address_line_1 + " " + dealerProfile?.dealer_address?.address_line_2 + " " + dealerProfile?.dealer_address?.city + " " + dealerProfile?.dealer_address?.state_name)
      }
      else if (order_detail?.appointment_details?.appointment_type == ScheduleOptions.DELIVERY && isGoogleEnabled) {
        schedule_value(ScheduleOptions.DELIVERY)
        const addressStr = order_detail?.appointment_details?.address?.address_line_1 + " " + order_detail?.appointment_details?.address?.address_line_2 + " " + order_detail?.appointment_details?.address?.city + " " + order_detail?.appointment_details?.address?.state_name
        getVerifyGoogleAddress(addressStr)
      }
    }
  }, [order_detail?.appointment_details, isGoogleEnabled])

  useEffect(() => {
    if (customerData) {
      const address = customerData?.customer_addresses?.find(
        (x: any) =>
          (x?.address_type === "Mailing" && x?.is_garaging) ||
          x?.address_type === "Garaging"
      )
      form.setValue('address', address)
      form.setValue('address.state_name', address?.state_name)
      form.setValue('address.state_id', states?.find((x: any) => x.name === address?.state_name)?.id)
    }
  }, [customerData])

useEffect(() => {
  const number = scheduleRequestData?.contact_number
    ? scheduleRequestData.contact_number
    : order_detail?.appointment_details?.address?.contact_number;

  setContactNumber(number);
}, [scheduleRequestData, order_detail]);
  const getVerifyGoogleAddress = (address: string) => {
    verifyAddress(
      {
        address: address,
      },
      {
        onSuccess: (data) => {
          setAddressLocation(data.results[0]?.geometry?.location)
        },
      }
    );
  };

  const addAppointment = () => {
    setScheduleRequestData({
      ...form.getValues(),
      id: order_detail?.appointment_details?.id ? order_detail?.appointment_details?.id : 0,
      appointment_type: tab,
      address_data:
        tab == ScheduleOptions.DELIVERY && form.getValues("address")
          ? {
            address_line_1: form.getValues("address").address_line_1,
            address_line_2: form.getValues("address").address_line_2,
            state_name: form.getValues("address").state_name,
            zip_code: form.getValues("address").zip_code,
            county: form.getValues("address").county,
            city: form.getValues("address").city,
            contact_number: form.getValues("contact_number"),
            verified: true,
            address_type: 'Garaging'
          }
          : null
    })

    dispatch({
      type: OrderActionType.UPDATE_SCHEDULE_ORDER,
      payload: {
        ...form.getValues(),
        id: order_detail?.appointment_details?.id ? order_detail?.appointment_details?.id : 0,
        appointment_type: tab,
        address_data:
          tab == ScheduleOptions.DELIVERY && form.getValues("address")
            ? {
              address_line_1: form.getValues("address").address_line_1,
              address_line_2: form.getValues("address").address_line_2,
              state_name: form.getValues("address").state_name,
              zip_code: form.getValues("address").zip_code,
              county: form.getValues("address").county,
              city: form.getValues("address").city,
              contact_number: form.getValues("contact_number"),
              verified: true,
              address_type: 'Garaging'
            }
            : null
      }
    })
    pageDispatch({
      type: pagesActionType.UPDATE_SCHEDULE,
      payload: {
        success: true,
        error: false
      }
    })
    setOpenPopup(false)
  }
  const removeFormData = () => {
    if(tab===ScheduleOptions.PICKUP){
      form.reset({});
    }
    else{
      form.setValue("appointment_date", ""), form.setValue("address", customerData?.customer_addresses?.find(
        (x: any) =>
          (x?.address_type === "Mailing" && x?.is_garaging) ||
          x?.address_type === "Garaging"
      ));
    }
    setScheduleRequestData({});
    dispatch({
      type: OrderActionType.UPDATE_SCHEDULE_ORDER,
      payload: null
    })
    pageDispatch({
      type: pagesActionType.UPDATE_SCHEDULE,
      payload: {
        success: false,
        error: false
      }
    })
  }
  const submitAppointment = () => {
    const event: Event = {
      session: user_session,
      slug:tenant,
      event_name:Tags.INFORMATION_FILL,
      user_id: user_profile?.user_id,
      page_type:PageType.ScheduleAppointments
      }
      createTags(event)
    if (form.getValues("address") && tab === ScheduleOptions.DELIVERY) {
      addAppointment()
    }
    else if (tab === ScheduleOptions.PICKUP) {
      addAppointment()
    }
    else {
      setOpenPopup(true)
    }
  }
  return (
    <PageWrap theme={theme} className='page-wrap'>
      <PageHeader
        marginBottom={3}
        title={PLACEHOLDERS.SCHEDULE_TITLE}
        subTitle={PLACEHOLDERS.SCHEDULE_SUB_TITLE}
      />
      {dealerProfile?.schedule_option?.find((item => item == 'pickup')) && scheduleRequestData?.appointment_type != ScheduleOptions.PICKUP &&
        <QuestionStack
          dFlex
          title={PLACEHOLDERS.SCHEDULE_DEALERSHIP_STORE_PICKUP}
          actionArea
          onLinkClick={() => { setOpenPopup(true); setTab(ScheduleOptions.PICKUP);
            schedule_value(PLACEHOLDERS.SCHEDULE_DEALERSHIP_STORE_PICKUP)
          }}
        />}

      {(tab === ScheduleOptions.PICKUP || scheduleRequestData?.id >= 0) && scheduleRequestData?.appointment_type == ScheduleOptions.PICKUP && <ProductDetailCard
        title={PLACEHOLDERS.SCHEDULE_OPTIONS_PICKUP_TITLE}
        caption={dealerProfile?.dealer_address.address_line_1 + ", " + dealerProfile?.dealer_address.city + ", " + dealerProfile?.dealer_address.state_name + ", " + dealerProfile?.dealer_address.zip_code + ", United States"}
        listItems={[
          {
            title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_SCHEDULED_ON,
            value: dayjs(scheduleRequestData.appointment_date)?.format('MMMM') + " " + dayjs(scheduleRequestData.appointment_date)?.format('DD') + ", " + dayjs(scheduleRequestData.appointment_date)?.format('YYYY') + ", " + dayjs(scheduleRequestData.appointment_date)?.format('hh:mm') + " " + dayjs(scheduleRequestData.appointment_date)?.format('A')
          },
          {
            title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_CONTACT_NUMBER,
            value: dealerProfile?.contact_number
          }
        ]}
        onBtnClick={() => {
          setOpenPopup(true); form.reset({
            ...scheduleRequestData,
            appointment_date: dayjs(scheduleRequestData.appointment_date)
          })
        }}
        buttonText={PLACEHOLDERS.SCHEDULE_EDIT_SCHEDULE}
        onLinkClick={() => {
          setConfirmationPopupState(true)
        }}
        linkText={PLACEHOLDERS.SCHEDULE_REMOVE_SCHEDULE}
        mediaContent={
          isGoogleEnabled && <MapContainer location={addressLocation} />
        }
      />}
      {dealerProfile?.schedule_option?.find((item => item == 'delivery')) && scheduleRequestData?.appointment_type != ScheduleOptions.DELIVERY && <QuestionStack
        dFlex
        title={PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_QUESTION}
        actionArea
        onLinkClick={() => { setOpenPopup(true); setTab(ScheduleOptions.DELIVERY);
          schedule_value(PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_QUESTION)
         }}
        marginTopXs={3}
        marginTopMd={3}
      />}
      {(tab === ScheduleOptions.DELIVERY || scheduleRequestData?.id >= 0) && scheduleRequestData?.appointment_type == ScheduleOptions.DELIVERY &&
        <Box theme={theme} mt={3}>
          <ProductDetailCard
            title={PLACEHOLDERS.SCHEDULE_OPTIONS_DELIVERY_TITLE}
            caption={[
              scheduleRequestData?.address?.address_line_1,
              scheduleRequestData?.address?.address_line_2,
              scheduleRequestData?.address?.city,
              scheduleRequestData?.address?.state_name,
              scheduleRequestData?.address?.zip_code
            ].filter(Boolean).join(", ") + ", United States"
            }
            listItems={[
              {
                title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_SCHEDULED_ON,
                value: dayjs(scheduleRequestData.appointment_date)?.format('MMMM') + " " + dayjs(scheduleRequestData.appointment_date)?.format('DD') + ", " + dayjs(scheduleRequestData.appointment_date)?.format('YYYY') + ", " + dayjs(scheduleRequestData.appointment_date)?.format('hh:mm') + " " + dayjs(scheduleRequestData.appointment_date)?.format('A')
              },
              ...(contactNumber ? [{
                title: PLACEHOLDERS.SCHEDULE_LIST_ITEM_CONTACT_NUMBER,
                value: contactNumber
              }] : []),

            ]}
            onBtnClick={() => {
              setOpenPopup(true); form.reset({
                ...scheduleRequestData,
                appointment_date: dayjs(scheduleRequestData.appointment_date),
                contact_number:scheduleRequestData.contact_number? scheduleRequestData.contact_number:contactNumber
              })
            }}
            buttonText={PLACEHOLDERS.SCHEDULE_EDIT_SCHEDULE}
            onLinkClick={() => {
              setConfirmationPopupState(true)
            }}
            linkText={PLACEHOLDERS.SCHEDULE_REMOVE_SCHEDULE}
            mediaContent={
              isGoogleEnabled && <MapContainer location={addressLocation} />
            }
          />
        </Box>}
      {openPopup &&
      <Popup>
      <Dialog
        disablePortal
        variant={undefined}
        size="sm"
        title={PLACEHOLDERS.SCHEDULE_APPOINTMENT_TITLE}
        open={openAppointmentPopup}
        onCloseDialog={handleAppointmentDialog}
        customFooter={
          <>
            {form.watch("appointment_date") &&
              (tab == ScheduleOptions.DELIVERY ? (
                <Button
                  theme={theme}
                  primary
                  text={PLACEHOLDERS.BUTTON_TEXT_CONTINUE}
                  onClick={form.handleSubmit(submitAppointment)}
                />
              ) : (
                <Button
                  theme={theme}
                  primary
                  text={PLACEHOLDERS.BUTTON_TEXT_CONTINUE}
                  onClick={submitAppointment}
                />
              ))}
          </>
        }
        theme={theme}
      > <RescheduleAppointmentDialog tab={tab} scheduleOrderForm={form} isGoogleEnabled={isGoogleEnabled} /></Dialog>
      </Popup>}

      {confirmationPopupState && (
        <ConfirmationDialog
          openPopUp={confirmationPopupState}
          onConfirm={removeFormData}
          setPopUpState={setConfirmationPopupState}
          confirmationTitle={PLACEHOLDERS.SCHEDULE_REMOVE_APPOINTMENT_DETAILS_CONFIRMATION}
          confirmationText={PLACEHOLDERS.SCHEDULE_UNDO_ACTION_CONFIRMATION}
          primaryBtnText={PLACEHOLDERS.CONFIRM_TEXT}
          secondaryBtnText={PLACEHOLDERS.CANCEL_TEXT}
          icon={<Icon name="ErrorTriangle" />}
          onCancel={function (_action: any): void {
            setConfirmationPopupState(false)
          }}
          hideCancelButton={false}
        />
      )}
    </PageWrap>
  )
}