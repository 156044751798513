import { FC, useState } from 'react'
import { useTheme } from '@mui/material'
import { ContractingStack } from '@components'
import { Typography } from '@ntpkunity/controls'
import { DocumentPackageStatus } from 'constants/enums'
import { IDocumentPackage } from '@interfaces'
import { DocumentViewModal } from '@ntpkunity/controls-common'
import { useOrderContext } from '@pages'

export const DocumentPackage: FC<{ documentPackage: IDocumentPackage, PLACEHOLDERS: any }> = ({ documentPackage, PLACEHOLDERS }) => {
  const theme = useTheme()
  const [openDocumentModal, setOpenDocumentModal] = useState(false)
  const [selectedDocument, setSelectedDocument] = useState()
  const { state: { order } } = useOrderContext()

  const handleOptionSelection = (e, key, value) => {
    switch (key) {
      case 'view':
        setSelectedDocument(value)
        setOpenDocumentModal(true)
        break
      case 'sign':
        setSelectedDocument(value)
        break
      default:
        setSelectedDocument(value)
        setOpenDocumentModal(true)
    }
  }

  return (
    <>
      <ContractingStack
        heading={PLACEHOLDERS.CONTRACTING_VIEW_AND_SIGN_CONTRACTS}
        description={
          <>
            <Typography theme={theme} className='description text-muted' mb={3} display={'block'} component={'small'} variant='caption'>
              {PLACEHOLDERS.CONTRACTING_OPEN_AND_VIEW_CONTRACTS}
            </Typography>
          </>}
        accordian
        handleOptionSelection={handleOptionSelection}
        items={documentPackage?.documents?.map(document => ({
          title: document?.title,
          success: document?.status === DocumentPackageStatus.Signed,
          error: document?.status !== DocumentPackageStatus.Signed,
          menu: document,
          subItems: document?.signatures?.map(signature => ({
            subTitle: `(${signature?.signer})`,
            success: signature?.status === DocumentPackageStatus.Signed,
            error: signature?.status !== DocumentPackageStatus.Signed,
            successText: signature?.status === DocumentPackageStatus.Signed && PLACEHOLDERS?.CONTRACTING_SIGNED_STATUS,
            errorText: signature?.status !== DocumentPackageStatus.Signed && PLACEHOLDERS?.CONTRACTING_NOT_SIGNED_STATUS
          }))
        }))}
      />
      {openDocumentModal && (
        <DocumentViewModal
          openPopup={openDocumentModal}
          setOpenPopup={setOpenDocumentModal}
          selectedDocument={selectedDocument}
          referenceId={order?.reference_id}
          referenceNumber={order?.reference_number}
          placeholders={PLACEHOLDERS}
        />
      )}
    </>
  )
}