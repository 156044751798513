import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CustomCalendarWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.custom-calendar-wrap': {
    '.calendar-header': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 24,
      '.btn': {
        '&.with-icon': {
          '&.icon-only': {
            padding: 0
          }
        },
        '&.left-btn': {
          transform: 'rotate(90deg)'
        },
        '&.right-btn': {
          transform: 'rotate(-90deg)'
        }
      }
    },
    '.calendar-body': {
      marginTop: 16,
      overflow: 'hidden',
      '.calendar-items': {
        '.selection-button-wrap': {
          justifyContent: 'center',
          '.cj-btn': {
            padding: '20px 12px',
            backgroundColor: 'transparent',
            minWidth: 56,
            borderRadius: 40,
            gap: 6,
            '.MuiTypography-body2': {
              fontSize: theme.typography.caption.fontSize,
              lineHeight: theme.typography.caption.lineHeight,
              textTransform: 'uppercase',
              fontWeight: theme.typography.fontWeightRegular,
              color: theme.palette.grey[600]
            },
            '.MuiTypography-caption': {
              fontSize: theme.typography.h4.fontSize,
              lineHeight: theme.typography.h4.lineHeight,
              textTransform: 'uppercase',
              fontWeight: theme.customVariables.fontWeightSemiBold,
              color: theme.palette.grey[900]
            },
            '&.selected': {
              backgroundColor: theme.palette.grey[700],
              '.MuiTypography-body2': {
                color: theme.palette.common.white
              },
              '.text-muted': {
                color: theme.palette.common.white
              }
            },
            '&.disabled': {
              '.MuiTypography-body2': {
                color: theme.palette.grey[200]
              },
              '.text-muted': {
                color: theme.palette.grey[200]
              }
            }
          }
        }
      }
    }
  }
}))
