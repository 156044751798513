import React, { ReactNode } from 'react'
import { Footer, Header } from '../../libraries'
import { useTheme } from '@mui/material'
import { BaseLayoutWrap } from './base-layout.style'

export const BaseLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  return (
    <BaseLayoutWrap theme={theme} className='base-layout-wrap'>
      <Header theme={theme} />
        <main className='main-content'>
          {children}
        </main>
      <Footer />
    </BaseLayoutWrap>
  )
}
