import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SectionTitleWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.section-title-wrap': {
    display: 'flex',
    alignItems: 'center',
    gap: 24,
    '.section-title': {
        flexGrow: 1
    },
    '.action-area': {
        flexShrink: 0
    }
  }
}))
