import { useMutation, useQuery, useQueryClient } from "react-query";
import { ContractManagement } from "../apis";
import { QueryKeys } from "@constants";

export const useGetAllDocumentPackages = (payload) => {
  return useQuery(
    QueryKeys.GET_ALL_DOCUMENT_PACKAGES,
    () => {
      return ContractManagement.getDocumentPackages(payload);
    },
    { enabled: !!payload?.referenceId && !!payload?.orderId }
  )
}

export const useGetPreSignedUrl = (key: string) => {
  return useQuery(
    [QueryKeys.GENERATE_PRESIGNED_URL, key],
    () => {
      return ContractManagement.getPresSignedUrl({ key });
    },
    { enabled: !!key }
  )
}

export const useSignDocumentPackage = (): any => {
  const queryClient = useQueryClient();
  return useMutation<any>(
    (body: any) => {
      return ContractManagement.signDocumentPackage(body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_ALL_DOCUMENT_PACKAGES);
      }
    }
  )
}

export const useUpdateCustomnerSignature = (): any => {
  const queryClient = useQueryClient();
  return useMutation<any>(
    (body: any) => {
      return ContractManagement.updateCustomerSignature(body);
    },
    {
      onSuccess() {
        queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_SIGNATURE);
      }
    }
  )
}
