import { useTheme } from '@mui/material'
import { ProductDetailCardWrap } from './product-detail-card.style'
import { ProductCardDetailProps } from './product-detail-card.props'
import clsx from 'clsx'
import { Box, Button, Grid, Typography } from '@ntpkunity/controls'

export const ProductDetailCard = ({ title, subTtile, caption, listItems, linkText, buttonText, onBtnClick, onLinkClick, mediaContent }: ProductCardDetailProps) => {
    const theme = useTheme()
  return (
    <ProductDetailCardWrap className='product-detail-card-wrap' theme={theme}>
      <Grid
          theme={theme}
          container
          item
          spacing={{xs:3, lg:5}}
        >
        <Grid item xs={12} lg={6.3} theme={theme}>
          <Box theme={theme} className='cj-card-content'>
            {title &&
              <Box theme={theme}>
                <Typography theme={theme} variant='subtitle1' component={'p'}>
                  {title}
                </Typography>
                {subTtile &&
                  <Typography theme={theme} className='text-primary' variant='h4' component={'h4'}>
                    {subTtile}
                  </Typography>
                }
                {caption &&
                  <Typography theme={theme} className='text-muted' variant='caption' component={'small'} display={'block'}>
                    {caption}
                  </Typography>
                }
                {listItems &&
                  <Box theme={theme} className='list-items'>
                    {listItems.map((item, index) => (
                      <Box theme={theme} sx={{ display: 'flex', gap: '4px'}} key={index}>
                        <Typography theme={theme} className='fw-sbold' variant='caption' component={'small'}>
                          {item.title}
                        </Typography>
                        <Typography theme={theme} className={clsx(item.textType === 'danger' ? 'text-danger' : 'text-muted')} variant='caption' component={'small'}>
                          {item.value}
                        </Typography>
                      </Box>
                    ))}
                  </Box>
                }
              </Box>
            }
            
            {onBtnClick &&
              <Box theme={theme}>
                <Button theme={theme} secondary text={buttonText} onClick={onBtnClick} />
              </Box>
            }
            {onLinkClick &&
              <Typography theme={theme} className='fw-medium' variant='body2' component={'span'} display={'block'}>
                  <a href="#" className='text-primary decoration-none' onClick={onLinkClick}>{linkText}</a>
              </Typography>
            }
          </Box>
        </Grid>
        <Grid item xs={12} lg={5.7} theme={theme}>
          <Box theme={theme} className='media-element'>
            {mediaContent}
          </Box>
        </Grid>
      </Grid>
    </ProductDetailCardWrap>
  )
}
