import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AlertMessageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.alert-message': {
        display: 'flex',
        padding: '24px',
        alignItems: 'center',
        gap: 8,
        alignSelf: 'stretch',
        borderRadius: 16,
        backgroundColor: theme.palette.grey[50],
        [theme.breakpoints.down('sm')]:{
            alignItems: 'flex-start',
        },
        svg: {
            path: {
                stroke: theme.palette.error.main
            }
        },
        '.MuiTypography-root': {
            flex: '1 1 0',
            a: {
                textDecoration: 'none',
                color: theme.palette.primary.main
            },
        },
        '&.error': {
            backgroundColor: theme.palette.error.main,
            color: theme.palette.common.white,
            svg: {
                path: {
                    stroke: theme.palette.common.white
                }
            },
            '.MuiTypography-root': {
                color: theme.palette.common.white,
                a: {
                    color: theme.palette.common.white
                },
            }
        },
    }
}))
