import { useForm } from 'react-hook-form';
import dayjs from "dayjs";
import {formatPayloadDate} from "../../helpers/methods";

export const getDefaultLicenseValues = (licenseInformation: any) => {
  if (!licenseInformation) return {};

  const { first_name, last_name, expiry_date, date_of_birth, license_number, front_image, back_image, address } = licenseInformation;

  return {
    first_name,
    last_name,
    expiry_date: formatPayloadDate(dayjs(expiry_date)),
    date_of_birth: formatPayloadDate(dayjs(date_of_birth)),
    license_number,
    front_image,
    back_image,
    issue_state: address?.state_name,
  };
};

export function useLicenseDetailsForm(licenseInformation: any, formStates: any) {


  const licenceForm = useForm<any>({
    mode: 'onChange',
    defaultValues: formStates?.['licence']?.data || getDefaultLicenseValues(licenseInformation)
  });

  const watchLicenseFields = licenceForm.watch();
  return { licenceForm, watchLicenseFields };
}
