import React, { ReactNode, useState } from 'react'
import { SelectionButtonWrap } from './selection-button.style'
import { useTheme } from '@mui/material'
import { SelectionProps } from './selection-button.props'
import { Box, Button, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'

export const SelectionButton = ({ buttonItems }: SelectionProps) => {
  const theme = useTheme()
  return (
    <SelectionButtonWrap className='selection-button-wrap' theme={theme}>
      {buttonItems?.map((item, index) => (
        <Box theme={theme} key={index} className={clsx({ 'cj-btn': true, 'bg-light': item.bgLight, 'selected': item.isSelected, 'disabled': item.disabled })} id={item.id} onClick={item.onClick}>
          {item.title &&
            <Typography theme={theme} className='fw-medium' variant='body2' component={'span'} display={'block'}>{item.title}</Typography>
          }
          {item.subtitle &&
            <Typography theme={theme} className='fw-medium text-muted' variant='caption' component={'small'} display={'block'}>{item.subtitle}</Typography>
          }
        </Box>
      ))}
    </SelectionButtonWrap>
  )
}
