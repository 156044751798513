import React from 'react'
import { useTheme } from '@mui/material'
import { SearchAreaWrap } from './search.style'
import { Autocomplete, Icon } from '@ntpkunity/controls'
import clsx from 'clsx'
import { SearchProps } from './search.props'

export const Search = ({ placeholder, hasBg, colorWhite } : SearchProps) => {
    const theme = useTheme()
  return (
    <SearchAreaWrap theme={theme}
        className={clsx({ 'search-area-wrap': true, 'has-bg': hasBg, 'white-color': colorWhite })} 
    >
        <Autocomplete
            theme={theme}
            items={[
                {
                    label: 'The Lord of the Rings: The Return of the King',
                    id: 2003,
                },
                { label: 'The Good, the Bad and the Ugly', id: 1966 },
                { label: 'Fight Club', id: 1999 },
                {
                    label: 'The Lord of the Rings: The Fellowship of the Ring',
                    id: 2001,
                },
            ]}
            disablePortal={true}
            endAdornment={<Icon name="SearchIcon" />}
            placeholder={placeholder}
        />
    </SearchAreaWrap>
  )
}
