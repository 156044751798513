import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const OrderStatusLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.order-status-layout-wrap': {
    minHeight: '100vh',
    backgroundColor: '#F5F5F7',
    margin: -8,
    padding: '40px 16px',
    '.MuiContainer-root': {
        [theme.breakpoints.down('md')]: {
            paddingLeft: 24,
            paddingRight: 24
        }
    },
    '.btn': {
        '&.btn-secondary': {
            backgroundColor: 'transparent'
        }
    },
    [theme.breakpoints.down('md')]: {
        padding: '24px 0'
    }
  }
}))
