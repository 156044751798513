import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SearchAreaWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.search-area-wrap': {
    '.u-custom-autocomplete': {
      '.MuiAutocomplete-root': {
        '.u-form-group': {
          '.MuiInputBase-formControl': {
            height: 56,
            borderRadius: 28,
            padding: '0 16px 0px 0',
            '.MuiInputBase-input': {
              padding: '16px 16px 16px 24px !important',
            },
            svg: {
              path: {
                stroke: theme.palette.grey[300]
              }
            }
          }
        }
      },
      '.MuiAutocomplete-popper': {
        zIndex: 1200,
        '.MuiAutocomplete-paper': {
          borderRadius: 16,
          '.MuiAutocomplete-listbox': {
            '.MuiAutocomplete-option': {
              padding: '8px 24px'
            }
          }
        }
      }
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%'
    },
    '&.white-color': {
        '.u-custom-autocomplete': {
            '.MuiAutocomplete-root': {
                '.u-form-group': {
                    '.MuiInputBase-formControl': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        '.MuiInputBase-input': {
                        
                        },
                        svg: {
                            path: {
                                stroke: theme.palette.common.white
                            }
                        },
                        '&:hover': {
                            'fieldset': {
                                borderColor: theme.palette.common.white
                            }
                        },
                        '&.Mui-focused': {
                            'fieldset': {
                                borderColor: theme.palette.common.white
                            }
                        }
                    },
                    'fieldset': {
                        borderColor: theme.palette.common.white
                    }
                }
            },
        }
    },
    '&.has-bg': {
        '.u-custom-autocomplete': {
            '.MuiAutocomplete-root': {
                '.u-form-group': {
                    '.MuiInputBase-formControl': {
                        color: 'rgba(255, 255, 255, 0.87)',
                        backgroundColor: 'rgba(255, 255, 255, 0.10)',
                        backdropFilter: 'blur(20px)'    
                    },
                    
                }
            },
        }
    }
  }
}))
