import { useTheme } from '@mui/material'
import { Grid, Input, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox, SelectionButton } from '@components'
import React, {useEffect, useState} from 'react'
import { MakeModelPopupWrap } from './make-modal-poup.style'
import { useAppContext } from '@app/context-provider'


export const MakeModelPopupOne = (props) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const {messages, years, setValue, clearErrors, trigger, getValues, setMakeModelYearTrim} = props
    const values = getValues()

    const {year} = values
    const [filter, setFilter] = useState(years?.sort((a, b) => b.yearId - a.yearId) || [])
    const [filterValue, setFilterValue] = useState('')
    const [selectedItem, setSelected] = useState(year)
    const [selectedValue] = useState(year)


    useEffect(() => {
        if(year !== selectedValue){
            setValue(messages.name.Make, null)
            setValue(messages.name.Model, null)
            setValue(messages.name.Trim, null)
            setMakeModelYearTrim(prev =>({
                ...prev,
                "make_id": null,
                "model_id": null,
            }))

        }
    }, [year]);
    useEffect(() => {
        const item = filter.find(item => item?.yearId == selectedItem)
        if(item){
            setMakeModelYearTrim(prevState => ({
                ...prevState,
                'year_id': item?.yearId
            }))
            clearErrors()
            trigger()
        }

    }, [selectedItem]);

    const handleFilter = (value) => {
        setFilter(years?.filter(item => item?.yearId?.toString().includes(value?.toString())))
        setFilterValue(value)
    }
    const handleSelection = (item) => {
      setSelected(item.yearId)
      setValue(messages?.name?.Year, item?.yearId)

    }
    return(
        <MakeModelPopupWrap theme={theme} className='make-model-popup-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3} className='make-modal-popup'>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2 }}
                >
                    <Grid item xs={12} theme={theme} className='sticky'>
                        <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>{PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_ONE_YEAR_PROMPT_LABEL}</Typography>
                        <Textbox>
                            <Input value={filterValue} name='yearFilter' type={'text'} fullWidth theme={theme} placeholder={PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_ONE_INPUT_PLACEHOLDER} onChange={(value) => handleFilter(value)}/>
                        </Textbox>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <SelectionButton
                            buttonItems={filter?.map((item) => ({
                                title: item?.yearId,
                                onClick: () => handleSelection(item),
                                isSelected: item?.yearId == selectedItem
                            }))}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </MakeModelPopupWrap>
    )
}


