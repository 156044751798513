import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const HeaderMenuWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.header-menu-wrap': {
    display: 'flex',
    flexGrow: 1,
    '.toggle-icon': {
      padding: 0,
      '.menu-icon': {
        height: 24,
        width: 24,
        cursor: 'pointer',
        [theme.breakpoints.down('md')]: {
          zIndex: 99,
          position: 'relative'
        },
        '.toggle-bar': {
          position: 'relative',
          top: '50%',
          transition: 'all 0.3s ease-in-out',
          '&:after': {
            height: 2,
            width: 16,
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 10px 0 0' + theme.palette.grey[500],
            content: "''",
            position: 'absolute',
            top: -6,
            left: 0
          },
          '&:before': {
            height: 2,
            width: 16,
            transition: 'all 0.3s ease-in-out',
            boxShadow: '0 -10px 0 0' + theme.palette.grey[500],
            content: "''",
            position: 'absolute',
            top: 6,
            left: 0
          }
        }
      },
      '&.change': {
        '.toggle-bar': {
          backgroundColor: 'transparent',
          transition: 'all 0.6s ease-in-out',
          '&:after': {
            top: 0,
            left: 6,
            boxShadow: '0 10px 0 0' + theme.palette.grey[500],
            transform: 'translateY(-8px) rotate(45deg)',
            transition: 'all 0.3s ease-in-out'
          },
          '&:before': {
            top: 0,
            left: 6,
            boxShadow: '0 -10px 0 0 ' + theme.palette.grey[500],
            transform: 'translateY(6px) rotate(-45deg)',
            transition: 'all 0.3s ease-in-out'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        flexGrow: 1,
        order: 1,
        justifyContent: 'flex-start',
        '&.icon-search': {
          flexGrow: 0,
          flexShrink: 0,
          order: 3
        }
      },
      [theme.breakpoints.up('md')]: {
        display: 'none'
      }
    },
    '.header-nav-wrap': {
      flexGrow: 1,
      [theme.breakpoints.up('md')]: {
        display: 'flex'
      },
      [theme.breakpoints.down('md')]: {
        border: 0,
        margin: 0,
        padding: 8,
        width: '100%',
        top: 0,
        left: 0,
        right: 'auto',
        position: 'fixed',
        maxHeight: 'none',
        height: 'auto',
        backgroundColor: theme.palette.common.white,
        transition: 'all .5s',
        transform: 'translateY(-100%)',
        boxShadow: '0 6px 12px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '0 0 16px 16px',
        visibility: 'hidden',
        zIndex: 12,
        '&.menu-open': {
          maxHeight: 'calc(100vh - 80px)',
          transform: 'translateY(73px)',
          overflowY: 'auto',
          overflowX: 'hidden',
          visibility: 'visible'
        }
      },
      '.navbar-nav': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: 0,
        marginBottom: 0,
        marginTop: 0,
        listStyle: 'none',
        [theme.breakpoints.up('md')]: {
          flexDirection: 'row',
          '&.nav-center': {
            flexGrow: 1
          },
          '&.nav-right': {
            flexShrink: 0,
            '.nav-item': {
              marginRight: 32,
              '&:last-child': {
                marginRight: 0
              }
            }
          }
        },
        '.nav-item': {
          marginRight: 40,
          alignItems: 'center',
          display: 'flex',
          '&:last-child': {
            marginRight: 0
          },
          '&.icon-item': {
            '.nav-link': {
              width: 24,
              height: 24,
              'svg': {
                'path': {
                  stroke: theme.palette.grey[300]
                }
              }
            }
          },
          [theme.breakpoints.down('md')]: {
            marginRight: 0,
            marginBottom: 0,
            padding: 0,
            minHeight: 'auto'
          }
        },
        '&.nav-right': {
          '.nav-item': {
            '&.icon-item': {
              [theme.breakpoints.down('md')]: {
                display: 'none'
              }
            }
          }
        }
      },
      '.responsive-user-menu': {
        position: 'relative',
        marginTop: 8,
        '&::before': {
          display: 'block',
          content: '""',
          borderTopStyle: 'solid',
          borderWidth: 1,
          borderColor: theme.palette.grey[100],
          margin: '0 24px'
        },
        '.navbar-nav': {
          paddingTop: 8,
          '.nav-link': {
            '.start-icon': {
              svg: {
                path: {
                  stroke: 'none'
                }
              }
            }
          }
        }
      }
    }
  }
}))
