import React, { ReactNode } from 'react'
import { useTheme } from '@mui/material'
import { OrderStatusLayoutWrap } from './order-status-layout.style'
import { Container } from '@ntpkunity/controls'

export const OrderStatusLayout = ({ children }: { children: ReactNode }) => {
  const theme = useTheme()
  return (
    <OrderStatusLayoutWrap theme={theme} className='order-status-layout-wrap'>
        <Container theme={theme} maxWidth={'lg'} >
          {children}
        </Container>
    </OrderStatusLayoutWrap>
  )
}
