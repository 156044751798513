import React, { useReducer, useEffect } from 'react'
import { useTheme } from '@mui/material'
import { SubmitOrderControl } from '../../../libraries/submit-order/submit-order.component'
import { SnackbarUtility } from '@utilities'
import { Snackbar } from '@ntpkunity/controls'
import { useParams } from "react-router-dom";
import { getOrder } from '../../(order-management)/order/order-context-provider'
import { useGetOrderInformation } from "@hooks";
import { useOrderContext } from 'pages/(order-management)/order/order-context-provider'
import { Status } from 'constants/enums'
import { useScrollOnTop } from 'hooks/order-management'


export const SubmitOrderPage = () => {
  const theme = useTheme()
  const { orderId } = useParams();
  const { state: { order }, dispatch: orderDispatch, handleSubmitOrder } = useOrderContext()
  const [snackbarState, snackbardispatch] = useReducer(
    SnackbarUtility.snackbarReducer,
    SnackbarUtility.initialState
  );
  const { data: orderData }: { data: any, isLoading: boolean } = useGetOrderInformation(orderId);
  useScrollOnTop(orderData)
  useEffect(() => {
    if (orderData) {
      orderDispatch(getOrder(orderData))
      if (orderData?.status === Status.Draft) {
        handleSubmitOrder()
      }
    }
  }, [orderData])

  return (
    <>
      <SubmitOrderControl
        snackbardispatch={snackbardispatch}
      />
      <Snackbar
        theme={theme}
        message={snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      />
    </>
  )
}