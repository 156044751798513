import { Endpoint } from "@constants";
import { http } from "../utilities";

const UMS_BASE_URL = process.env.UMS_BASE_URL;
const CONFIG_BASE_URL = process.env.CONFIG_BASE_URL;

export namespace TenantManagement {
  export const getTenantConfigurations = async ({ slug }: { slug: string }) => {
    const body = {
      url: Endpoint.GET_LENDER_BY_SLUG,
      method_type: "get",
    };
    return http(UMS_BASE_URL).post(`${Endpoint.UMS_DMS_CALL}/${slug}`, body);
  };

  export const getDefaultLenderConfigurations = async ({ company_id }: { company_id: number }) => {
    const headers = {
      'company_id ': company_id
    }
    return http(CONFIG_BASE_URL, headers)
    .get(`${Endpoint.DEFAULT_LENDER_CONFIGURATION}`)
    .then((res) => {
      return res.data
    });
  };
}
