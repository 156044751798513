import { useMutation, useQuery } from "react-query"
import { TradeInManagement } from "../apis/trade-in-management"
import {QueryKeys} from "../constants";


export const useGetQuestions = (company_id: any) => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getQuestionsAndAnswers(body, company_id);
        }
    );
}

export const useGetConfiguration = (dealer_code:any, integration_type:any, provider_name: any, company_id: any) => {
        return useQuery([QueryKeys.GET_CONFIGURATION_PROVIDER, provider_name] , () => {
            return TradeInManagement.getConfiguration(dealer_code,integration_type,provider_name, company_id)
        }
    )
}

export const useGetVehicleDetailsByVin = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInThroughVin(body);
        }
    );
}

export const useGetTradeInYear = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInYear(body);
        }
    );
}

export const useGetTradeInMake = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInMake(body);
        }
    );
}

export const useGetTradeInModel = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradinModel(body);
        }
    );
}

export const useGetTradeInTrim = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInTrim(body);
        }
    );
}

export const useGetTradeInVehicleDetail = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInVehicleDetail(body);
        }
    );
}

export const useGetTradeInVehicleValue = () => {
    return useMutation(
        async (body: any) => {
            return  TradeInManagement.getTradeInVehicleValue(body);
        }
    );
}
