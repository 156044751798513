import { useEffect, useState, useReducer, useContext } from 'react'
import { useTheme } from '@mui/material'
import { AboutCard, DocumentsCard } from '@libraries'
import { Box, Image, Snackbar } from '@ntpkunity/controls'
import { Stack } from '@components'
import { ProfileAddress } from './ProfileAddress'
import { ChangePasswordComponent } from '@ntpkunity/controls-ums'
import { Navigation } from '@constants'
import { AppContext, useAppContext } from '@app/context-provider'
import { useGetCustomerByEmail, useGetCustomerContact, useGetLicenseInformation } from '@hooks'
import { SnackbarUtility } from '@utilities'
import loader from './../../../public/assets/images/loader-disabled-btn.gif'
import { UserProfileWrap } from './user-profile.style'

export const ProfilePage = () => {
    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders;
    const [response, setResponse] = useState<any>()
    const [isPasswordShow, setIsPasswordShow] = useState(false)
    const { state: app_state } = useAppContext()
    const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)
    const customer_data = JSON.parse(localStorage.getItem("settings"))?.email
    const { data: customerData } = useGetCustomerByEmail(customer_data);
    const { data: licenseInformation } = useGetLicenseInformation(customerData?.reference_id ?? "");
    const { data: contactData } = useGetCustomerContact(customerData?.reference_id);

    const layoutText = {
        title: PLACEHOLDERS.USER_PROFILE_CHANGE_PASSWORD,
        buttonText: PLACEHOLDERS.USER_PROFILE_SAVE_CHANGE,
        passwordHelperText: PLACEHOLDERS.USER_PROFILE_HYPER_TEXT,
        placeholder: PLACEHOLDERS.ABOUT_CARD_TYPE_HERE,
        oldpasswordText:PLACEHOLDERS.CHANGE_PASSWORD_TEXT,
        currentPasswordLabel:PLACEHOLDERS.currentPasswordLabel,
        newPasswordLabel:PLACEHOLDERS.newPasswordLabel,
        confirmPasswordLabel:PLACEHOLDERS.confirmPasswordLabel,
        password:PLACEHOLDERS.password,
        oldpassword:PLACEHOLDERS.oldpassword,
        confirmpassword:PLACEHOLDERS.confirmpassword,
        passworderror:PLACEHOLDERS.passworderror
    }

    useEffect(() => {
        if (response?.message) {
            snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(response?.message));
            setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 5000);
        }
    }, [response])

    return (
        <>
            <UserProfileWrap theme={theme} className='user-profile'>
                <Box theme={theme} mt={2}>
                    <AboutCard contactData={contactData} customerReferenceID={customerData?.reference_id} />
                </Box>
                <Box theme={theme} mt={2}>
                    <Stack paddingXs={3} paddingMd={4} className='change-password res-btn'>
                        <ChangePasswordComponent
                            theme={theme}
                            setResponse={setResponse}
                            next_screen_url={Navigation.PROFILE.USER_PROFILE.replace(':tenant', app_state.slug)}
                            layoutText={layoutText}
                            isAdminPassword={true}
                            setIsOnSaveShow={setIsPasswordShow}
                            isOnSaveShow={isPasswordShow}
                        />
                    </Stack>
                </Box>
                <Box theme={theme} mt={2}>
                    <Stack paddingXs={3} paddingMd={4} className='delivery-preference res-btn'>
                        <ProfileAddress contactData={contactData} customerReferenceID={customerData?.reference_id} />
                    </Stack>
                </Box>
                <Box theme={theme} mt={2}>
                    {licenseInformation ? <DocumentsCard licenseData={licenseInformation} /> : <></>}
                </Box>
                <Snackbar
                    theme={theme}
                    message={
                        <Box theme={theme} display={'flex'} gap={1}>
                            <Box theme={theme} width="30px" display={'flex'}>
                                <Image theme={theme} src={loader} aspectRatio="16:9" />
                            </Box> {snackbarState.message}
                        </Box>}
                    open={snackbarState.open}
                    onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
                    anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
                />
            </UserProfileWrap>
        </>
    )
}