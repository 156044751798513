import { useTheme } from '@mui/material'
import { Box, Typography } from '@ntpkunity/controls'
import { ProductDecriptionCardProps } from './product-description-card.props'
import { ProductDecriptionCardWrap } from './product-description-card.style'
import clsx from 'clsx'
import React from 'react'

export const ProductDecriptionCard = ({ label, title, productPrice, secondaryText, desc, link }: ProductDecriptionCardProps) => {
    const theme = useTheme()

    return (
      <ProductDecriptionCardWrap theme={theme} className="product-detail">
        <Box theme={theme} className="cj-card">
          <Box theme={theme}>
            {label && (
              <Typography
                theme={theme}
                className="text-muted text-body2"
                variant="body2"
                component={"p"}
                display={"block"}
              >
                {label}
              </Typography>
            )}
            {title && (
              <Typography
                theme={theme}
                variant="h3"
                component={"h3"}
                display={"block"}
                className="text-h3"
              >
                {title}
              </Typography>
            )}
            {productPrice && (
              <Typography
                theme={theme}
                variant="h4"
                component={"h4"}
                display={"block"}
                className="text-primary text-h4"
              >
                {productPrice}
              </Typography>
            )}
            {secondaryText && (
              <Typography
                theme={theme}
                className="text-muted text-body2"
                variant="body2"
                component={"p"}
                display={"block"}
                mt={1}
              >
                {secondaryText}
              </Typography>
            )}
          </Box>
          {desc && (
            <Typography
              theme={theme}
              className="text-muted text-body2"
              variant="body2"
              component={"p"}
              display={"block"}
            >
              {desc}
            </Typography>
          )}
          {link && (
            <Box theme={theme} className="card-action">
              {link.map(
                (item, index) =>
                  !item?.hideLink && (
                    <Typography
                      theme={theme}
                      variant="body2"
                      component={"span"}
                      display={"block"}
                      key={index}
                      className="text-body2"
                    >
                      <a
                        className="link decoration-none fw-medium text-primary text-body2"
                        onClick={item.onClick}
                      >
                        {item.title}
                      </a>
                    </Typography>
                  )
              )}
            </Box>
          )}
        </Box>
      </ProductDecriptionCardWrap>
    );
}