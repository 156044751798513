import { useTheme } from '@mui/material'
import { Box, Button, Icon, Typography, Dialog } from '@ntpkunity/controls'
import { AlertPopupWrap } from './alert-poup.style'
import { Popup } from '@components'



export const AlertDialog = (props) => {
    const theme = useTheme()

    const { title, subtitle, btnText, onClick, isOpen, onClose, disabled, startIcon } = props;

    return (
        <>
            <Popup>
                <Dialog
                    theme={theme}
                    size="sm"
                    open={isOpen}
                    onCloseDialog={onClose}
                    disablePortal
                    noFooter
                >
                    <AlertPopupWrap theme={theme} className='alert-popup-wrap'>
                        {
                            <Box theme={theme} mb={3} pt={6}>
                                <Icon name="DialogAlertIcon" />
                            </Box>
                        }
                        {title &&
                            <Typography theme={theme} component={'h2'} variant='h2' mb={2}>
                                {title}
                            </Typography>
                        }

                        {subtitle &&
                            <Typography theme={theme} component={'p'} variant='body2'>
                                {subtitle}
                            </Typography>
                        }
                        {btnText &&
                            <Box theme={theme} mt={3} mb={8} display={'flex'} justifyContent={'center'}>
                                <Button theme={theme} danger text={btnText} onClick={onClick} disabled={disabled} startIcon={startIcon} />
                            </Box>
                        }
                    </AlertPopupWrap>
                </Dialog>
            </Popup>
        </>
    )
}


