export enum OrderStatus {
    COMPLETED = "Completed",
    DRAFT = "Draft",
    CANCELLED = "Cancelled",
    APPROVED = "Approved",
    DECLINED = "Declined",
    SUBMITTED = "Submitted",
    CONDITIONED= "Conditioned",
    ReSubmitted = "Re Submitted",
    PendingConfirmation = "Pending Confirmation",
    TradeInInspection = "Trade-In Inspection",
    AssetInspectionPending = "Asset Inspection pending",
    PendingTradeInConfirmation = "Pending Trade-In Confirmation",
    Withdrawn = "Withdrawn",
    REVIEWED= 'Reviewed'
}
