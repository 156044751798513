import axios, { AxiosError } from 'axios';

// export class ApiError extends Error {
//   name: string

//   constructor(public response: Response, public data?: ErrorDataType, message?: string) {
//     super(message || response.statusText)
//     this.name = this.constructor.name
//   }
// }

// export type ErrorDataType = {
//   status?: number
//   errorCode?: string
//   message?: string
// }

export const http = (URL, optionalHeaders = null) => {
  const access_token = localStorage.getItem('access_token');
  const headers = {
    'Content-type': 'application/json',
    ...optionalHeaders
  }

  if (access_token) {
    headers['Authorization'] = `Bearer ${access_token}`
  }

  const AXIOS = axios.create({
    baseURL: URL,
    withCredentials: false,
    headers: headers
  });

  const requestInterceptor = async (request) => {
    switch (request.baseURL) {
    }
    return request
  }
  const errorInRequestInterceptor = async (error) => { }

  AXIOS.interceptors.request.use(requestInterceptor, errorInRequestInterceptor);

  const responseInterceptor = (response) => { return response }
  const errorInResponseInterceptor = (error: AxiosError) => {
    const status = error?.response?.status;
    switch (status) {
      default:
        return Promise.reject(error?.response?.data);
    }
  }

  AXIOS.interceptors.response.use(responseInterceptor, errorInResponseInterceptor);

  return AXIOS;
}
