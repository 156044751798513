import React, {FC, useContext, useEffect, useState} from 'react'
import { useTheme } from '@mui/material'
import { PageWrap } from './strip-payment.style'
import {Accordion, Box, Button, Icon, Skeleton, Typography} from '@ntpkunity/controls'
import { AccordianBox, PageHeader, Stack, SummaryList } from '@components'
import {convertToAmountWithDecimals} from "../../helpers/methods";
import { AppContext } from '@app/context-provider'
import { PaymentCheck } from 'constants/enums';

export const StripPayment: FC<{
    orderDetails: any,
    handleOpenConfirmationDialog: any,
    paymentDetails: any
    paymentDetailsLoading: boolean

}> = ({orderDetails, handleOpenConfirmationDialog, paymentDetails, paymentDetailsLoading}) => {
    const theme = useTheme()
    const [firstMonthDetails, setFirstMonthDetails] = useState([])
    const [totalPaidAmount, setTotalPaidAmount] = useState(0)
    const [transactionHistory, setTransactionHistory] = useState(paymentDetails?.transaction_history || [])
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders;
    const default_currency_code = appState.state.default_currency_code || 'USD';

    useEffect(() => {
        const list = [];
        if (orderDetails?.due_at_signing) {
            list.push({
                title: PLACEHOLDERS.STRIPE_PAYMENT_BASE_MONTHLY_PAYMENT,
                subtitle: `${convertToAmountWithDecimals(orderDetails?.estimated_monthly_payment, default_currency_code)}`
            });
        }
        if (orderDetails?.saleTax) {
            list.push({
                title: PLACEHOLDERS.STRIPE_PAYMENT_SALES_TAX,
                subtitle: `${convertToAmountWithDecimals(orderDetails?.saleTax, default_currency_code)}`
            });
        }
        setFirstMonthDetails(list);
    }, [orderDetails]);

    useEffect(()=>{
        setTotalPaidAmount(paymentDetails?.total_paid_amount ? parseFloat(paymentDetails?.total_paid_amount) : 0)
        setTransactionHistory(paymentDetails?.transaction_history || [])
    },[paymentDetails])

    return (
        <PageWrap theme={theme} className='page-wrap'>
            <PageHeader
                marginBottom={3}
                title={PLACEHOLDERS.STRIPE_PAYMENT_TITLE}
                subTitle={PLACEHOLDERS.STRIPE_PAYMENT_SUBTITLE}
            />
                <>
                    {transactionHistory?.length > 0 ?
                        <>
                            <Stack paddingMd={3} marginTopMd={3} marginTopXs={3}>
                                <Box theme={theme} className='stack-header'>
                                    <Box theme={theme} className='header-text'>
                                        <Typography theme={theme} component={'p'} variant='subtitle2'>{PLACEHOLDERS.STRIPE_PAYMENT_OUTSTANDING_BALANCE}</Typography>
                                        {paymentDetailsLoading ?

                                        <Skeleton theme={theme} width={'20%'} height={24} />:
                                        <Typography theme={theme} component={'h4'} variant='h4'
                                                    className='text-primary text-h4'>{convertToAmountWithDecimals(parseFloat(orderDetails?.due_at_signing)-totalPaidAmount, default_currency_code)}</Typography>
                                        }
                                    </Box>
                                    {paymentDetailsLoading ?
                                        <Skeleton theme={theme} width={'192px'} height={49} />:
                                    <Button className='action-btn' theme={theme} primary text={PLACEHOLDERS.STRIPE_PAYMENT_CONTINUE_PAYMENT} onClick={() => handleOpenConfirmationDialog()} disabled={parseFloat(orderDetails?.due_at_signing)-totalPaidAmount === 0}/>
                                    }
                                </Box>
                            </Stack>

                            {paymentDetailsLoading?
                            <Skeleton theme={theme} width={'40%'} height={84} sx={{marginTop:1}} />
                                :

                            (paymentDetails?.transaction_history.map((item, index) =>
                                <Stack paddingMd={3} marginTopMd={3} marginTopXs={3} key={index}>

                                    <Box theme={theme} display={'flex'} alignItems={'center'} gap={3}>
                                        {item?.funding === 'checking' ?
                                            <Icon className='text-primary' name="LenderConfigurations"/>:
                                            <Icon className='text-primary' name="PayCardIcon"/>
                                        }
                                        <Box theme={theme} flexGrow={1}>
                                            <Typography theme={theme} variant='caption' component={'small'}
                                                        display={'block'} className='text-muted'>{item?.status ? item?.status == 'Pending' ? 'Payment In-Process': item?.status == 'Approved' ? 'Amount Paid': false: false}: {convertToAmountWithDecimals(item?.order_amount, default_currency_code)}</Typography>

                                            <Typography theme={theme} variant='body2' component={'span'} display={'block'}
                                                        className='fw-sbold'>{item?.funding == PaymentCheck.CHECK || item?.funding == PaymentCheck.DEBIT  ? 'ACH': item?.funding} {item?.card_last4 ? '- ' + item.card_last4.slice(-4) : ''}</Typography>
                                        </Box>
                                    </Box>

                                </Stack>
                            ))}

                        </>
                        :
                        <Stack paddingMd={3} marginTopMd={3} marginTopXs={3}>
                            <Box theme={theme} className='stack-header'>
                                <Box theme={theme} className='header-text'>
                                    <Typography theme={theme} component={'p'} variant='subtitle2'>{PLACEHOLDERS.STRIPE_PAYMENT_TOTAL_PAYABLE_AMOUNT}</Typography>

                                    {
                                        paymentDetailsLoading ? <Skeleton theme={theme} width={'20%'} height={24} />:
                                        <Typography theme={theme} component={'h4'} variant='h4' className='text-primary text-h4'>{convertToAmountWithDecimals(orderDetails?.due_at_signing, default_currency_code)}</Typography>
                                    }

                                </Box>
                                {
                                    paymentDetailsLoading ? <Skeleton theme={theme} width={'192px'} height={49} />:

                                <Button className='action-btn' theme={theme} primary text={PLACEHOLDERS.STRIPE_PAYMENT_CONTINUE_PAYMENT} onClick={() => handleOpenConfirmationDialog()} />
                                }
                            </Box>
                        </Stack>
                        }

            <Stack paddingMd={3} marginTopMd={3} marginTopXs={3}>
                <Box theme={theme} className='stack-header'>
                    <Box theme={theme} className='dFlex justifyContentBetween w-100'>
                        <Typography theme={theme} component={'p'} variant='subtitle1' className='text-truncated'>{PLACEHOLDERS.STRIPE_PAYMENT_DUE_AT_SIGNING}</Typography>
                        <Typography theme={theme} component={'p'} variant='subtitle1' className='text-primary text-truncated'>{convertToAmountWithDecimals(orderDetails?.due_at_signing, default_currency_code)}</Typography>
                   </Box>
                </Box>
                <AccordianBox>
                    <Box theme={theme} sx={{ mt: 3 }}>
                        <Accordion theme={theme} items={[
                            {
                                title: PLACEHOLDERS.STRIPE_PAYMENT_FIRST_MONTH_PAYMENT,
                                key: 'string2',
                                subtitle: <>
                                    {
                                    convertToAmountWithDecimals(orderDetails?.estimated_monthly_payment, default_currency_code)
                                    }
                                </>,
                                content: <>
                                    <SummaryList options={firstMonthDetails}
                                    />
                                </>,
                            },
                        ]}>
                        </Accordion>
                    </Box>
                </AccordianBox>

                <Stack bgLight paddingMd={3} marginTopXs={3} dFlex justifyBetween>
                    <Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>{PLACEHOLDERS.STRIPE_PAYMENT_DOWN_PAYMENT}</Typography>
                    {
                    <Typography theme={theme} variant='subtitle2' className='fw-medium' component={'p'}>{convertToAmountWithDecimals(orderDetails?.down_payment, default_currency_code)}</Typography>
                    }
                </Stack>
            </Stack>

            </>
        </PageWrap>
    )
}
