import React, { ReactNode } from 'react'
import { CjFileUploaderWrap } from './file-uploader.style'
import { useTheme } from '@mui/material'

export const FileUploader = ({ children }: { children: ReactNode }) => {
    const theme = useTheme()
  return (
    <CjFileUploaderWrap className='cj-file-uploader-wrap' theme={theme}>
        {children}
    </CjFileUploaderWrap>
  )
}
