import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductDecriptionCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-detail': {
        '.cj-card': {
            backgroundColor: '#F5F5F7',
            padding: '32px',
            borderRadius: 16,
            border: '2px solid transparent',
            transition: '0.4s',
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            [theme.breakpoints.down('md')]:{
                padding: '24px',
            },
            '.card-action': {
                '.link': {
                    cursor: 'pointer'
                }
            }
        }
    }
}))
