import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ProductOrderCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-order-card': {
        backgroundColor: theme.palette.common.white,
            padding: '24px',
            borderRadius: 16,
            display: 'flex',
            alignItems: 'stretch',
            flexDirection: 'column',
            gap: 24,
            height: '100%',
            '.card-head': {
                position: 'relative',
                '.status': {
                    textTransform: 'uppercase',
                    position: 'absolute',
                    left: '-8px',
                    top: '-7px',
                    borderRadius: 8,
                    padding: '4px 8px',
                    '&.in-progress': {
                        background: 'linear-gradient(0deg, rgba(0, 51, 253, 0.08) 0%, rgba(0, 51, 253, 0.08) 100%), #FFF',
                        color: theme.palette.primary.main
                    },
                    '&.completed': {
                        background: 'linear-gradient(0deg, rgba(51, 204, 89, 0.08) 0%, rgba(51, 204, 89, 0.08) 100%), #FFF',
                        color: theme.palette.success.main
                    },
                    '&.cancelled': {
                        background: 'linear-gradient(0deg, rgba(255, 51, 51, 0.08) 0%, rgba(255, 51, 51, 0.08) 100%), #FFF',
                        color: theme.palette.error.main
                    },
                    '&.draft': {
                        background: 'linear-gradient(0deg, rgba(0, 0, 0, 0.08) 0%, rgba(0, 0, 0, 0.08) 100%), #FFF',
                        color: '#666666'
                    },
                    '&.conditioned': {
                        background: 'linear-gradient(0deg, rgba(255, 204, 0, 0.08) 0%, rgba(255, 204, 0, 0.08) 100%), #FFF',
                        color: '#FFCC00'
                    }
                },
                '.card-img': {
                    '.img': {
                        width: '100%',
                        height: '165px',
                        objectFit: 'cover',
                        borderRadius: 16,
                    }
                }
            },
            '.card-content': {
                minHeight: 112,
                display: 'flex',
                flexDirection: 'column',
                gap: 24,
                '.title': {
                    display: 'flex',
                    justifyContent: 'space-between',
                    gap: 8,
                    [theme.breakpoints.between('md', 'lg')]: {
                        flexWrap: 'wrap'
                    }
                },
                '.id-vin': {
                    display: 'flex',
                    alignItems: 'flex-start',
                    alignContent: 'flex-start',
                    alignSelf: 'stretch',
                    flexWrap: 'wrap',
                    gap: 4,
                    '.order-id': {
                        background: '#F5F5F7',
                        padding: '3px 8px',
                        borderRadius: 8,
                        
                    }
                }
            },
            '.card-action': {
                display: 'flex',
                gap: 16,
            }
    }
}))
