import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const DealershipCardDetailWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.dealership-card-detail-wrap': {
    '.dealer': {
        cursor: 'pointer',
        '.location': {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            marginTop: 10,
            svg: {
                width: 24,
                height: 24,
                path: {
                    stroke: '#999999'
                }
            }
        },
        '&.selected': {
            backgroundColor: '#333',
            '.MuiTypography-root': {
                color: theme.palette.common.white
            },
            svg: {
                path: {
                    stroke: theme.palette.common.white
                }
            }
        }
    },
    '.no-dealership': {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: '80px',
        textAlign: 'center'
    }
  }
}))
