import { colors } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.detail': {
        '.address-layout-wrap': {
            '.MuiGrid-root.MuiGrid-container': {
                '.MuiGrid-root.MuiGrid-item.MuiGrid-grid-lg-3':
                {
                    [theme.breakpoints.down('md')]: {
                        maxWidth: '50%',
                        flexBasis: '50%'
                    },

                }
            },
            '.MuiButton-root.btn.btn-primary': {
                backgroundColor: 'transparent',
                borderColor: theme.palette.grey[300],
                color: theme.palette.grey[900],
                [theme.breakpoints.down('md')]: {
                    width: '100%'
                },
                '&:hover': {
                    backgroundColor: theme.palette.grey[900],
                    borderColor: theme.palette.grey[900],
                    color: theme.palette.common.white
                },
            },
        }
    },
    '&.google-map-empty-div': {
        '.google-map': {
            '>': {
                '&:nth-child(2)': {
                    display: 'none'
                },
                '&:nth-child(3)': {
                    borderRadius: 8,
                    aspectRatio: '16/9'
                }
            }
        }
    }
}))
