// using reducer to manage the state of the snackbar
export namespace SnackbarUtility {

  // initial state of the snackbar
  export const initialState = {
    open: false,
    message: '',
  }

  // action types for the snackbar
  const SNACKBAR_OPEN = 'SNACKBAR_OPEN'
  const SNACKBAR_CLOSE = 'SNACKBAR_CLOSE'

  // action creators for the snackbar
  export const OPEN_SNACKBAR = (message: string) => ({ type: SNACKBAR_OPEN, message })
  export const CLOSE_SNACKBAR = () => ({ type: SNACKBAR_CLOSE })

  // reducer for the snackbar
  export const snackbarReducer = (state = initialState, action: any) => {
    switch (action.type) {
      case SNACKBAR_OPEN:
        return { open: true, message: action.message }
      case SNACKBAR_CLOSE:
        return { open: false, message: '' }
      default:
        return state
    }
  }

}