import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SectionWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.section-wrap': {
    paddingTop: 60,
    paddingBottom: 60,
    '.brand-container': {
        '.MuiGrid-container': {
            '.MuiGrid-item': {
                [theme.breakpoints.up('md')]: {
                    flexBasis: '12.5%',
                    maxWidth: '12.5%'
                }
            }
        }
    }
  }
}))
