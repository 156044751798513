import { Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { IBoxProps } from '@ntpkunity/controls'

export const OrderChangePopupWrap = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(({ theme }) => ({
    '&.final-popup-wrap': {
      '.product-img': {
        '.aspect-ratio-image-wrap': {
          'img': {
            borderRadius: '16px'
          }
        }

      }
    }
  }))