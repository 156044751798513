import { useTheme } from '@mui/material'
import { Stack, SelectionButton } from '../../../components'
import { Skeleton, Typography } from '@ntpkunity/controls'
import { FC, useEffect, useContext, useState } from 'react'
import { Translations } from '../../../constants'
import { IContractMileage } from "../../../interfaces"
import { AppContext } from '@app/context-provider'
import { ActionType, OrderActionType, useOrderContext, usePaymentContext } from 'pages'
import { updateAnnualUsage } from 'pages/(order-management)/order/order-context-provider'

export const Mileage: FC<{ mileages: IContractMileage[], setupDataLoading: boolean }> = ({ mileages, setupDataLoading }) => {
  const theme = useTheme()
  const { state, dispatch } = usePaymentContext()
  const { state: { order: orderState }, dispatch: orderDispatch } = useOrderContext()
  const [selectedMiles, setSelectedMiles] = useState(orderState.annual_usage)
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  useEffect(() => {
    setSelectedMiles(orderState.annual_usage)
  }, [orderState.annual_usage])

  return (
    <Stack marginTopXs={3} className='scroll-horizontal'>
      <Typography theme={theme} component={'p'} variant='subtitle1' marginBottom={'12px'}>{PLACEHOLDERS.PAYMENT_MILES_TEXT}</Typography>
      {setupDataLoading ? <Skeleton theme={theme} width={'100%'} height={40} /> :
        <SelectionButton
          buttonItems={mileages?.map(mileage => (
            {
              title: `${mileage?.value / 1000}k`,
              bgLight: true,
              isSelected: mileage?.value === (selectedMiles),
              onClick: () => {
                setSelectedMiles(mileage?.value)
                orderDispatch(updateAnnualUsage(mileage?.value))
              }
            }
          ))}
        />
      }
    </Stack>
  )
}
