import { useTheme } from '@mui/material'
import { Grid, Input, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox, SelectionButton } from '@components'
import { MakeModelPopupWrap } from './make-modal-poup.style'
import React, {useEffect, useState} from 'react'
import { useAppContext } from '@app/context-provider'

export const MakeModelPopupThree = (props) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const {series, getValues, setValue, messages, clearErrors, trigger, setMakeModelYearTrim} = props
    const values = getValues()
    const {model} = values
    const [filter, setFilter] = useState(series || [])
    const [filterValue, setFilterValue] = useState('')
    const [selectedItem, setSelected] = useState(model)
    const [selectedValue] = useState(model)


    useEffect(() => {
        if(model !== selectedValue){
            setValue(messages.name.Trim, null)
        }
    }, [model]);

    useEffect(() => {
        const item = filter.find(item => item?.modelName === selectedItem)
        if(item) {
            setMakeModelYearTrim(prevState => ({
                ...prevState,
                'model_id': item?.modelId
            }))
            clearErrors()
            trigger()
        }
    }, [selectedItem]);
    const handleFilter = (value) => {
        setFilter(series?.filter(item => item?.modelName?.toLowerCase().includes(value?.toLowerCase())))
        setFilterValue(value)
    }
    const handleSelection = (item) => {
        setSelected(item?.modelName)
        setValue(messages?.name?.Model, item?.modelName)
        setMakeModelYearTrim(prevState =>({
            ...prevState,
            'model_id': item?.modelId
        }))
    }
    return(
        <MakeModelPopupWrap theme={theme} className='make-model-popup-two-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={3} className='make-modal-popup'>
                <Grid
                    theme={theme}
                    container
                    rowSpacing={3}
                    columnSpacing={{ xs: 2 }}
                >
                    <Grid item xs={12} theme={theme} className='sticky'>
                        <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>{PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_THREE_MODEL_PROMPT_LABEL}</Typography>
                        <Textbox>
                            <Input value={filterValue} name='modelFilter' type={'text'} fullWidth theme={theme} placeholder={PLACEHOLDERS.TRADE_IN_MAKE_MODEL_POPUP_THREE_INPUT_PLACEHOLDER} onChange={(value) => handleFilter(value)}/>
                        </Textbox>
                    </Grid>
                    <Grid item xs={12} theme={theme}>
                        <SelectionButton
                            buttonItems={filter.map((item) => ({
                                title: item.modelName,
                                onClick: () => handleSelection(item),
                                isSelected: item?.modelName === selectedItem
                            }))}
                        />
                    </Grid>
                </Grid>
            </Stack>
        </MakeModelPopupWrap>
    )
}


