import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'
import success from './../../public/assets/icons/successTick.svg'

export const StepperProgressWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.stepper-progress-wrap': {
        ul: {
            paddingLeft: 0,
            listStyleType: 'none',
            '.step-item': {
                display: 'flex',
                '.bullot': {
                    width: '24px',
                    height: '24px',
                    border: '2px solid' + theme.palette.grey[100],
                    marginRight: '8px',
                    borderRadius: '50px',
                    position: 'relative',
                    flexShrink: 0,
                    zIndex: 2,
                },
                '.content': {
                    position: 'relative',
                    '.MuiTypography-caption': {
                        paddingBottom: '24px',
                        display: 'block',
                        '&::before': {
                            content: `""`,
                            position: 'absolute',
                            border: '2px dashed' + theme.palette.grey[100],
                            borderRight: 'none',
                            borderRadius: 10,
                            height: 'calc(100% - 22px)',
                            top: '22px',
                            left: '-21px'
    
                        }
                    }
                },
                '&.active': {
                    '.bullot': {
                        borderWidth: 7,
                        borderColor: theme.palette.primary.main,
                    },
                    '.content': {
                        '.MuiTypography-caption': {
                            '&:before': {
                                display: 'block',
                                background: 'linear-gradient(#fff 0 0) padding-box, linear-gradient(180deg, rgb(0, 51, 253) 0, rgb(204, 206, 217) 100%) border-box',
                                border: '2px dashed #F5F5F7',
                                borderRight: 'none'
                            }
                        } 
                    }
                },
                '&.completed': {
                    '.bullot': {
                        backgroundImage: `url(${success})`,
                        borderColor: theme.palette.success.main,
                        backgroundColor: theme.palette.success.main,
                        backgroundSize: 'cover',
                        '&::after': {
                            content: `''`,
                            position: 'absolute',
                            
                            top: '3px',
                            left: '6px',
                        }
                    },
                    '.content': {
                        '.MuiTypography-caption': {
                            '&::before': {
                                borderStyle: 'solid',
                                borderColor: 'transparent transparent transparent' + theme.palette.success.main,
                                borderWidth: 2
                            }
                        } 
                    }
                },
                '&:last-child': {
                    '.content': {
                        '.MuiTypography-caption': {
                            paddingBottom: 0,
                        }
                    }
                }
            }
        }
    }
}))
