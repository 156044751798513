import { styled } from '@mui/material/styles'
import AppBar from '@mui/material/AppBar'
import { HeaderProps } from './header.types'

export const HeaderWrap = styled(
  AppBar,
  {}
)<Partial<HeaderProps>>(({ theme }) => ({
  '&.header-wrap': {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    borderBottom: '1px solid' + theme.palette.grey[50],
    position: 'fixed',
    [theme.breakpoints.down('md')]: {
      position: 'relative',
    },
    '.u-toolbar': {
      minHeight: 72,
      padding: '0 24px',
      [theme.breakpoints.up('md')]: {
        padding: '0 40px'
      }
    }
  }
}))
