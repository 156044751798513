import { Endpoint } from "../constants/endpoint";
import { http } from "../utilities";

export const DMS_BASE_URL =  process.env.DMS_BASE_URL;

export namespace LeadManagement {

  export const updateLead = (data :any ):any => {
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_LEAD}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

}
