import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const ConsentTextWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.consent-text': {
      'p': {
        fontFamily: theme.typography.fontFamily,
        fontSize: theme.typography.body2.fontSize,
        lineHeight: theme.typography.body2.lineHeight,
        fontWeight: theme.typography.fontWeightMedium,
        color: theme.palette.grey[600],
        marginBottom: 0,
        marginTop: 24
      }
    }
}))
