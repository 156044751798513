import { useEffect, useState } from "react";
import { useGetCreditResponse, useUploadDocument } from "@hooks";
import { useTheme } from "@mui/material";
import { Box, Button, Icon, Typography } from "@ntpkunity/controls";
import { useOrderContext } from "@pages";
import { useAppContext } from "@app/context-provider";
import { StipPopupComponent } from "./stipulation-popup.component";
import { OrderStatus } from "@constants";

export const Stipulation = ({ currency, setReSubmitRef, setIsReSubmitEnabled, isReSubmitEnabled }: { currency: string, setReSubmitRef: any, setIsReSubmitEnabled: any, isReSubmitEnabled: boolean }) => {
    const theme = useTheme();
    const { state: { order, isFlowExecuted }, handleUpdateOrder } = useOrderContext()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;
    const [openStipDialog, setOpenStipDialog] = useState(false)
    const [stipulations, setStipulations] = useState<any>()
    const [filters, setFilters] = useState({
        terms: 0,
        downPayment: 0,
        documents: []
    });
    const [documents, setDocuments] = useState(filters?.documents || []);
    const { data: creditDecision } = useGetCreditResponse(order?.reference_number);
    const { mutate: uploadDocument, isLoading: uploadDocumentLoading } = useUploadDocument();

    const stipulationTerm = creditDecision?.[0]?.stipulation.find(stip => stip.stipulation_code === "ST003");
    const stipulationFinance = creditDecision?.[0]?.stipulation.find(stip => stip.stipulation_code === "ST001");
    const stipulationUpload = creditDecision?.[0]?.stipulation.find(stip => stip.stipulation_code === "ST004");

    const termsValid = filters.terms !== 0;
    const downPaymentValid = order?.net_finance_amount >= stipulationFinance?.value_from && order?.net_finance_amount <= stipulationFinance?.value_to;

    const isReSubmit = (termsValid || !stipulationTerm) &&
        (downPaymentValid || !stipulationFinance) &&
        filters?.documents?.some(item => item?.document_urls?.some(document => document?.location?.length));

    const handleFilterUpdate = (key: any, value: any) => {
        setFilters(prevFilters => ({
            ...prevFilters,
            [key]: value
        }));
    };

    const reSubmitOrder = () => {
        uploadDocument(documents, {
            onSuccess: () => {
                handleUpdateOrder()
            },
        });
    };

    useEffect(() => {
        setIsReSubmitEnabled(!isReSubmit || uploadDocumentLoading)
    }, [isReSubmit, uploadDocumentLoading])

    useEffect(() => {
        if (setReSubmitRef) {
            setReSubmitRef({ reSubmitOrder })
        }
    }, [setReSubmitRef])

    useEffect(() => {
        const filteredStipulations = [];
        if (stipulationTerm) {
            filteredStipulations.push({
                title: `${PLACEHOLDERS.SUBMIT_ORDER_TERM_BETWEEN} ${stipulationTerm?.value_from} ${PLACEHOLDERS.TERM_AND} ${stipulationTerm?.value_to} ${PLACEHOLDERS.TERM_MONTHS}`,
                btnText: `${PLACEHOLDERS.CHANGE_TERM}`,
                isValid: filters.terms !== 0,
            });
        }
        if (stipulationFinance) {
            filteredStipulations.push({
                title: `${PLACEHOLDERS.SUBMIT_ORDER_FINANCED_AMOUNT_BETWEEN}
                ${currency}${stipulationFinance?.value_from?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })}
                   ${PLACEHOLDERS.TERM_AND}
                   ${currency}${stipulationFinance?.value_to?.toLocaleString(undefined, {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2
                })}. ${PLACEHOLDERS.ADJUST_FINANCED_PAYMENT}`,
                btnText: `${PLACEHOLDERS.ADJUST_FINANCED_PAYMENT}`,
                isValid: downPaymentValid,
            });
        }
        if (stipulationUpload) {
            filteredStipulations.push({
                title: filters?.documents?.[0]?.document_urls?.[0]?.location ? `${PLACEHOLDERS.DOCUMENT_UPLOADED_SUCCESSFULLY}` : `${PLACEHOLDERS.IS_DOCUMENT_UPLOADED_SUCCESSFULLY}. ${PLACEHOLDERS.PLEASE_UPLOAD}`,
                btnText: `${PLACEHOLDERS.DOCUMENT_UPLOAD}`,
                isValid: filters?.documents?.some(item => item?.document_urls?.some(document => document?.location?.length)),
            });
        }
        setStipulations(filteredStipulations)
    }, [filters, stipulationFinance, stipulationTerm, stipulationUpload, order?.net_finance_amount])


    return (
        <>
            {(order?.status === OrderStatus.CONDITIONED && stipulations) && stipulations?.map((item, index) => (
                <Box theme={theme} key={index} className='items'>
                    <Box theme={theme} className={`term ${item.isValid ? 'icon-green' : 'icon-error'}`}>
                        <Icon name={item.isValid ? 'GreenCheckIcon' : 'InfoErrorIcon'} />
                    </Box>
                    <Box theme={theme} className='term-text'>
                        <Typography theme={theme} className='' variant='caption' component={'small'}>{item?.title}</Typography>
                        <Button theme={theme} className='text-primary' defaultBtn text={item?.btnText} onClick={() => setOpenStipDialog(true)} />
                    </Box>
                </Box>
            ))}
            {openStipDialog && (
                <StipPopupComponent
                    onCloseDialog={() => setOpenStipDialog(false)}
                    order_info={order}
                    defaultCurrency={currency}
                    filters={filters}
                    setFilters={setFilters}
                    handleFilterUpdate={handleFilterUpdate}
                    reSubmitOrder={reSubmitOrder}
                    isReSubmitEnabled={isReSubmitEnabled}
                    setDocuments={setDocuments}
                    documents={documents}
                />
            )}
        </>
    )
}