import styled from "@emotion/styled";
import { Box, IBoxProps } from "@ntpkunity/controls";


export const CarouselButtons = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(({ theme }) => ({
    '&.carousel-button-group': {
      '.carousel-control': {
        position: 'absolute',
        backgroundColor: theme.palette.common.white,
        width: 24,
        height: 24,
        marginTop: -15,
        top: '16%',
        padding: 0,
        borderRadius: '50%',
        transform: 'translateY(-50%)',
        [theme.breakpoints.down('md')]: {
          display: 'none'
        },
        '&.next-btn': {
          right: 0,
          transform: 'translatey(-50%) rotate(180deg)'
        },
        '&.prev-btn': {
          left: 0
        }
      }
    }
}))

export const CarouselWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.carousel-wrap': {
    '.react-multi-carousel-list': {
      padding: 0
    },
    '.react-multi-carousel-track': {
      paddingBottom: 16
    },
    '.react-multi-carousel-item': {
      'img': {
        borderRadius: 16
      }
    },
    '.react-multi-carousel-dot': {
      'button': {
        width: 4,
        height: 4
      },
      '&.react-multi-carousel-dot--active': {
        'button': {
          width: 16,
          height: 4,
          backgroundColor: theme.palette.primary.main
        }
      }
    }
  }
}))



