import { SelectChangeEvent, Stack, useTheme } from "@mui/material";
import { Select, Typography } from "@ntpkunity/controls";
import { CoverageItemProps } from "./coverage-item.props";
import { CoverageItemWrap } from "./coverage-item.style";
import React, { useContext } from "react";
import { AppContext } from "@app/context-provider";

export const CoverageItem = ({ title, coveragePrice }: CoverageItemProps) => {
  const theme = useTheme();
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders;
  return (
    <CoverageItemWrap theme={theme} className="coverage-item-wrap">
      <Stack className="cj-card">
        <Typography
          theme={theme}
          className="fw-medium list-title"
          variant="body2"
          component={"p"}
          display={"block"}
        >
          {title}
        </Typography>
        <Typography
          theme={theme}
          className="text-primary"
          variant="subtitle1"
          component={"p"}
          display={"block"}
        >
          {"$"}
          {coveragePrice}
          /{PLACEHOLDERS.PER_MONTH}.
        </Typography>
      </Stack>
    </CoverageItemWrap>
  );
};
