import { createContext } from 'react';
interface FniContextType {
    addOrRemoveProduct: (id: number, check: boolean, price: number | null, term:number | null) => void;
    isLoading: boolean;
}

export const fniContext = createContext<FniContextType>({
    addOrRemoveProduct: (id: number, check: boolean, price: number = null, term: number = null) => { },
    isLoading: false,
});
