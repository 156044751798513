import {
  pagesActionType,
  useJourneyLayoutContext
} from "./journey-layout-context-provider";
import { JourneyLayout } from "@layouts";
import { Typography } from "@ntpkunity/controls";
import { JourneyFooter, PaymentBreakDownPopup } from "@libraries";
import { getNextTabTitle } from "@helpers";
import { Tabs } from "@constants";
import {
  Contracting,
  PaymentWithStripePage, SchedulePage, useOrderContext
} from "../../pages";
import {useEffect, useState} from "react";
import { useTheme } from "@mui/material";
import { updateCollapseState, useAppContext } from "@app/context-provider";
import {useGetStripePaymentDetails} from "../../hooks/stripe-payments";
import {useParams} from "react-router-dom";
import {useScrollOnTop} from "../../hooks/order-management";

export const PostOrderJourneyLayoutComponent = ({ vehicle,
  showPopup,
  orderTabs,
  nextTab,
  handleLinkClick,
  handleCloseDialog,
  onNextTabClick,
  children }) => {
  const theme = useTheme()
  const [activeTab, setActivetab] = useState(orderTabs?.[0] || "Contracting")
  const { state: appState, } = useAppContext()
  const { state: orderState } = useOrderContext()
  const { state: pageState, dispatch: pageDispatch } = useJourneyLayoutContext()
  const { orderId } = useParams()
  const {data: paymentDetails } = useGetStripePaymentDetails(orderState?.order?.reference_number ? orderState?.order.reference_number: orderId)

  const PLACEHOLDERS = appState.language.placeholders;
  const { dispatch } = useAppContext();

  useScrollOnTop(activeTab)

  useEffect(() => {
    if (paymentDetails?.total_paid_amount) {

      const dueAtSigning = orderState?.order?.due_at_signing;
      pageDispatch({
        type: pagesActionType.UPDATE_STRIPE_PAYMENT,
        payload: {
          success: paymentDetails.total_paid_amount == dueAtSigning,
          error: paymentDetails.total_paid_amount != dueAtSigning,
        },
      });
    }
  }, [paymentDetails, orderState]);

  const getActiveTab = (tab: string) => {
    switch (tab) {
      case Tabs.STRIPE_PAYMENTS:
        return <PaymentWithStripePage />
      case Tabs.CONTRACTING:
        return <Contracting />
      case Tabs.SCHEDULE:
        return <SchedulePage />
      default:
        return children
    }
  }

  const getActiveTabTitle = () => {
    switch (activeTab) {
      case Tabs.STRIPE_PAYMENTS:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE;
      case Tabs.CONTRACTING:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_CONTRACTING_TITLE
      case Tabs.SCHEDULE:
        return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE
    }
  }

  const onTabClick = (tab, setState) => {
    setActivetab(tab),
    setState(true)
    dispatch(updateCollapseState());
  }

  const createOrderStepObject = (tab: string,setState:(value: boolean) => void) => {

    switch (tab) {
      case Tabs.STRIPE_PAYMENTS:
        return {
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE,
          subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: activeTab !== Tabs.STRIPE_PAYMENTS ? pageState?.stripePayment.success : false,
          error: activeTab !== Tabs.STRIPE_PAYMENTS ? pageState?.stripePayment.error : false,
          isActive: activeTab === Tabs.STRIPE_PAYMENTS,
          onClick: () => {
            onTabClick(Tabs.STRIPE_PAYMENTS, setState)
          }
        }
      case Tabs.CONTRACTING:
        return {
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_CONTRACTING_TITLE,
          subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: activeTab !== Tabs.CONTRACTING ? pageState?.contracting.success : false,
          error: activeTab !== Tabs.CONTRACTING ? pageState?.contracting.error : false,
          isActive: activeTab === Tabs.CONTRACTING,
          onClick: () => onTabClick(Tabs.CONTRACTING, setState)
        }
      case Tabs.SCHEDULE:
        return {
          title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE,
          subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
          success: activeTab !== Tabs.SCHEDULE ? pageState?.appointment.success : false,
          error: activeTab !== Tabs.SCHEDULE ? pageState?.appointment.error : false,
          isActive: activeTab === Tabs.SCHEDULE,
          onClick: () => onTabClick(Tabs.SCHEDULE, setState)
        }
    }
  }

  return (
    <JourneyLayout
      orderTabs={orderTabs}
      nextTab={nextTab}
      getActiveTab={getActiveTab}
      getActiveTabTitle={getActiveTabTitle}
      createOrderStepObject={createOrderStepObject}
      activeTab={activeTab}
      setActivetab={setActivetab}

    >
      <Typography theme={theme} className='text-muted disclaimer' variant='caption' component={'small'} display={'block'} textAlign={'center'} mt={3}>
        {PLACEHOLDERS.PRICE_CHANGE_DISCLAIMER}
      </Typography>
      <JourneyFooter
        title={PLACEHOLDERS.FOOTER_TITLE}
        subTitle={`${(orderState?.order.estimated_monthly_payment).toLocaleString("en-US", {
          style: "currency",
          currency: appState?.default_currency_code || 'USD',
        })}/${PLACEHOLDERS.FOOTER_SUBTITLE}`}
        linkText={PLACEHOLDERS.FOOTER_LINK_TEXT}
        onLinkClick={handleLinkClick}
        buttonText={getNextTabTitle(orderTabs, orderState.activeTab, PLACEHOLDERS) !== '' ? `${PLACEHOLDERS.NEXT_TEXT}: ${getNextTabTitle(orderTabs, orderState.activeTab, PLACEHOLDERS)}` : ''}
        onButtonClick={onNextTabClick}
      />
      {showPopup && <PaymentBreakDownPopup onCloseDialog={handleCloseDialog} order_info={orderState?.order} />}
    </JourneyLayout>
  )

}