import { Routes, Route } from "react-router-dom";
import {
  HomePage,
  SignInPage,
  OtpPage,
  EmailVerificationPage,
  UserProfilePage,
  ForgotPasswordPage,
  ResendPasswordLinkPage,
  ResetPasswordPage,
  ResetPasswordLinkExpiredPage,
  SignupConsent,
  OrderPage,
  OrderContextProvider,
  InventoryPage,
  SubmitOrderPage,
  AppointmentsPage,
  FavoritesPage,
  MyOrdersPage,
  PrefrencesPage
} from '@pages';
import { Navigation } from '@constants'

import { Configurations } from './configuration'
import {BaseLayout, ProfileLayout} from "@layouts";
import { WorkflowProvider } from './router-workflow'
import { ProtectedRoute, Unprotected } from './router-protection'

export const RouteProvider = () => {
  return (
    <WorkflowProvider>
    <BaseLayout>
    <Routes>
      <Route path={Navigation.BASE_PATH} element={<Configurations />}>

        {/* ==================== (Home) ==================== */}
        <Route index element={<HomePage />} />
        {/* ==================== (Home) ==================== */}

        {/* ==================== (Authentication) ==================== */}
        <Route path={Navigation.AUTHENTICATION.SIGN_IN_PAGE} element={<Unprotected Component={SignInPage} />} />
        <Route path={Navigation.AUTHENTICATION.LOG_IN_PAGE} element={<Unprotected Component={SignInPage} />} />
        <Route path={Navigation.AUTHENTICATION.OTP_PAGE} element={<Unprotected Component={OtpPage} />} />
        <Route path={Navigation.AUTHENTICATION.FORGOT_PASSWORD} element={<Unprotected Component={ForgotPasswordPage} />} />
        <Route path={Navigation.AUTHENTICATION.RESEND_PASSWORD_LINK} element={<Unprotected Component={ResendPasswordLinkPage} />} />
        <Route path={`/${Navigation.AUTHENTICATION.RESET_PASSWORD}`} element={<Unprotected Component={ResetPasswordPage} />} />
        <Route path={Navigation.AUTHENTICATION.EMAIL_VERIFICATION} element={<Unprotected Component={EmailVerificationPage} />} />
        <Route path={Navigation.AUTHENTICATION.RESET_PASSWORD_LINK_EXPIRED} element={<Unprotected Component={ResetPasswordLinkExpiredPage} />} />
        <Route path={Navigation.AUTHENTICATION.USER_PROFILE} element={<Unprotected Component={UserProfilePage} />} />
        <Route path={Navigation.AUTHENTICATION.SIGNUP_CONSENT} element={<Unprotected Component={SignupConsent} />} />
        {/* ==================== (Authentication) ==================== */}

        {/* ================= (inventory-management) ================= */}
        <Route path={Navigation.INVENTORY_PAGE} element={<InventoryPage />} />
        {/* ================= (inventory-management) ================= */}

        {/* ================= (order-management) ================= */}
        <Route path={Navigation.ORDER_PAGE} element={
          <OrderContextProvider>
            <OrderPage />
          </OrderContextProvider>
        } />
        <Route path={Navigation.ORDER_ID_PAGE} element={
          <OrderContextProvider>
            <OrderPage />
          </OrderContextProvider>
        } />
        {/* ================= (order-management) ================= */}

        {/* ================= (submit-order) ================= */}
        <Route path={Navigation.APPLCATION_JOURNEY.SUBMIT_ORDER_PAGE} element={
        <OrderContextProvider>
          <SubmitOrderPage />
        </OrderContextProvider>
        
        } />
        {/* ================= (order-management) ================= */}

        {/* ================= (Profile) ================= */}
        <Route path={Navigation.PROFILE.USER_PROFILE} element={<ProtectedRoute Component={ProfileLayout} />} />
        <Route path={Navigation.PROFILE.APPOINTMENTS} element={<ProtectedRoute Component={AppointmentsPage} />} />
        <Route path={Navigation.PROFILE.FAVORITES} element={<ProtectedRoute Component={FavoritesPage} />} />
        <Route path={Navigation.PROFILE.ORDERS} element={<ProtectedRoute Component={MyOrdersPage} />} />
        <Route path={Navigation.PROFILE.PREFRENCES} element={<ProtectedRoute Component={PrefrencesPage} />} />
      </Route>
      <Route path={Navigation.PAGE_NOT_FOUND} element={<>Page not found.</>} />
    </Routes>
    </BaseLayout>
    </WorkflowProvider>
  )
}
