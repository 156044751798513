import { useTheme } from '@mui/material'
import { Box, Button, Icon, List, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'
import { StepperProgressWrap } from './stepper-progess.style'
import { StepperProgressProps } from './stepper-progess.props'
import React from 'react'

export const StepperProgress = ({ stepItems }: StepperProgressProps) => {
    const theme = useTheme()

    return(
        <StepperProgressWrap theme={theme} className='stepper-progress-wrap'>
            <ul>
                {stepItems.map((item, index) => (
                    <li key={index} className={clsx({ 'step-item': true, 'active': item.active, 'completed': item.completed })}>
                        <Box theme={theme} className='bullot'></Box>
                        <Box theme={theme} className='content'>
                            <Typography theme={theme} variant='subtitle1' component={'p'} display={'block'}>{item.title}</Typography>
                            <Typography theme={theme} variant='caption' component={'small'} className='text-muted' mt={0.5}>{item.subtitle}</Typography>
                        </Box>
                    </li>
                ))}
            </ul>
        </StepperProgressWrap>
        
    )
}