import React, { useContext } from 'react'
import { FC } from 'react'
import {  Grid, Typography, Box, ScrollableTabs  } from '@ntpkunity/controls'
import { useTheme  } from '@mui/material'
import { Tab } from '@components'
import { AppContext } from '@app/context-provider'

type IAssetListViewControlProps = {
    tabs?: any
    totalSum?: any
    defaultCurrency?: string

}
export const Tabs: FC<IAssetListViewControlProps> = ({
  tabs,
  totalSum,
  defaultCurrency
}) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  return (
    <>
      <Tab autoWidth hasBorder>
        <ScrollableTabs scrollButtons={'auto'} theme={theme} items={tabs} defaultTabIndex={0} />
      </Tab>
    </>
  )
}

