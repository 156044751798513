import { ReactNode, useContext, useEffect, useReducer, useState } from 'react'
import { useTheme } from '@mui/material'
import { Box, Grid, Snackbar, CircleLoader } from '@ntpkunity/controls'
import clsx from 'clsx'
import { JourneyLayoutWrap } from './journey-layout.style'
import { ProductCard, ProductDetail, Steps, StepsCount } from 'components'
import { OrderActionType, useOrderContext } from 'pages'
import { AppContext, updateCollapseState, useAppContext } from '@app/context-provider'
import {IEmploymentDetails, ILicenseDetail, IVehicle} from '@interfaces'
import { useQueryClient } from 'react-query'
import {FinanceTab, MitekCallTypes, Navigation, QueryKeys, ResidenceType} from '@constants'
import {pagesActionType, UpdateTabsStatus, useJourneyLayoutContext} from './journey-layout-context-provider'
import {getDealerCode, getNextTabTitle, isEmpty} from '@helpers'
import {
    SaveEmploymentDetails, useAddPersonalFinance,
    useConvertQuotationToApplication,
    useSaveOrderRequest,
    useUpdateCustomerContact, useUpdateEmploymentInfo,
    useUpdateOrderRequest, useUpdatePeronalFinanceInfo
} from '@hooks'
import {updateOrder } from 'pages/(order-management)/order/order-context-provider'

import { AssetDetailControl } from 'inventory-controls'
import { SnackbarUtility } from '@utilities'
import { useWorkflowContext } from '@app/router-workflow'
import {useFormContext} from "../../pages/(order-management)/order/form-context-provider";
import {formatPayloadDate, getBase64, getEmployedSinceDate, getMoveInDate} from "../../helpers/methods";
import { AlertDialog } from 'libraries/trade-in-journey-components/alert-popup/alert-popup'
import { useUpdateCustomerLicenseInfo } from 'hooks/customer-management'
import {useParams} from "react-router-dom";

export const JourneyLayout = ({
  orderTabs,
  nextTab,
  getActiveTab,
  getActiveTabTitle,
  createOrderStepObject,
  activeTab,
  setActivetab,
  children,
  hideDisclaimer
}: {
  orderTabs: any, nextTab: string, getActiveTab: any,
  getActiveTabTitle: any, activeTab: any, setActivetab: any
  createOrderStepObject: any, children?: ReactNode, hideDisclaimer?: boolean
}) => {
  const theme = useTheme()
  const { navigateNext, getOrderWorkflow } = useWorkflowContext()
  const [stepsCollapsed, setStepCollapsed] = useState(true)
  const [financeTab, setFinanceTab] = useState<{isOpen: boolean, tab: string[]}>({isOpen:false, tab:[] })
  const { dispatch } = useAppContext();

  const {finalSave} = useFormContext()
  const [openPopup, setOpenPopup] = useState(false)
  const { state: pageState, dispatch: pageDispatch } = useJourneyLayoutContext()
  const { state: { order }, dispatch: orderDispatch,calculateQuotation } = useOrderContext()
  const appState = useContext(AppContext);
  const [snackbarState, snackbardispatch] = useReducer(SnackbarUtility.snackbarReducer, SnackbarUtility.initialState)

  const PLACEHOLDERS = appState.state.language.placeholders
  const defaultCurrency = appState.state.default_currency_symbol

  const { mutate: saveOrderRequest, isLoading: saveRequestLodaing } = useSaveOrderRequest()
  const { mutate: updateOrderRequest, isLoading: updateRequestLodaing } = useUpdateOrderRequest()
  const { mutate: convertQuotationRequest } = useConvertQuotationToApplication()
  const { getDirtyForms, getFormData, resetFormState, formStates, isFormValid } = useFormContext()
  const { mutate: updateCustomerByReferenceId, isLoading: updateCustomerLoading } = useUpdateCustomerContact()
  const { mutate: addEmploymentData } = SaveEmploymentDetails()
  const { mutate: updateEmployInfoReferenceId } = useUpdateEmploymentInfo()
  const { mutate: createPersonalFinance } = useAddPersonalFinance()
  const { mutate: updatePersonalFinance } = useUpdatePeronalFinanceInfo()
  const { mutate: updateLicenseByInfoReferenceId } = useUpdateCustomerLicenseInfo();
  const { vin } = useParams()

  const queryClient = useQueryClient();
  const vehicle: IVehicle = queryClient.getQueryData(
    [QueryKeys.VEHICLE, vin]
  );

  useEffect(() => {
    if (nextTab)
      setActivetab(nextTab)
  }, [nextTab])

  useEffect(() => {
    orderDispatch({
      type: OrderActionType.UPDATE_ACTIVE_TAB,
      payload: activeTab
    })
  }, [activeTab])

  useEffect(() => {
    pageDispatch(UpdateTabsStatus(order))
  }, [order])

const handleSubmit = async () => {
    const workflow = JSON.parse(localStorage.getItem('workflow'));

    if (workflow.next_state === 'Sign In') {
        navigateNext(Navigation.AUTHENTICATION.SIGN_IN_PAGE);
        return;
    }
    
    try {
       const result = await validateFinanceData(); // Ensure this completes before proceeding
        
        if (pageState.addons.error || pageState.fnI.error || pageState.insurance.error || pageState.payments.error || !result ) {
                return false;
            }
            
            
        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS));
        const payload = {
            ...order,
            down_payment: (order?.down_payment * 100) / vehicle.internet_price,
            allowed_usage: order?.annual_usage,
            running_id: localStorage.getItem('running_id')
        };

        if (order?.identifier) {
            await new Promise<void>((resolve, reject) => {
                updateOrderRequest(payload, {
                    onSuccess() {
                        queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, payload.reference_number]);
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_UPDATE_SUCCESS));
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                        resolve();
                    },
                    onError() {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_UPDATE_FAIL));
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                        reject();
                    }
                });
            });
        } else {
            await new Promise<void>((resolve, reject) => {
                saveOrderRequest([payload], {
                    onSuccess(response) {
                        orderDispatch(updateOrder(response[0]));
                        const quotationObject = response
                            .filter(data => data?.finance_type === order?.finance_type)
                            .map(data => {
                                return { ...data, base_rate: 0, margin: 0, applicable_rate: 0 };
                            });

                        convertQuotationRequest({ ...quotationObject[0], changeVehicleStatus: false }, {
                            onSuccess() {
                                snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_SUCCESS));
                                setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                                navigateNext(Navigation.ORDER_PAGE, {
                                    'vin': vehicle.vin,
                                    'orderId': response[0].reference_number
                                });
                                resolve();
                            },
                            onError() {
                                snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_FAILED));
                                setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                                reject();
                            }
                        });
                    },
                    onError() {
                        snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_SAVE_FAILED));
                        setTimeout(() => { snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR()); }, 3000);
                        reject();
                    }
                });
            });
        }
    } catch (error) {
        console.error("An error occurred during submission:", error);
    }
};

const validateFinanceData = async () => {
    const dirtyForms = getDirtyForms();
    const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
    const customer_email = JSON.parse(localStorage.getItem('settings'))?.email;
    snackbardispatch(SnackbarUtility.OPEN_SNACKBAR(PLACEHOLDERS.ORDER_VALIDATION_IN_PROGRESS));
    let pendingMutations = [];

    for (const form of dirtyForms) {
        switch (form.formName) {
            case 'ContactDetails':
                if (form.state.isDirty) {
                    const contactData = getFormData('ContactDetails');
                    const isValid = isFormValid('ContactDetails')
                    if (isValid) {
                        const contactPayload = {
                            customer_profile: { ...contactData, updated_by: customer_email },
                            reference_id: customerData?.reference_id
                        };
    
                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            updateCustomerByReferenceId(contactPayload, {
                                onSuccess: () => {
                                    resetFormState('ContactDetails');
                                    resolve();
                                },
                                onError: reject
                            });
                        });
    
                        pendingMutations.push(mutationPromise);
                    } else {
                    setFinanceTab(prevState => ({
                        isOpen: true,
                        tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_PERSONAL_DETAILS]
                    }));
                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                    return false;
                    }
                   
                }
                break;

            case 'licence':
                if (form.state.isDirty) {
                    const licenceData = getFormData('licence');
                    const isValid = isFormValid('licence')
                    if (isValid) {
                        const licencePayload = {
                            ...licenceData,
                            address: {state: licenceData?.state},
                            expiry_date: formatPayloadDate(licenceData?.expiry_date),
                            date_of_birth: formatPayloadDate(licenceData?.date_of_birth),
                            reference_id: customerData?.reference_id
                        };
    
                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            updateLicenseByInfoReferenceId(licencePayload, {
                                onSuccess: () => {
                                    resetFormState('licence');
                                    resolve();
                                },
                                onError: reject
                            });
                        });
    
                        pendingMutations.push(mutationPromise);
                    } else {
                    setFinanceTab(prevState => ({
                        isOpen: true,
                        tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_TITLE_LICENSE_DETAILS]
                    }));
                    snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                    return false;
                    }
                   
                }
                break;

            case 'employment':
                if (form.state.isDirty) {
                    const employInfoData = getFormData('employment');
                    const isValid = isFormValid('employment')
                    if (isValid) {
                        const employmentPayload = {
                            ...employInfoData,
                            annual_other_income: parseInt(employInfoData.annual_other_income),
                            annual_employment_income: parseInt(employInfoData.annual_employment_income),
                            employed_since: getEmployedSinceDate(employInfoData.employed_since),
                            reference_id: customerData?.reference_id
                        };
    
                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            const mutationFunction = isEmpty(employInfoData)
                                ? addEmploymentData
                                : updateEmployInfoReferenceId;
    
                            mutationFunction(employmentPayload, {
                                onSuccess: (response) => {
                                    handleEmploymentUpdate(response);
                                    resolve();
                                },
                                onError: reject
                            });
                        });
    
                        pendingMutations.push(mutationPromise);
                    } else {
                        setFinanceTab(prevState => ({
                            isOpen: true,
                            tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_TITLE_EMPLOYMENT_DETAILS]
                        }));
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        return false;
                    }
                }
                break;

            case 'personalFinance':
                if (form.state.isDirty) {
                    const personalFinanceData = getFormData('personalFinance');
                    const isValid = isFormValid('personalFinance')
                    if (isValid) {
                        const financePayload = {
                            ...personalFinanceData,
                            monthly_payment: (personalFinanceData.residence === ResidenceType.Renting || personalFinanceData.residence === ResidenceType.Mortgage)
                                ? personalFinanceData.monthly_payment
                                : 0,
                            reference_id: customerData?.reference_id
                        };
    
                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            const mutationFunction = isEmpty(personalFinanceData)
                                ? createPersonalFinance
                                : updatePersonalFinance;
    
                            mutationFunction(financePayload, {
                                onSuccess: () => {
                                    handlePersonalFinanceUpdate();
                                    resolve();
                                },
                                onError: reject
                            });
                        });
    
                        pendingMutations.push(mutationPromise);
                    } else {
                        setFinanceTab(prevState => ({
                            isOpen: true,
                            tab: [...(prevState?.tab || []), PLACEHOLDERS.FINANCING_TITLE_PERSONAL_FINANCE_DETAILS]
                        }));
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        return false;
                    }
                 
                }
                break;

            case 'residence':
                if (form.state.isDirty) {
                    const residenceData = getFormData('residence');
                    const isValid = isFormValid('residence')
                    if (isValid) {
                        const addresses = [];

                        addAddressIfValid(residenceData.mail_address, addresses, 'Mailing');
                        addAddressIfValid(residenceData.garaging_address, addresses, 'Garaging');
                        addAddressIfValid(residenceData.Billing, addresses, 'Billing');
    
                        const residencePayload = {
                            customer_addresses: addresses,
                            reference_id: customerData?.reference_id
                        };
    
                        const mutationPromise = new Promise<void>((resolve, reject) => {
                            updateCustomerByReferenceId(residencePayload, {
                                onSuccess: () => {
    
                                    handleResidenceUpdate();
                                    if (residenceData.mail_address?.is_garaging) {
                                       calculateQuotation()
                                    }
                                    else if (residenceData.garaging_address) {
                                       calculateQuotation()
                                    }
                                    resolve();
                                },
                                onError: reject
                            });
                        });
    
                        pendingMutations.push(mutationPromise);
                    } else {
                        setFinanceTab({isOpen: true, tab: [...financeTab?.tab, PLACEHOLDERS.FINANCING_TITLE_RESIDENCE_DETAILS]})
                        snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())
                        return false;
                    }
               
                }
                break;

            default:
                break;
        }
    }

    function handleEmploymentUpdate(response) {
        resetFormState('employment');
        pageDispatch({
            type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
            payload: { success: true, error: false }
        });
    }

    function handlePersonalFinanceUpdate() {
        resetFormState('personalFinance');
        pageDispatch({
            type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
            payload: { success: true, error: false }
        });
    }

    function handleResidenceUpdate() {
        resetFormState('residence');
        pageDispatch({
            type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
            payload: { success: true, error: false }
        });
    }

    function addAddressIfValid(address, addresses, type) {
        if (address) {
            address.address_type = type;
            address.verified = !getDealerCode();
            addresses.push({
                ...address,
                move_in_date: address.move_in_duration ? getMoveInDate(address.move_in_duration) : null,
                is_garaging: address.is_garaging
            });
        }
    }

    try {
        await Promise.all(pendingMutations);
        return true;
    } catch (error) {
        console.error('Error occurred:', error);
        return false;
    }
};




  return (
    <JourneyLayoutWrap theme={theme} className={clsx({ 'journey-layout-wrap': true, 'hide-disclaimer': hideDisclaimer })} p={{ xs: 3 }}>
      {openPopup && (
        <AssetDetailControl
          theme={theme}
          selectedVehicle={{ vehicle }}
          defaultCurrency={defaultCurrency}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          onClick={() => { }}
          hideFooter={true}
        />
      )}
      <Grid theme={theme} container item spacing={{ xs: 3, md: 1 }} flexWrap={{ lg: 'nowrap' }}>
        <Grid theme={theme} item xs={12} md={4} lg={3} maxWidth={{ lg: 370 }} className='sticky'>
          <Box theme={theme} className={clsx('steps-area', stepsCollapsed ? '' : 'steps-collapsed')}>
            <StepsCount
              title={getActiveTabTitle()}
              subtitle={getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS) !== '' ? `${PLACEHOLDERS.NEXT_TEXT}: ${getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS)}` : ''}
              counter
              CounterStepCurrent={orderTabs ? orderTabs?.findIndex((item) => item == activeTab) + 1 : 0}
              CounterStepTotal={orderTabs?.length || 0}
              counterProgressValue={orderTabs ? ((orderTabs?.findIndex((item) => item == activeTab) + 1) / orderTabs.length) * 100 : 0}
              actionArea
              onBack={() => {getOrderWorkflow()}}
              onSave={handleSubmit}
              onClose={() => {getOrderWorkflow()}}
              progressBar
              collapse={(!stepsCollapsed)}
              progressValue={orderTabs ? ((orderTabs?.findIndex((item) => item == activeTab) + 1) / orderTabs.length) * 100 : 0}
              onClick={() => {
                setStepCollapsed(!stepsCollapsed)
                dispatch(updateCollapseState())
              }
              }
            />
            <Box theme={theme} className='all-steps-container'>
              {vehicle && (
                <ProductCard
                  productImg={vehicle?.photo_urls?.[0]?.location}
                  productbg={false}
                  title={`${vehicle?.year} ${vehicle?.make} ${vehicle?.model}`}
                  subtitle={vehicle?.trim_description}
                  catagory={`${vehicle?.doing_business_as}  |  ${vehicle?.city}, ${vehicle?.state}`}
                  onClick={() => setOpenPopup(true)}
                />
              )}
              {orderTabs?.length > 0 && <Steps
                stepItems={orderTabs?.map((item) => { return createOrderStepObject(item,setStepCollapsed) })}
              />}
              {vehicle && (
                <ProductDetail
                  modal={vehicle?.model_code}
                  stock={vehicle?.stock_number}
                  vin={vehicle?.vin}
                  onClick={() => { }}
                />
              )}
            </Box>
          </Box>
        </Grid>
        <Grid theme={theme} item xs={12} md={8} lg={9} maxWidth={{ lg: 1133 }} ml={{ md: 'auto' }} mr={{ md: 'auto' }}>
          <div className='journey-content-outer'>
            <div className='content-inner'>
              {getActiveTab(activeTab)}
              {children && children}
            </div>
          </div>
        </Grid>
      </Grid>
      <Snackbar
        theme={theme}
        message={[PLACEHOLDERS.ORDER_SAVING_IN_PROGRESS, PLACEHOLDERS.ORDER_VALIDATION_IN_PROGRESS].includes(snackbarState.message) ?
          <Box theme={theme} display={'flex'}>
            <Box theme={theme} width="30px">
              <CircleLoader theme={theme} size='xs' />
            </Box> {snackbarState.message}
          </Box> : snackbarState.message}
        open={snackbarState.open}
        onClose={() => snackbardispatch(SnackbarUtility.CLOSE_SNACKBAR())}
        anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      />
    {financeTab?.isOpen && 
       <AlertDialog
       title={'Failed to save data'}
       subtitle={`You have invalid data on tab ${financeTab?.tab?.join(', ').replace(/,([^,]*)$/, ' and$1')}. Please add mandatory information and try again`}
       btnText={'Cancel'}
       isOpen={financeTab?.isOpen}
       onClick={() => { setFinanceTab({isOpen: false, tab: [] }) }}
       onClose={() => { setFinanceTab({isOpen: false, tab: [] }) }}
   />}
    </JourneyLayoutWrap>
  )
}
