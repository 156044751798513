import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const AssetCardWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.product-wrap': {
      position: 'relative',

      '.product-card': {
        backgroundColor: theme.palette.common.white,
        padding: '20px 24px',
        borderRadius: 16,
        marginBottom: 8,
        transition: '0.4s',
        position: 'relative',
        '.bookmark': {
          position: 'absolute',
          right: '24px',
          top: 0
        },
        '.card-img': {
          width: '100%',
          height: 'auto',
          textAlign: 'center',
          marginBottom: '16px',
          '.img': {
            width: '244px',
            height: '136px',
            objectFit: 'cover'
          }
        },
        '.card-content': {
          '.text-row': {
            display: 'flex',
            justifyContent: 'space-between'
          }
        }
      },
    }
}))
