import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const FooterWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.footer-wrap': {
    padding: '24px 40px',
    backgroundColor: theme.palette.grey[50],
    '.MuiTypography-body2': {
      lineHeight: theme.typography.body1.lineHeight
    },
    '.social-links': {
      'a': {
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '.ic-16': {
          width: 16,
          height: 16
        },
        'svg': {
          'path': {
            fill: '#808080'
          }
        }
      },
      [theme.breakpoints.down('md')]: {
        marginTop: 24,
        justifyContent: 'flex-start'
      }
    },
    '.nav-links': {
      '.nav-link': {
        a: {
          textDecoration: 'none',
          color: theme.palette.grey[600],
        },
        [theme.breakpoints.down('md')]: {
          marginBottom: 24
        }
      },
      [theme.breakpoints.down('md')]: {
        display: 'block',
        columnCount: 2,
        columnGap: 24,
        width: '100%'
      }
    },
    '.all-right': {
      display: 'flex',
      justifyContent: 'end',
      [theme.breakpoints.down('md')]: {
        justifyContent: 'start',
      },
    },
    [theme.breakpoints.down('sm')]: {
      padding: 24,
    },
    [theme.breakpoints.down('md')]: {
      '&.collapse': {
        display: 'none'
      }
    }
    
  }
}))
