import { useEffect, useState } from "react";
import {
    FinancingPage,
    FnIProductsPage,
    InsurancePage, OrderActionType,
    PaymentContextProvider,
    PaymentsPage, RebatesPage, ReviewOrderPage, SchedulePage, TradeInPage,
    useOrderContext
} from "@pages";
import { updateCollapseState, useAppContext } from "@app/context-provider";
import { user_session } from '@constants'
import {
    getVehicleDetailByVin,
    useGetCustomerContact,
    useGetPersonalFinance,
    useGetLicenseInformation,
    useGetEmploymentInformation,
    useUpdateLead
} from "@hooks";
import { updateLenderId, updateOrderAsset } from "../../pages/(order-management)/order/order-context-provider";
import { pagesActionType, useJourneyLayoutContext } from "./journey-layout-context-provider";
import { JourneyLayout } from "@layouts";
import { useTags } from 'hooks/event-analytics'
import { Event } from 'apis/event-analytics'
import { Tags, PageType, PaymentType } from 'constants/enums';
import { useParams } from 'react-router-dom'
import { AddOn, JourneyFooter, PaymentBreakDownPopup } from "@libraries";
import { getNextTabTitle } from "@helpers";
import { QueryKeys, Tabs } from "@constants";
import { IOrderOption } from "@interfaces";
import { useQueryClient } from "react-query";
import { useScrollOnTop } from "../../hooks/order-management";
import { mapPayload } from "helpers/lead";
import {calculateAdjustedTradeInAmount} from "../../helpers/methods";

export const PreOrderJourneyLayoutComponent = (
    { vehicle,
        showPopup,
        orderTabs,
        nextTab,
        handleLinkClick,
        handleCloseDialog,
        onNextTabClick,
        setNextTab,
        children }) => {
    const { tenant } = useParams()
    const { mutate: createTags } = useTags(tenant);
    const { mutate: updateLead } = useUpdateLead();
    const { state: appState, dispatch: appDispatch } = useAppContext()
    const { state: pageState, dispatch: pageDispatch } = useJourneyLayoutContext()
    const { state: { order, activeTab }, dispatch: orderDispatch } = useOrderContext()
    const [totalSum, setTotalSum] = useState(0);
    const [currentTab, setCurrentTab] = useState(Tabs.PAYMENTS);
    const user_profile = JSON.parse(localStorage.getItem("user_profile"))
    const PLACEHOLDERS = appState?.language?.placeholders;
    const defaultCurrency = appState?.default_currency_symbol
    const { data: vehicleData } = getVehicleDetailByVin(vehicle?.vin)
    const queryClient = useQueryClient();
    const customerData: any = queryClient.getQueryData(QueryKeys.GET_CUSTOMER_BY_EMAIL);
    const { state: { order: orderState }} = useOrderContext()
    const { data: contactData } = useGetCustomerContact(
        customerData?.reference_id
    );
    const { data: personalFinanceData } = useGetPersonalFinance(
        customerData?.reference_id ?? ""
    );
    const setting = JSON.parse(localStorage.getItem("settings"))
    const { data: licenseInformation, isFetched: isLicenseFetched } = useGetLicenseInformation(customerData?.reference_id ?? "");
    const { data: employmentInfo, isLoading: employmentInfoLoading } =
        useGetEmploymentInformation(customerData?.reference_id);

    useScrollOnTop(currentTab)
    const { dispatch } = useAppContext();

    useEffect(() => {
        orderDispatch(updateOrderAsset(vehicle))
        orderDispatch(updateLenderId(appState?.lender_id))
    }, vehicle)

    useEffect(() => {
        if (order?.order_options?.length) {
            const total = order?.order_options.reduce((sum, item) => {
                return sum + item.applied_price;
            }, 0);
            setTotalSum(total);
        }
    }, [order?.order_options?.length])

    useEffect(() => {
        if (order?.estimated_monthly_payment) {
            let lead = mapPayload(+vehicle?.dealer_id, tenant, customerData?.reference_id, 'vehicle_details', vehicle)
            lead = mapPayload(+vehicle?.dealer_id, tenant, customerData?.reference_id, 'vehicle_details', {
                estimated_monthly_payment: order?.estimated_monthly_payment,
                due_at_signing: order?.due_at_signing,
                credit_rating:order?.credit_rating,
                net_finance_amount:order?.net_finance_amount,
                down_payment:order?.down_payment,
                finance_type:order?.finance_type,
                base_rate:order?.base_rate,
                contract_term:order?.contract_term
            }, 'finance')
            lead = mapPayload(+vehicle?.dealer_id, tenant, customerData?.reference_id,'customer_details',{email:setting?.email,lead_source:"Drift"})
            lead = mapPayload(+vehicle?.dealer_id, tenant, customerData?.reference_id,'lead_source',"Drift")
            updateLead(lead);
        }
    }, [order?.estimated_monthly_payment])

    useEffect(() => {
        if (vehicleData) {
            const event: Event = {
                session: user_session,
                slug: tenant,
                event_name: Tags.SELECT_VEHICLE,
                user_id: user_profile?.user_id,
                vin: vehicleData.vin,
                vehicle_status: vehicleData.vehicle_status,
            }
            createTags(event)
            const event_detail: Event = {
                session: user_session,
                slug: tenant,
                event_name: Tags.VIEW_DETAILS,
                user_id: user_profile?.user_id,
                vin: vehicleData.vin,
                vehicle_status: vehicleData.vehicle_status,
                page_type: PageType.Payments
            }
            createTags(event_detail)
            const orderOptions = vehicleData?.dealer_options?.map((option: IOrderOption) => ({
                option_id: option?.option_id ?? option?.id,
                product_name: option?.product_name,
                installation_mode: option?.installation_mode,
                dealer_id: option?.dealer_id,
                price: option?.price || 0,
                applied_price: option?.installation_mode == 'Pre-Installed' && option?.is_price_inclusive ? 0 : option?.price,
                is_price_inclusive: option.is_price_inclusive,
                rv_balloon_percentage: option?.rv_balloon_percentage || option?.rv_adder || 0,
                rv_balloon_value: option?.rv_balloon_value || option?.rv_adder || 0
            }));
            orderDispatch({
                type: OrderActionType.UPDATE_ORDER_OPTION,
                payload: [...orderOptions]
            })
        }
    }, [vehicleData])


    useEffect(() => {
        if (isLicenseFetched && licenseInformation) {
            pageDispatch({
                type: pagesActionType.UPDATE_LICENSE_DETAILS,
                payload: {
                    success: true,
                    error: false,
                },
            });
        }
        if (contactData?.first_name && contactData?.last_name && contactData?.ssn && contactData?.mobile_number) {
            pageDispatch({
                type: pagesActionType.UPDATE_PERSONAL_DETAILS,
                payload: {
                    success: true,
                    error: false,
                },
            });
        }
        if (personalFinanceData) {
            pageDispatch({
                type: pagesActionType.UPDATE_PERSONAL_FINANCE_DETAILS,
                payload: {
                    success: true,
                    error: false,
                },
            });
        }
        if (contactData?.customer_addresses.length > 0) {
            pageDispatch({
                type: pagesActionType.UPDATE_RESIDENCE_DETAILS,
                payload: {
                    success: true,
                    error: false,
                },
            });
        }
        if (employmentInfo) {
            pageDispatch({
                type: pagesActionType.UPDATE_EMPLOYMENT_DETAILS,
                payload: {
                    success: true,
                    error: false,
                },
            });
        }
    }, [isLicenseFetched, contactData, personalFinanceData, employmentInfo]);

    const onTabClick = (tab, setState) => {
        setNextTab(tab)
        setCurrentTab(tab),
            setState(true)
        dispatch(updateCollapseState());
    }
    const getActiveTab = (tab: string) => {
        switch (tab) {
            case Tabs.ADD_ONS:
                return <AddOn />
            case Tabs.FINANCING:
                return <FinancingPage />
            case Tabs.FNI:
                return <FnIProductsPage />
            case Tabs.INSURANCE:
                return <InsurancePage />
            case Tabs.PAYMENTS:
                return (
                    <PaymentContextProvider>
                        <PaymentsPage />
                    </PaymentContextProvider>
                )
            case 'rebates':
                return <RebatesPage />
            case Tabs.SCHEDULE:
                return <SchedulePage />
            case Tabs.TRADE_IN:
                return <TradeInPage />
            case Tabs.REVIEW_ORDER:
                return <ReviewOrderPage />
            default:
                return children
        }
    }

    const getActiveTabTitle = () => {
        switch (currentTab) {
            case Tabs.PAYMENTS:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE
            case Tabs.FNI:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE
            case Tabs.ADD_ONS:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_ADD_ONS_TITLE
            case Tabs.TRADE_IN:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_TRADE_IN_TITLE
            case Tabs.INSURANCE:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE
            case Tabs.SCHEDULE:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE
            case Tabs.FINANCING:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FINANCING_TITLE
            case Tabs.REVIEW_ORDER:
                return PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_REVIEW_ORDER_TITLE
        }
    }

    const createOrderStepObject = (tab: string, setState: (value: boolean) => void) => {
        switch (tab) {
            case Tabs.PAYMENTS:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_PAYMENTS_TITLE,
                    subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
                    onClick: () => onTabClick(Tabs.PAYMENTS, setState),
                    isActive: currentTab === Tabs.PAYMENTS,
                    success: currentTab !== Tabs.PAYMENTS ? pageState.payments.success : false,
                    error: currentTab !== Tabs.PAYMENTS ? pageState.payments.error : false,
                }
            case Tabs.FNI:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FNI_PRODUCTS_TITLE,
                    subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
                    isActive: currentTab === Tabs.FNI,
                    success: currentTab !== Tabs.FNI ? pageState.fnI.success : false,
                    error: currentTab !== Tabs.FNI ? pageState.fnI.error : false,
                    onClick: () => onTabClick(Tabs.FNI, setState)
                }
            case Tabs.ADD_ONS:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_ADD_ONS_TITLE,
                    subtitle: order?.order_options?.length ? subtitle : `0 ${PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SELECTED_SUB_TITLE} / ${defaultCurrency}0.00`,
                    success: currentTab !== Tabs.ADD_ONS ? pageState.addons.success : false,
                    error: currentTab !== Tabs.ADD_ONS ? pageState.addons.error : false,
                    isActive: currentTab === Tabs.ADD_ONS,
                    onClick: () => onTabClick(Tabs.ADD_ONS, setState)
                }

            case Tabs.TRADE_IN:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_TRADE_IN_TITLE,
                    subtitle: pageState.tradeIn.success ? `Added / ${calculateAdjustedTradeInAmount(order, appState.default_currency_symbol)}`
                            : PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_NONE_SELECTED_SUB_TITLE,
                    success: currentTab !== Tabs.TRADE_IN ? pageState.tradeIn.success : false,
                    error: currentTab !== Tabs.TRADE_IN ? pageState.tradeIn.error : false,
                    isActive: currentTab === Tabs.TRADE_IN,
                    onClick: () => onTabClick(Tabs.TRADE_IN, setState)
                }
            case Tabs.INSURANCE:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_INSURANCE_TITLE,
                    subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
                    isActive: currentTab === Tabs.INSURANCE,
                    success: currentTab !== Tabs.INSURANCE ? pageState.insurance.success : false,
                    error: currentTab !== Tabs.INSURANCE ? pageState.insurance.error : false,
                    onClick: () => onTabClick(Tabs.INSURANCE, setState)
                }
            case Tabs.SCHEDULE:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_SCHEDULE_TITLE,
                    subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
                    success: currentTab !== Tabs.SCHEDULE ? pageState.appointment.success : false,
                    error: currentTab !== Tabs.SCHEDULE ? pageState.appointment.error : false,
                    isActive: currentTab === Tabs.SCHEDULE,
                    onClick: () => onTabClick(Tabs.SCHEDULE, setState)
                }
            case Tabs.FINANCING:
                return {
                    title: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_FINANCING_TITLE,
                    subtitle: PLACEHOLDERS.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,

                    success: currentTab !== Tabs.FINANCING ? pageState.finance.employment_details.success &&
                        pageState.finance.license_details.success && pageState.finance.personal_details.success &&
                        pageState.finance.personal_finance_details.success && pageState.finance.residence_details.success : false,

                    error: currentTab !== Tabs.FINANCING ? pageState.finance.employment_details.error ||
                        pageState.finance.license_details.error || pageState.finance.personal_details.error ||
                        pageState.finance.personal_finance_details.error || pageState.finance.residence_details.error : false,

                    isActive: currentTab === Tabs.FINANCING,
                    onClick: () => onTabClick(Tabs.FINANCING, setState)
                }
            case Tabs.REVIEW_ORDER:
                return {
                    title: PLACEHOLDERS?.JOURNEY_LAYOUT_STEP_ITEMS_REVIEW_ORDER_TITLE,
                    subtitle: PLACEHOLDERS?.JOURNEY_LAYOUT_STEP_ITEMS_2_MINUTES_SUB_TITLE,
                    success: false,
                    error: false,
                    isActive: currentTab === Tabs.REVIEW_ORDER,
                    onClick: () => onTabClick(Tabs.REVIEW_ORDER, setState)
                }
        }
    }
    const subtitle = `${order?.order_options?.length} ${PLACEHOLDERS?.JOURNEY_LAYOUT_STEP_ITEMS_SELECTED_SUB_TITLE} / ${defaultCurrency}${totalSum}`;
    return (

        <JourneyLayout
            orderTabs={orderTabs}
            nextTab={nextTab}
            getActiveTab={getActiveTab}
            getActiveTabTitle={getActiveTabTitle}
            createOrderStepObject={createOrderStepObject}
            activeTab={currentTab}
            setActivetab={setCurrentTab}
            hideDisclaimer={false}
        // need to check the logic
        // hideDisclaimer={orderTabs[orderTabs?.length - 1] === activeTab ? true : false}
        >
            <JourneyFooter
                noFooter={orderTabs?.[orderTabs?.length - 1] === activeTab ? true : false}
                title={orderState?.finance_type === PaymentType.Lease ? PLACEHOLDERS.FOOTER_TITLE_LEASE : PLACEHOLDERS.FOOTER_TITLE}
                subTitle={`${(order?.estimated_monthly_payment + order?.monthly_sales_use_tax || 0)?.toLocaleString("en-US", {
                    maximumFractionDigits: 2,
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: appState?.default_currency_code || 'USD',
                })}/${PLACEHOLDERS.FOOTER_SUBTITLE}`}
                linkText={PLACEHOLDERS.FOOTER_LINK_TEXT}
                onLinkClick={handleLinkClick}
                buttonText={getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS) !== '' ? `${PLACEHOLDERS.NEXT_TEXT}: ${getNextTabTitle(orderTabs, activeTab, PLACEHOLDERS)}` : ''}
                onButtonClick={onNextTabClick}
            />
            {showPopup && <PaymentBreakDownPopup onCloseDialog={handleCloseDialog} order_info={order} />}
        </JourneyLayout>
    )
}