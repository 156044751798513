import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const LoaderPopupWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.loader-popup-wrap': {
    '.popup-loader': {
        position: 'relative',
        '.loader-wrap': {
          height: 'auto',
          '.loader': {
            position: 'relative',
            top: 0,
            transform: 'none'
          }
        }
    }
  }
}))
