import { useTheme } from '@mui/material'
import { FC, useEffect, useState } from 'react'
import { Box, Button, Dialog, Grid, Image, Typography, } from '@ntpkunity/controls'
import { Popup, Stack } from '@components'
import { useAppContext } from '@app/context-provider'
import { AlertDialog } from 'libraries/trade-in-journey-components/alert-popup/alert-popup'
import { useOrderContext } from '@pages'
import { useGetTradeInHistory } from '@hooks'
import { updateOrderTradeIn } from 'pages/(order-management)/order/order-context-provider'
import DisableLoader from '../../../src/shared/assets/loader-disabled.gif'

export const TradeInOffer: FC<{
    open?: boolean,
    defaultCurrency: string,
}> = ({ open, defaultCurrency}) => {
    const theme = useTheme()
    const { state: { order, isFlowExecuted }, dispatch: orderDispatch, handleSubmitOrder, handleUpdateOrder,  loading } = useOrderContext()

    const [tradeInCopy, setTradeInCopy] = useState(null)
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const [isAlertOpen, setIsAlertOpen] = useState(false);
    const { data: trade_in_history } = useGetTradeInHistory(order?.id);

    const acceptedTradeINOffer = () => {
        handleSubmitOrder()
    }

    const removeTradeINOffer = () => {
        setIsAlertOpen(true);
        setTradeInCopy(order?.order_tradein)
        orderDispatch(updateOrderTradeIn(null))
    }

    const handleAlertClose = () => {
        orderDispatch(updateOrderTradeIn(tradeInCopy))
        setIsAlertOpen(false)
    };

    const confirmRemove = () => {
        handleUpdateOrder()
    };

    useEffect(() => {
        if (isFlowExecuted)
            setIsAlertOpen(false)
    }, [isFlowExecuted])

    const payoffAmount = order?.order_tradein?.payoff_amount ?? 0;
    const tradeInAmount = order?.order_tradein?.trade_in_amount ?? 0;
    let trade_in_amount = typeof tradeInAmount === 'number' ? tradeInAmount : parseInt(tradeInAmount);

    let payoff_amount = typeof payoffAmount === 'number' ? payoffAmount : parseInt(payoffAmount);
    const adjustedTradeInAmount = payoff_amount > 0 ? trade_in_amount - payoff_amount : trade_in_amount;

    return (
        <>
            {isAlertOpen ? <AlertDialog
                title={PLACEHOLDERS.TRADE_IN_REMOVAL_WARNING}
                subtitle={PLACEHOLDERS.TRADE_IN_REMOVAL_CONFIRMATION}
                btnText={PLACEHOLDERS.TRADE_IN_REMOVAL_ACTION}
                isOpen={isAlertOpen}
                onClick={confirmRemove}
                onClose={handleAlertClose}
                disabled={loading}
                startIcon={loading && <img src={DisableLoader} alt="Loader" />}
            /> : <Popup>
                <Dialog
                    theme={theme}
                    size="sm"
                    open={open}
                    disablePortal
                    noFooter
                >
                    <Stack paddingXs={0} paddingMd={0}>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={0}
                            columnSpacing={{ xs: 2, }}
                        >
                            <Grid item xs={12} theme={theme} sx={{ textAlign: 'center' }}>
                                <Box theme={theme} mb={3} className='product-img'>
                                    <Image
                                        theme={theme}
                                        src={order?.order_tradein?.asset_image[0]}
                                        aspectRatio='16:9'
                                    />
                                </Box>
                                <Typography theme={theme} component={'h3'} variant='h3' marginBottom={3}>{PLACEHOLDERS.TRADE_IN_OFFER_DEALER_MESSAGE} {defaultCurrency} {adjustedTradeInAmount ? adjustedTradeInAmount?.toLocaleString() : 0}</Typography>
                                <Typography className='text-muted' theme={theme} component={'p'} variant='caption'
                                    marginBottom={3}>{PLACEHOLDERS.TRADE_IN_OFFER_REVISED} <span className='fw-medium text-dark'>{order?.order_tradein?.year}  {order?.order_tradein?.make} {order?.order_tradein?.model} {order?.order_tradein?.trim} </span>
                                    {PLACEHOLDERS.TRADE_IN_OFFER_FROM_TEXT} <span className='fw-medium text-dark line-through'>{defaultCurrency}
                                        {trade_in_history?.[0]?.trade_in_amount ? trade_in_history?.[0]?.trade_in_amount?.toLocaleString() : 0}</span> {PLACEHOLDERS.TRADE_IN_OFFER_TO_TEXT} <span className='fw-medium text-dark'>{defaultCurrency}{order?.order_tradein?.trade_in_amount?.toLocaleString()}.</span> {PLACEHOLDERS.TRADE_IN_OFFER_ACCEPT_PROMPT} </Typography>
                            </Grid>
                        </Grid>
                        <Grid
                            theme={theme}
                            container
                            rowSpacing={3}
                            columnSpacing={2}
                            sx={{ mb: 8 }}
                        >
                            <Grid item xs={12} theme={theme} display={'flex'} justifyContent={'center'}>
                                <Button theme={theme} onClick={acceptedTradeINOffer} primary
                                    text={PLACEHOLDERS.TRADE_IN_OFFER_ACCEPT}
                                    disabled={loading}
                                    startIcon={loading && <img src={DisableLoader} alt="Loader" />}
                                />
                            </Grid>
                            <Grid item xs={12} theme={theme} sx={{ textAlign: 'center' }}>
                                <Typography theme={theme} component={'p'} variant='body2' className='text-muted'>{PLACEHOLDERS.TRADE_IN_OFFER_DECLINE}
                                    <a href='#' onClick={removeTradeINOffer} className='text-primary fw-medium decoration-none'> {PLACEHOLDERS.TRADE_IN_OFFER_REMOVED_TEXT}</a>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Stack>
                </Dialog>
            </Popup>}
        </>
    )
}


