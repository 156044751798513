import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CoverageItemWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
    '&.coverage-item-wrap': {
        '.cj-card': {
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row',
            justifyContent: 'space-between',
            backgroundColor: '#F5F5F7',
            borderRadius: '8px',
            padding: '12px 16px',
            gap: '8px',
            '.u-form-group': {
                minWidth: 140,
                textAlign: 'right',
                '.u-form-control': {
                    height: '24px',
                    '&.u-select': {
                        '.MuiInputBase-input': {
                            padding: '0px 32px 0px 0px',
                            fontSize: theme.typography.subtitle1.fontSize,
                            fontWeight: theme.typography.subtitle1.fontWeight,
                            color: theme.palette.primary.main
                        },
                        '.MuiSelect-icon': {
                            right: 0
                        }
                    },
                    '.MuiOutlinedInput-notchedOutline': {
                        border: 'none'
                    }
                },
                '.MuiPopover-root': {
                    '.MuiPaper-root': {
                        top: '42px !important',
                        textAlign: 'left'
                    }
                }
            },
        }
    }
}))
