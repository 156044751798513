import { QueryKeys } from "@constants";
import { AuthenticationManagement, DealerManagement, OrderManagement } from "../apis";
import { useMutation, useQuery } from "react-query";

export const useGetDealerPreference = (dealerCode: string) => {
  return useQuery(
    QueryKeys.GET_DEALER_PREFERENCE,
    () => {
      return DealerManagement.getDealerPreference({ dealerCode });
    },
    { enabled: !!dealerCode }
  );
};

export const useGetDealerByDealerCode = (data) => {
  const {dealerCode, ...rest} = data;
  return useQuery(
    "dealer-by-dealer-code",
    () => {
      return DealerManagement.getDealerByDealerCode(data);
    },
    {enabled: !!dealerCode}
  )
}

export const useGetDealerFeebyState = (): any => {
  return useMutation<any>((body: any) => {
    return DealerManagement.getDealerFeesByState({
      dealerCode: body?.dealerCode,
      stateCode: body?.stateCode,
    });
  });
  // return useQuery(
  //   QueryKeys.GET_DEALER_FEE_BY_STATE,
  //   () => {
  //     return DealerManagement.getDealerFeesByState({ dealerCode, stateCode });
  //   },
  //   { enabled: !!dealerCode && !!stateCode }
  // );
};
