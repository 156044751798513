import { Link, useTheme } from '@mui/material'
import {
  Icon,
  Box,
  Typography,
  Button,
  Image
} from '@ntpkunity/controls'
import clsx from 'clsx'
import { ProductCardWrap } from './product-card.style'
import React from 'react'

export const ProductCard = ({productImg, productbg, title, subtitle, catagory, onClick}) => {
  const theme = useTheme()
  return (
    <ProductCardWrap theme={theme} className='product-wrap'>
      <Box theme={theme} className='product-card'>
        {productbg && 
          <Box theme={theme} className='product-card-bg'>
            <Image 
                theme={theme}
                src={productbg}
                aspectRatio='16:9'
            />
          </Box>
        }
        <Box theme={theme} className='card-content'>
          <Box theme={theme} width={'100%'}>
            {productImg && 
              <Box theme={theme} className={clsx({ 'product-card-fg': true, 'no-bg': productbg === false })}>
                <Image 
                  theme={theme}
                  src={productImg}
                  aspectRatio='16:9'
                />
              </Box>
            }
            <Box theme={theme} className='text-items'>
              {subtitle &&
                  <Typography theme={theme} className='subtitle text-muted fw-medium' variant='body1' component={'p'} display={'block'}>{subtitle}</Typography>
              }
              {title &&
                <Box theme={theme} className='title'>
                  <Typography theme={theme} variant='h3' component={'h3'} display={'block'}>{title}</Typography>
                  {onClick && 
                    <Button defaultBtn theme={theme} onClick={onClick} iconText={<Icon name="CicleLinkIcon" />} />
                  }
                </Box>
              }
              {catagory &&
                <Box theme={theme} className='catagories'>
                  <Link variant='caption' component={'small'} display={'block'}>{catagory}</Link>
                </Box>
              }
            </Box>
          </Box>
        </Box>
      </Box>
    </ProductCardWrap>
  )
}
