import { Endpoint } from "@constants";
import { http } from "../utilities";
import { IEmploymentDetails } from "@interfaces";

export const DMS_BASE_URL =  process.env.DMS_BASE_URL;
export const HUBEX_BASE_URL = process.env.HUBEX_BASE_URL;
export const CONFIG_API_GATEWAY = process.env.CONFIG_API_GATEWAY

export namespace OrderManagement {
  export const getVehicle = async ({ vin }: { vin: string }) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.VEHICLE_BY_VIN}/${vin}`)
      .then((res) => {
        return res.data;
      });
  };
  export const getVehicleDetail = async ({ vin }: { vin: string }) => {
    return http(CONFIG_API_GATEWAY)
      .get(`${Endpoint.VEHICLE_DETAIL_BY_VIN}/${vin}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getSetupData = async (queryParams) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.GET_SETUP_DATA}?${queryParams}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getTrimCode = async ({
    dealer_code,
    trim_code,
  }: {
    dealer_code: string;
    trim_code: string;
  }) => {
    return http(DMS_BASE_URL)
      .get(
        `${Endpoint.OPTION_TRIM_CODE}?dealer_code=${dealer_code}&trim_code=${trim_code}`
      )
      .then((res) => {
        return res.data;
      });
  };
  export const getIntegrationByProviderName = ({
    provider_name,
    integration_type,
    dealer_code,
    company_id
  }: {
    provider_name: string;
    integration_type: string;
    dealer_code: string;
    company_id:number
  }) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.INTEGRATION_BY_PROVIDER_NAME}/${dealer_code}/${integration_type}/${provider_name}`,{
        headers: {
          "company_id":company_id
        }
      })
      .then((res) => {
        return res.data;
      });
  };
  export const getIntegrationConfiguration = (data:any) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.INTEGRATION_CONFIGURATION_BY_PROVIDER_NAME}/${data.dealer_code}/${data.integration_type}/${data.provider_name}`,{
        headers: {
          "company_id":data.company_id
        }
      })
      .then((res) => {
        return res.data;
      });
  };
  export const getFinancialInsuranceProduct = (columnFilters: string) => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.FNI_PRODUCT}?`.concat(columnFilters))
      .then((res) => {
        return res.data;
      });
  };
  export const verifyAddress = (request): any => {
    return http(HUBEX_BASE_URL)
      .get(`${Endpoint.VERIFY_ADDRESS}/${request.body.address}`)
      .then((res) => {
        return res.data;
      });
  };
  export const getFnIProductRate = (request): any => {
    return http(HUBEX_BASE_URL)
      .post(`${Endpoint.GET_RATES}`, request.body.data)
      .then((res) => {
        return res.data;
      });
  };
  export const getDealerProfileByDealerCode = ({
    dealer_code,
  }: {
    dealer_code: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.DEALER_PROFILE}/${dealer_code}`)
      .then((res) => {
        return res.data;
      });
  };
  export const getCountries = (data): any => {
    return http(DMS_BASE_URL)
      .get(Endpoint.EP_GET_COUNTRIES, data)
      .then((res) => {
        return res.data;
      });
  };

  export const getOrder = ({ orderId }: { orderId: string }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_ORDER_INFORMATION}/${orderId}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getStates = () => {
    return http(DMS_BASE_URL)
      .get(Endpoint.EP_GET_STATES)
      .then((res) => {
        return res.data;
      });
  };

  export const getVehicleByTrimCode = ({
    trimCode,
  }: {
    trimCode: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.VEHICLE_BY_TRIM_CODE}/${trimCode}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getPrograms = (data): any => {
    return http(DMS_BASE_URL)
      .post(Endpoint.GET_ALL_PROGRAMS, data)
      .then((res) => {
        return res.data;
      });
  };

  export const calculateOrderQuotation = (data): any => {
    return http(DMS_BASE_URL)
      .post(Endpoint.CALCULATE_ORDER_QUOTATION, data)
      .then((res) => {
        return res.data;
      });
  };

  export const customerByEmail = (data): any => {
    return http(DMS_BASE_URL)
      .patch(Endpoint.CUSTOMER_EMAIL_CHECK, data)
      .then((res) => {
        return res.data;
      });
  };
  export const customerContractByReferenceID = ({
    reference_id,
  }: {
    reference_id: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_CUSTOMER}?reference_id=${reference_id}`)
      .then((res) => {
        return res.data;
      });
  };

  export const updateCustomerContractByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPDATE_CONTACT_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };
  export const getCompanyIntegrations = (data): any => {
    return http(DMS_BASE_URL)
      .get(Endpoint.EP_GET_COMPANY_INTEGRATIONS, {
        headers: data,
      })
      .then((res) => {
        return res.data;
      });
  };
  export const getCurrency = () => {
    return http(DMS_BASE_URL)
      .get(Endpoint.EP_GET_CURRENCY)
      .then((res) => {
        return res.data;
      });
  };

  export const saveOrderRequest = (data): any => {
    const running_id = localStorage.getItem('running_id') || '0';
    return http(DMS_BASE_URL)
      .post(Endpoint.SAVE_ORDER_QUOTATION, [{ ...data[0], running_id: parseInt(running_id) }])
      .then((res) => {
        return res.data;
      });
  };

  export const convertQuotationToApplication = (data): any => {
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.CONVERT_QUOTATION_TO_APPLICATION}/${data.identifier}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const getPersonalFinanceInfo = ({
    reference_id,
  }: {
    reference_id: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_PERSONAL_FINANCE}?reference_id=${reference_id}`)
      .then((res) => {
        return res.data;
      });
  };

  export const saveEmploymentDetails = (data: IEmploymentDetails) => {
        const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    return http(DMS_BASE_URL)
      .post(`${Endpoint.EP_SAVE_EMPLOYMENT_DETAILS}?dealer_code=${dealer_code}`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const calculateNfa = (data): any => {
    return http(DMS_BASE_URL)
      .post(Endpoint.CALCULATE_NFA, data)
      .then((res) => {
        return res.data;
      });
  };

  export const updateEmploymentInfoByReferenceID = (
    data: IEmploymentDetails
  ): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPDATE_EMPLOYMENT_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };

  export const EmploymentInfoByReferenceID = ({
    reference_id,
  }: {
    reference_id: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_GET_EMPLOYMENT_INFO}?reference_id=${reference_id}`)
      .then((res) => {
        return res.data;
      });
  };

  export const updateOrderRequest = (data): any => {
    return http(DMS_BASE_URL)
      .patch(`${Endpoint.CONFIGURE_QUOTATION}/${data.identifier}`, data)
      .then((res) => {
        return res.data;
      });
  };

  export const createEmploymentInfoByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_PERSONAL_FINANCE}?dealer_code=${dealer_code}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const updatePersonalFinanceByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code

    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPDATE_PERSONAL_FINANCE_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };
  export const createCustomerByReferenceID = (data): any => {
    const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
    const { reference_id, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_CREATE_CUSTOMER_DETAILS}?reference_id=${reference_id}&dealer_code=${dealer_code}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };

  export const updateOrderStatus = (data): any => {
    const { reference_number, ...restPayload } = data;
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.UPDATE_ORDER_STATUS}/${reference_number}`,
        restPayload
      )
      .then((res) => {
        return res.data;
      });
  };

  export const submitOrder = (data): any => {
    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_SUBMIT_ORDER}?reference_number=${data.orderId}`
      )
      .then((res) => {
        return res.data;
      });
  };

  export const addAppointment = (data): any => {
    return http(DMS_BASE_URL)
      .post(
        `${Endpoint.EP_APPOINTMENT}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  }

  export const updateAppointment = (data): any => {
    return http(DMS_BASE_URL)
      .patch(`${Endpoint.EP_APPOINTMENT}`, data)
      .then((res) => {
        return res.data;
      });
  }
  export const deleteAppointment = (data): any => {
    return http(DMS_BASE_URL)
      .delete(`${Endpoint.EP_APPOINTMENT}/${data.id}`, data)
      .then((res) => {
        return res.data;
      });
  }

  export const getLenderConfigurationByRadius = (data): any => {
    return http(CONFIG_API_GATEWAY)
      .get(Endpoint.EP_GET_LENDER_CONFIGURATION_RADIUS, {
        headers: data,
      })
      .then((res) => {
        return res.data;
      });
  };

  export const getDealerDistance = (data): any => {
    const { company_id, radius, zip_code, ...restPayload } = data;
    const baseUrl = `${DMS_BASE_URL}${Endpoint.GET_DEALER_DISTANCE_BY_CUSTOMER}`;
    const url = `${baseUrl}?company_id=${company_id}&radius=${radius}${zip_code ? `&zip_code=${zip_code}` : ''
      }`;
    return http(DMS_BASE_URL)
      .get(url)
      .then((res) => {
        return res.data;
      });
  };

  export const getVehicleDetailByVin = ({
    vin,
  }: {
    vin: string;
  }): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.EP_UPDATE_VEHICLE_DETAIL}/${vin}`)
      .then((res) => {
        return res.data;
      });
  };

  export const getcustomerOrdersByReferenceID = (query_params: string): any => {
    return http(DMS_BASE_URL)
      .get(`${Endpoint.GET_WORKQUEUE}?page_number=0&page_size=100`.concat(query_params))
      .then((res) => {
        return res.data;
      });
  };


  export const getCreditResponse = ({reference_number}: { reference_number: any }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.EP_CREDIT_RESPONSE}?reference_number=${reference_number}`)
    .then((res) => {
        return res.data
    });
  }

  export const createDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(Endpoint.EP_UPLOAD_DOCUMENT, data)
      .then((res) => {
        return res.data;
      });
  };

  export const createOrderDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(Endpoint.ORDER_DOCUMENT, data)
      .then((res) => {
        return res.data;
      });
  };

  export const createOrderFniDocumnet = (data :any) => {
    return http(DMS_BASE_URL)
      .post(Endpoint.ORDER_DOCUMENT_FNI, data)
      .then((res) => {
        return res.data;
      });
  };

  export const uploadDocument = (data :any ): any => {
    const { customer_reference_id, ...restPayload } = data[0];
    return http(DMS_BASE_URL)
      .patch(
        `${Endpoint.EP_UPLOAD_DOCUMENT_by_CUSTOMER_REFERENCE}/${customer_reference_id}`,
        data
      )
      .then((res) => {
        return res.data;
      });
  };

  export const nonFinancialStips = ({external_reference_id}: { external_reference_id: string }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.EP_UPLOAD_DOCUMENT}?external_reference_id=${external_reference_id}`)
    .then((res) => {
        return res.data
    });
  }

  export const getStipulationsByLenderId = ({company_id}: { company_id: string }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.EP_STIPULATION}?company_id=${company_id}`)
    .then((res) => {
        return res.data
    });
  }
  export const getTradeInHistoryByOrder = ({order_id}: { order_id: string }): any => {
    return http(DMS_BASE_URL)
    .get(`${Endpoint.GET_TRADE_IN_HISTORY}/${order_id}`)
    .then((res) => {
        return res.data
    });
  }
}
