import { FC, useContext, useEffect, useState } from 'react'
import { Divider, useTheme } from '@mui/material'
import {
  Typography,
  FileDragDrop,
  FilePreview,
  DatePicker,
  Box,
  Grid,
  Input,
  FileUpload,
  FileUploadPreview
} from '@ntpkunity/controls'
import { Controller } from 'react-hook-form'
import { IOrderInsurance } from '@models'
import AddressDetailsComponent from '../../libraries/insurance-control/customer-details/address-detail'
import { FileUploader, Stack } from '@components'
import { InsuranceFormWrap } from './insurance.style'
import { AppContext } from '@app/context-provider'

export const Insurance: FC<{
  InsuranceForm?: any
  formData?: IOrderInsurance
}> = ({ InsuranceForm }) => {
  const theme = useTheme()
  const appState = useContext(AppContext);
  const PLACEHOLDERS = appState.state.language.placeholders

  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = InsuranceForm
  const image = watch('image')
  const [imageFiles, setImageFiles] = useState<any[]>([])
  const onimageUpload = (e: any) => {
    if (e && e?.target && e?.target?.files && e?.target?.files.length > 0) {
      addFilesForPreview(e.target.files)
      clearErrors('insurance_documents')
    }
  }
  const onimageDrop = (e: any) => {
    if (
      e &&
      e?.dataTransfer &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files &&
      e?.dataTransfer?.files.length > 0
    ) {
      addFilesForPreview(e.dataTransfer.files)
      clearErrors('insurance_documents')
    }
  }
  const addFilesForPreview = async (files: any) => {
    const newImagesArray: any[] = []

    for (let i = 0; i < files.length; i++) {
      const file = files[i]

      try {
        const result = await readFileAsDataURL(file)
        newImagesArray.push({
          document: result,
          content_type: file.type,
          name: file.name,
          file_size: `${(file.size / 1024).toFixed(2)}KB`,
          created_by: 'null'
        })

        if (i === files.length - 1) {
          setValue('insurance_documents', [...(image || []), ...newImagesArray])
          setImageFiles([...imageFiles, ...newImagesArray])
        }
      } catch (error) {
        console.error('Error reading file:', error)
      }
    }
  }

  const readFileAsDataURL = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()

      fileReader.onload = (event) => {
        if (event.target && event.target.result) {
          resolve(event.target.result.toString())
        } else {
          reject('Failed to read file as data URL.')
        }
      }

      fileReader.onerror = () => {
        reject('Error reading file as data URL.')
      }

      fileReader.readAsDataURL(file)
    })
  }

  const onRemoveFile = (idx: string) => {
    const _index = Number(idx)
    const newImagesArray = imageFiles.filter((_obj: any, index: number) => index !== _index)
    setImageFiles(newImagesArray)
    const formImages = InsuranceForm.getValues('insurance_documents').filter(
      (_obj: any, index: number) => index !== _index
    )
    setValue('insurance_documents', formImages)
  }
  const base64ToFile = (base64Strings: any[]): File[] => {
    return base64Strings
      ?.map((base64String, index) => {
        const matches = base64String?.document?.match(/^data:([A-Za-z-+\/]+);base64,(.+)$/)
        if (!matches || matches.length !== 3) {
          console.error(`Invalid Base64 string format for element at index ${index}.`)
          return null
        }
        const mime = matches[1]
        const bstr = matches[2]
        let n = bstr.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n)
        }
        return new File([u8arr], base64String.name || `file_${index + 1}`, {
          type: mime
        })
      })
      .filter((file) => file !== null) as File[]
  }
  useEffect(() => {
    if (InsuranceForm?.getValues('insurance_documents')?.length > 0) {
      const filesArray = base64ToFile(InsuranceForm?.getValues('insurance_documents'))
      setImageFiles(filesArray)
    }
  }, [InsuranceForm?.getValues('insurance_documents')])
  return (
    <InsuranceFormWrap className='insurance-form-wrap' theme={theme}>
      <Stack bgLight paddingMd={3}>
        <Grid theme={theme} container item spacing={3}>
          <Grid item theme={theme} xs={12}>
            <Controller
              name="insurer_name"
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  label={PLACEHOLDERS.INSURANCE_PROVIDER_NAME}
                  placeholder={PLACEHOLDERS.INSURANCE_ADD_HERE}
                  type="text"
                  {...field}
                  error={errors?.insurer_name?.message}
                ></Input>
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={12}>
            <Controller
              name="agent_name"
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  label={PLACEHOLDERS.INSURANCE_AGENT_NAME}
                  placeholder={PLACEHOLDERS.INSURANCE_ADD_HERE}
                  type="text"
                  {...field}
                  error={errors?.agent_name?.message}
                ></Input>
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={12}>
            <Controller
              name="policy_number"
              control={control}
              render={({ field }) => (
                <Input
                  theme={theme}
                  fullWidth
                  label={PLACEHOLDERS.INSURANCE_POLICY_NUMBER}
                  placeholder={PLACEHOLDERS.INSURANCE_ADD_HERE}
                  type="text"
                  {...field}
                  error={errors?.policy_number?.message}
                ></Input>
              )}
            />
          </Grid>
          <Grid item theme={theme} xs={12}>
            <Controller
              name="expiration_date"
              control={control}
              defaultValue=""
              render={({ field: { value, onChange } }) => (
                <DatePicker
                  label={PLACEHOLDERS.INSURANCE_EXPIRATION}
                  disablePast
                  disablePortal={true}
                  theme={theme}
                  value={value ?? ''}
                  onChange={(e: any) => {
                    if (
                      e instanceof Date &&
                      e.getFullYear() === 1970 &&
                      e.getMonth() === 0 &&
                      e.getDate() === 1
                    ) {
                      onChange(null)
                    } else {
                      onChange(e.toISOString())
                    }
                  }}
                  error={errors.expiration_date?.message}
                />
              )}
            />
          </Grid>
        </Grid>
      </Stack>
      <Stack bgLight paddingMd={3} marginTopXs={2} marginTopMd={2}>
        <Typography
              theme={theme}
              variant="subtitle1"
              component="p"
          >
            {PLACEHOLDERS.INSURANCE_PROOF}
        </Typography>
        <Typography
            theme={theme}
            variant="caption"
            className='text-muted'
            component="span"
            display={'block'}
          >
            {PLACEHOLDERS.INSURANCE_SUPPORTED_FORMATS}
        </Typography>
        <Box theme={theme} className='insurance-file-upload' mt={3}>
          <FileUploader>
            <Controller
              name="insurance_documents"
              control={control}
              defaultValue={undefined}
              render={({ field }) => (
                <FileUpload
                  {...field}
                  theme={theme}
                  width="300"
                  hoverLabel={
                    <>{PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_1} <span className='text-primary'>{PLACEHOLDERS.FILE_DRAG_DROP_HOVER_LABEL_2}</span></>
                  }
                  height="243px"
                  backgroundColor="white"
                  onDrop={onimageDrop}
                  onChange={onimageUpload}
                  allowMultiple={true}
                  inlinePreview={false}
                  error={errors?.insurance_documents?.message}
                />
              )}
            />
            {imageFiles && imageFiles.length > 0 && imageFiles[0] != null && (
              <FileUploadPreview
                files={imageFiles.length > 0 ? imageFiles.map((image, index)=>(
                  {key:`${index}`, file:image, onRemoveFile:(event, index)=>{onRemoveFile(event)}})) : []}
                theme={theme}
              />
            )}
          </FileUploader>
        </Box>
      </Stack>
      <Stack bgLight paddingMd={3} marginTopXs={2} marginTopMd={2}>
        <Typography
              theme={theme}
              variant="subtitle1"
              component="p"
          >
            {PLACEHOLDERS.INSURANCE_PROVIDER_ADDRESS}
        </Typography>
        <Box theme={theme} className='insurance-address'>
          <AddressDetailsComponent
              form={InsuranceForm}
              isGoogleEnabled={false}
              addressValue={'insurer_address'}
              moveIn={false}
              errorProps={errors.insurer_address}
              control={control}
              contactName={"agent_phone"}
              contactError={errors?.agent_phone?.message}
              showContactNumber = {true}
          />
        </Box>
      </Stack>
    </InsuranceFormWrap>
  )
}