import React from 'react'
import { FC, useEffect, useState } from 'react'
import { TextField, useTheme } from '@mui/material'
import {
  Skeleton,
  Box,
  Typography,
  Icon
} from '@ntpkunity/controls'
import { RescheduleAppointmentWrap } from './delivery-step-one.style'
import dayjs, { Dayjs } from 'dayjs'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import localizedFormat from 'dayjs/plugin/localizedFormat'
import { useAppContext } from '@app/context-provider'
import AddressDetailsComponent from 'libraries/insurance-control/customer-details/address-detail'
import { ScheduleOptions } from '@constants'

export const RescheduleAppointmentDialog: FC<{
  tab:string
  scheduleOrderForm?:any
  isGoogleEnabled: boolean
}> = ({ tab,scheduleOrderForm,isGoogleEnabled }) => {
  const theme = useTheme()
  const {
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors }
  } = scheduleOrderForm
  dayjs.extend(utc)
  dayjs.extend(timezone)
  dayjs.extend(customParseFormat)
  dayjs.extend(localizedFormat)
  const maxSelectableDate = dayjs().add(15, 'day')
  const [dateTime, setDateTime] = React.useState<Dayjs | null>()
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders;
  const lender_id = appState.lender_id
  const [isVerified, setIsVerified] = useState(false)
  const [, setIsAddressDirty] = useState(false)

  useEffect(()=>{
    if(dateTime){
      setValue("appointment_date",dateTime?.utc()?.toISOString())
    }
  },[dateTime])

  useEffect(()=>{
    if(scheduleOrderForm.getValues("appointment_date")){
      setDateTime(dayjs(scheduleOrderForm.getValues("appointment_date")))
    }
    if(scheduleOrderForm.getValues("address")?.verified){
      setIsVerified(true)
    }
    if(scheduleOrderForm.getValues("address")?.address_line_1===" " ||scheduleOrderForm.getValues("address")?.city==="" || scheduleOrderForm.getValues("address")?.state_name==="" || scheduleOrderForm.getValues("address")?.state_id==="" || scheduleOrderForm.getValues("address")?.county==="" || scheduleOrderForm.getValues("address")?.zip_code===""){
      setIsVerified(false)
    }
  },[scheduleOrderForm,scheduleOrderForm.getValues("address") ])

  return (

        tab==ScheduleOptions.PICKUP ? (
          <>
          <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} inputProps={{...props.inputProps, readOnly: true}} />}
                label={PLACEHOLDERS.CHOOSE_DATE_TIME_LABEL_TEXT}
                value={dateTime ? dateTime : null}
                ampm={true}
                minutesStep={30}
                disablePast={true}
                onChange={(newValue: Dayjs | null) => {
                  setDateTime(newValue)
                }}
                minTime={dayjs('2022-02-14T09:00')}
                maxTime={dayjs('2022-02-14T20:30')}
                maxDate={maxSelectableDate}
                InputProps={{
                  autoComplete: 'off'
                }}
              />
            </LocalizationProvider>
          </RescheduleAppointmentWrap>
        </>
        ): tab==ScheduleOptions.DELIVERY ?(
            <>
          <RescheduleAppointmentWrap theme={theme} className="dialog date-time-picker">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DateTimePicker
                renderInput={(props) => <TextField {...props} inputProps={{...props.inputProps, readOnly: true}} />}
                label={PLACEHOLDERS.CHOOSE_DATE_TIME_LABEL_TEXT}
                value={dateTime ? dateTime : null}
                ampm={true}
                minutesStep={30}
                disablePast={true}
                onChange={(newValue: Dayjs | null) => {
                  setDateTime(newValue)

                }}
                minTime={dayjs('2022-02-14T09:00')}
                maxTime={dayjs('2022-02-14T20:30')}
                maxDate={maxSelectableDate}
                InputProps={{
                  autoComplete: 'on'
                }}
              />
            </LocalizationProvider>
            <Box theme={theme} className="address-layout">
              <Typography
                theme={theme}
                component="h4"
                variant="h4"
                sx={{ mb: 1, mt: 3 }}
                display={'flex'}
                className="title-icon"
              >
                {PLACEHOLDERS.SCHEDULE_CHOOSE_DELIVERY_LOCATION}
                {isVerified && isGoogleEnabled ? (
                  <Box theme={theme} sx={{ marginLeft: '6px' }}>
                    <Icon name="GreenCheckIcon" />
                  </Box>
                ) : null}
              </Typography>
              { tab == ScheduleOptions.DELIVERY ? (
                <AddressDetailsComponent
                form={scheduleOrderForm}
                isGoogleEnabled={isGoogleEnabled}
                setIsAddressDirty={setIsAddressDirty}
                addressValue={'address'}
                moveIn={false}
                errorProps={errors.address}
                control={control}
                isContactRequired={true}
            />
              ) : (
                <>
                  <Skeleton theme={theme} width={'100%'} height={'60px'} />
                  <Skeleton theme={theme} width={'100%'} height={'60px'} />
                  <Skeleton theme={theme} width={'100%'} height={'60px'} />
                </>
              )}
            </Box>
          </RescheduleAppointmentWrap>
        </>
        ) : (
          <>
            <Skeleton theme={theme} width={'100%'} height={'60px'} />
            <Skeleton theme={theme} width={'100%'} height={'60px'} />
            <Skeleton theme={theme} width={'100%'} height={'60px'} />
          </>
        )
  )
}
