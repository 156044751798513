import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {setMoveInDate} from "../../helpers/methods";
export const getDefaultValues = (contactData: any, customerReferenceId: any) => {

    const mailAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Mailing');
    const garagingAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Garaging');
    const carParkingAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Billing');

    return {
      mail_address: {
        ...mailAddress,
        state_id: mailAddress?.state_name,
        move_in_duration: setMoveInDate(mailAddress?.move_in_date),
        is_garaging: mailAddress?.is_garaging
      },
      garaging_address: !mailAddress?.is_garaging ? {
        ...garagingAddress,
        state_id: garagingAddress?.state_name,
      } : {},
      Billing: {
        ...carParkingAddress,
        state_id: carParkingAddress?.state_name,
      },
      customerReferenceId: customerReferenceId
    };
  };
export function useResidenceDetailsForm(contactData: any, customerReferenceId: any, PLACEHOLDERS: any, formStates: any) {
    const mailAddress = contactData?.customer_addresses?.find((x: any) => x?.address_type === 'Mailing');


    function createValidationSchema() {
        const schema = yup.object().shape({
            mail_address: yup.object().shape({
                address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
                city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
                state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                state_id: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED),
                move_in_duration: yup
                    .string()
                    .nullable()
                    .required(PLACEHOLDERS.RESIDENCE_DETAILS_MOVE_IN_DATE_IS_REQUIRED)
                    .matches(/^(0[1-9]|1[0-2])(19|20)\d{2}$/, PLACEHOLDERS.RESIDENCE_DETAILS_MOVE_IN_DATE_REQUIRED_FORMAT),
                is_garaging: yup.boolean().nullable()
            }),
            garaging_address: yup.object().when('mail_address.is_garaging', {
              is: false,
              then: yup.object().shape({
                  address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
                  city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
                  state_id: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                  state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                  zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED)
              }),
              otherwise: yup.object().shape({}) // No validation if garaging is not needed
          }),
            Billing: yup.object().shape({
                address_line_1: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ADDRESS_LINE_1_IS_REQUIRED),
                city: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_CITY_IS_REQUIRED),
                state_name: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                state_id: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_STATE_NAME_IS_REQUIRED),
                zip_code: yup.string().nullable().required(PLACEHOLDERS.RESIDENCE_DETAILS_ZIP_CODE_IS_REQUIRED)
            })
        })
        return schema
    }

  const validationSchema = createValidationSchema();

  const residentForm = useForm<any>({
    defaultValues: formStates?.['residence']?.data || getDefaultValues(contactData, customerReferenceId),
    resolver: yupResolver(validationSchema),
    mode: 'onChange'
  });

  const watchResidenceFields = residentForm.watch();
  return { residentForm, watchResidenceFields };
}
