import { styled } from '@mui/material/styles'

export const ProductDetailDialog = styled('div')(({ theme }) => ({
  minHeight: '69.9vh',
  '.img-wrap': {
    borderRadius: 8,
    width: '100%',
    maxHeight: 383,
    display: 'flex',
    verticalAlign: 'middle',
    textAlign: 'center',
    overFlow: 'hidden',
    marginBottom: 16,
    img: {
      maxWidth: '100%',
      maxHeight: 383,
      width: 'auto',
      height: 'auto',
      display: 'block',
      margin: 'auto'
    }
  },
  '.custom-link': {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    color: theme.palette.grey[600],
    textDecoration: 'none',
    cursor: 'pointer',
    width: 'auto',
    display: 'inline-block',
    clear: 'both',
    '&:hover': {
      color: theme.palette.primary.main,
      textDecoration: 'underline !important'
    },
    '&.custom-link': {
      '.MuiTypography-root': {
        display: 'flex',
        alignItems: 'center',
        gap: 8
      },
      svg: {
        transform: 'rotate(180deg)'
      }
    }
  },
  '.mini-card': {
    border: '1px solid' + theme.palette.divider,
    borderRadius: 8,
    padding: 24,
    '.title-tooltip': {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      '.styled-tooltip': {
        '.MuiButton-root': {
          padding: 0
        }
      }
    }
  }
}))
