import { useTheme } from '@mui/material'
import { Box, Input, Typography, } from '@ntpkunity/controls'
import { Stack, Textbox } from '@components'
import { VinPopupWrap } from './vin-popup.style'
import img from '../../../public/assets/images/vin-img.svg'
import { Controller } from "react-hook-form"
import { useAppContext } from '@app/context-provider'
import {useEffect, useState} from "react";


export const VinOnePopup = (props) => {
  const theme = useTheme()
  const { state: appState } = useAppContext()
  const PLACEHOLDERS = appState.language.placeholders
  const { control, messages, errors, getValues, setValue } = props;
  const values = getValues()
  const { vin } = values
  const [selectedValue] = useState(vin)


    useEffect(() => {
        if(vin && vin?.length > 16 && vin !== selectedValue){
            setValue(messages.name.Trim, null)
        }
    }, [vin]);

  return (
    <VinPopupWrap theme={theme} className='vin-popup-wrap has-header-footer'>
      <Stack bgLight paddingXs={3} paddingMd={3} className='vin-popup'>
        <Typography theme={theme} component={'p'} variant='subtitle1' mb={'8px'}>{PLACEHOLDERS.TRADE_IN_VIN_ONE_POPUP_PROMPT_LABEL}</Typography>
        <Controller
          name={messages.name.Vin} 
          rules={{ required: true, minLength: 17, maxLength:17}}
          control={control} 
          render={({field}) => (
          <Textbox>
              <Input
                type={'text'}
                theme={theme}
                placeholder={PLACEHOLDERS.TRADE_IN_VIN_ONE_POPUP_INPUT_PLACEHOLDER}
                helperText={errors?.[messages.name.Vin] ? null: PLACEHOLDERS.TRADE_IN_VIN_ONE_POPUP_FORMAT_LABEL}
                {...field}
                fullWidth
                error={errors?.[messages.name.Vin] ? messages.validation[messages.name.Vin][errors?.[messages.name.Vin].type] : false}
                />
            </Textbox>
          )}
          />
           
      </Stack>
      <Typography theme={theme} component={'p'} variant='body2' mt={2} className='text-muted text-center'>
        {PLACEHOLDERS.TRADE_IN_VIN_ONE_POPUP_INSTRUCTIONS_LABEL}
      </Typography>
      <Box theme={theme} className='product-img'>
        <img src={img} alt='product img' />
      </Box>
    </VinPopupWrap>
  )
}


