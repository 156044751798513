import React from 'react'
import { useTheme } from '@mui/material'
import clsx from 'clsx'
import { StackProps } from './stack.props'
import { StackWrap } from './stack.style'

export const Stack = ({ className, paddingMd = 4, paddingXs = 3, marginTopXs, marginTopMd, bgLight, bgDark, hasShadow, children, dFlex, dColumn, dNone, justifyBetween, alignItemsCenter, onClick } : StackProps) => {
    const theme = useTheme()
  return (
    <StackWrap className={clsx(className, { 'stack-wrap': true, 'bg-light': bgLight, 'bg-dark': bgDark, 'has-shadow': hasShadow, 'd-flex': dFlex, 'd-none': dNone, 'justify-content-between': justifyBetween, 'align-items-center': alignItemsCenter, 'flex-direction-column': dColumn })} theme={theme} p={{xs: paddingXs, md: paddingMd}} mt={{xs:marginTopXs, md:marginTopMd}} onClick={onClick}>
        {children}
    </StackWrap>
  )
}
