import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const CjFileUploaderWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.cj-file-uploader-wrap': {
    textAlign: 'center',
    '.u-custom-file-upload': {
      marginBottom: '16px',
      '.root-drag-file': {
        height: '214px',
        '.image-viewer-wrap': {
          height: '100%',
          width: '100%',
          '.upload-success': {
            height: '100%'
          }
        }
      }
    }
  },
  '&.custom-uploader-wrap': {
    textAlign: 'center',
    '.u-custom-file-upload': {
      marginBottom: '16px',
      '.root-drag-file': {
        height: '214px',
        '.image-viewer-wrap': {
          height: '100%',
          width: '100%',
          '.upload-success': {
            height: '100%'
          }
        }
      }
    }
  },
  '.image-viewer-wrap': {
    '.file-preview': {
      '.file-icon': {
        width: 32,
        height: 32
      },
      '.file-name-wrap': {
        textAlign: 'left',
        '.file-name': {
          fontWeight: theme.typography.fontWeightMedium
        }
      }
    }
  }
}))
