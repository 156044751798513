import { useTheme } from '@mui/material'
import {Box, Button, DuoTab, Grid, Input, Skeleton, Typography,} from '@ntpkunity/controls'
import { Stack, Textbox, SelectionButton, CjButton, Tab } from '@components'
import React from 'react'
import RadioGroupRating from '../../../components/rating/rating.component'
import { ConditionPopupWrap } from './condition-popup.style'
import { useAppContext } from '@app/context-provider'

export const ConditionRatingPopup = (props) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders
    const { control, messages, question, answers, errors, getValues } = props;
    const values = getValues()
    const {model, year, make, trim} = values;

    return(
        <>
        <ConditionPopupWrap theme={theme} className='condition-popup-wrap has-header-footer'>
            <Stack bgLight paddingXs={3} paddingMd={4} className='condition-wrap'>
                {question ?
                    <Typography theme={theme} component={'p'} variant='subtitle1' marginBottom={3}>
                        {question}{model && model.split(',').length >= 2 ? ' '+model.trim().replace(/,/g, ' '): ` ${year} ${make} ${model} ${trim ? trim : ''}`}{PLACEHOLDERS.QUESTION_MARK_TEXT}
                    </Typography>:
                        <Skeleton theme={theme} width={'100%'} height={'20px'} style={{marginBottom: '24px'}} />
                }
                <RadioGroupRating control={control} messages={messages} answer={answers} errors={errors} />
                <Typography theme={theme} component={'small'} variant='caption' className='text-muted' marginTop={'12px'} display={'block'}>
                    {PLACEHOLDERS.TRADE_IN_CONDITION_POPUP_EXCELLENT_DESCRIPTION}
                </Typography>
            </Stack>
        </ConditionPopupWrap>
        </>
    )
}


