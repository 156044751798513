import React from "react";
import ReactDOM from "react-dom";
import singleSpaReact from "single-spa-react";
import { Application } from "../app/application";

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Application,
  errorBoundary(err, info, props) {
    console.log(err, info, props);
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;
