import { QueryClientProvider, QueryClient } from "react-query";
import { StyledEngineProvider } from '@mui/material'
import { ThemeProvider } from '@mui/material/styles'
import { UMSConfigurationComponent } from '@ntpkunity/controls-ums'
import { ReactQueryDevtools } from "react-query/devtools";
import { createBrowserRouter, RouterProvider} from "react-router-dom";
import { CUSTOMER_JOURNEY_THEME, CUSTOMER_JOURNEY_THEME_WRAP } from '@styles'
import { BaseLayout } from '@layouts'
import { RouteProvider as MainRouteProvider } from './router'
import { ModalProvider } from 'react-modal-hook'

import { ApplicationContextProvider } from './context-provider'

export const Application = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        retry: 1,
      },
    },
  })
    const router = createBrowserRouter([
    { path: '*', Component: MainRouteProvider }
  ])


  return (
    <ApplicationContextProvider>
      <UMSConfigurationComponent>
          <QueryClientProvider client={queryClient}>
              <StyledEngineProvider injectFirst>
                <ThemeProvider theme={CUSTOMER_JOURNEY_THEME}>
                  <CUSTOMER_JOURNEY_THEME_WRAP
                    theme={CUSTOMER_JOURNEY_THEME}
                    className="cj-global-theme-wrap"
                  >
                    <ModalProvider>
                    <RouterProvider router={router} />
                    </ModalProvider>
                  </CUSTOMER_JOURNEY_THEME_WRAP>
                </ThemeProvider>
              </StyledEngineProvider>
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>

      </UMSConfigurationComponent>
    </ApplicationContextProvider>
  );
}
