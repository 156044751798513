import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SelectionButtonWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.selection-button-wrap': {
    display: 'flex',
    alignItems: 'center',
    gap: 8,
    '.cj-btn': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.grey[900],
        padding: '10px 16px',
        borderRadius: 8,
        display: 'flex',
        flexDirection: 'column',
        cursor: 'pointer',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'nowrap',
        whiteSpace: 'nowrap',
        '&.bg-light': {
          backgroundColor: '#F5F5F7'
        },
        '&.selected': {
          backgroundColor: theme.palette.grey[700],
          color: theme.palette.common.white,
          '.text-muted': {
            color: theme.palette.grey[100]
          }
        },
        '&.disabled': {
          color: theme.palette.grey[200],
          '.text-muted': {
            color: theme.palette.grey[200]
          }
        }
    }
  }
}))
