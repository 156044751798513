import { useTheme } from '@mui/material'
import { CircleLoader, Typography, } from '@ntpkunity/controls'
import { Stack } from '@components'
import { LoaderPopupWrap } from './loader.style'
import { LoaderProps } from './loader.props'

export const LoaderPopup = ( {title, subtitle} : LoaderProps) => {
    const theme = useTheme()
    
    return(
        <LoaderPopupWrap theme={theme} className='loader-popup-wrap no-header-footer'>
            <Stack className='popup-loader text-center' paddingMd={0} paddingXs={0}>
                <CircleLoader theme={theme} size='xl'/>
                {title && 
                    <Typography theme={theme} component={'h3'} variant='h3' mb={'8px'} mt={3}>{title}</Typography>
                }
                {subtitle &&
                <Typography theme={theme} component={'p'} variant='body2' className='text-muted'>{subtitle}</Typography>
                }
                
            </Stack>
        </LoaderPopupWrap>
    )
}


