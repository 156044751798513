import { OrderManagement } from "../apis";
import { useMutation, useQuery } from "react-query";
import { QueryKeys } from "../constants";
import { useQueryClient } from 'react-query'
import {useCallback, useEffect, useRef} from "react";
import {scrollOnTop} from "../helpers/methods";
import {useBeforeUnload} from "react-router-dom";
import {useFormContext} from "../pages/(order-management)/order/form-context-provider";

export const useGetVehicleByVin = (vin: string) => {
  return useQuery([QueryKeys.VEHICLE, vin], () => {
    return OrderManagement.getVehicle({vin});
  });
};

export const useGetVehicleDetailByVin = (vin: string) => {
  return useQuery([QueryKeys.VEHICLE_DETAIL, vin], () => {
    return OrderManagement.getVehicleDetail({vin});
  });
};

export const useGetSetupData = (queryParams: any) => {
  return useQuery(QueryKeys.GET_SETUP_DATA, () => {
    return OrderManagement.getSetupData(queryParams);
  });
};

export const useGetTrimCode = (dealer_code: string, trim_code: string) => {
  return useQuery([QueryKeys.ADDON, dealer_code, trim_code], () => {
    return OrderManagement.getTrimCode({dealer_code, trim_code});
  }, {enabled: !!dealer_code && !!trim_code});
};

export const useGetIntegrationByProviderName = (provider_name: string, integration_type: string, company_id): any => {
  const dealer_code = JSON.parse(localStorage.getItem('dealer_information'))?.dealer?.dealer_code
  const {data, isLoading, error, mutate} = useMutation(() => {
    return OrderManagement.getIntegrationByProviderName({provider_name, integration_type, dealer_code, company_id});
  });
  return {data, isLoading, error, mutate};
};

export const UseGetFinancialInsuranceProduct = (): any => {
  const {data, isLoading, error, mutate} = useMutation(
      (columnFilters: string) => {
        return OrderManagement.getFinancialInsuranceProduct(columnFilters);
      }
  );
  return {data, isLoading, error, mutate};
};

export const useVerifyAddress = (): any => {
  const {data, isLoading, error, mutate} = useMutation((body) => {
    return OrderManagement.verifyAddress({body});
  });
  return {data, isLoading, error, mutate};
};


export const useGetFnIProductRate = (): any => {
  const {data, isLoading, error, mutate} = useMutation((body) => {
    return OrderManagement.getFnIProductRate({body});
  },
    {
      retry: 2,
    });
  return {data, isLoading, error, mutate};
};

export const useGetDealerProfileByDealerCode = (dealer_code) => {
  return useQuery(
      QueryKeys.DEALER_PROFILE,
      () => {
        return OrderManagement.getDealerProfileByDealerCode({dealer_code});
      },
      {enabled: !!dealer_code}
  );
};

export const useGetStates = (): any => {
  return useQuery(QueryKeys.GET_ALL_STATES, () => {
    return OrderManagement.getStates();
  });
};

export const useCreateOrderDocument = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.createOrderDocumnet(body);
  });
};

export const useCreateOrderFniDocument = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.createOrderFniDocumnet(body);
  });
};

export const useGetCountries = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.getCountries(body);
  });
};

export const useGetOrderInformation = (orderId: string) => {
  return useQuery(
      [QueryKeys.GET_ORDER_INFORMATION, orderId],
      () => {
        return OrderManagement.getOrder({orderId});
      },
      {enabled: !!orderId}
  );
};

export const useGetVehicleByTrimCode = (trimCode: string) => {
  return useQuery(
      QueryKeys.VEHICLE_TRIM_CODE,
      () => {
        return OrderManagement.getVehicleByTrimCode({trimCode});
      },
      {enabled: !!trimCode}
  );
};

export const useGetAllPrograms = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.getPrograms(body);
  });
};

export const useCalculateOrderQuotation = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.calculateOrderQuotation(body);
  });
};

export const useCustomerByEmail = (email: string, dealer_id: any) => {
  return useQuery(QueryKeys.CUSTOMER_EMAIL, () => {
    return OrderManagement.customerByEmail({email, dealer_id});
  }, {enabled: !!email && !!dealer_id});
};

export const useGetCustomerContact = (reference_id: string) => {
  return useQuery(QueryKeys.CUSTOMER_CONTRACT, () => {
    return OrderManagement.customerContractByReferenceID({reference_id});
  }, {enabled: !!reference_id});
};

export const useUpdateCustomerContact = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
        return OrderManagement.updateCustomerContractByReferenceID(body);
      },
      {
        onSuccess: async (_responce: any) => {
          queryClient.invalidateQueries(QueryKeys.CUSTOMER_CONTRACT)
        }
      }
  );
};
export const useGetCurrencies = (): any => {
  return useQuery(QueryKeys.GET_CURRENCY, () => {
    return OrderManagement.getCurrency();
  });
};

export const useGetCompanyIntegrations = (company_id: any) => {
  return useQuery(QueryKeys.GET_COMPANY_ID, () => {
    return OrderManagement.getCompanyIntegrations({ company_id });
  });
};

export const useSaveOrderRequest = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
        return OrderManagement.saveOrderRequest(body);
      },
      {
        onSuccess: (response: any) => {
          queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, response[0].reference_number])
        }
      });
};
export const useGetIntegrationConfiguration = (): any => {
  const {data, isLoading, error, mutateAsync} = useMutation((body: any) => {
    return OrderManagement.getIntegrationConfiguration(body);
  });
  return {data, isLoading, error, mutateAsync};
};

export const useConvertQuotationToApplication = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.convertQuotationToApplication(body);
  });
};

export const useGetPersonalFinance = (reference_id: string) => {
  return useQuery(QueryKeys.GET_PERSONAL_FINANCE, () => {
    return OrderManagement.getPersonalFinanceInfo({reference_id});
  }, {enabled: !!reference_id});
};
export const useCalculateNfa = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.calculateNfa(body);
  });
};
export const SaveEmploymentDetails = (): any => {
  return useMutation<any>((data: any) => {
    return OrderManagement.saveEmploymentDetails(data);
  });
};

export const useGetEmploymentInformation = (reference_id: string) => {
  return useQuery(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS, () => {
    return OrderManagement.EmploymentInfoByReferenceID({reference_id});
  }, {enabled: !!reference_id});
};

export const useUpdateEmploymentInfo = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
        return OrderManagement.updateEmploymentInfoByReferenceID(body);
      },
      {
        onSuccess: async (_responce: any) => {
          queryClient.invalidateQueries(QueryKeys.GET_CUSTOMER_EMPLOYMENT_DETAILS)
        }
      });
};

export const useUpdateOrderRequest = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.updateOrderRequest(body);
  });
};

export const useAddPersonalFinance = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
        return OrderManagement.createEmploymentInfoByReferenceID(body)
      },
      {
        onSuccess: async (_responce: any) => {
          queryClient.invalidateQueries(QueryKeys.GET_PERSONAL_FINANCE)
        }
      });
};

export const useUpdatePeronalFinanceInfo = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
    return OrderManagement.updatePersonalFinanceByReferenceID(body)
  }, {
    onSuccess: async (_responce: any) => {
      queryClient.invalidateQueries(QueryKeys.GET_PERSONAL_FINANCE)
    }
  });
};

export const useCreateCustomerContact = (): any => {
  const {data, isLoading, error, mutate} = useMutation((body) => {
    return OrderManagement.createCustomerByReferenceID(body);
  });
  return {data, isLoading, error, mutate};
};

export const useUpdateOrderStatus = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
    return OrderManagement.updateOrderStatus(body)
  }, {
    onSuccess: async (responce: any) => {
      queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, responce?.reference_number])
    }
  });
};

export const useSubmitOrder = (): any => {
  const queryClient = useQueryClient()
  return useMutation<any>((body: any) => {
    return OrderManagement.submitOrder(body)
  }, {
    onSuccess: async (responce: any) => {
      queryClient.invalidateQueries([QueryKeys.GET_ORDER_INFORMATION, responce?.reference_num])
      queryClient.invalidateQueries([QueryKeys.GET_ORDER_STIPULATION_DATA, responce?.reference_num])
    }
  });
};

export const useAddAppointment = (): any => {
  return useMutation<any>((body: any) => {
    return OrderManagement.addAppointment(body);
  });
};

export const useUpdateAppointment = (): any => {
  const {data, isLoading, error, mutate} = useMutation((body) => {
    return OrderManagement.updateAppointment(body);
  });
  return {data, isLoading, error, mutate};
};

export const useDeleteAppointment = (): any => {
  const {data, isLoading, error, mutate} = useMutation((body) => {
    return OrderManagement.deleteAppointment(body);
  });
  return {data, isLoading, error, mutate};
};

export const useGetConfigurationRadius = (company_id: any) => {
  return useQuery(QueryKeys.GET_CONFIGURATION_RADIUS, () => {
    return OrderManagement.getLenderConfigurationByRadius({company_id});
  }, {enabled: !!company_id});
};

export const getAllDealerDistanceByCustomer = () => {
  return useMutation<any, Error, any>((body: any) => {
    return OrderManagement.getDealerDistance(body)
  });
};

export const getVehicleDetailByVin = (vin: string) => {
  return useQuery(QueryKeys.GET_VEHICLE_DATA, () => {
    return OrderManagement.getVehicleDetailByVin({vin});
  }, {enabled: !!vin});
};

export const useGetCustomerOrders = (): any => {
  return useMutation<any>((query_params: any) => {
    return OrderManagement.getcustomerOrdersByReferenceID(query_params)
  })
};

export const useGetCreditResponse = (reference_number: string): any => {
  return useQuery([QueryKeys.GET_ORDER_STIPULATION_DATA, reference_number], () => {
    return OrderManagement.getCreditResponse({reference_number});
  }, {enabled: !!reference_number})
};

export const useGetNonFinancialStips = (): any => {
  return useMutation<any, Error, string>((external_reference_id: string) => {
    return OrderManagement.nonFinancialStips({external_reference_id});
  });
};

export const useGetStipulationsByLenderId = (): any => {
  return useMutation<any, Error, string>((company_id: string) => {
    return OrderManagement.getStipulationsByLenderId({company_id});
  });
};

export const useCreateDocument = (): any => {
  const {data, isLoading, error, mutate} = useMutation(
      (body: any) => {
        return OrderManagement.createDocumnet(body);
      }
  );

  return {data, isLoading, error, mutate};
};

export const useUploadDocument = (): any => {
  const {data, isLoading, error, mutate} = useMutation((body: any) => {
    return OrderManagement.uploadDocument(body);
  });
  return {data, isLoading, error, mutate};
};

export const useGetTradeInHistory = (order_id) => {
  return useQuery(
      QueryKeys.TRADE_IN_HISTORY,
      () => {
        return OrderManagement.getTradeInHistoryByOrder({order_id});
      },
      {enabled: !!order_id}
  );
};

export const useScrollOnTop = (dependency: any) => {
  useEffect(() => {
    scrollOnTop()
  }, [dependency]);
};

export const useBeforeUnloadHandler = (isDirty) => {
  const beforeOnload = useCallback(
    (event) => {
      if (isDirty) {
        event.preventDefault();
        return (event.returnValue = '');
      } else {
        return null;
      }
    },
    [isDirty]
  );

  useBeforeUnload(beforeOnload, { capture: true });
};


export const useCustomDirtyForm = (formName: string, watchAllFields: any, isValid: any) => {
  const { updateFormState, resetFormState, formStates, setInitialFormState } = useFormContext();

  useEffect(() => {
    if (!formStates[formName]?.initialData) {
      setInitialFormState(formName, watchAllFields);
    }
  }, []);
  useEffect(() => {
    const formIsDirty = JSON.stringify(formStates[formName]?.initialData) !== JSON.stringify(watchAllFields);
    if (formIsDirty !== formStates?.[formName]?.isDirty || JSON.stringify(formStates?.[formName]?.data) !== JSON.stringify(watchAllFields) || isValid !== formStates?.[formName]?.isValid) {
      updateFormState(formName, { isDirty: formIsDirty, data: watchAllFields, isValid: isValid });
    }
  }, [watchAllFields, formName, formStates, updateFormState]);

  const resetForm = () => {
    resetFormState(formName);
  };

  return { resetForm };
};
