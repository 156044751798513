import React from 'react'
import { useTheme } from '@mui/material'
import { ProfileLayout } from '@layouts'
import { Orders } from '@libraries'

export const MyOrdersPage = () => {
    const theme = useTheme()
    return (
            <Orders />
    )
}