import { useTheme } from '@mui/material'
import {
  Icon,
  Box,
  Typography,
  Button,
  Image
} from '@ntpkunity/controls'
import { OrderConfirmationWrap } from './order-confirmation.style'
import { OrderConfirmationProps } from './order-confirmation.props'

export const OrderConfirmationLayout = ({ productImg, productbg, bgText, title, heading, congrats, description, terms, onLinkClick, linkText, linkDesc, subtitle, summaryBtnText, orderContinueBtnText, onClick, onViewOrderSummaryClick, onContinueOrderClick, onChatClick, onCallClick, onDirectionClick, chatBtnText, callBtnText, directionBtnText, isContinueOrderBtnDisabled, actionButtons }: OrderConfirmationProps) => {
  const theme = useTheme()
  return (
    <OrderConfirmationWrap theme={theme} className='order-confirmation-wrap'>
      <Box theme={theme} className='product-card'>
        {productbg &&
          <Box theme={theme} className='product-card-bg'>
            <img src={productbg} className='img' />
          </Box>
        }
        {bgText &&
          <Box theme={theme} className='product-bg-text'>
            <Typography theme={theme} className='bg-text' variant='h1' component={'h1'} display={'block'}>{bgText}</Typography>
          </Box>
        }
        <Box theme={theme} className='card-content'>
          <Box theme={theme} width={'100%'}>
            <Box theme={theme} className='text-items'>
              {subtitle &&
                <Typography theme={theme} className='subtitle text-muted fw-medium' variant='body1' component={'span'} display={'block'}>{subtitle}</Typography>
              }
              {title &&
                <Box theme={theme} className='title'>
                  <Typography theme={theme} variant='h3' component={'h3'}>{title}</Typography>
                  {onClick &&
                    <Button defaultBtn theme={theme} onClick={onClick} iconText={<Icon name="CicleLinkIcon" />} />
                  }
                </Box>
              }
            </Box>
            {productImg &&
              <Box theme={theme} className='product-card-fg'>
                <Image
                  theme={theme}
                  src={productImg}
                  aspectRatio='16:9'
                />
              </Box>
            }

              <Box theme={theme} className='card-detail'>
                <Box theme={theme}>
                  {heading &&(
                  <Typography theme={theme} variant='h2' component={'h2'} className='text-h2'>{heading}</Typography>
                  )}
                  {congrats && (
                    <Typography theme={theme} variant='h2' component={'h2'} className='text-h2'>{congrats}</Typography>
                  )}
                  {description &&
                    <Typography theme={theme} className='text-muted text-body2' variant='body2' component={'p'} mt={1}>{description}</Typography>
                  }
                </Box>
                {terms &&
                  <Box theme={theme} className='section'>
                    {terms}
                  </Box>
                }
                {actionButtons}
                {onViewOrderSummaryClick ?
                  <></>
                  :
                  <Box theme={theme} className='action-btns'>
                    {onChatClick &&
                      <Button
                        secondary
                        theme={theme}
                        text={chatBtnText}
                        onClick={onChatClick}
                        startIcon={<Icon name='StartChatIcon' />}
                      />
                    }
                    {onCallClick &&
                      <Button theme={theme} secondary text={callBtnText} onClick={onCallClick} startIcon={<Icon name="CallIcon" />} />
                    }
                    {onDirectionClick &&
                      <Button theme={theme} secondary text={directionBtnText} onClick={onDirectionClick} startIcon={<Icon name="DirectionIcon" />} />
                    }
                  </Box>
                }

                {onLinkClick &&
                  <Box theme={theme} className='link-text'>
                    {linkDesc &&
                      <Typography theme={theme} className='text-muted' variant='caption' component={'small'} display={'block'}>
                        {linkText &&
                          <a href='javascript:;' className='link decoration-none' onClick={onLinkClick}>{linkText}</a>
                        }
                        {linkDesc}
                      </Typography>
                    }
                  </Box>
                }
              </Box>

          </Box>
        </Box>
      </Box>
    </OrderConfirmationWrap>
  )
}
