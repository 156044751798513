import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SignatureStatusStackWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.signature-status-stack-wrap': {
    '.title': {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        display: 'inline-block !important',
        maxWidth: 150,
        [theme.breakpoints.down('sm')]: {
            width: 85
        }
    },
    '.item-status-text': {
        display: 'flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        gap: 8,
        '&.error': {
            svg: {
                path: {
                    stroke: theme.palette.error.main
                }
            }
        }
    }
  }
}))
