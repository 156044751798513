import { useTheme } from '@mui/material'
import { Box, Button, Icon, List, Typography } from '@ntpkunity/controls'
import clsx from 'clsx'
import { CompletedStepsWrap } from './completed-steps.style'
import { CompletedStepsProps } from './completed-steps.props'
import React from 'react'

export const CompletedSteps = ({ stepItems }: CompletedStepsProps) => {
    const theme = useTheme()

    return(
        <CompletedStepsWrap theme={theme} className='completed-steps-wrap'>
            <ul>
                {stepItems.map((item, index) => (
                    <li key={index} data-testid ={index} className={clsx({ 'step-item': true, 'success': item.success, 'error': item.error })}>
                        {item.success && <Icon name='GreenCheckCircleIcon'/>}
                        {item.error && <Icon name='InfoErrorIcon'/>}
                        <Box theme={theme} className='content'>
                            <Typography theme={theme} variant='subtitle1' component={'p'} display={'block'}>{item.title}</Typography>
                            <Typography theme={theme} variant='caption' component={'small'} className='text-muted'>{item.subtitle}</Typography>
                        </Box>
                    </li>
                ))}
            </ul>
        </CompletedStepsWrap>
        
    )
}