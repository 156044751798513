import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const HeaderMenuRightWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.header-menu-right-wrap': {
    flexShrink: 0,
    '.navbar-nav': {
      '&.nav-right': {
        '.nav-item': {
          '&.dropdown': {
            '.MuiPaper-root': {
              width: 200,
              '.MuiList-root': {
                '.u-dropdown-item': {
                  '&.divider': {
                    borderTopStyle: 'solid',
                    borderWidth: 1,
                    borderRadius: 0,
                    borderColor: theme.palette.grey[100],
                    margin: '8px 24px 8px 16px',
                    padding: 0
                  },
                  '&.selected': {
                    display: 'flex',
                    alignItems: 'center',
                    alignSelf: 'stretch',
                    gap: 12,
                    '.selected-icon': {
                      display: 'flex',
                      width: '100%',
                      alignItems: 'center',
                      alignSelf: 'stretch',
                      gap: 12,
                      justifyContent: 'space-between',
                      '.tick-icon': {
                        display: 'flex',
                        marginRight: 0,
                        path: {
                          stroke: '#9295A6'
                        }
                      }
                    }
                  },
                  display: 'flex',
                  alignItems: 'center',
                  alignSelf: 'stretch',
                  gap: 12
                }
              },
              '.selected-icon': {
                '.tick-icon': {
                  display: 'none'
                }
              },
              '.menu-icon': {
                marginRight: 0,
                path: {
                  stroke: 'none'
                }
              }
            },
          }
        }
      }
    },
    
    '.language-menu': {
      position: 'relative',
      marginTop: 8,
      '&::before': {
        display: 'block',
        content: '""',
        borderTopStyle: 'solid',
        borderWidth: 1,
        borderColor: theme.palette.grey[100],
        margin: '0 24px'
      },
      '.navbar-nav': {
        paddingTop: 8
      }
    },
    
  }
}))
