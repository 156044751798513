import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const BaseLayoutWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.base-layout-wrap': {
    '.main-content': {
      paddingTop: 80,
      paddingRight: 8,
      paddingLeft: 8,
      paddingBottom: 8,
      minHeight: 'calc(100vh - 72px)',
      [theme.breakpoints.down('md')]: {
        paddingTop: 0,
        minHeight: 'auto'
      },
      '.brand-search-tabs-filter': {
        position: 'sticky',
        top: 80
      }
    }
  }
}))
