import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const SummaryListWrap = styled(
    Box,
    {}
  )<Partial<IBoxProps>>(({ theme }) => ({
    '&.cj-summary-wrap': {
        '.summary-list' :{
            display: 'flex',
            justifyContent: 'space-between',
            '.MuiTypography-root': {
                margin: 0,
                marginTop: 16,
                paddingLeft: 16
            }
        }
    }
  }))
