import React, {useEffect} from 'react';
import {LicenseDetails} from "../../../../libraries";
import {useCustomDirtyForm} from "../../../../hooks/order-management";
import {getDefaultLicenseValues} from "../../../../utilities/form/license-details";
import {useFormContext} from "../../../(order-management)/order/form-context-provider";

const LicenseDetailsWrapper = ({
  setTab,
  licenseData,
  licenceForm,
  watchLicenseFields,
}) => {

    const {formStates} = useFormContext()

    useCustomDirtyForm('licence', watchLicenseFields, licenceForm?.formState?.isValid);

    useEffect(() => {
        if(licenseData) {
            licenceForm?.reset(formStates?.['licence']?.data || getDefaultLicenseValues(licenseData))
        }
    }, [licenseData]);

  return (
    <LicenseDetails
      setTab={setTab}
      licenseData={licenseData}
      form={licenceForm}
    />
  );
};

export default LicenseDetailsWrapper;
