import { useTheme } from '@mui/material'
import { Typography, Grid, Box } from '@ntpkunity/controls'
import { FileUploader, Stack } from '@components'
import { FC } from "react";
import { useAppContext } from '@app/context-provider'
import { CustomImageUploader } from 'libraries/license-details/custom-image-uploader';
import { licenseImage } from '../../../helpers/index'

export const DocumentsCard: FC<{
    licenseData?
}> = ({ licenseData }) => {
    const theme = useTheme()
    const { state: appState } = useAppContext()
    const PLACEHOLDERS = appState.language.placeholders;

    return (
        <>
            <Stack paddingXs={3} paddingMd={4} className='delivery-preference'>
                <Typography theme={theme} variant='h4' component={'h4'} mb={3} display={'block'}>
                    {PLACEHOLDERS.DOCUMENTS_CARD_YOUR_DOCUMENTS}
                </Typography>
                <Grid theme={theme} container item rowSpacing={3} columnSpacing={2}>
                    <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                        <FileUploader>
                            {licenseData?.front_image &&
                                <CustomImageUploader>
                                    <Box theme={theme} className="img-wrap">
                                        <img src={licenseImage(licenseData?.front_image)} />
                                    </Box>
                                </CustomImageUploader>
                            }
                            <Typography
                                theme={theme}
                                className="text-muted"
                                variant="caption"
                                component={"small"}
                            >
                                {PLACEHOLDERS.DOCUMENTS_CARD_FRONT_SIDE_DRIVER_LICENSE}
                            </Typography>
                        </FileUploader>
                    </Grid>
                    <Grid theme={theme} item xs={12} sm={12} md={6} lg={6}>
                        <FileUploader>
                            {licenseData?.back_image &&
                                <CustomImageUploader>
                                    <Box theme={theme} className="img-wrap">
                                        <img src={licenseImage(licenseData?.back_image)} />
                                    </Box>
                                </CustomImageUploader>
                            }
                            <Typography
                                theme={theme}
                                className="text-muted"
                                variant="caption"
                                component={"small"}
                            >
                                {PLACEHOLDERS.DOCUMENTS_CARD_BACK_SIDE_DRIVER_LICENSE}
                            </Typography>
                        </FileUploader>
                    </Grid>
                </Grid>
            </Stack>
        </>
    )
}
