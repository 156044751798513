import { useTheme } from '@mui/material'
import {Dialog } from '@ntpkunity/controls'
import { Popup } from '@components'
import { FC, useContext, useEffect, useState } from 'react';
import {useGetCurrencies } from '@hooks'
import { AppContext } from '@app/context-provider';
import { IOrder } from '@interfaces';
import { loadDefaultCurrencyConfigurations } from '@app/context-provider'
import {PaymentBreakDownGridContent } from './payment-breakdown-control'
import { PaymentType } from 'constants/enums';


export const PaymentBreakDownPopup:FC<{order_info:IOrder, onCloseDialog:()=>void,  defaultCurrency?:string}> = ({onCloseDialog, order_info, defaultCurrency}) => {
    const theme = useTheme()
    const appState = useContext(AppContext);
    const PLACEHOLDERS = appState.state.language.placeholders
    const default_currency_symbol = appState.state.default_currency_symbol
    const [showPopup, setShowPopup] = useState(true);
    const handleCloseDialog = () => {
        setShowPopup(false);
        onCloseDialog();
    };
    return(
        <Popup>
            <Dialog
                theme={theme}
                size="sm"
                title={
                    order_info.finance_type === PaymentType.Lease
                      ?order_info?.contract_term + PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_LEASE_LABEL
                      : order_info.finance_type === PaymentType.Finance
                        ?order_info?.contract_term  + PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_FINANCE_LABEL
                        : PLACEHOLDERS.PAYMENT_BREAKDOWN_POPUP_TITLE}
                open={showPopup}
                onCloseDialog={handleCloseDialog}
                disablePortal
                noFooter
            >
                 <PaymentBreakDownGridContent
                    theme={theme}
                    order_info={order_info}
                    default_currency_symbol={default_currency_symbol || defaultCurrency}
                    sortedOptionsArray={order_info?.order_options?.sort((a, b) => a.installation_mode.localeCompare(b.installation_mode))}
                />
            </Dialog>
        </Popup>
    )
}
