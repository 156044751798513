import { styled } from '@mui/material/styles'
import { Box, IBoxProps } from '@ntpkunity/controls'

export const PageWrap = styled(
  Box,
  {}
)<Partial<IBoxProps>>(({ theme }) => ({
  '&.page-wrap': {
    '.stack-header': {
        display: 'flex',
        alignItems: 'center',
        gap: 24,
        '.header-text': {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            [theme.breakpoints.down('sm')]: {
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              gap: 24
            }
        },
        '.action-btn': {
          [theme.breakpoints.down('sm')]: {
            width: '100%'
          }
        },
        [theme.breakpoints.down('sm')]: {
          flexDirection: 'column'
        }
    },
    '&.dialog-wrap': {
      '.stack-wrap': {
        '.cj-input-wrap': {
          '.u-form-group': {
            '.u-form-control': {
              '.drop-icon': {
                    padding: 0,
                    marginLeft: 8

              }
            }
          }
        }
      },
      '.card-select':{
        '.u-select':{
            '.MuiInputBase-input':{
                display: 'flex',
                alignItems: 'center'
            },
            '.option-text':{
                flexGrow: 1
            },
            '.currency-icon':{
                flexShrink: 0,
                'svg':{
                    width:'auto',
                    maxHeight: 16
                }
            }
        }
      }
    }
  }
}))
