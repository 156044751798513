import { IFniProduct } from "@interfaces";
import { useTheme } from "@mui/material";
import { Grid } from "@ntpkunity/controls";
import React, { FC, useState } from "react";
import { FniProductDetailCard } from "./fni-product-detail-card";
import { useOrderContext } from 'pages'

export const FniProductCard: FC<{
  products: Array<IFniProduct>;
  rates: any;
}> = ({ products, rates }) => {
  const theme = useTheme();
  const { state } = useOrderContext()

  return (
    <>
      <Grid
        theme={theme}
        container
        item
        spacing={{ xs: 3, md: 2 }}
        alignItems={"stretch"}
      >
        {products?.map((item, index) => (
          <Grid theme={theme} item xs={12} lg={6} key={index}>
            <FniProductDetailCard fniProduct={item} rates={rates} selectedTerm={state?.order?.contract_term}/>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
